export interface PrescribingProviderAction {
    type: PrescribingProviderActionType;
    payload?: any; // Use specific type if possible
}


export enum PrescribingProviderActionType { 
    SET_PRESCRIBING_PROVIDERS_LIST = "SET_PRESCRIBING_PROVIDERS_LISTS_LIST",
    SET_PRESCRIBING_PROVIDER = "SET_PRESCRIBING_PROVIDER_FOR_EDIT",
    ADD_PRESCRIBING_PROVIDER = "ADD_PRESCRIBING_PROVIDER",
    SET_PRESCRIBING_PROVIDER_TO_DELETE = 'SET_PRESCRIBING_PROVIDER_TO_DELETE',
    CLOSE_EDIT_PRESCRIBING_PROVIDER_MODAL = 'CLOSE_EDIT_PRESCRIBING_PROVIDER_MODAL',
    CLOSE_ADD_PRESCRIBING_PROVIDER_MODAL = 'CLOSE_ADD_PRESCRIBING_PROVIDER_MODAL',
    SET_ADD_PRESCRIBING_PROVIDER_ERROR_MESSAGE = 'SET_ADD_PRESCRIBING_PROVIDER_ERROR_MESSAGE'
}
