import { GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Divider, Typography
} from "@mui/material";
import useApiQueryStaffDirectory from "../../../../../../../../api/hooks/service_provider/use_api_query_staff_directory";
import { DocumentationType } from "@xng/reporting";
import useUserManagementContext from "../../../../hooks/context/use_user_management_context";
import STAFF_DIRECTORY_LOCAL_STORAGE_KEYS from "../../constants/staff_directory_local_storage_keys";
import { useMemo, useRef, useState } from "react";
import { DualActionModal } from "../../../../../../../../design";
import GridSectionLayout from "../../../../../../../../design/high-level/common/grid_section_layout";
import XNGCheckboxLabel from "../../../../../../../../design/low-level/checkbox_label";
import useSelectedDistricts from "../use_selected_districts";
import usePalette from "../../../../../../../../hooks/usePalette";
import { API_CLIENTS, API_SERVICEPROVIDERS, API_USERS } from "../../../../../../../../api/api";
import produce from "immer";
import useFeedbackModal from "../../../../../../../../hooks/use_feedback_modal";
import useApiMutatePostAddUserToDistricts from "../../../../../../../../api/hooks/user/use_api_mutate_post_add_user_to_districts";
import useStaffDirectoryHomePageContext from "../context/use_staff_directory_home_page_context";
import { StaffDirectoryProfile, UserResponse } from "../../../../../../../../profile-sdk";
import { useXNGDispatch } from "../../../../../../../../context/store";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { setLoggedInClient } from "../../../../../../../../context/slices/loggedInClientSlice";
import { placeholderForFutureLogErrorText } from "../../../../../../../../temp/errorText";
import { cookieExists, setLoggedInUserCookie } from "../../../../../../../../utils/cookies";
import { setUserResponse } from "../../../../../../../../context/slices/userProfileSlice";
import { ROUTES_XLOGS } from "../../../../../../../../constants/URLs";
import { useNavigate } from "react-router-dom";
import QueryStatusModal from "../../../../../../../../design/modal_templates/query_status_modal";
import { UseMutationResult } from "@tanstack/react-query";
import { MSBDataGridPopoverMenu } from "../../../../../../../../fortitude";

type GridRowsColumnDefType = GridColDef<StaffDirectoryRecords[number]>;
export type StaffDirectoryRecords = NonNullable<
  NonNullable<ReturnType<typeof useApiQueryStaffDirectory>["data"]>["dataList"]
>;

export default function useStaffDirectoryTableColumnDefinition() {
  const isMsbAdmin = useUserManagementContext().store.userManagementData.user?.isMsbAdmin;
  const isExecutiveAdmin = useUserManagementContext().store.userManagementData.user?.isSuperAdmin;

  const columnDefDefaultProps: Partial<GridRowsColumnDefType> = {
    flex: 1,
    minWidth: 120,
    headerAlign: "left",
    valueGetter(params) {
      return params.value || emptyCellValue;
    },
    renderHeader(params) {
      return (
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          {getHeaderNameFromField(params.field)}
        </Typography>
      );
    },
  };

  const emptyCellValue = "N/A";

  const loginActivateColumnDef = (): GridRowsColumnDefType => {
    if (isMsbAdmin || isExecutiveAdmin || cookieExists("originalUserID")) {
      return {
        ...columnDefDefaultProps,
        field: "login",
        sortable: false,
        renderCell(params) {
          return <ActivateLoginCell row={params.row} />;
        },
      };
    }

    return {} as GridRowsColumnDefType;
  };

  const staffDirectoryColumnDefinitions: GridRowsColumnDefType[] = [
    loginActivateColumnDef(),
    {
      ...columnDefDefaultProps,
      field: "firstName",
      headerName: "First Name",
    },
    {
      ...columnDefDefaultProps,
      field: "lastName",
      headerName: "Last Name",
    },
    {
      ...columnDefDefaultProps,
      field: "email",
      headerName: "Email Address",
    },
    {
      ...columnDefDefaultProps,
      field: "docType",
      headerName: "Doc Type",
      valueGetter(params) {
        switch (params.value) {
          case DocumentationType.NUMBER_0:
            return "Paper";
          case DocumentationType.NUMBER_1:
            return "Import";
          case DocumentationType.NUMBER_2:
            return "X Logs";
          default:
            return "X Logs";
        }
      },
    },
    {
      ...columnDefDefaultProps,
      field: "serviceProviderTypes",
      headerName: "Service Provider Type(s)",
      minWidth: 200,
      sortable: false,
      renderCell(params) {
        return (
          <MSBDataGridPopoverMenu
            value={params.value || []}
            emtpyValue={emptyCellValue}
            getRenderValue={(value:any) => {
              return value?.name ?? emptyCellValue;
            }}
            getUniqueKey={(value) => value.id}
          />
        );
      },
    },
    {
      ...columnDefDefaultProps,
      field: "campusAssignments",
      headerName: "Campus(es)",
      minWidth: 200,
      sortable: false,
      renderCell(params) {
        return (
          <MSBDataGridPopoverMenu
            value={params.value || []}
            emtpyValue={emptyCellValue}
            getRenderValue={(value:any) => {
              return value?.name ?? emptyCellValue;
            }}
            getUniqueKey={(value) => value.id}
          />
        );
      },
    },
    {
      ...columnDefDefaultProps,
      field: "clientAssignmentStatus",
      headerName: "Xlogs Status",
      valueGetter(params) {
        const row = params.row;
        return row.hasAccess || row.clientAssignmentStatus === 0 ? "Active" : "Inactive";
      },
    },
    {
      ...columnDefDefaultProps,
      field: "employeeId",
      headerName: "Employee ID",
    },
  ];

  return { staffDirectoryColumnDefinitions };
}

function ActivateLoginCell(props: Readonly<{ row: StaffDirectoryRecords[number] }>) {
  const clientId = useUserManagementContext().store.userManagementData.client?.id;
  const stateInUs = useUserManagementContext().store.userManagementData.stateInUs;
  const [isProviderActivationModalOpen, setIsProviderActivationModalOpen] = useState(false);
  const appInsights = useAppInsightsContext();
  const adminUserId = useUserManagementContext().store.userManagementData.user?.id;
  const adminUserEmail = useUserManagementContext().store.userManagementData.user?.emailAddress;
  const dispatch = useXNGDispatch();
  const navigate = useNavigate();
  const { onSuccessfulSave, onFailedSave } = useFeedbackModal();
  const clientAssignments =
    useUserManagementContext().store.userManagementData.user?.clientAssignments;
  const palette = usePalette();

  const { refetch: directoryRefetch } = useStaffDirectoryHomePageContext().apiQueryStaffDirectory;
  const [showActivatingUserModal, setShowActivatingUserModal] = useState(false);
  const [activatingUserStatus, setActivatingUserStatus] =
    useState<UseMutationResult["status"]>("idle");
  const {
    selectedDistricts,
    setSelectedDistricts,
    selectedAllAuthorizedDistricts,
    deselectedDistricts,
    districtOptions,
  } = useSelectedDistricts(clientId ?? "", clientAssignments);
  const { mutateAsync: addUserToDistrictsMutate } = useApiMutatePostAddUserToDistricts({
    queryParams: {
      state: stateInUs,
    },
    options: {
      onSuccess() {
        directoryRefetch();
      },
    },
  });

  useMemo(() => {
    localStorage.setItem(STAFF_DIRECTORY_LOCAL_STORAGE_KEYS.client_id, clientId ?? ""); // initialize persistant storage for client id
  }, [clientId]);

  const homepageSavingText = useRef("saving homePage Info");
  const selectedProviderIdToActivate = useRef<string | undefined>(undefined);

  const onActivateUser = async () => {
    if (selectedDistricts.length === 0) return;
    homepageSavingText.current = "Activating User";
    setShowActivatingUserModal(true);

    try {
      setActivatingUserStatus("pending");

      const user = await API_USERS.v1UsersByServiceProviderGet(
        selectedProviderIdToActivate.current ?? "",
        clientId ?? "",
        stateInUs || "",
      );

      const serviceProvider = await API_SERVICEPROVIDERS.v1ServiceProvidersIdGet(
        selectedProviderIdToActivate.current ?? "",
        clientId ?? "",
        stateInUs,
      );

      const serviceProviderRequestBody = produce(serviceProvider, (draft) => {
        draft.districtsOfOperation = selectedDistricts;
        draft.clientAssignmentStatus = 0;
      });

      await API_SERVICEPROVIDERS.v1ServiceProvidersIdPatch(
        selectedProviderIdToActivate.current ?? "",
        clientId!,
        stateInUs,
        { profile: serviceProviderRequestBody },
      );

      await addUserToDistrictsMutate({
        clientId: clientId ?? "",
        userId: user.id ?? "",
        districts: selectedDistricts,
      });
      setActivatingUserStatus("success");
    } catch (e) {
      setActivatingUserStatus("error");
      // console.log(e);
    }
  };
  const onActivateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: StaffDirectoryProfile,
  ) => {
    e.stopPropagation();
    selectedProviderIdToActivate.current = row?.id;
    deselectedDistricts.current = [];
    setSelectedDistricts([...districtOptions]);
    setIsProviderActivationModalOpen(true);
  };

  const trackLoginEvent = useTrackEvent(appInsights, `StaffDirectoryLogin`, {
    actingUser: {
      userId: "",
      email: "",
    },
    targetUser: {
      firstName: "",
      lastName: "",
      userId: "",
    },
    localTime: "",
  });

  async function handleLoginEvent(user: UserResponse, providerID: string, clientID: string) {
    trackLoginEvent({
      actingUser: {
        userId: adminUserId!,
        email: adminUserEmail!,
      },
      targetUser: {
        firstName: user.firstName!,
        lastName: user.lastName!,
        userId: user.id!,
      },
      localTime: "",
    });
    setLoggedInUserCookie(user.id!, adminUserId!, providerID, clientID);
    dispatch(setUserResponse(user));

    function refreshScreen() {
      navigate(ROUTES_XLOGS.calendar);
    }

    if (user.clientAssignments !== null) {
      if (user.clientAssignments!.length > 0) {
        // set to last logged in or zeroth by default
        const authorizedClientIDs =
          user.clientAssignments
            ?.filter((ca) => (ca.authorizedDistricts?.length ?? 0) > 0)
            .map((ca) => ca.client!.id) ?? [];
        const fallbackClientID = authorizedClientIDs[0] ?? user.clientAssignments![0].client!.id;
        const loggedInClientID = user.loggedInClientId ?? fallbackClientID;
        if (!loggedInClientID) throw new Error(placeholderForFutureLogErrorText);
        if (loggedInClientID === undefined) throw new Error(placeholderForFutureLogErrorText);

        try {
          const loggedInClient = await API_CLIENTS.v1ClientsIdGet(loggedInClientID, stateInUs);
          dispatch(setLoggedInClient(loggedInClient));
          refreshScreen();
          onSuccessfulSave(
            `Successfully logged in as ${user.firstName} ${user.lastName}`,
            refreshScreen,
          );
        } catch (err) {
          onFailedSave(`Failed to login as ${user.firstName} ${user.lastName}`);
        }
      }
    }
  }

  const onLoginButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: StaffDirectoryProfile,
  ) => {
    e.stopPropagation();
    try {
      const user = await API_USERS.v1UsersByServiceProviderGet(row.id!, clientId!, stateInUs);
      handleLoginEvent(user, row.id!, clientId!);
    } catch (err) {
      onFailedSave(`Failed to login as ${row.firstName} ${row.lastName}`);
    }
  };
  return (
    <>
      {props.row.hasAccess || props.row.clientAssignmentStatus === 0 ? (
        <Button
          sx={{ whiteSpace: "nowrap" }}
          // disabled={row.districtStatus}
          onClick={(e) => onLoginButtonClick(e, props.row)}
        >
          Login
        </Button>
      ) : (
        <Button
          sx={{ whiteSpace: "nowrap" }}
          // disabled={row.districtStatus}
          onClick={(e) => onActivateButtonClick(e, props.row)}
        >
          Activate
        </Button>
      )}
      <DualActionModal
        open={isProviderActivationModalOpen}
        injectContent={{
          // icon: getConfirmationModalIcon(),
          header: "Select Districts to Add Provider To",
          body: (() => (
            <>
              <GridSectionLayout
                headerConfig={{
                  titleOverride: <></>,
                  headerContent: (
                    <Box display={"flex"}>
                      {districtOptions?.length > 0 && (
                        <XNGCheckboxLabel
                          label={"Select All Districts"}
                          size="small"
                          checked={selectedAllAuthorizedDistricts}
                          onChange={(e) => {
                            if (e.target.checked) {
                              deselectedDistricts.current = [];
                              setSelectedDistricts([...districtOptions]);
                            } else {
                              deselectedDistricts.current =
                                districtOptions?.map((district) => district.name ?? "") || [];
                              setSelectedDistricts([]);
                            }
                          }} // checked={false}
                        />
                      )}
                      <Divider light />
                    </Box>
                  ),
                }}
                maxHeight={"150px"}
                fullWidth={true}
                rows={[
                  {
                    fullwidth: true,
                    useCellStyling: {
                      sx: {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      },
                    },
                    cells: districtOptions.map((district) => (
                      <XNGCheckboxLabel
                        key={district.id}
                        whiteSpace="unset"
                        label={district.name}
                        size="small"
                        checked={selectedDistricts.some(
                          (selected_district) => selected_district.id === district.id,
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            deselectedDistricts.current = deselectedDistricts.current?.filter(
                              (d) => d !== district.name,
                            );
                            setSelectedDistricts((prev) => [...prev, district]);
                          } else {
                            if (district?.name)
                              deselectedDistricts.current = [
                                ...(deselectedDistricts.current || []),
                                district.name,
                              ];
                            setSelectedDistricts((prev) =>
                              prev.filter(
                                (selected_district) => selected_district.id !== district.id,
                              ),
                            );
                          }
                        }} // checked={false}
                      />
                    )),
                  },
                ]}
              />
              <Typography>
                By clicking Save, you will activate this service provider in these districts.
              </Typography>
            </>
          ))(),
          noText: "Cancel",
          yesText: "Save",
          buttonStyles: {
            yesButton: {
              p: 2,
              width: "102px",
              borderColor: palette.primary[2],
              bgcolor: palette.primary[2],
              "&:hover": {
                color: "white",
                borderColor: palette.primary[1],
                bgcolor: palette.primary[1],
              },
            },
            noButton: {
              p: 2,
              width: "102px",
              backgroundColor: "transparent",
              color: "primary.main",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "primary.main",
              "&:hover": {
                color: palette.danger[1],
                borderColor: "transparent",
                backgroundColor: "transparent",
                outline: "solid 2px",
                outlineColor: palette.danger[1],
                // bgcolor: "primary.main",
                // borderWidth: "3px",
              },
            },
          },
        }}
        onClose={() => setIsProviderActivationModalOpen(false)}
        onConfirm={() => {
          onActivateUser();
          setIsProviderActivationModalOpen(false);
        }}
        onReject={() => setIsProviderActivationModalOpen(false)}
      />
      <QueryStatusModal
        isOpen={showActivatingUserModal}
        status={activatingUserStatus}
        content={{
          errorTitle: "Error",
          errorBody: "Failed to activate user",
          pendingTitle: "Activating User",
          successTitle: "Success",
          successBody: "User has been activated successfully",
        }}
        onSettledClose={() => {
          setActivatingUserStatus("idle");
          setShowActivatingUserModal(false);
        }}
      />
    </>
  );
}

function getHeaderNameFromField(field: string): string {
  switch (field) {
    case "firstName":
      return "First Name";
    case "lastName":
      return "Last Name";
    case "email":
      return "Email Address";
    case "docType":
      return "Doc Type";
    case "serviceProviderTypes":
      return "Service Provider Type(s)";
    case "campusAssignments":
      return "Campus(es)";
    case "clientAssignmentStatus":
      return "Xlogs Status";
    case "employeeId":
      return "Employee ID";
    case "login":
      return "Activate / Login as User";
    default:
      return "N/A";
  }
}