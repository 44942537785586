import { InstanceReportUserTableRow } from '../components/tables/instance_report_table'
import { StudentInstanceRecord } from '@xng/reporting'

const useGenerateInstanceReportTableRows = (data:StudentInstanceRecord[] ):InstanceReportUserTableRow [] => {
    function generateRows(data:StudentInstanceRecord,idx:number):InstanceReportUserTableRow{
        return {
            id: data?.medId + `---${idx}`,
            firstName: data?.firstName ?? "",
            lastName: data?.lastName ?? "",
            birthDate: data?.dob?.toDateString() ?? "",
            localId: data?.localId ?? "",
            medicaidId: data?.medId ?? "",
            unpostedSessionCount: data?.unpostedCount ?? 0,
            postedSessionCount: data?.postedCount ?? 0,
            providerNames: data?.serviceProviders ?? "",
            serviceArea: data?.serviceAreaName ?? ""
        }
    }
    return data?.map((item,idx)=>generateRows(item,idx)) ?? []
}

export default useGenerateInstanceReportTableRows