import useStudentProfileContext from "../use_student_profile_context";
import produce from "immer";

type Props = {
  studentProfileForm: ReturnType<typeof useStudentProfileContext>["studentProfileForm"];
};

export default function useSaveGoalFormField(props: Props) {
  const {
    studentProfileForm: { getValues, resetField, setValue, trigger },
  } = props;

  async function isSaved() {
    const isValid = await trigger("goalsAndObjectives.goalForm");

    if (isValid) {
      const goalForm = getValues("goalsAndObjectives.goalForm");
      const goalsArrayFormValue = getValues("goalsAndObjectives.goals");
      const updatedGoalsArray = produce(goalsArrayFormValue ?? [], (draft) => {
        /* perform transformation on goalForm to remove empty added objective object to end of objectives array
                     this is necessary to ensure we aren't adding empty objectives and messing up data as we don't have a way to delete objectives 
                     and the implementation creates an edge case where a provider can add a pointless objective object to their data
                  */
        if (
          goalForm?.objectives &&
          goalForm?.objectives?.length > 0 &&
          !goalForm?.objectives?.at(-1)?.description?.trim() &&
          !goalForm?.objectives?.at(-1)?.number?.trim()
        ) {
          goalForm.objectives.pop();
        }

        const goalIndex = draft.findIndex((goal) => goal.internalId === goalForm?.internalId);

        if (goalIndex !== -1) {
          draft[goalIndex] = {
            ...draft[goalIndex],
            ...goalForm,
          };
        } else if (goalForm) {
          draft.push(goalForm);
        }
      });

      setValue("goalsAndObjectives.goals", updatedGoalsArray, {
        shouldDirty: true,
      });
      resetField("goalsAndObjectives.goalForm", {
        defaultValue: null,
      });
      setValue("goalsAndObjectives.goalForm", goalForm);
    }

    return isValid;
  }

  return { isSaved };
}
