import { Box, Checkbox, FormControlLabel, IconButton, Tooltip } from "@mui/material";
import { CgClose } from "react-icons/cg";
import { MSBIconRenderer } from "../../../../../fortitude";

export type CheckboxOptionProps = {
  label: string;
  checked: boolean;
  onClick: () => void;
  useClose?: { onClose: () => void; tooltip?: string };
  disabled?: boolean;
};

/**
 * Presentational component designed to potentially reuse on other features, currently only serves observations view of notator
 */
function CheckboxOption(props: Readonly<CheckboxOptionProps>) {
  const { label, checked, onClick, useClose } = props;

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        ".close-button": { display: "none" },
        ":hover": { ".close-button": { display: "block" } },
      }}
    >
      <FormControlLabel
        sx={{ width: "100%", mr: 0 }}
        control={<Checkbox onClick={onClick} />}
        label={label}
        checked={checked}
        disabled={props.disabled}
        className="noselect" // Prevents text-highlighting for checkbox clicks in quick succession
      />
      {props.useClose && !props.disabled && (
        <Tooltip title={useClose?.tooltip} placement="top" arrow disableInteractive>
          <IconButton
            onClick={() => useClose?.onClose()}
            className="close-button"
            sx={{ width: "2.375rem", height: "2.375rem" }}
          >
            <MSBIconRenderer i={<CgClose />} size="md" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

export default CheckboxOption;
