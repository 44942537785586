import { Outlet } from "react-router"
import ReportsLayout from "../../../reports_layout"

 
const InstanceReportLayout = () => {
  return (
    <ReportsLayout title="Instance Report" gutterTop>
      <Outlet />
    </ReportsLayout>
  )
}

export default InstanceReportLayout