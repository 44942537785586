import { Box } from "@mui/material";
import useStudentProfileContext from "./hooks/use_student_profile_context";
import StudentProfilePageHeader from "./components/student_profile_page_header";
import StudentProfileTab from "./components/student_profile_tab";
import useUserRoles from "../../../../hooks/use_user_roles";

export default function StudentProfilePage() {
  const {
    queryStudentProfileApiClient: { data: studentProfileData },
    mutateStudentProfileApiClient: { isPending: savingStudentProfile },
    studentProfileForm: {
      handleSubmit,
      watch,
      formState: { isDirty },
    },
    handleFormSubmit,
    navigation: { handleBackBtnClick, selectedTab, handleSetSelectedTab },
  } = useStudentProfileContext();

  const roles = useUserRoles();

  const goalFormId = watch("goalsAndObjectives.goalForm")?.internalId;

  return (
    <Box
      sx={{
        px: "20px",
        position: "relative",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        pb: 2,
        gap: 5,
      }}
      component={"form"}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <StudentProfilePageHeader
        studentProfileData={{
          firstName: studentProfileData?.firstName ?? "",
          middleName: studentProfileData?.middleName ?? "",
          lastName: studentProfileData?.lastName ?? "",
          status: studentProfileData?.xLogsStatus ?? 5,
        }}
        /* 
          Explanation for the '!!goalFormId && selectedTab.tabLabel === "Goals/Obj Info")' condition:
          - The goalFormId is used to determine if the user is currently editing a goal or objective, because we generate a new goalFormId upon navigation to the editGoalObjView.
          - The selectedTab.tabLabel is used to determine if the user is currently on the Goals/Obj Info tab, because we want to disable the save button when the user is in the Goals/Obj Info tab and editing a goal.
          - Therefore, if the user is editing a goal or objective (goalFormId is truthy) and on the Goals/Obj Info tab, we want to hide the save button.
        */
        saveBtn={{
          disable: !isDirty || savingStudentProfile,
          hide: !!goalFormId && selectedTab.tabLabel === "Goals/Obj Info",
          loading: savingStudentProfile,
        }}
        onBackBtnClick={() => {
          handleBackBtnClick();
        }}
      />
      <StudentProfileTab
        roles={roles}
        selectedTab={selectedTab}
        setSelectedTab={handleSetSelectedTab}
        disabled={savingStudentProfile}
      />
    </Box>
  );
}
