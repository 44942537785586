import { Button, ButtonProps } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

type Props = {
  onClick: ButtonProps["onClick"];
};

export default function StudentProfileBackBtn(props: Readonly<Props>) {
  return (
    <Button
      startIcon={<ArrowBackIosIcon fontSize="small" sx={{ m: 0 }} />}
      sx={{
        ".MuiButton-startIcon": {
          mr: 0,
        },
        borderRadius: 0,
      }}
      variant="text"
      onClick={props.onClick}
    >
      Back
    </Button>
  );
}
