import { Box, Divider, Skeleton, SkeletonProps, Tab, Tabs } from "@mui/material";
import React from "react";
import StudentProfileBackBtn from "./student_profile_back_btn";
import studentProfileTabsItems from "../constants/student_profile_tabs_items";
import InputFieldGrid from "./input_field_grid";

type Props = {
  onBackBtnClick: () => void;
};

export default function StudentProfileSkeleton(props: Readonly<Props>) {
  return (
    <Box
      sx={{
        px: "20px",
        position: "relative",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        pb: 2,
        gap: 5,
      }}
    >
      {/* HEADER */}
      <Box>
        <Box sx={{ p: 1 }}>
          <StudentProfileBackBtn onClick={props.onBackBtnClick} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Skeleton width={300} height={60} />
          <Skeleton variant="rectangular" width={90} height={40} />
        </Box>

        <Box>
          <Skeleton width={130} height={40} />
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Skeleton variant="circular" width={15} height={15} />
            <Skeleton width={60} height={40} />
          </Box>
        </Box>
      </Box>

      {/* TABS*/}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            aria-label="student profile tabs"
            scrollButtons="auto"
            variant="scrollable"
            value={0}
          >
            {studentProfileTabsItems.map((tab) => {
              return (
                <Tab
                  key={tab.label}
                  label={tab.label}
                  sx={{ textTransform: "capitalize", paddingLeft: 0 }}
                />
              );
            })}
          </Tabs>
        </Box>
        <Box sx={{ overflowY: "auto", height: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5, py: 2 }}>
            <Box>
              <HeaderSkeleton />
              <Box>
                <InputFieldGrid
                  items={[
                    {
                      key: "firstName",
                      content: <InputFieldSkeleton />,
                    },
                    {
                      key: "middleName",
                      content: <InputFieldSkeleton />,
                    },
                    {
                      key: "lastName",
                      content: <InputFieldSkeleton />,
                    },
                    {
                      key: "grade",
                      content: <InputFieldSkeleton />,
                    },
                    {
                      key: "dateOfBirth",
                      content: <InputFieldSkeleton />,
                    },
                    {
                      key: "gender",
                      content: <InputFieldSkeleton />,
                    },
                    {
                      key: "primaryLanguage",
                      content: <InputFieldSkeleton />,
                    },
                  ]}
                />
              </Box>
            </Box>
            <Divider />
            <Box>
              <HeaderSkeleton />
              <Box>
                <InputFieldGrid
                  items={[
                    {
                      key: "studentIdGivenBySchoolDistrict",
                      content: <InputFieldSkeleton />,
                    },
                    {
                      key: "medicaidId",
                      content: <InputFieldSkeleton />,
                    },
                    {
                      key: "studentIdGivenByState",
                      content: <InputFieldSkeleton />,
                    },
                  ]}
                />
              </Box>
            </Box>
            <Divider />
            <Box>
              <HeaderSkeleton />
              <Box>
                <InputFieldGrid
                  items={[
                    {
                      key: "schoolCampus",
                      content: <InputFieldSkeleton />,
                    },
                    {
                      key: "districtOfLiability",
                      content: <InputFieldSkeleton />,
                    },
                  ]}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const InputFieldSkeleton = React.forwardRef<HTMLSpanElement, SkeletonProps>((props, ref) => {
  return <Skeleton ref={ref} width={"100%"} height={40} variant="rectangular" {...props} />;
});
const HeaderSkeleton = React.forwardRef<HTMLSpanElement, SkeletonProps>((props, ref) => {
  return (
    <Skeleton ref={ref} variant="rectangular" width={300} height={40} sx={{ mb: 2 }} {...props} />
  );
});
