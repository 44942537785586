import useApiQueryData from "../use_api_query_data";
import { API_STATESNAPSHOTS } from "../../api";
import XNGApiQueryParamObject from "../../../types/xng_api_query_param_object";
import { useXNGSelector } from "../../../context/store";
import { selectClientID } from "../../../context/slices/loggedInClientSlice";

type Data = Awaited<ReturnType<typeof API_STATESNAPSHOTS.v1StateSnapshotsByDateServiceAreasGet>>;
type QueryParams = {
  state: string;
  date?: Date;
  filterForMedicaidInfoOnly?: boolean;
};

const useApiQueryGetServiceAreasByDate = (
  paramObject: XNGApiQueryParamObject<QueryParams, Data>,
) => {
  const { state, date, filterForMedicaidInfoOnly } = paramObject.queryParams;
  const clientID = useXNGSelector(selectClientID);

  return useApiQueryData({
    queryFn: async () =>
      await API_STATESNAPSHOTS.v1StateSnapshotsByDateServiceAreasGet(
        state,
        clientID,
        date,
        filterForMedicaidInfoOnly,
      ),
    queryKey: ["v1StateSnapshotsByDateServiceAreasGet", clientID, state, date, filterForMedicaidInfoOnly],
    staleTime: 1000 * 60 * 60, // 1 hours
    ...(paramObject.options ?? {}),
  });
};

export default useApiQueryGetServiceAreasByDate;
