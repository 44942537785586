import useStudentProfileContext from "../hooks/use_student_profile_context";
import StudentProfileSkeleton from "./student_profile_skeleton";
import StudentProfilePage from "../student_profile_page";

export default function StudentProfileSuspenseBoundary() {
  const {
    queryStudentProfileApiClient: { isLoading: queryStudentProfileIsLoading },
    navigation: { handleBackBtnClick },
  } = useStudentProfileContext();

  return (
    <>
      {queryStudentProfileIsLoading ? (
        <StudentProfileSkeleton
          onBackBtnClick={() => {
            handleBackBtnClick({
              ignoreIsDirty: true,
            });
          }}
        />
      ) : (
        <StudentProfilePage />
      )}
    </>
  );
}
