import { Box, Modal, Typography } from "@mui/material";
import PrescribingProviderModalRoot from "../ModalRoot/PrescribingProviderModalRoot"; 
import React, { useEffect, useMemo, useState } from "react";
import { API_PRESCRIBING_PROVIDER } from "../../../../../../../api/api";
import useUserManagementContext from "../../../../user_management/hooks/context/use_user_management_context";
import usePrescribingProvider from "../../../hooks/usePrescribingProvider";
import { GetPrescribingProvidersResponse } from "../../../../../../../profile-sdk";
import { PrescribingProviderActionType } from "../../../context/prescribing_provider_actions";
import FullPageLoadingScreen from "../../../../../../../design/high-level/common/full_page_loading_screen";
import { commonModalStyles } from "../common_styles"; 
import { providerExists } from "../../../utils/util";

enum InputNames {
  PROVIDER_FIRST_NAME = "firstName",
  PROVIDER_LAST_NAME = "lastName",
  NPI = "npi",
  MEDICAID = "stateMedicaidNumber",
} 

const Add: React.FC<{ isOpened: boolean; onClose: () => void }> = ({ isOpened, onClose }) => {
  const [providerInformation, setProviderInformation] = React.useState({
    [InputNames.PROVIDER_FIRST_NAME]: "",
    [InputNames.NPI]: "",
    [InputNames.MEDICAID]: "",
    [InputNames.PROVIDER_LAST_NAME]: "",
  });
  const [error, setError] = useState<{
    firstName: string;
    lastName: string;
    npi: string;
    stateMedicaidNumber: string;
  }>({
    firstName: "",
    lastName: "",
    npi: "",
    stateMedicaidNumber: "",
  });
  const state_in_us = useUserManagementContext().store.userManagementData.stateInUs;
  const client = useUserManagementContext().store.userManagementData.client;
  const {
    dispatch,
    state: { data },
  } = usePrescribingProvider();
  const [isLoading, setIsLoading] = useState(false);

  const prescribingProviders = useMemo(() => {
    return data.map((provider) => {
      return {
        id: provider.id as string,
        name: `${provider.firstName} ${provider.lastName}`,
        npi: provider.npi as string,
        stateMedicaidNumber: provider.stateMedicaidNumber as string,
      };
    });
  }, [data]);
  
  
  

  const parseProviderString = (input: string) => {
    const result: { [key: string]: string } = {};
    const regex = /FirstName:(.*?),LastName:(.*?),NPI:(.*)/i;
    const matches = input.match(regex);

    if (matches) {
      result.firstName = matches[1];
      result.lastName = matches[2];
      result.npi = matches[3];
    }

    return `Prescribing Provider ${result.firstName} ${result.lastName} with NPI ${result.npi} already exists.`;
  };

  useEffect(() => {
    if (error.firstName && providerInformation?.firstName) {
      setError({ ...error, firstName: "" });
    }
    if (error.lastName && providerInformation?.lastName) {
      setError({ ...error, lastName: "" });
    }
    if (error.npi && providerInformation?.npi) {
      setError({ ...error, npi: "" });
    }
    if (error.stateMedicaidNumber && providerInformation?.stateMedicaidNumber) {
      setError({ ...error, stateMedicaidNumber: "" });
    }
  }, [providerInformation]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProviderInformation({ ...providerInformation, [e.target.name]: e.target.value.trim() });
  };

  const createNPIProvider = async () => {
    try {
      if (!client) {
        throw new Error("Client not found");
      }
      setIsLoading(true);
      const prescribingProviderRequest = {
        ...providerInformation,
        client: {
          id: client.id,
          name: client.name,
          startDate: client.startDate,
          endDate: client.endDate,
        },
      };

      if (providerExists(providerInformation, prescribingProviders)) {
        dispatch({
          type: PrescribingProviderActionType.SET_ADD_PRESCRIBING_PROVIDER_ERROR_MESSAGE,
          payload: `Prescribing Provider ${prescribingProviderRequest.firstName} ${prescribingProviderRequest.lastName} with NPI ${prescribingProviderRequest.npi} already exists.`,
        });
        return;
      }

      const response = await API_PRESCRIBING_PROVIDER.v1PrescribingProvidersPost(
        state_in_us,
        prescribingProviderRequest,
      );
      // update prescribing providers list
      if (response) {
        const response: GetPrescribingProvidersResponse =
          await API_PRESCRIBING_PROVIDER.v1PrescribingProvidersGet(
            client.id as string,
            state_in_us,
          );
        dispatch({
          type: PrescribingProviderActionType.SET_PRESCRIBING_PROVIDERS_LIST,
          payload: response.prescribingProviders,
        });
        setProviderInformation({
          [InputNames.PROVIDER_FIRST_NAME]: "",
          [InputNames.NPI]: "",
          [InputNames.MEDICAID]: "",
          [InputNames.PROVIDER_LAST_NAME]: "",
        });
        onClose();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);

      if (error && typeof error === "object" && "status" in error && error.status === 409) {
        // @ts-ignore
        const errorResponse = await error.text();

        if (errorResponse) {
          dispatch({
            type: PrescribingProviderActionType.SET_ADD_PRESCRIBING_PROVIDER_ERROR_MESSAGE,
            payload: parseProviderString(errorResponse),
          });
        }
      }
    }
  };

  const handleClose = () => {
    setProviderInformation({
      [InputNames.PROVIDER_FIRST_NAME]: "",
      [InputNames.NPI]: "",
      [InputNames.MEDICAID]: "",
      [InputNames.PROVIDER_LAST_NAME]: "",
    });
    onClose();
  };

  const onSave = () => {
    let isEmpty = false;
    for (const [key, value] of Object.entries(providerInformation)) {
      if (key === InputNames.MEDICAID) {
        continue;
      }
      if (value === "") {
        isEmpty = true;
        setError((prev) => ({ ...prev, [key]: "Required" }));
      }
    }
    if (isEmpty) return;
    // console.log(providerInformation)
    createNPIProvider();
  };

  return (
    <>
      {!isLoading ? (
        <Modal
          open={isOpened}
          onClose={onClose}
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <PrescribingProviderModalRoot>
            <PrescribingProviderModalRoot.Title>
              <Typography sx={{ fontSize: "18px", fontWeight: "500", color: "black" }}>
                Add Provider Information
              </Typography>
            </PrescribingProviderModalRoot.Title>
            <Box display={"flex"} gap={"20px"} flexDirection={"row"}>
              <PrescribingProviderModalRoot.InputMember
                name={InputNames.PROVIDER_FIRST_NAME}
                value={providerInformation[InputNames.PROVIDER_FIRST_NAME]}
                onChange={handleChange}
                style={commonModalStyles.providerName}
                label="Provider First Name"
                placeHolder="Type First Name"
                error={error?.firstName}
              />
              <PrescribingProviderModalRoot.InputMember
                name={InputNames.PROVIDER_LAST_NAME}
                value={providerInformation[InputNames.PROVIDER_LAST_NAME]}
                onChange={handleChange}
                style={commonModalStyles.providerName}
                label="Provider Last Name"
                placeHolder="Type Last Name"
                error={error?.lastName}
              />
            </Box>
            <Box>
              <Box display={"flex"} mt={4} flexDirection={"row"} gap={"20px"}>
                <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={"20px"}>
                  <PrescribingProviderModalRoot.InputMember
                    name={InputNames.NPI}
                    value={providerInformation[InputNames.NPI]}
                    onChange={handleChange}
                    label="NPI"
                    placeHolder="Type NPI Number"
                    error={error?.npi}
                  />
                </Box>
                <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={"20px"}>
                  <PrescribingProviderModalRoot.InputMember
                    name={InputNames.MEDICAID}
                    value={providerInformation[InputNames.MEDICAID]}
                    onChange={handleChange}
                    label="State Medicaid Number"
                    placeHolder="Type Medicaid Number"
                    error={error?.stateMedicaidNumber}
                  />
                </Box>
              </Box>
              <Box
                mt={3}
                display={"flex"}
                width={"100%"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={"20px"}
              >
                <PrescribingProviderModalRoot.ButtonOutlined
                  sx={{
                    flex: 1,
                    minWidth: 0,
                    overflow: "hidden",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </PrescribingProviderModalRoot.ButtonOutlined>
                <PrescribingProviderModalRoot.ButtonNormal
                  sx={{
                    flex: 1,
                    minWidth: 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={onSave}
                >
                  Save
                </PrescribingProviderModalRoot.ButtonNormal>
              </Box>
            </Box>
          </PrescribingProviderModalRoot>
        </Modal>
      ) : (
        <FullPageLoadingScreen text="creating prescribing provider" />
      )}
    </>
  );
};

export default Add;
