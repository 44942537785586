import { Box, Stack } from "@mui/system";
import XNGButton from "../../../../../../../../design/low-level/button";
import UserApprovalFilter from "../../components/toolbar/user_approval_filter";
import Typography from "@mui/material/Typography";
import UserApprovalsTabsEnum from "../../types/user_approvals_tabs_enum";
import {
  RespondToManyRequestsForDistrictAccessRequest,
  RespondToRequestForDistrictAccessRequest,
} from "../../../../../../../../profile-sdk";
import { Button, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { MSBICONS } from "../../../../../../../../fortitude";
import UsersTabState, { UsersTabAction } from "../../types/users_tabs_state";
import UsersHistoryTabState, { UsersHistoryTabAction } from "../../types/users_history_tabs_state";
import useUserManagementContext from "../../../../hooks/context/use_user_management_context";
import { ConstructRoleAssignmentsFromXLogsRoleEnumOrString } from "../../../../../../../../utils/xlogs_role_mapper";
import useUserApprovalsContext from "../../hooks/use_user_approvals_context";
import QueryStatusModal from "../../../../../../../../design/modal_templates/query_status_modal";
import { useState } from "react";
import ApprovalsModalListItemsContent, {
  ApprovalsModalListItemsType,
} from "../approvals_modal_list_items_content";

type Props = {
  currentTabData: UsersTabState | UsersHistoryTabState;
  currentTabDispatch: React.Dispatch<UsersTabAction> | React.Dispatch<UsersHistoryTabAction>;
};

const UserApprovalsToolBar = (props: Props) => {
  const adminUser = useUserManagementContext().store.userManagementData.user;
  const selectedTab = useUserApprovalsContext().selectedTabIndex;
  const {
    data: cardData,
    refetch: refetchUserApprovalsCards,
    isPending: usersLoading,
    isFetching: isFetchingUsers,
  } = useUserApprovalsContext().queryUserManagementCardsApiClientHandler;
  const hasHitBreakpoint = useMediaQuery("(width <= 736px)");
  const isUserTab = selectedTab !== UserApprovalsTabsEnum.approval_denial_history;
  const [openValidationModal, setOpenValidationModal] = useState(false);
  const [openApprovalQueryStatusModal, setOpenApprovalQueryStatusModal] = useState<boolean>(false);
  const [openDenialQueryStatusModal, setOpenDenialQueryStatusModal] = useState<boolean>(false);
  const [inValidUsers, setInValidUsers] = useState<ApprovalsModalListItemsType>(new Map());
  const [approvedOrDeniedUsers, setApprovedOrDeniedUsers] = useState<ApprovalsModalListItemsType>(
    new Map(),
  );
  const {
    mutate: makeUsersRequestingAccessPatchRequest,
    isPending: isUsersRequestingAccessPatchRequestPending,
    status: patchUsersRequestingAccessToDistrictStatus,
  } = useUserApprovalsContext().patchUsersRequestingAccessToDistrictApiClientHandler;
  const { Grid4X4, ThreeHorizontalLines, Refresh, LoadingAnimation } = MSBICONS;

  const disableButtons =
    usersLoading ||
    isUsersRequestingAccessPatchRequestPending ||
    ((props.currentTabData as UsersTabState)?.selectedUsers &&
      (props.currentTabData as UsersTabState).selectedUsers.length === 0);

  function onApprovalOrDenialButtonClick(responseType: number) {
    const tab = props.currentTabData as UsersTabState;
    const selectedUserCards = tab.selectedUsers.map((userId) => tab.usersOptions.get(userId)!);
    let isValid = true;
    let invalidUsers: ApprovalsModalListItemsType = new Map();
    let approvedOrDeniedUsersList: ApprovalsModalListItemsType = new Map();

    const respondToManyRequestBody: RespondToManyRequestsForDistrictAccessRequest = {
      responsesToRequestsForDistrictAccess: selectedUserCards
        .map((card) => {
          const cardId = card.user?.id!;
          const selectedUserdistricts = tab.selectedDistricts.get(cardId)!;
          const userName = `${card.user?.firstName} ${card.user?.lastName}`;
          approvedOrDeniedUsersList.set(cardId, {
            id: card.user?.id!,
            itemName: userName,
          });
          let responses: RespondToRequestForDistrictAccessRequest[] = [];
          if (selectedUserdistricts.length === 0) {
            invalidUsers.set(cardId, {
              id: card.user?.id!,
              itemName: userName,
            });
            isValid = false;
          }
          selectedUserdistricts.forEach((districtRef) => {
            const spTypes = tab.selectedServiceProviderTypes.get(cardId)!;
            if (spTypes.length === 0) {
              if (!invalidUsers.has(cardId)) {
                invalidUsers.set(cardId, {
                  id: cardId,
                  itemName: userName,
                });
              }
              isValid = false;
            }
            responses.push({
              notificationId: districtRef.notificationId,
              responseType: responseType,
              message: `You have been ${
                responseType === 0 ? "granted" : "denied"
              } access to ${districtRef.district?.name}.`,
              clientId: card.client?.id,
              districtId: districtRef.district?.id,
              respondingUser: adminUser || {},
              roleAssignments: ConstructRoleAssignmentsFromXLogsRoleEnumOrString(
                tab.selectedXlogsRole.get(cardId)!,
              ),
              serviceProviderTypesAssignments: spTypes,
            });
          });
          return responses;
        })
        .flat(),
    };

    if (!isValid && responseType === 0) {
      setOpenValidationModal(true);
      setInValidUsers(invalidUsers);
      return;
    }
    setApprovedOrDeniedUsers(approvedOrDeniedUsersList);
    makeUsersRequestingAccessPatchRequest(respondToManyRequestBody, {
      onSuccess(_data, _variables) {
        refetchUserApprovalsCards();
      },
    });

    if (responseType === 0) {
      setOpenApprovalQueryStatusModal(true);
    } else {
      setOpenDenialQueryStatusModal(true);
    }
  }

  return (
    <>
      <Box mb={2}>
        <Typography
          variant="body1"
          fontWeight={700}
          display={selectedTab === UserApprovalsTabsEnum.approval_denial_history ? "block" : "none"}
        >
          To see approval/denial history, click on the user card to view dates.
        </Typography>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            flexDirection: hasHitBreakpoint ? "column" : "row",
          }}
        >
          <UserApprovalFilter
            selectedTab={selectedTab}
            tabData={props.currentTabData}
            tabDispatch={props.currentTabDispatch}
            disableFilter={usersLoading}
          />
          <Stack
            gap={1}
            direction={"row"}
            alignItems={"center"}
            sx={{
              display:
                selectedTab === UserApprovalsTabsEnum.approval_denial_history ? "none" : "flex",
            }}
          >
            <Tooltip title="Refresh" placement="top">
              <IconButton
                disabled={usersLoading || isFetchingUsers}
                onClick={() => refetchUserApprovalsCards()}
                sx={{
                  opacity: usersLoading ? 0.5 : 1,
                  svg: {
                    width: "16px",
                    height: "16px",
                  },
                }}
              >
                {isFetchingUsers ? <LoadingAnimation /> : <Refresh />}
              </IconButton>
            </Tooltip>
            {isUserTab && (
              <Tooltip
                title={
                  (props.currentTabData as UsersTabState).viewType === "grid" ? "List" : "Grid"
                }
                placement="top"
              >
                <div>
                  <IconButton
                    disabled={usersLoading}
                    onClick={() => {
                      const tabData = props.currentTabData as UsersTabState;
                      if (tabData.viewType === "grid") {
                        (props.currentTabDispatch as React.Dispatch<UsersTabAction>)({
                          type: "select_view_type",
                          payload: "list",
                        });
                      } else {
                        (props.currentTabDispatch as React.Dispatch<UsersTabAction>)({
                          type: "select_view_type",
                          payload: "grid",
                        });
                      }
                    }}
                    sx={{
                      opacity: usersLoading ? 0.5 : 1,
                      svg: {
                        width: "20px",
                        height: "20px",
                      },
                    }}
                  >
                    {(props.currentTabData as UsersTabState).viewType === "grid" ? (
                      <Grid4X4 />
                    ) : (
                      <ThreeHorizontalLines />
                    )}
                  </IconButton>
                </div>
              </Tooltip>
            )}
            <Button
              color="error"
              disabled={disableButtons}
              sx={{
                display: selectedTab !== UserApprovalsTabsEnum.user_approvals ? "none" : "flex",
              }}
              onClick={() => onApprovalOrDenialButtonClick(1)}
            >
              Deny
            </Button>
            <XNGButton
              color="primary"
              disabled={disableButtons}
              onClick={() => onApprovalOrDenialButtonClick(0)}
            >
              Approve
            </XNGButton>
          </Stack>
        </Stack>
      </Box>
      <QueryStatusModal
        isOpen={openValidationModal}
        status={"error"}
        onSettledClose={() => {
          setOpenValidationModal(false);
          setInValidUsers(new Map());
        }}
        content={{
          errorTitle: "Invalid User Selection",
          cancelButtonText: "Close",
          errorBody: (
            <ApprovalsModalListItemsContent
              headerText="The following user(s) have not been assigned to either a district or service provider type."
              listItems={inValidUsers}
              variant="error"
            />
          ),
        }}
      />
      <QueryStatusModal
        isOpen={openApprovalQueryStatusModal}
        status={patchUsersRequestingAccessToDistrictStatus}
        onSettledClose={() => setOpenApprovalQueryStatusModal(false)}
        content={{
          errorTitle: "Error",
          pendingTitle: "Approving user(s)...",
          errorBody: (
            <ApprovalsModalListItemsContent
              headerText="Error Approving user(s)"
              listItems={approvedOrDeniedUsers}
              variant="error"
            />
          ),
          successTitle: "Thank You!",
          successBody: (
            <ApprovalsModalListItemsContent
              headerText="User(s) have successfully been approved"
              badItemsText="The following user(s) highlighted in red could not be approved. Please click approve button to try again."
              listItems={approvedOrDeniedUsers}
              badItemsIds={
                isFetchingUsers
                  ? []
                  : cardData?.unapprovedUsers?.map((card) => card.user?.id ?? "") ?? []
              }
              variant="success"
            />
          ),
        }}
      />
      <QueryStatusModal
        isOpen={openDenialQueryStatusModal}
        status={patchUsersRequestingAccessToDistrictStatus}
        onSettledClose={() => setOpenDenialQueryStatusModal(false)}        
        content={{
          errorTitle: "Error",
          pendingTitle: "Denying user(s)...",
          errorBody: (
            <ApprovalsModalListItemsContent
              headerText="Error Denying user(s)"
              listItems={approvedOrDeniedUsers}
              variant="error"
            />
          ),
          successTitle: "Thank You!",
          successBody: (
            <ApprovalsModalListItemsContent
              headerText="User(s) have successfully been denied"
              badItemsText="The following user(s) highlighted in red could not be denied. Please click deny button to try again."
              listItems={approvedOrDeniedUsers}
              badItemsIds={
                isFetchingUsers
                  ? []
                  : cardData?.deniedUsers?.map((card) => card.user?.id ?? "") ?? []
              }
              variant="success"
            />
          ),
        }}
      />
    </>
  );
};

export default UserApprovalsToolBar;
