import dayjs from "dayjs";

export default function getLocalAndUtcDateTime(){
    const offset = dayjs().toDate().getTimezoneOffset() / 60;

    return {
        local: dayjs()
        .hour(dayjs().hour() - offset)
        .toDate(),
        utc: dayjs().toDate()
    }
}