import { Box, Tab, Tabs } from "@mui/material";
import React, { useMemo } from "react";
import studentProfileTabsItems from "../constants/student_profile_tabs_items";
import { useXNGSelector } from "../../../../../context/store";
import { StudentProfileTabSelection } from "../context/student_profile_context_provider";
import useUserRoles from "../../../../../hooks/use_user_roles";

type Props = {
  selectedTab: StudentProfileTabSelection;
  setSelectedTab: (index: StudentProfileTabSelection) => void;
  roles: ReturnType<typeof useUserRoles>;
  disabled?: boolean;
};

export default function StudentProfileTab(props: Readonly<Props>) {
  const { selectedTab, setSelectedTab, disabled, roles } = props;

  // This feature flag is used to hide the Medicaid tab based on state, the 'DisableMedicaidTabForNonAdmins' might be a little misleading, but this is its purpose
  const featureDisabledByStateflag = useXNGSelector(
    (state) => state.featureFlags.flags.DisableMedicaidTabForNonAdmins,
  );

  function handleChange(
    _: React.SyntheticEvent,
    newValue: number,
    label: StudentProfileTabSelection["tabLabel"],
  ) {
    setSelectedTab({
      index: newValue,
      tabLabel: label,
    });
  }

  const visibleStudentProfileTabs = useMemo(() => {
    return studentProfileTabsItems.filter((tab) =>
      tab.attributes.shouldRenderTab({
        featureDisabledByStateflag,
        roles,
      }),
    );
  }, [featureDisabledByStateflag, roles]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflow: "hidden",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab.index}
          onChange={(event, newValue) =>
            handleChange(event, newValue, visibleStudentProfileTabs[newValue].label)
          }
          aria-label="student profile tabs"
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {visibleStudentProfileTabs.map((tab, i) => {
            return (
              <Tab
                key={tab.label}
                label={tab.label}
                sx={{
                  textTransform: "capitalize",
                  paddingLeft: 0,
                  fontWeight: selectedTab.index === i ? "bold" : "normal",
                }}
                disabled={disabled}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box
        sx={{
          overflowY: "auto",
          height: "100%",
        }}
      >
        {visibleStudentProfileTabs[selectedTab.index].content}
      </Box>
    </Box>
  );
}
