import { useMemo } from "react";
import { useXNGSelector } from "../../../../../../context/store";
import { selectAuthorizedDistricts } from "../../../../../../context/slices/userProfileSlice";
import useApiQuerySchoolCampusesDropdownDisplaysGet from "../../../../../../api/hooks/districts/use_api_query_school_campuses_drop_down_displays_get";
import useApiQueryData from "../../../../../../api/hooks/use_api_query_data";
import { API_DISTRICTS } from "../../../../../../api/api";
import isInState from "../../utils/is_in_state";

type Props = {
  stateInUs: string;
};

/**
 * Custom hook to fetch and manage campus and district options based on the provided state in the US.
 *
 * @param {Props} props - The properties object.
 * @param {string} props.stateInUs - The state in the US for which to fetch the district and campus options.
 *
 * @returns {Object} An object containing:
 * - `getDistrictsOptionsBasedOnState`: A memoized object with district options and related states.
 * - `campusApiQueryClientManager`: The API query client manager for fetching campus options.
 *
 * The `getDistrictsOptionsBasedOnState` object includes:
 * - `districts`: The list of districts based on the state.
 * - `isDistrictsLoading`: A boolean indicating if the districts are currently being loaded.
 * - `isDistrictsError`: A boolean indicating if there was an error loading the districts.
 * - `refetchDistricts`: A function to refetch the districts.
 */
export default function useApiQueryCampusAndDistrictOptionsBasedOnState(props: Props) {
  const { stateInUs } = props;

  const districtsOptions = useXNGSelector(selectAuthorizedDistricts);

  const campusApiQueryClientManager = useApiQuerySchoolCampusesDropdownDisplaysGet({
    queryParams: {
      districtIds: districtsOptions.map((district) => district.id!).join(","),
      state: stateInUs,
    },
  });

  const {
    data: districtsResponse,
    isError: isDistrictsError,
    isLoading: isDistrictsLoading,
    refetch: refetchDistricts,
  } = useApiQueryData({
    queryKey: ["v1DistrictsGet", stateInUs],
    queryFn: async () => {
      return await API_DISTRICTS.v1DistrictsGet(stateInUs);
    },
    enabled: isInState(stateInUs, "NH"),
  });

  const getDistrictsOptionsBasedOnState = useMemo(() => {
    if (isInState(stateInUs, "NH")) {
      return {
        districts: districtsResponse?.districts,
        isDistrictsLoading,
        isDistrictsError,
        refetchDistricts,
      };
    } else {
      return {
        districts: districtsOptions,
        isDistrictsLoading: false,
        isDistrictsError: false,
        refetchDistricts: () => {},
      };
    }
  }, [
    districtsOptions,
    districtsResponse?.districts,
    isDistrictsError,
    isDistrictsLoading,
    refetchDistricts,
    stateInUs,
  ]);
  return {
    getDistrictsOptionsBasedOnState,
    campusApiQueryClientManager,
  };
}
