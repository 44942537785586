/* 
Due to it's enum mapping on the backend, this array inadvertently maps each array item to it's corresponding index in the array. 
This is not a problem in and of itself, but it does mean that if the order of the array changes, the index will no longer correspond to the correct plan of care. 
This is not an issue currently, but it is something to be aware of in the future.
Iep = 0, Referral = 1, _504 = 2, RtiMtss = 3, Ell = 4, Other = 5 
*/
const plansOfCareLabels = [
  "IEP (Special Education)",
  "Referral",
  "504",
  "RTI / MTSS",
  "ELL",
  "Other Plan of Care",
];

export default plansOfCareLabels;
