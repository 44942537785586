import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { Typography, IconButton, useTheme, CircularProgress, Palette } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { HiMiniEllipsisVertical } from "react-icons/hi2";
import { getSizing } from "../../design/sizing";
import { SessionDayViewCard, SessionResponse, SessionStatus, StudentRef } from "../../session-sdk";
import { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { XNGIconRenderer, XNGICONS } from "../../design/icons";
import { placeholderForFutureLogErrorText } from "../../temp/errorText";
import { API_SESSIONS } from "../../api/api";
import { useXNGDispatch, useXNGSelector } from "../../context/store";
import { selectStateInUS } from "../../context/slices/stateInUsSlice";
import { EventContentArg } from "@fullcalendar/core";
import { EditSessionSeriesModal } from "../scheduler/edit_session_series_modal";
import { MWDContext } from "./calendar";
import { Box, styled } from "@mui/system";
import { getUserTimeZone, timezoneAdjustedStartOrEndTimes } from "../../utils/timeZones";
import { selectServiceProviderProfile } from "../../context/slices/loggedInClientSlice";
import useRefetchUnpostedSessionsRequests from "../unposted_sessions/hooks/use_refetch_unposted_sessions_requests";
import { DeleteSessionModal } from "../modals/delete_session";
import { SessionEllipsisMenu } from "../../components/session_ellipsis_menu";
import { MSBIconRenderer } from "../../fortitude";
import { MWD } from "./types";
import useSessionStatusColors from "../../hooks/use_session_status_colors";
import { pushError } from "../../context/slices/errorEntries";
import { useNavigateToSession } from "./hooks/use_navigate_to_session";
import { sanitizeSession } from "../notator/logic/sanitize_session";
import EditTodaysSessionModal from "../modals/edit_todays_session_modal";
import { useCreateSessionInDatabase } from "../../hooks/use_create_session_in_database";
dayjs.extend(weekday);

const CalendarEventCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "statusColor", // Avoid forwarding internal props to the DOM element
})<{ statusColor: string; mwd: MWD; isNonSchoolDay: boolean }>(
  ({ theme, statusColor, mwd, isNonSchoolDay }) => ({
    width: "100%",
    height: "100%",
    maxHeight: mwd === "Month" ? "1.25rem" : "100%", // Allows 3 session cards to show on most teachers' screens (not sure how crucial this is for UX, but this was a requirement at one point).
    overflow: "hidden",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: statusColor,
    color: theme.palette.getContrastText(statusColor),
    borderRadius: ".25rem",
    pointerEvents: isNonSchoolDay ? "none" : "auto",
  }),
);

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => {
  return <Tooltip {...props} classes={{ popper: className }} />;
})(() => {
  const bgcolor = "#0009";

  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: bgcolor,
      color: "white",
    },
    [`& .${tooltipClasses.tooltipArrow}`]: {
      backgroundColor: bgcolor,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: bgcolor,
    },
    zIndex: 9999,
  };
});

export function FullCalendarEvent(
  props: Readonly<{
    event: EventContentArg;
    onReloadCalendar: () => void;
    studentCaseload?: StudentRef[];
  }>,
) {
  const session: SessionDayViewCard = props.event.event._def.extendedProps as SessionDayViewCard;

  const [ellipseOpen, setEllipseOpen] = useState<boolean>(false);
  const [ellipseAnchorEl, setEllipseAnchorEl] = useState<HTMLElement | null>(null);
  const [deleteSessionModalOpen, setDeleteSessionModalOpen] = useState<boolean>(false);
  const [editSessionSeriesModalOpen, setEditSessionSeriesModalOpen] = useState<boolean>(false);
  const [sessionResponse, setSessionResponse] = useState<SessionResponse | undefined>(undefined);
  const [calendarEventClicked, setCalendarEventClicked] = useState<boolean>(false);
  const { refetchOnSessionAction } = useRefetchUnpostedSessionsRequests();

  const { palette } = useTheme();

  const stateInUS = useXNGSelector(selectStateInUS);
  const mwd: MWD = useContext(MWDContext) as MWD;

  const isNonSchoolDay = props.event.event.extendedProps?.hasOwnProperty("type");

  // Timezone adjustments
  const { timezoneAdjustedStartTime, timezoneAdjustedEndTime } = timezoneAdjustedStartOrEndTimes(
    stateInUS,
    "display",
    session?.startTime!,
    session?.endTime!,
  );

  /**
   * TODO: Decouple this network logic from this presentational calendar event file. Easiest route may be moving it into the session editor modals itself (containerizing it).
   */
  useEffect(() => {
    if (ellipseOpen) {
      getSession();
    }

    async function getSession() {
      if (session.id === undefined) throw Error(placeholderForFutureLogErrorText);
      const serviceProviderId = session.serviceProvider?.id;
      const sessionDate = dayjs(session.startTime);
      const response = await API_SESSIONS.v1SessionsGet(
        serviceProviderId!,
        stateInUS,
        session.id,
        session.seriesId,
        sessionDate.toDate(),
        getUserTimeZone(),
      );
      setSessionResponse(response);
    }
  }, [ellipseOpen]);

  /**
   * TODO: Decouple this network logic from this presentational calendar event file. Easiest route may be moving it into the DeleteSessionModal itself (containerizing it).
   */
  async function deleteSession() {
    if (session.id === undefined) throw Error(placeholderForFutureLogErrorText);
    const serviceProviderId = session.serviceProvider?.id;
    const sessionDate = dayjs(session.startTime);
    await API_SESSIONS.v1SessionsDelete(
      serviceProviderId!,
      stateInUS,
      session.id,
      session.seriesId,
      sessionDate.toDate(),
    );
    refetchOnSessionAction();
    props.onReloadCalendar();
  }

  /**
   * TODO: Decouple this network logic from this presentational calendar event file. Easiest route may be moving it into the DeleteSessionModal itself (containerizing it).
   */
  async function deleteMultipleSessions(idsToDelete: string[], datesToDelete: string[]) {
    let deleteRequests = idsToDelete.join(", ");
    let datesDeleteRequests = datesToDelete.join(", ");
    if (session.seriesId === undefined) throw Error(placeholderForFutureLogErrorText);
    const serviceProviderId = session.serviceProvider?.id;
    const seriesId = session.seriesId;
    await API_SESSIONS.v1SessionsDeleteManyDeleteDelete(
      serviceProviderId!,
      stateInUS,
      deleteRequests,
      datesDeleteRequests,
      seriesId,
    );
    refetchOnSessionAction();
    props.onReloadCalendar();
  }

  const serviceProviderId = useMemo(() => session.serviceProvider?.id ?? null, [session]);

  /**
   * TODO: Decouple this network logic from this presentational calendar event file. Easiest route may be moving it into the DeleteSessionModal itself (containerizing it).
   */
  async function deleteSeries() {
    if (session.seriesId === undefined) throw Error(placeholderForFutureLogErrorText);
    await API_SESSIONS.v1SessionsDeleteRecurringSessionDelete(
      session.seriesId,
      serviceProviderId!,
      stateInUS,
    );
    refetchOnSessionAction();
    props.onReloadCalendar();
  }

  const serviceProvider = useXNGSelector(selectServiceProviderProfile);
  const navigateToSession = useNavigateToSession();
  const createSessionInDatabase = useCreateSessionInDatabase();

  const dispatch = useXNGDispatch();

  const handleEventClick = useCallback(() => {
    const sessionIsVirtualAndScheduled = session.id === null && Boolean(session.seriesId);
    setCalendarEventClicked(true);

    if (sessionIsVirtualAndScheduled) {
      createSessionInDatabaseAndOpen();
    } else {
      if (!session.startTime || !session.id || !session.serviceProvider?.id) {
        dispatch(
          pushError({ titleID: "One or more parameters required to view session was undefined!" }),
        );
        setCalendarEventClicked(false);
        return;
      }

      navigateToSession({
        sessionID: session.id,
        sessionDate: session.startTime,
        sessionSeriesID: session.seriesId,
        sessionServiceProviderID: session.serviceProvider.id,
      });
    }

    async function createSessionInDatabaseAndOpen() {
      const [sessionResponse, error] = await createSessionInDatabase(session);

      if (!sessionResponse || error) {
        dispatch(pushError(error ?? { titleID: "Failure while creating session" }));
        setCalendarEventClicked(false);
        return;
      }

      if (
        !sessionResponse.id ||
        !session.startTime ||
        !session.seriesId ||
        !session.serviceProvider?.id
      ) {
        setCalendarEventClicked(false);
        throw new Error("One or more parameters were undefined!");
      }

      navigateToSession({
        sessionID: sessionResponse.id,
        sessionDate: session.startTime,
        sessionSeriesID: session.seriesId,
        sessionServiceProviderID: session.serviceProvider.id,
      });
    }
  }, [session, createSessionInDatabase, dispatch, pushError, navigateToSession]);

  const statusColors = useSessionStatusColors(session.status ?? SessionStatus.NUMBER_0); // Just coalesce to prevent the application from crashing

  const [isEditTodaysSessionModalOpen, setIsEditTodaysSessionModalOpen] = useState<boolean>(false);

  /**
   * TODO: Decouple this network logic from this presentational calendar event file.
   */
  async function saveSession(freshSession: SessionResponse) {
    const sanitizedSession = sanitizeSession(freshSession);
    const response = await API_SESSIONS.v1SessionsPut(stateInUS, sanitizedSession);
    setSessionResponse(response);
  }

  const isPostedOrClosedOnSchoolDay = useMemo(
    () => session.status !== 4 && session.status !== 5 && !isNonSchoolDay,
    [session.status, isNonSchoolDay],
  );

  return (
    <>
      {/* MODALS */}
      <DeleteSessionModal
        setDeleteSession={setDeleteSessionModalOpen}
        showDeleteSession={deleteSessionModalOpen}
        deleteSession={deleteSession}
        deleteMultipleSessions={deleteMultipleSessions}
        deleteSeries={deleteSeries}
        seriesId={session.seriesId}
        providerId={session.serviceProvider?.id}
        state={stateInUS}
      />

      <EditSessionSeriesModal
        open={editSessionSeriesModalOpen}
        startDate={session.startTime!}
        endDate={session.endTime!}
        onClose={() => setEditSessionSeriesModalOpen(false)}
        onRequestRefreshSessions={() => props.onReloadCalendar()}
        editedSession={sessionResponse}
        studentCaseload={props.studentCaseload}
        studentList={sessionResponse?.studentJournalList?.map(
          (journal) => journal.student as StudentRef,
        )}
      />
      <EditTodaysSessionModal
        open={isEditTodaysSessionModalOpen}
        startDate={session.startTime!}
        endDate={session.endTime!}
        onClose={() => setIsEditTodaysSessionModalOpen(false)}
        editedSession={sessionResponse}
        saveSession={saveSession}
        onRequestRefreshSessions={() => props.onReloadCalendar()}
        studentCaseload={props.studentCaseload}
        studentList={sessionResponse?.studentJournalList?.map(
          (journal) => journal.student as StudentRef,
        )}
        modalType="single"
      />

      <SessionEllipsisMenu
        open={ellipseOpen}
        anchorEl={ellipseAnchorEl}
        onClose={() => setEllipseOpen(false)}
        onDeleteSessionClick={() => setDeleteSessionModalOpen(true)}
        onEditSessionSeriesClick={() =>
          session.seriesId
            ? setEditSessionSeriesModalOpen(true)
            : setIsEditTodaysSessionModalOpen(true)
        }
        contentDependencies={{
          sessionIsRecurring: Boolean(session.seriesId),
          isUsersOwnSession: session.serviceProvider?.id === serviceProvider?.id!,
        }}
      />

      {/* DOM HIERARCHY */}

      <TooltipWrapper
        title={
          <Box sx={{ p: ".25rem", display: "flex", flexDirection: "column", gap: ".5rem" }}>
            <Typography variant="body1" color="inherit">
              {session.title}
            </Typography>
            <Typography variant="body2" color="inherit">
              {session.service?.name}
            </Typography>
            <Typography variant="body2" color="inherit">
              {dayjs(timezoneAdjustedStartTime).format("dddd, MMMM D, YYYY h:mm A")} -{" "}
              {dayjs(timezoneAdjustedEndTime).format("h:mm A")}
            </Typography>
          </Box>
        }
        calendarEventClicked={calendarEventClicked}
        palette={palette}
      >
        <CalendarEventCard
          isNonSchoolDay={isNonSchoolDay}
          mwd={mwd}
          onClick={calendarEventClicked ? undefined : handleEventClick}
          statusColor={statusColors.primary}
        >
          <Box sx={{ display: "flex", alignItems: "center", pl: ".25rem" }}>
            {mwd !== "Day" && (
              <Dot bgcolor={isNonSchoolDay ? "#757575" : statusColors.primaryDark} />
            )}

            {mwd === "Day" && <DayViewStudentData session={session} />}

            <Typography
              variant={"body2"}
              sx={{
                px: ".25rem",
                maxWidth: mwd === "Day" ? "unset" : "calc((100vw / 7) - 2.7rem)",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {session.title}
            </Typography>
          </Box>

          {isPostedOrClosedOnSchoolDay && (
            <IconButton
              onClick={(e) => {
                if (calendarEventClicked) {
                  return undefined;
                } else {
                  e.stopPropagation();
                  setEllipseOpen(true);
                  setEllipseAnchorEl(e.currentTarget);
                }
              }}
              sx={{ width: "1.5rem", height: "1.5rem" }}
            >
              <MSBIconRenderer
                color={palette.getContrastText(statusColors.primary)}
                size="xs"
                i={<HiMiniEllipsisVertical />}
              />
            </IconButton>
          )}
        </CalendarEventCard>
      </TooltipWrapper>
    </>
  );
}

function DayViewStudentData(props: Readonly<{ session: SessionDayViewCard }>) {
  const { session } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        [theme.breakpoints.down("md")]: {
          justifyContent: "flex-start",
          mt: 1,
        },
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
        mt: 0.5,
        gap: 4,
      }}
    >
      <StudentAttendanceIcon
        title="Students in Session"
        studentList={session.totalStudents}
        color="primary"
      />
      <Box
        sx={{
          display: "flex",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
          gap: 4,
        }}
      >
        <StudentAttendanceIcon
          title="Present Students"
          studentList={session.presentStudents}
          color="success"
        />
        <StudentAttendanceIcon
          title="Absent Students"
          studentList={session.absentStudents}
          color="error"
        />
      </Box>
    </Box>
  );
}

function TooltipWrapper(
  props: Readonly<{
    children: React.ReactNode;
    title: React.ReactNode;
    calendarEventClicked: boolean;
    palette: Palette;
  }>,
) {
  return (
    <StyledTooltip disableInteractive title={props.title} placement="top" arrow>
      <Tooltip
        disableHoverListener
        arrow
        open={props.calendarEventClicked}
        title={
          <Box sx={{ display: "flex", alignItems: "center", gap: ".5rem", p: ".25rem" }}>
            <Typography variant="inherit">Loading session...</Typography>
            <CircularProgress
              size="1rem"
              sx={{ color: props?.palette?.contrasts?.[5] }}
              variant="indeterminate"
            />
          </Box>
        }
      >
        <div style={{ minWidth: "100%", minHeight: "100%", height: "100%" }}>{props.children}</div>
      </Tooltip>
    </StyledTooltip>
  );
}

function StudentIconBadge(
  props: Readonly<{
    numberOfStudents: number;
    color: "primary" | "success" | "error";
  }>,
) {
  const { palette } = useTheme();

  const badgeColor = palette[props.color][2]!;

  return (
    <Box
      sx={{
        display: "flex",
        width: getSizing(2.2),
        height: getSizing(2.2),
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 999,
        bgcolor: palette[props.color][2],
        color: palette.getContrastText(badgeColor),
      }}
    >
      <Typography variant="body2">{props.numberOfStudents}</Typography>
    </Box>
  );
}

function StudentAttendanceIcon(
  props: Readonly<{
    title: string;
    studentList: StudentRef[] | undefined;
    color: "primary" | "success" | "error";
  }>,
) {
  return (
    <StyledTooltip
      title={
        <Box
          sx={{
            width: getSizing(40),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="body1">{props.title}</Typography>
          {props.studentList?.map((student, index) => (
            <Typography key={index} variant="body2">
              {student.firstName + " " + student.lastName}
            </Typography>
          ))}
        </Box>
      }
      placement="top-start"
      arrow
      disableInteractive
    >
      <div>
        <Box sx={{ display: "flex" }}>
          <XNGIconRenderer size="md" i={<XNGICONS.Person />} />
          <StudentIconBadge numberOfStudents={props.studentList?.length!} color={props.color} />
        </Box>
      </div>
    </StyledTooltip>
  );
}

const Dot = styled(Box)(() => ({
  minWidth: ".5rem",
  maxWidth: ".5rem",
  minHeight: ".5rem",
  maxHeight: ".5rem",
  borderRadius: 999,
}));
