import { useState, useMemo } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import XNGButton from "../common/xng-button";
import { ROUTES_XLOGS } from "../../../../../constants/URLs";
import useUserRole from "../../../../../hooks/useUserRole";
import DlgSignature from "../dialog/dlg-signature";
import DlgPostSuccess from "../dialog/dlg-post-success";
import DlgWrongSignature from "../dialog/dlg-wrong-signature";
import { useUnpostedSessionsBatchPostContext } from "../../providers/unposted_sessions_batch_post_provider";
import { API_SESSIONS } from "../../../../../api/api";
import {
  ActualSession,
  PatchSessionStudentJournal,
  RequestRevisionsRequest,
} from "../../../../../session-sdk";
import { useXNGSelector } from "../../../../../context/store";
import {
  selectLoggedInClientAssignment,
  selectUser,
} from "../../../../../context/slices/userProfileSlice";
import sessionStorageKeys from "../../../../../browser/sessionStorageKeys";
import useBatchRequestRevisionsModal from "../../hooks/use_batch_request_revisions_modal";

import { selectClientID } from "../../../../../context/slices/loggedInClientSlice";
import QueryStatusModal from "../../../../../design/modal_templates/query_status_modal";
import useApiMutateRequestRevisionsAndUpdateSessionStatus from "../../hooks/api/use_api_mutate_request_revisions_and_update_session_status";
import { useNavigate } from "react-router";
import useBatchPostingMatchPath from "../../hooks/use_batch_posting_match_path";
import MSBBack from "../../../../../fortitude/components/button_back";
import { produce } from "immer";
import getLocalAndUtcDateTime from "../../../../../utils/get_local_and_utc_date_time";

export default function PageHeader() {
  const electronicSignatureKey = sessionStorageKeys.ALL_SESSIONS_ELECTRONIC_SIGNATURE_KEY;

  const signature = sessionStorage.getItem(electronicSignatureKey);
  const applySignatureToAllSessions = useMemo(() => Boolean(signature), [signature]);

  const { isApprover } = useUserRole();
  const {
    selectedSessionIds,
    onSelectAllSessions,
    selectedStudentIds,
    selectedProviderIds,
    startDate,
    endDate,
    sessions,
    stateInUs,
    refresh,
    isPosting,
    setIsPosting,
    setIsPosted,
    students,
  } = useUnpostedSessionsBatchPostContext();
  const user = useXNGSelector(selectUser)!;
  const loggedInClientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const clientId = useXNGSelector(selectClientID);
  const [isSigDialogOpen, setSigDialogOpen] = useState(false);
  const [isWrongSigDialogOpen, setWrongSigDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState(false);
  const { showDialog, revisionsDialogEl } = useBatchRequestRevisionsModal({
    onSubmitRevisions: handleRequestRevisionBatch,
  });
  const [showQueryStatusModal, setShowQueryStatusModal] = useState(false);
  const { mutateAsync: mutateBatchRequestRevisions, status: batchRequestRevisionsStatus } =
    useApiMutateRequestRevisionsAndUpdateSessionStatus({
      queryParams: {
        state: stateInUs,
      },
      options: {
        onMutate: () => {
          setShowQueryStatusModal(true);
        },
        onSuccess: () => {
          onSelectAllSessions(false);
          refresh();
        },
      },
    });
  const batchPostingMatchPath = useBatchPostingMatchPath();
  const navigate = useNavigate();
  const redirectRoute = useMemo(() => {
    switch (batchPostingMatchPath.customId) {
      case "my-sessions":
        return ROUTES_XLOGS.unposted_sessions.mySessions;
      case "dec-sessions":
        return ROUTES_XLOGS.unposted_sessions.decSessions;
      case "assistant-sessions":
        return ROUTES_XLOGS.unposted_sessions.assistantSessions;
      default:
        return ROUTES_XLOGS.unposted_sessions.mySessions;
    }
  }, [batchPostingMatchPath.customId]);
  async function handleRequestRevisionBatch(revisionsText: string) {
    const batchRequestRevisionsBody: Required<RequestRevisionsRequest> = {
      sessionIdsGroupedByServiceProviders: selectedProviderIds
        .map((providerId) => {
          return {
            serviceProviderId: providerId,
            sessionIds: selectedSessionIds.filter((sessionId) => {
              return (
                sessions.find((session) => session.id === sessionId)?.serviceProvider?.id ===
                providerId
              );
            }),
          };
        })
        .filter((group) => group.sessionIds.length > 0),
      revisionsNote: {
        note: revisionsText,
        serviceProviderRequestingRevisions: loggedInClientAssignment.serviceProviderProfile,
      },
    };

    await mutateBatchRequestRevisions(batchRequestRevisionsBody);
  }

  const handlePostBatch = async (fullName?: string, isApplyAll?: boolean) => {
    setIsPosting(true);
    const loggedInClientName = `${user?.firstName} ${user?.lastName}`;

    if (!applySignatureToAllSessions && loggedInClientName !== fullName) {
      setWrongSigDialogOpen(true);
      setIsPosting(false);
      return;
    }

    if (isApplyAll && fullName) {
      sessionStorage.setItem(electronicSignatureKey, fullName);
    }

    type SelectedSessionIdsMap = {
      [key: string]: string[];
    };

    const selectedSessionIdsMap: SelectedSessionIdsMap = {};
    const updatedSessionList: ActualSession[] = [];
    const updatedSelectedSessionIds = [...selectedSessionIds];

    //populate arrival and depature time
    selectedSessionIds.forEach((sessionId) => {
      const session = sessions.find((session) => session.id === sessionId);
      if (session) {
        const updatedSession = produce(session, (draft) => {
          draft.studentJournalList?.forEach((studentJournal) => {
            if (
              studentJournal.studentAttendanceRecord?.arrivalTime === null ||
              studentJournal.studentAttendanceRecord?.departureTime === null
            ) {
              studentJournal.studentAttendanceRecord.arrivalTime =
                studentJournal.studentAttendanceRecord?.arrivalTime ||
                session.meetingDetails?.startTime;
              studentJournal.studentAttendanceRecord.departureTime =
                studentJournal.studentAttendanceRecord?.departureTime ||
                session.meetingDetails?.endTime;
            }
          });
        });
        updatedSessionList.push(updatedSession);
      }
    });

    updatedSessionList.forEach((session) => {
      if (updatedSelectedSessionIds.includes(session.id!)) {
        const serviceProviderId = session.serviceProvider?.id!;
        selectedSessionIdsMap[serviceProviderId] = selectedSessionIdsMap[serviceProviderId] || [];
        selectedSessionIdsMap[serviceProviderId].push(session.id!);
      }
    });

    const { serviceProviderProfile, isAutonomous, isApprover, isProxyDataEntry } =
      loggedInClientAssignment;

    const approvalPatchRequests: ReturnType<
      typeof API_SESSIONS.v1SessionsPatchSessionStudentJournalApprovalPatch
    >[] = [];
    const {
      local: localDateTime,
      utc: utcDateTime,
    } = getLocalAndUtcDateTime();
    for (const serviceProviderId in selectedSessionIdsMap) {
      const requestBody: PatchSessionStudentJournal = {
        clientId: clientId!,
        sessionIds: selectedSessionIdsMap[serviceProviderId],
        startDate:
          selectedSessionIds.length === updatedSessionList.length
            ? startDate.startOf("day").toDate()
            : undefined,
        endDate:
          selectedSessionIds.length === updatedSessionList.length
            ? endDate.endOf("day").toDate()
            : undefined,
        studentIds: selectedStudentIds,
        postSession: students.length === selectedStudentIds.length,
        serviceProviderId,
        currentUser: {
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          emailAddress: user?.emailAddress,
        },
        currentUserServiceProvider: {
          id: serviceProviderProfile?.id,
          firstName: serviceProviderProfile?.firstName,
          lastName: serviceProviderProfile?.lastName,
        },
        electronicSignature: {
          statementType: 3,
          isSigned: true,
          signedOnDateLocal: localDateTime,
          signedOnDateUtc: utcDateTime,
          signedByFullName: signature ?? fullName,
          objectId: user?.id, //TODO: This should be the Azure B2C OID provided by msal
          documentText: "",
          requestIpAddress: "",
        },
        isAutonomous,
        isApprover,
        isActingAsProxy: isProxyDataEntry,
      };
      approvalPatchRequests.push(
        API_SESSIONS.v1SessionsPatchSessionStudentJournalApprovalPatch(stateInUs, requestBody),
      );
    }

    try {
      await Promise.all(approvalPatchRequests);
      setSuccessDialogOpen(true);
      setIsPosting(false);
      setIsPosted(true);
    } catch (err) {
      setIsPosting(false);
      console.error(err);
    }
    refresh();
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", padding: "20px 72px", gap: "20px" }}>
        <MSBBack
          onClick={() => {
            navigate(redirectRoute);
          }}
        />

        <Typography
          sx={{ flexGrow: 1, fontSize: "24px", fontWeight: 500, lineHeight: "40px" }}
          component="h1"
        >
          Session Approval
        </Typography>

        {isApprover() && (
          <XNGButton
            variant="outlined"
            onClick={() => {
              showDialog({
                showWarning: selectedSessionIds.length > 1,
              });
            }}
            disabled={!selectedSessionIds.length}
          >
            Request Revision
          </XNGButton>
        )}
        <XNGButton
          onClick={() => (applySignatureToAllSessions ? handlePostBatch() : setSigDialogOpen(true))}
          disabled={!selectedSessionIds.length || isPosting}
        >{`Approve Batch (${selectedSessionIds.length})`}</XNGButton>
      </Box>

      <Divider sx={{ borderWidth: "1px" }} />

      <DlgSignature
        open={isSigDialogOpen}
        onClose={() => setSigDialogOpen(false)}
        onPostBatch={handlePostBatch}
        userRoleText={"Approve"}
      />

      <DlgWrongSignature open={isWrongSigDialogOpen} onClose={() => setWrongSigDialogOpen(false)} />

      <DlgPostSuccess open={isSuccessDialogOpen} onClose={() => setSuccessDialogOpen(false)} />
      {revisionsDialogEl}
      <QueryStatusModal
        isOpen={showQueryStatusModal}
        content={{
          pendingTitle: "Requesting Revisions, Please Wait...",
          errorTitle: "Failed to Request Revisions",
          errorBody: "An error occurred while requesting revisions. Please try again.",
          successTitle: "Revisions Requested",
          successBody: "Revisions have been requested successfully.",
        }}
        status={batchRequestRevisionsStatus}
        onSettledClose={() => setShowQueryStatusModal(false)}
      />
    </>
  );
}
