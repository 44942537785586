import { useCallback } from "react";
import produce from "immer";
import { useNotatorTools } from "../../../tools";
import toggleFromArray from "../../../../../utils/getToggledArray";

/**
 * TODO: Create automated tests for this.
 */
function useObservationTabDataOperationHandlers(props: {selectedStudentIndex: number | undefined}) {
  const { setDraftSession, selectedStudentIndex, draftSession } = useNotatorTools();
  const actualStudentIndex = props.selectedStudentIndex ? props.selectedStudentIndex : selectedStudentIndex;

  const toggleCheckedObservation = useCallback(
    (toggledOption: string) => {
      setDraftSession(
        produce((draft) => {
          const currentCheckedObs = [
            ...draft.studentJournalList![actualStudentIndex].observationSection!.observations!,
          ];
          const newCheckedObs = toggleFromArray(toggledOption, currentCheckedObs);
          draft.studentJournalList![actualStudentIndex].observationSection!.observations =
            newCheckedObs;
        }),
      );
    },
    [draftSession, actualStudentIndex, setDraftSession],
  );

  const deleteCustomObservation = useCallback(
    (removedOb: string) => {

      setDraftSession(
        produce((draft) => {
          const ledger = draft.sessionJournal!.customCareProvisionLedger!;
          ledger.observations = ledger.observations?.filter((ob) => ob !== removedOb); 

          // proceed to remove custom observation under current student journal list
          if(draft?.studentJournalList){
            draft.studentJournalList[actualStudentIndex].observationSection!.observations = 
            draft.studentJournalList[actualStudentIndex].observationSection!.observations?.filter((ob) => ob !== removedOb);
          }
        }),
      );
      
    },
    [draftSession, actualStudentIndex, setDraftSession],
  );

  return { toggleCheckedObservation, deleteCustomObservation };
}

export default useObservationTabDataOperationHandlers;
