import { API_USERS } from "../../../../../api/api";
import useCaseLoadManager from "./use_case_load_manager";
import useApiQueryData from "../../../../../api/hooks/use_api_query_data";

const useGetDECUserProfileData = () => {
  const {
    state: { clientId, stateInUs, selectedDecApprover },
  } = useCaseLoadManager();

  return useApiQueryData({
    queryFn: async () =>
      await API_USERS.v1UsersByServiceProviderGet(
        selectedDecApprover?.id ?? "",
        clientId ?? "",
        stateInUs ?? "",
      ),
    queryKey: ["v1UsersByServiceProviderGet", selectedDecApprover?.id, clientId, stateInUs],
    enabled: !!selectedDecApprover?.id,
  });
};

export default useGetDECUserProfileData;
