import { Alert, Box, Slide, Snackbar, Typography } from "@mui/material";
import SidebarLayout from "../../../../layouts/SidebarLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { STUDENTS_SIDEBAR } from "../../sidebar";
import { API_DISTRICTS, API_SERVICEPROVIDERS, API_STUDENTS } from "../../../../api/api";
import { useXNGSelector } from "../../../../context/store";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import { selectLoggedInClientAssignment } from "../../../../context/slices/userProfileSlice";
import { selectClientID } from "../../../../context/slices/loggedInClientSlice";
import { placeholderForFutureLogErrorText } from "../../../../temp/errorText";
import {
  CaseloadStudentDisplay,
  ClientRef,
  CreateStudentRequest,
  GetCaseloadStudentsResponse,
  SchoolCampusRef,
  StudentRef,
} from "../../../../profile-sdk";
import XNGSpinner from "../../../../design/low-level/spinner";
import { selectActingServiceProvider } from "../../../../context/slices/dataEntryProvider";
import useApiQueryServiceProviderById from "../../../../api/hooks/service_provider/use_api_query_service_provider_by_id";
import useDistrictsFetchAndSet from "../../hooks/useDistrictsFetchAndSet";
import useSortStudentsByName from "../../hooks/use_sort_students_by_name";
import StudentManagerHeader from "./header_controller";
import useEffectSkipMount from "../../../../hooks/use_effect_after_mount";
import SearchCardResults from "./components/search_card_results";
import ProviderCaseloadCardResults from "./components/provider_caseload_card_results";
import ProviderCaseloadListResults from "./components/provider_caseload_list_results";
import localStorageKeys from "../../../../constants/localStorageKeys";
import { MSBICONS } from "../../../../fortitude";

type StudentManagerLayoutMode = "grid" | "list";

function StudentManager() {
  const [layoutMode, setLayoutMode] = useState<StudentManagerLayoutMode>(
    (localStorage.getItem(
      localStorageKeys.USER_PREFERRED_STUDENT_MANAGER_LAYOUT_MODE,
    ) as StudentManagerLayoutMode) ?? "list",
  );
  const [studentCaseloadResponse, setStudentCaseloadResponse] =
    useState<GetCaseloadStudentsResponse | null>(null);
  const [filteredStudents, setFilteredStudents] = useState<CaseloadStudentDisplay[]>([]);
  const [searchedStudents, setSearchedStudents] = useState<CaseloadStudentDisplay[]>([]);
  const [showToast, setShowToast] = useState({
    show: false,
    message: "",
  });

  const [campusList, setCampusList] = useState<SchoolCampusRef[] | undefined>();
  const state = useXNGSelector(selectStateInUS);
  const loggedInClientId = useXNGSelector(selectClientID);
  const userClientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const actingServiceProvider = useXNGSelector(selectActingServiceProvider);
  const usesCampusRestrictions = !!useXNGSelector(
    (state) => state.loggedInClient?.usesCampusRestrictions,
  );

  // We call this here so the hook runs as soon as they hit the student manager page to set some session storage state
  useDistrictsFetchAndSet(state);

  const { data: serviceProviderProfile } = useApiQueryServiceProviderById({
    queryParams: {
      clientId: loggedInClientId,
      providerId: actingServiceProvider?.id,
      state: state,
    },
    options: {
      staleTime: 60 * 60 * 1000,
    },
  });

  async function fetchAndSetCaseLoad() {
    const providerID = actingServiceProvider?.id;
    if (loggedInClientId === undefined) throw Error(placeholderForFutureLogErrorText);
    if (providerID === undefined) throw Error(placeholderForFutureLogErrorText);

    const caseloadResponse = await API_STUDENTS.v1StudentsCaseloadGet(
      providerID,

      loggedInClientId,

      state,
    );
    setStudentCaseloadResponse(caseloadResponse);

    const arr = [...caseloadResponse.caseloadStudentDisplays!];

    arr.sort(sortStudentsByName);

    setFilteredStudents(arr);
  }

  async function fetchAndSetCampusList() {
    const providerID = actingServiceProvider?.id;
    if (loggedInClientId === undefined) throw Error(placeholderForFutureLogErrorText);
    if (providerID === undefined) throw Error(placeholderForFutureLogErrorText);
    let authorizedSchool = userClientAssignment.authorizedDistricts![0].id;
    if (!authorizedSchool) {
      return;
    }
    const campusResponse = await API_DISTRICTS.v1DistrictsIdSchoolCampusesDropdownDisplaysGet(
      authorizedSchool,
      state,
    );
    setCampusList(campusResponse.schoolCampuses);
  }

  async function createAndAddStudent(body: CreateStudentRequest, caseload: boolean) {
    const providerID = actingServiceProvider?.id;
    if (loggedInClientId === undefined) throw Error(placeholderForFutureLogErrorText);
    if (providerID === undefined) throw Error(placeholderForFutureLogErrorText);
    body.client = {} as ClientRef;
    body.client.id = loggedInClientId;
    body.client.name = userClientAssignment.client?.name;

    const createResponse = await API_STUDENTS.v1StudentsPost(state, body);
    const studentID = createResponse.id;
    if (studentID === undefined) throw Error(placeholderForFutureLogErrorText);
    if (caseload) {
      await API_SERVICEPROVIDERS.v1ServiceProvidersIdStudentCaseloadAddStudentPatch(
        providerID,
        loggedInClientId,
        studentID,
        state,
      );

      fetchAndSetCaseLoad();
    }
  }

  async function deleteStudent(studentID: CreateStudentRequest[]) {
    const providerID = actingServiceProvider?.id;
    if (loggedInClientId === undefined) throw Error(placeholderForFutureLogErrorText);
    if (providerID === undefined) throw Error(placeholderForFutureLogErrorText);

    const studentIds = studentID.map((student: any) => {
      return student.id;
    });
    await API_SERVICEPROVIDERS.v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch(
      studentIds,
      providerID,
      loggedInClientId,
      state,
    );

    fetchAndSetCaseLoad();
  }

  const sortStudentsByName = useSortStudentsByName();

  async function addStudentToCaseloadByID(studentID: string) {
    const providerID = actingServiceProvider?.id;
    if (loggedInClientId === undefined) throw Error(placeholderForFutureLogErrorText);
    if (providerID === undefined) throw Error(placeholderForFutureLogErrorText);

    if (studentID === undefined) throw Error(placeholderForFutureLogErrorText);
    await API_SERVICEPROVIDERS.v1ServiceProvidersIdStudentCaseloadAddStudentPatch(
      providerID,
      loggedInClientId,
      studentID,
      state,
    );

    fetchAndSetCaseLoad();
  }

  useEffect(() => {
    fetchAndSetCaseLoad();
  }, [actingServiceProvider]);
  useEffect(() => {
    fetchAndSetCampusList();
  }, [userClientAssignment]);

  const [searchBarValue, setSearchBarValue] = useState<string>("");
  useEffectSkipMount(() => {
    handleSearchForStudent();

    async function handleSearchForStudent() {
      if (searchBarValue.length === 0) return;

      const providerID = actingServiceProvider?.id;
      if (loggedInClientId === undefined) throw Error(placeholderForFutureLogErrorText);
      if (providerID === undefined) throw Error(placeholderForFutureLogErrorText);

      const searchResponse = await API_STUDENTS.v1StudentsSearchGet(
        loggedInClientId,
        searchBarValue,
        state,
        usesCampusRestrictions
          ? serviceProviderProfile?.activeSchoolCampuses?.map((campus) => campus.id).join(",")
          : undefined,
      );
      setSearchedStudents(searchResponse.caseloadStudentDisplays!.sort(sortStudentsByName));
    }
  }, [searchBarValue]);

  const handleLayoutButtonClick = useCallback(() => {
    const newLayoutMode: StudentManagerLayoutMode = layoutMode === "grid" ? "list" : "grid";
    setLayoutMode(newLayoutMode);
    localStorage.setItem(
      localStorageKeys.USER_PREFERRED_STUDENT_MANAGER_LAYOUT_MODE,
      newLayoutMode,
    );
  }, [layoutMode]);

  const failedStudentImports: StudentRef[] = useMemo(
    () => studentCaseloadResponse?.failedStudentFetches ?? [],
    [studentCaseloadResponse],
  );

  const { LoadingAnimation } = MSBICONS;
  // DOM HIERARCHY

  const loadingCaseloadFallback = useMemo(() => {
    return (
      <Box pl={2}>
        <Typography sx={{ mb: 2 }}>My Caseload</Typography>
        <Alert icon={<LoadingAnimation />} severity="info" sx={{ maxWidth: "lg" }}>
          Please wait, Loading your student Caseload
        </Alert>
      </Box>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {studentCaseloadResponse === undefined ? (
        <XNGSpinner fullPage />
      ) : (
        <SidebarLayout
          sidebarContent={STUDENTS_SIDEBAR.tabs}
          content={
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <StudentManagerHeader
                layoutMode={layoutMode}
                onLayoutButtonClick={handleLayoutButtonClick}
                filteredStudents={filteredStudents}
                setFilteredStudents={setFilteredStudents}
                studentCaseLoad={studentCaseloadResponse?.caseloadStudentDisplays ?? []}
                createAndAddStudent={createAndAddStudent}
                deleteStudent={deleteStudent}
                campusList={campusList}
                usesCampusRestrictions={usesCampusRestrictions}
                searchBarState={{
                  value: searchBarValue,
                  onChange: (v) => setSearchBarValue(v),
                }}
              />

              {layoutMode === "grid" && (
                <>
                  {searchBarValue.length > 0 && (
                    <SearchCardResults
                      onCardClick={addStudentToCaseloadByID}
                      filteredStudents={filteredStudents}
                      searchedStudents={searchedStudents}
                      onCardClickSideEffect={() => {
                        setSearchBarValue("");
                      }}
                    />
                  )}

                  {!studentCaseloadResponse?.caseloadStudentDisplays ? (
                    loadingCaseloadFallback
                  ) : (
                    <ProviderCaseloadCardResults
                      filteredStudents={filteredStudents}
                      failedStudentImports={failedStudentImports}
                      totalStudentsInCaseload={
                        studentCaseloadResponse?.caseloadStudentDisplays?.length ?? 0
                      }
                      isLoading={studentCaseloadResponse === null}
                      onStudentAddedSideEffect={() => {
                        setShowToast({
                          show: true,
                          message: "Student added to caseload",
                        });
                      }}
                    />
                  )}
                </>
              )}

              {layoutMode === "list" && (
                <>
                  {searchBarValue.length > 0 && (
                    <SearchCardResults
                      onCardClick={addStudentToCaseloadByID}
                      filteredStudents={filteredStudents}
                      searchedStudents={searchedStudents}
                      onCardClickSideEffect={() => {
                        setSearchBarValue("");
                      }}
                    />
                  )}

                  {!studentCaseloadResponse?.caseloadStudentDisplays ? (
                    loadingCaseloadFallback
                  ) : (
                    <ProviderCaseloadListResults
                      filteredStudents={filteredStudents}
                      failedStudentImports={failedStudentImports}
                      totalStudentsInCaseload={
                        studentCaseloadResponse?.caseloadStudentDisplays?.length ?? 0
                      }
                      isLoading={studentCaseloadResponse === null}
                      onStudentAddedSideEffect={() => {
                        setShowToast({
                          show: true,
                          message: "Student added to caseload",
                        });
                      }}
                    />
                  )}
                </>
              )}
              <Snackbar
                open={showToast.show}
                onClose={() =>
                  setShowToast({
                    show: false,
                    message: showToast.message,
                  })
                }
                TransitionComponent={Slide}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                autoHideDuration={3000}
              >
                <Alert
                  onClose={() =>
                    setShowToast({
                      show: false,
                      message: showToast.message,
                    })
                  }
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {showToast.message}
                </Alert>
              </Snackbar>
            </Box>
          }
        />
      )}
    </>
  );
}

export default StudentManager;
