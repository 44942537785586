import { createContext, useState } from "react";
import Box from "../../../../design/components-dev/BoxExtended";
import AdminHeaderDistrictSelector from "../../common/admin_header_district_selector";
import { Paper } from "@mui/material";
import { DistrictRef } from "../../../../profile-sdk";
import { XNGSlide, XNGSliderViewport } from "../../../../design/low-level/slider_viewport";
import CampusInformationTableView from "./views/table_view";
import {
  CampusInformationContextStore,
  CampusInformationSlide,
  CampusInformationSlide1Screen,
} from "./types/types";
import { CampusInformationSlide1 } from "./views/slide_1";
import { useFetchTable } from "./hooks/use_fetch_table";
import { ADMIN_VISUAL_STANDARD_SPACING } from "../../constants/spacing";
import useClientsIdPatch from "../../../../api/hooks/clients/use_clients_id_patch";
import { useXNGDispatch, useXNGSelector } from "../../../../context/store";
import { selectLoggedInClientAssignment } from "../../../../context/slices/userProfileSlice";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import { setCampusRestrictions } from "../../../../context/slices/loggedInClientSlice";
import QueryStatusModal from "../../../../design/modal_templates/query_status_modal";

const REM_SPACING = "1.5rem";

export const CampusInformationContext = createContext<CampusInformationContextStore>({
  district: null,
  setSelectedSlide: () => {},
  setSlide1Screen: () => {},
  table: null,
});

export default function CampusInformation() {
  const [district, setDistrict] = useState<DistrictRef | null>(null);
  const clientId = useXNGSelector(selectLoggedInClientAssignment).client?.id ?? "";
  const state = useXNGSelector(selectStateInUS);
  const [selectedSlide, setSelectedSlide] = useState<CampusInformationSlide>(0);
  const [slide1Screen, setSlide1Screen] = useState<CampusInformationSlide1Screen>({ id: "add" });
  const [openToggleRestrictionsModal, setOpenToggleRestrictionsModal] = useState(false);
  const usesCampusrestrictions = !!useXNGSelector(state => state.loggedInClient?.usesCampusRestrictions);
  const dispatch = useXNGDispatch();
  const table = useFetchTable({ dependencies: { district } });
  const contextStore: CampusInformationContextStore = {
    district,
    setSelectedSlide,
    setSlide1Screen,
    table,
  };

  const {
    mutateAsync: patchClientAsync,
    isPending: isPatchClientPending,
  } = useClientsIdPatch({
    queryParams: {
      id: clientId,
      state,
    }
  })

  async function toggleCampusRestrictions(checked: boolean) {
    dispatch(setCampusRestrictions(checked));

    try {
      await patchClientAsync({
        usesCampusRestrictions: checked,
      });
    }
    catch (error) {
      setOpenToggleRestrictionsModal(true);
      dispatch(setCampusRestrictions(!checked));
    }
  }

  return (
    <CampusInformationContext.Provider value={contextStore}>
          <Box
            p={REM_SPACING}
            sx={{
              p: ADMIN_VISUAL_STANDARD_SPACING,
              display: "flex",
              flexDirection: "column",
              gap: REM_SPACING,
            }}
          >
            <AdminHeaderDistrictSelector
              onChange={(d) => {
                setDistrict(d);
              }}
              useCampusRestrictions={{
                canToggleCampusRestrictions: !isPatchClientPending,
                isCampusRestricted: usesCampusrestrictions,
                onToggleCampusRestrictions: (checked) => {
                  toggleCampusRestrictions(checked)
                },
              }}
            />

            <Paper
              sx={{
                boxShadow: "3",
                padding: "2rem",
                height: "calc(100vh - 16rem)",
                overflow: "hidden",
              }}
            >
              <XNGSliderViewport selectedSlideIndex={selectedSlide}>
                <XNGSlide>
                  <CampusInformationTableView />
                </XNGSlide>

                <XNGSlide>
                  <CampusInformationSlide1 screen={slide1Screen} />
                </XNGSlide>
              </XNGSliderViewport>
            </Paper>
            <QueryStatusModal
              isOpen={openToggleRestrictionsModal}
              onSettledClose={() => setOpenToggleRestrictionsModal(false)}
              status="error"
              content={{
                errorTitle: "Error",
                errorBody: "There was an error toggling campus restrictions. Please try again.",
              }}
            />
          </Box>
    </CampusInformationContext.Provider>
  );
}
