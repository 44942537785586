import { API_USERS } from "../../../../../api/api";
import useApiMutateData from "../../../../../api/hooks/use_api_mutate_data";
import { PatchClientAssignmentRequest } from "../../../../../profile-sdk";
import useCaseLoadManager from "./use_case_load_manager";

export const usePatchUserToClientAssignments = () => {
  const {
    state: { clientId, stateInUs },
  } = useCaseLoadManager();
  return useApiMutateData({
    mutationFn: async (request: { body: PatchClientAssignmentRequest; userId: string }) =>
      await API_USERS.v1UsersIdClientAssignmentsClientIdPatch(
        request.userId ?? "",
        clientId ?? "",
        stateInUs,
        request.body,
      ),
    mutationKey: ["v1UsersIdClientAssignmentsClientIdPatch", stateInUs, clientId],
  });
};
