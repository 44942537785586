import { TextField, TextFieldProps } from "@mui/material";
import { forwardRef } from "react";

/**
 * `StudentProfileTextField` is a custom text field component designed for the student profile view.
 * It uses the `forwardRef` API to pass down a ref to the underlying `TextField` component.
 *
 * @component
 * @param {TextFieldProps} props - The properties passed to the `TextField` component.
 * @param {React.Ref<HTMLDivElement>} ref - The ref to be forwarded to the `TextField` component.
 *
 * @default
 * - `variant`: "outlined" - Provides a consistent outlined style for the text field.
 * - `fullWidth`: true - Ensures the text field spans the full width of its container.
 * - `size`: "small" - Uses a smaller size for a more compact and user-friendly design.
 * - `FormHelperTextProps`:
 *   - `sx`:
 *     - `color`: "error.main" - Sets the helper text color to the error color for better visibility of errors.
 *     - `ml`: 0 - Removes the left margin for a cleaner layout.
 *
 * These default styles are chosen to maintain a consistent and user-friendly design across the student profile view.
 */
const StudentProfileTextField = forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {
  return (
    <TextField
      variant="outlined"
      fullWidth
      size="small"
      {...props}
      FormHelperTextProps={{
        ...props?.FormHelperTextProps,
        sx: {
          color: "error.main",
          ml: 0,
          ...props?.FormHelperTextProps?.sx,
        },
      }}
      InputProps={{
        ...props?.InputProps,
        sx: {
          borderRadius: 0,
          ...props?.InputProps?.sx,
        },
      }}
      ref={ref}
    />
  );
});

export default StudentProfileTextField;
