import React from "react";
import {
  DistrictRef,
  UserManagementHistoryCard as UserManagementHistoryCardType,
} from "../../../../../../../../profile-sdk";
import { GetXlogsRoleStringFromEnum } from "../../../../../../../../utils/xlogs_role_mapper";
import useApiQueryGetServiceProviderTypesByDate from "../../../../../../../../api/hooks/state_snapshots/use_api_query_get_service_provider_types_by_date";
import { XLogsRoleStrings } from "../../../../../../../../context/types/xlogsrole";
import { MenuItem, Select, Typography } from "@mui/material";
import UserApprovalsCardShape from "./user_approvals_card_shape";

type Props = {
  userHistoryCard: UserManagementHistoryCardType;
  selectData: {
    serviceProviderApiOptionsManager: ReturnType<typeof useApiQueryGetServiceProviderTypesByDate>;
    districtsOptions: DistrictRef[];
    xlogsRoleOptions: XLogsRoleStrings[];
  };
};

const UserApprovalsHistoryCard = (props: Props) => {
  const {
    user,
    client,
    districts,
    currentStatus,
    xLogsRole,
    historicalStatuses,
    initialRegistrationDate,
  } = props.userHistoryCard;

  const [viewHistory, setViewHistory] = React.useState(false);

  return (
    <UserApprovalsCardShape
      useAvatarNameSection={{
        firstName: user?.firstName,
        lastName: user?.lastName,
      }}
      useClientDistrictStatusSection={{
        clientName: client?.name,
        status: currentStatus,
      }}
      useDistrictsSection={
        <Select
          multiple
          value={districts?.map((d) => JSON.stringify(d)) ?? []}
          onChange={undefined}
          disabled={props.selectData.districtsOptions === undefined}
          fullWidth
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {props.selectData.districtsOptions.map((district) => {
            return (
              <MenuItem key={district?.id} value={JSON.stringify(district)}>
                {district?.name}
              </MenuItem>
            );
          })}
        </Select>
      }
      useEmailSection={{
        email: user?.email,
      }}
      useServiceProviderTypeSection={
        <Select
          multiple
          value={[]}
          onChange={undefined}
          disabled={true}
          fullWidth
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {props.selectData.serviceProviderApiOptionsManager.data?.serviceProviderTypes?.map(
            (spType) => {
              return (
                <MenuItem key={spType.id} value={JSON.stringify(spType)}>
                  {spType.name}
                </MenuItem>
              );
            },
          )}
        </Select>
      }
      useXlogsRoleDropDownSection={
        <Typography>{GetXlogsRoleStringFromEnum(xLogsRole ?? 0)}</Typography>
      }
      useHistory={{
        historicalStatuses: historicalStatuses,
        initialRegisterationDate: initialRegistrationDate,
        viewHistory: viewHistory,
      }}
      onCardClick={() => {
        setViewHistory((prev) => !prev);
      }}
    />
  );
};

export default UserApprovalsHistoryCard;
