import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import {
  SessionResponse,
  UpdateSessionRequest,
  Location,
  ActualSession,
  GetSessionSeriesResponse,
  AddStudentToSessionRequest,
} from "../../session-sdk";
import { useXNGSelector } from "../../context/store";
import { selectStateInUS } from "../../context/slices/stateInUsSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { getUserTimeZoneByState, timezoneAdjustedStartOrEndTimes } from "../../utils/timeZones";
import { produce } from "immer";
import EditSessionFormWrapper from "./edit_session_form_wrapper";
import { EDIT_SESSION_MODAL_VALIDATION_SCHEMA } from "../../constants/edit_session_modal_validation_schema";
import { StudentRef } from "../../profile-sdk";
import { SchedulerFieldValues } from "./types";
import useConvertSessionToSeriesHandler from "../notator/hooks/use_convert_session_to_series_handler";
import QueryStatusModal from "../../design/modal_templates/query_status_modal";
import { useState } from "react";
import { selectClientID } from "../../context/slices/loggedInClientSlice";
import { API_SESSIONS } from "../../api/api";

interface EditTodaysSessionModalProps {
  open: boolean;
  startDate: Date;
  endDate: Date;
  onClose: () => void;
  editedSession?: SessionResponse;
  saveSession: (freshSession: SessionResponse) => Promise<SessionResponse | void>;
  setDraftSession?: (value: React.SetStateAction<SessionResponse>) => void;
  onRequestRefreshSessions?: () => void;
  studentCaseload?: StudentRef[];
  studentList?: StudentRef[];
  modalType: "single" | "metadata";
}

export default function EditTodaysSessionModal(props: Readonly<EditTodaysSessionModalProps>) {
  const {
    open,
    startDate,
    endDate,
    onClose,
    editedSession,
    saveSession,
    setDraftSession,
    onRequestRefreshSessions,
    studentCaseload,
    studentList,
    modalType,
  } = props;

  // REDUX SELECTORS
  const userStateInUS = useXNGSelector(selectStateInUS);
  const [existingSessionRecurrencePattern, setExistingSessionRecurrencePattern] =
    useState<GetSessionSeriesResponse>({} as GetSessionSeriesResponse);
  const clientId = useXNGSelector(selectClientID);

  // REACT HOOK FORM
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm<SchedulerFieldValues>({
    resolver: yupResolver(EDIT_SESSION_MODAL_VALIDATION_SCHEMA),
  });

  const [renderSingleToSeriesConversionErrorModal, setRenderSingleToSeriesConversionErrorModal] =
    useState(false);
  const { handleSetRecurrencePattern, convertSingleSessionToSessionSeries } =
    useConvertSessionToSeriesHandler();

  const [dayofWeekSelection, setDayOfWeekSelection] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [radio_onafter, setradio_onafter] = useState<"on" | "after">("on");
  const [includeNonSchoolDays, setIncludeNonSchoolDays] = useState(false);

  const handleDateCheckBoxChange = (index: number) => {
    const updatedSelection = [...dayofWeekSelection];
    updatedSelection[index] = !updatedSelection[index];
    setDayOfWeekSelection(updatedSelection);
  };

  const onEditSubmit = async (data: SchedulerFieldValues) => {
    // Timezone adjustments
    const { timezoneAdjustedStartTime, timezoneAdjustedEndTime } = timezoneAdjustedStartOrEndTimes(
      userStateInUS,
      "data",
      data.startTime,
      data.endTime,
    );

    const updateSessionRequest: UpdateSessionRequest = {
      id: editedSession!.id,
      title: data.title,
      service: {
        id: data.service.id,
        name: data.service.name,
        description: data.service.description,
        area: data.service.area,
        type: data.service.serviceType,
      },
      groupSetting: data.sessionType === "group",
      groupSize: data.groupSize,
      meetingDetails: {
        date: dayjs(data.startTime).startOf("day").toDate(),
        startTime: timezoneAdjustedStartTime as Date,
        endTime: timezoneAdjustedEndTime as Date,
        location: { name: data.location, description: data.locationDescription } as Location, // Todo: ID later
      },
      studentJournalList: editedSession!.studentJournalList?.map((item) => ({
        ...item,
        studentAttendanceRecord: {
          ...item.studentAttendanceRecord,
          arrivalTime: timezoneAdjustedStartTime as Date,
          departureTime: timezoneAdjustedEndTime as Date,
        },
      })),
    };

    let updatedSession: SessionResponse;
    // Removing students is straightforward, adding students has its own API call
    if (
      data.studentList.length &&
      editedSession?.studentJournalList?.length &&
      data.studentList.length !== editedSession?.studentJournalList?.length
    ) {
      if (data.studentList.length > editedSession?.studentJournalList.length) {
        const studentListIds = data.studentList.map((student) => student.id!);
        const studentJournalListIds =
          editedSession?.studentJournalList?.map((studentJournal) => studentJournal.student?.id) ||
          [];

        const requestBody: AddStudentToSessionRequest = {
          id: editedSession?.id,
          clientId,
          studentIds: studentListIds.filter(
            (studentId) => !studentJournalListIds.includes(studentId),
          ),
          serviceArea: editedSession?.service!.area,
          serviceProviderId: editedSession?.serviceProvider!.id,
          seriesId: editedSession?.seriesId,
          sessionDate: editedSession?.meetingDetails!.date,
          timezone: getUserTimeZoneByState(userStateInUS),
        };

        updatedSession = await API_SESSIONS.v1SessionsAddStudentPatch(userStateInUS, requestBody);
      } else {
        const updatedStudentJournalList = editedSession!.studentJournalList?.filter(
          (studentJournal) =>
            data.studentList.some((student) => student.id === studentJournal.student?.id),
        );

        updatedSession = produce(editedSession || {}, (draft) => {
          draft.studentJournalList = updatedStudentJournalList;
        });
      }
    } else {
      updatedSession = produce(editedSession || {}, (draft) => {
        draft = Object.assign(draft, updateSessionRequest);
      });
    }

    const conversion = await convertSingleSessionToSessionSeries({
      session: updatedSession,
      userStateInUS,
    });

    if (conversion.status === "complete") {
      updatedSession = conversion.session as ActualSession;
    } else if (conversion.status === "error") {
      setRenderSingleToSeriesConversionErrorModal(true);
    }

    if (setDraftSession) {
      setDraftSession(updatedSession);
    }
    saveSession(updatedSession);

    if (onRequestRefreshSessions && conversion.status !== "error") {
      onRequestRefreshSessions();
    }

    if (conversion.status !== "error") {
      onClose();
    }
  };

  const editSessionFormWrapperProps = {
    open,
    startDate,
    endDate,
    onClose: () => {
      if (!renderSingleToSeriesConversionErrorModal) {
        onRequestRefreshSessions?.();
        onClose();
      }
    },
    editedSession,
    submitCallback: onEditSubmit,
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    studentCaseload,
    studentList,
    setDayOfWeekSelection,
    dayofWeekSelection,
    setIncludeNonSchoolDays,
    includeNonSchoolDays,
    setradio_onafter,
    radio_onafter,
    handleDateCheckBoxChange,
    existingSessionRecurrencePattern,
  };

  return (
    <>
      <EditSessionFormWrapper
        modalType={modalType}
        {...editSessionFormWrapperProps}
        handleSetRecurrencePattern={handleSetRecurrencePattern}
      />
      <QueryStatusModal
        isOpen={renderSingleToSeriesConversionErrorModal}
        status={"error"}
        content={{
          errorTitle: "Error!",
          errorBody:
            "There was an error converting this session to a series. Please try again later.",
        }}
        onSettledClose={() => setRenderSingleToSeriesConversionErrorModal(false)}
      />
    </>
  );
}
