import React, { createContext, useMemo, useReducer } from "react";
import { useXNGSelector } from "../../../../../context/store";
import {
  selectLoggedInClientAssignment,
  selectUser,
} from "../../../../../context/slices/userProfileSlice";
import { selectStateInUS } from "../../../../../context/slices/stateInUsSlice";
import { ClientAssignment, UserResponse } from "../../../../../profile-sdk";
import { selectClientID } from "../../../../../context/slices/loggedInClientSlice";
import { CaseLoadManagementActions, caseLoadManagerReducer } from "./caseload_management_reducer";
import { ServiceProviderRef } from "@xng/profile";
import { CaseLoadUserTableRow } from "../tables/case_load_user_table";

export type CaseloadManagementContextType = {
  state: CaseLoadManagementRootState;
  dispatch: React.Dispatch<CaseLoadManagementActions>;
};

export type ModalStatus = "success" | "failure" | "pending" | "idle";

export type CaseLoadManagementRootState = {
  clientAssignment: ClientAssignment;
  user: UserResponse | null;
  stateInUs: string;
  clientId: string;
  selectedApprover: ServiceProviderRef | null;
  selectedDecApprover: ServiceProviderRef | null; 
  selectedServiceProviderCaseLoads: CaseLoadUserTableRow[];
  decServiceProviders: CaseLoadUserTableRow[];
};

export const CaseloadManagementContext = createContext<CaseloadManagementContextType>(
  {} as CaseloadManagementContextType,
);

export default function CaseloadManagementProvider(props: Readonly<{ children: React.ReactNode }>) {
  const clientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const user = useXNGSelector(selectUser);
  const stateInUs = useXNGSelector(selectStateInUS);
  const clientId = useXNGSelector(selectClientID);

  const initialState: CaseLoadManagementRootState = useMemo(() => {
    return {
      clientAssignment,
      user,
      stateInUs,
      clientId,
      selectedApprover: null, 
      selectedServiceProviderCaseLoads: [],
      decServiceProviders: [],
      selectedDecApprover: null,
    };
  }, [clientAssignment, user, stateInUs]);

  const [state, dispatch] = useReducer(caseLoadManagerReducer, initialState);

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [initialState, state],
  );

  return (
    <CaseloadManagementContext.Provider value={value}>
      {props.children}
    </CaseloadManagementContext.Provider>
  );
}
