 
import { ApiResponse, V1StudentReportsStudentInstancesReportGetReportPostRequest } from "@xng/reporting"; 
import { API_STUDENT_INSTANCES_REPORT } from "../../../../../api/api";
import useApiMutatePollData from "../../../../../api/hooks/use_api_mutate_poll_data";
import XNGApiMutateParamObject from "../../../../../types/xng_api_mutate_param_object";

type Data = Awaited<
  ReturnType<typeof API_STUDENT_INSTANCES_REPORT.v1StudentReportsStudentInstancesReportGetCSVPostRaw>
> extends ApiResponse<infer T>
  ? T
  : never;
type Body = V1StudentReportsStudentInstancesReportGetReportPostRequest; // generic parameter B is used to define body the type of the useApiMutateData hook

export const useApiMutatePollInstanceReport = (
  paramObject?: Partial<XNGApiMutateParamObject<{}, Data, Body>>,
) => {
  return useApiMutatePollData({
    mutationFn: async (body: Body) =>
      await API_STUDENT_INSTANCES_REPORT.v1StudentReportsStudentInstancesReportGetCSVPostRaw(body),
    mutationKey: ["v1StudentReportsStudentInstancesReportGetCSVPostRaw"],
    ...(paramObject?.options || {}),
  });
};

export type { Data as InstanceReportCsvData, Body as InstanceReportCsvRequestBody };
