import { Button, InputAdornment, Tooltip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

type Props = {
  tooltipTitle: string;
  onRemoveBtnClick: () => void;
};

export default function CareProvisionsInputFieldEndAdornment(props: Readonly<Props>) {
  return (
    <Tooltip
      title={props.tooltipTitle}
      placement="top"
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 15],
              },
            },
          ],
        },
      }}
    >
      <InputAdornment position="end">
        <Button
          size="small"
          sx={{
            p: 0,
            minWidth: 0,
            "&.MuiButton-sizeSmall": {
              height: "min-content",
            },
          }}
          color="error"
          onClick={props.onRemoveBtnClick}
        >
          <ClearIcon fontSize={"small"} sx={{ color: "white" }} />
        </Button>
      </InputAdornment>
    </Tooltip>
  );
}
