import useUserRoles from "../../../../../hooks/use_user_roles";
import TabContentWrapper from "../components/tab_content_wrapper";
import CareProvisionsTab from "../tabs/care_provisions_tab";
import GeneraInformationTab from "../tabs/general_information_tab";
import GoalsAndObjectivesTabs from "../tabs/goals_and_objectives_tab";
import MedicaidInfoTab from "../tabs/medicaid_info_tab";
import PlanOfCareInfoTab from "../tabs/plan_of_care_info_tab";

export type StudentProfileTabLabel =
  | "General Information"
  | "Plan Of Care Info"
  | "Medicaid Info"
  | "Goals/Obj Info"
  | "Care Provision";

type StudentProfileTab = {
  label: StudentProfileTabLabel;
  content: JSX.Element;
  attributes: {
    shouldRenderTab: (params: {
      featureDisabledByStateflag: boolean;
      roles: ReturnType<typeof useUserRoles>;
    }) => boolean;
  };
};

const studentProfileTabsItems: StudentProfileTab[] = [
  {
    label: "General Information",
    content: (
      <TabContentWrapper>
        <GeneraInformationTab />
      </TabContentWrapper>
    ),
    attributes: {
      shouldRenderTab(_) {
        return true;
      },
    },
  },
  {
    label: "Plan Of Care Info",
    content: (
      <TabContentWrapper>
        <PlanOfCareInfoTab />
      </TabContentWrapper>
    ),
    attributes: {
      shouldRenderTab(_) {
        return true;
      },
    },
  },
  {
    label: "Medicaid Info",
    content: (
      <TabContentWrapper>
        <MedicaidInfoTab />
      </TabContentWrapper>
    ),
    attributes: {
      shouldRenderTab({ featureDisabledByStateflag, roles }) {
        // render tab if user is an admin or use the feature flag rendered by state
        return (
          roles.includes("Delegated Admin") ||
          roles.includes("Executive Admin") ||
          !featureDisabledByStateflag
        );
      },
    },
  },
  {
    label: "Goals/Obj Info",
    content: <GoalsAndObjectivesTabs />,
    attributes: {
      shouldRenderTab(_) {
        return true;
      },
    },
  },
  {
    label: "Care Provision",
    content: (
      <TabContentWrapper>
        <CareProvisionsTab />
      </TabContentWrapper>
    ),
    attributes: {
      shouldRenderTab(_) {
        return true;
      },
    },
  },
];

export default studentProfileTabsItems;
