import { useMemo } from "react";
import { useNotatorTools } from "../tools";

const useTotalBillableTimesForAllStudentsInCurrentSession = (): number => {
  const { draftSession: editedSession } = useNotatorTools();

  const totalBillableMintesForAllStudentsInCurrentSession = useMemo(() => {
    return editedSession?.studentJournalList?.reduce((acc, studentJournal) => {
      if (!studentJournal.studentAttendanceRecord?.present) return acc + 0;
      return (
        acc +
        Number(
          studentJournal.careProvisionLedger?.activities?.reduce(
            (acc, activity) => acc + (activity?.totalMinutesSpent ?? 0),
            0,
          ),
        )
      );
    }, 0);
  }, [editedSession?.studentJournalList]);

  return totalBillableMintesForAllStudentsInCurrentSession!;
};

export default useTotalBillableTimesForAllStudentsInCurrentSession;
