import { styled } from "@mui/material/styles";
import Box, { type BoxProps } from "@mui/material/Box";

interface XNGLabelTextContainerProps extends BoxProps {
  flexItems?: boolean;
}

export default styled(Box, {
  shouldForwardProp: (prop) => prop !== "flexItems",
})<XNGLabelTextContainerProps>(({ flexItems = true }) => ({
  position:"relative",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: 20,
}));
