import { Box } from "@mui/material";
import Grid, { GridOwnProps, RegularBreakpoints } from "@mui/material/Grid/Grid";

type Props = {
  gridContainerProps?: GridOwnProps;
  items: {
    xs?: RegularBreakpoints["xs"];
    sm?: RegularBreakpoints["sm"];
    md?: RegularBreakpoints["md"];
    lg?: RegularBreakpoints["lg"];
    sx?: GridOwnProps["sx"];
    key: string;
    content: React.ReactNode;
  }[];
};

export default function InputFieldGrid(props: Readonly<Props>) {
  return (
    <Grid container spacing={2} {...props.gridContainerProps}>
      {props.items.map((gridItem) => {
        return (
          <Grid
            sx={gridItem.sx}
            key={gridItem.key}
            item
            xs={gridItem?.xs ?? 12}
            sm={gridItem?.sm ?? 6}
            md={gridItem?.md ?? 5}
            lg={gridItem?.lg ?? 4}
          >
            <Box>{gridItem.content}</Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
