import { useEffect, useState } from "react";

const useQueryICDCodes = () => {
  const [icdCodes, setIcdCodes] = useState<string[]>([]);
  const [isLoadingICDCodes, setIsLoadingICDCodes] = useState(false);
  const [hasIcdCodesError, setHasIcdCodesError] = useState(false);

  useEffect(() => {
    const icdCodesFile = "/icd10cm_codes_2024.txt";
    fetch(icdCodesFile)
      .then((response) => {
        setIsLoadingICDCodes(true);
        return response.text();
      })
      .then((text) => {
        const codes = text.split("\n");
        setIcdCodes(codes);
      })
      .catch((err) => {
        setIsLoadingICDCodes(false);
        setHasIcdCodesError(err);
      })
      .finally(() => {
        setIsLoadingICDCodes(false);
      });
  }, []);

  return { icdCodes, isLoadingICDCodes, hasIcdCodesError };
};

export default useQueryICDCodes;
