import { useState } from "react";
import { SessionResponse, StudentJournal } from "../../../session-sdk";
import { useNotatorTools } from "../tools";
import { FutureTabs } from "../types/types";
import { Dialog, Typography } from "@mui/material";
import Box from "../../../design/components-dev/BoxExtended";
import XNGCheckbox from "../../../design/low-level/checkbox";
import { getSizing } from "../../../design/sizing";
import XNGButton from "../../../design/low-level/button";
import produce from "immer";

function RemoveStudentModal(
  props: Readonly<{
    studentJournalList: StudentJournal[];
    showRemoveStudent: boolean;
    setShowRemoveStudent: React.Dispatch<React.SetStateAction<boolean>>;
    setSession: React.Dispatch<React.SetStateAction<SessionResponse>>;
    saveSession: Function;
    applyFuture: Array<FutureTabs[]>;
    setApplyFuture: React.Dispatch<React.SetStateAction<Array<FutureTabs[]>>>;
  }>,
) {
  const notatorTools = useNotatorTools();

  const [checked, setChecked] = useState<boolean[]>(
    props.studentJournalList.map(() => {
      return false;
    }),
  );

  const handleDone = async () => {
    const newStudentJournalList = props.studentJournalList;
    for (let i = checked.length - 1; i >= 0; i--) {
      if (checked[i]) {
        if (newStudentJournalList.length === 1) {
          return;
        }
        newStudentJournalList.splice(i, 1);
      }
    }
    setChecked(newStudentJournalList.map(() => false));

    /* 
    Update the care provision ledger for available students in the students journal list
    oddly enough, it seems at some point in the notator process there was a synchronization issue
    and the care provision ledger was not updated for the students in the student journal list. so we do it here. 
    not sure why we have to seperate stores/states for the same data point. An issue that would need to be addressed in the future.
    */
    const mutatedJournalList = produce(newStudentJournalList, (draft) => {
      draft.forEach((studentJournal) => {
        const index =
          notatorTools.draftSession.studentJournalList?.findIndex(
            (journal) => journal.student?.id === studentJournal.student?.id,
          ) ?? -1;
        if (index !== -1) {
          if (notatorTools.draftSession.studentJournalList) {
            studentJournal.careProvisionLedger =
              notatorTools.draftSession.studentJournalList[index].careProvisionLedger;
          }
        }
        const totalNumberOfStudentsInTheList = notatorTools?.draftSession?.studentJournalList?.length ?? 0
        const isLastStudentBeingRemoved = notatorTools?.selectedStudentIndex === ( totalNumberOfStudentsInTheList - 1)
        if (totalNumberOfStudentsInTheList !== 0 && isLastStudentBeingRemoved ) { 
           notatorTools?.setSelectedStudentIndex(0);
        }
      });
    });

    const sessionRes = await notatorTools.addRemoveStudentActions.remove(mutatedJournalList);

    notatorTools.saveSession(sessionRes);
  };

  return (
    <Dialog
      className="noselect"
      onClose={() => {
        props.setShowRemoveStudent(false);
      }}
      open={props.showRemoveStudent}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: getSizing(2),
          paddingTop: getSizing(7),
          paddingX: getSizing(2),
        }}
      >
        <Typography variant="h6">Remove Student(s)</Typography>
        <Typography variant="body1">Remove student(s) from today's session only.</Typography>
        {props.studentJournalList.map((student, i) => {
          const handleToggle = () => {
            let temp = checked;
            temp[i] = !temp[i];
            setChecked([...temp]);
          };
          return (
            <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
              <XNGCheckbox checked={checked[i]} onToggle={handleToggle} />
              <Typography variant="body1">
                {student.student?.firstName} {student.student?.lastName}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: getSizing(4),
          paddingTop: getSizing(2),
        }}
      >
        <XNGButton
          onClick={() => {
            props.setShowRemoveStudent(false);
            handleDone();
          }}
        >
          Done
        </XNGButton>
      </Box>
    </Dialog>
  );
}

export default RemoveStudentModal;
