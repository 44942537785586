import { Box } from "@mui/material";
import {
  DataGrid,
  GridRowSelectionModel,
  GridCellParams,
  GridPaginationModel
} from "@mui/x-data-grid";
import { MSBDataGridNoRowsOverlay } from "../../../../../../fortitude";

export interface InstanceReportUserTableRow {
  id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  localId: string;
  medicaidId: string;
  unpostedSessionCount: number;
  postedSessionCount: number;
  providerNames: string; // Could be a comma-separated string for multiple providers
  serviceArea: string;
}

type Props = {
  rows: InstanceReportUserTableRow[];
  initialPageSize?: number;
  onRowClick?: (row: any) => void;
  onSelectionChange?: (selectedRows: InstanceReportUserTableRow[]) => void;
  loading: boolean;
  paginationState: {
    paginationModel: GridPaginationModel;
    setPaginationModel: (paginationModel: GridPaginationModel) => void;
    totalRecords?: number;
  }; 
};

// Updated column definitions with conditional styling for Posted Sessions
const columnDef = [
  { field: "firstName", headerName: "Student First Name", flex: 1 },
  { field: "lastName", headerName: "Student Last Name", flex: 1 },
  { field: "birthDate", headerName: "Birth Date", flex: 1 },
  { field: "localId", headerName: "Local ID", flex: 1 },
  { field: "medicaidId", headerName: "Medicaid ID", flex: 1 },
  { field: "unpostedSessionCount", headerName: "Unposted Session Count", flex: 1 },
  {
    field: "postedSessionCount",
    headerName: "Posted Session Count",
    flex: 1,
    cellClassName: (params: GridCellParams) => (params.value === 0 ? "highlighted-cell" : ""),
  },
  { field: "providerNames", headerName: "Provider Names", flex: 1 },
  { field: "serviceArea", headerName: "Service Area", flex: 1 },
];

export function InstanceReportTable(props: Readonly<Props>) {
  const rowCount = props?.rows?.length ?? 0;

  const handleSelectionModelChange = (newSelectionModel: GridRowSelectionModel) => {
    const newSelectedRows = props?.rows?.filter((row) => newSelectionModel.includes(row.id));
    props?.onSelectionChange?.(newSelectedRows);
  };

  return (
    <Box
      aria-label="student-data-table"
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "800px",
        height: props.rows.length <= 8 ? "400px" : "650px",
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "contrasts.1",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          justifyContent: "left",
        },
        "& [role=row]:hover": {
          cursor: "pointer",
        },
        "& .highlighted-cell": {
          backgroundColor: "#FFF3CD", // Soft yellow background
          color: "#856404", // Dark text for contrast
          fontWeight: "bold",
        },
        "& .MuiDataGrid-row.Mui-selected": {
          backgroundColor: "transparent !important",
        },
        "& .MuiDataGrid-row:focus, & .MuiDataGrid-row:focus-within": {
          outline: "none !important",
        },
        "& .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
      }}
    >
      <DataGrid
        rows={props.rows ?? []}
        columns={columnDef}
        rowCount={rowCount}
        slots={{
          noRowsOverlay: MSBDataGridNoRowsOverlay("No instance report found"),
        }}
        sortingOrder={["asc", "desc"]}
        onRowSelectionModelChange={handleSelectionModelChange}
        pagination
        pageSizeOptions={[10, 20, 50, 100]}
        disableColumnSelector 
        disableColumnFilter
        paginationMode="client"
        initialState={{
          pagination: { paginationModel: { pageSize: 10, page: 0 } },
        }}
        paginationModel={props.paginationState.paginationModel}
        onPaginationModelChange={props.paginationState.setPaginationModel} 
        loading={props.loading}
        disableRowSelectionOnClick
        onRowClick={(params) => props.onRowClick?.(params.row)}
      />
    </Box>
  );
}
