import {
  ApiResponse,
  V1StudentReportsStudentInstancesReportGetCSVPostRequest,
} from "@xng/reporting";
import { API_STUDENT_INSTANCES_REPORT } from "../../../../../api/api"; 
import useApiMutatePollData from "../../../../../api/hooks/use_api_mutate_poll_data";
import XNGApiMutateParamObject from "../../../../../types/xng_api_mutate_param_object"; 

type Body = V1StudentReportsStudentInstancesReportGetCSVPostRequest;
type Data = Awaited<
  ReturnType<
    typeof API_STUDENT_INSTANCES_REPORT.v1StudentReportsStudentInstancesReportGetReportPostRaw
  >
> extends ApiResponse<infer T>
  ? T
  : never;

const useGenerateInstanceReportPolling = (
  paramObject?: Partial<XNGApiMutateParamObject<{}, Data, Body>>,
) => {
  const poll = useApiMutatePollData({
    mutationFn: async (body: Body) =>
      await API_STUDENT_INSTANCES_REPORT.v1StudentReportsStudentInstancesReportGetReportPostRaw({
        getStudentInstanceReportRequest: {
          ...body.getStudentInstanceReportRequest
        },
      }),

    mutationKey: ["v1StudentReportsStudentInstancesReportGetReportPostRaw"],
    ...(paramObject?.options || {}),
  });


  return poll;
};

export default useGenerateInstanceReportPolling;
