import dayjs, { Dayjs } from "dayjs";
import { useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { StudentRef } from "../../profile-sdk";
import {
  CreateRecurrencePatternRequest,
  CreateRecurringSesssionRequest,
  DayOfWeek,
  Location,
  RecurrenceMode,
} from "../../session-sdk";
import { CreateSessionRequest } from "../../session-sdk";
import { API_SESSIONS } from "../../api/api";
import { useXNGSelector } from "../../context/store";
import { selectStateInUS } from "../../context/slices/stateInUsSlice";
import { selectLoggedInClientAssignment } from "../../context/slices/userProfileSlice";
import { placeholderForFutureLogErrorText } from "../../temp/errorText";
import { SchedulerFieldValues } from "./types";
import { selectActingServiceProvider } from "../../context/slices/dataEntryProvider";
import { timezoneAdjustedStartOrEndTimes } from "../../utils/timeZones";
import useRefetchUnpostedSessionsRequests from "../unposted_sessions/hooks/use_refetch_unposted_sessions_requests";
import { EDIT_SESSION_MODAL_VALIDATION_SCHEMA } from "../../constants/edit_session_modal_validation_schema";
import EditSessionFormWrapper from "../modals/edit_session_form_wrapper";

export interface ISchedulerModal {
  open: boolean;
  startDate: Date;
  endDate: Date;
  onClose: () => void;
  studentCaseload: StudentRef[];
  onRequestRefreshSessions: () => void;
}
export function SchedulerModal(props: ISchedulerModal) {
  // REDUX SELECTORS
  const userStateInUS = useXNGSelector(selectStateInUS);
  const loggedInClientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const actingServiceProvider = useXNGSelector(selectActingServiceProvider);
  const { refetchOnSessionAction } = useRefetchUnpostedSessionsRequests();
  // LOCAL STATES
  const existingSessionRecurrencePattern = {};

  // REACT HOOK FORM
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<SchedulerFieldValues>({
    resolver: yupResolver(EDIT_SESSION_MODAL_VALIDATION_SCHEMA),
  });

  // INITIAL VALUES
  const START: Dayjs = dayjs(props.startDate);
  const END: Dayjs = dayjs(props.endDate);
  const { open, startDate, endDate, onClose, studentCaseload } = props;

  const sessionType = useWatch({ control, name: "sessionType" });

  // --------------- USEEFFECTS / API ---------------
  // SET DEFAULTS ON OPEN / CLOSE, CASELOAD CHANGE
  useEffect(() => {
    reset();
    setValue("studentList", new Array<StudentRef>());
    setValue("minutesDuration", END.diff(START, "minutes"));
  }, [props.studentCaseload, props.open]);

  const studentList = useWatch({ control, name: "studentList" }) || [];

  useEffect(() => {
    if (sessionType === "group") return;
    if (studentList.length > 1) {
      setValue("studentList", [studentList[0]]);
    }
  }, [sessionType]);
  // Set services based on user's date selection

  // --------------- CUSTOM STATES FOR CUSTOM FORM ---------------
  const [radio_onafter, setradio_onafter] = useState<"on" | "after">("on");
  const [includeNonSchoolDays, setIncludeNonSchoolDays] = useState(false);

  const [dayofWeekSelection, setDayOfWeekSelection] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleDateCheckBoxChange = (index: number) => {
    const updatedSelection = [...dayofWeekSelection];

    updatedSelection[index] = !updatedSelection[index];
    setDayOfWeekSelection(updatedSelection);
  };

  // --------------- ONSUBMIT ---------------
  const onSubmit = async (data: SchedulerFieldValues) => {
    postSessionToDB();
    props.onClose();
    reset();

    async function postSessionToDB() {
      // Timezone adjustments
      const { timezoneAdjustedStartTime, timezoneAdjustedEndTime } =
        timezoneAdjustedStartOrEndTimes(userStateInUS, "data", data.startTime, data.endTime);

      const createSessionRequest: CreateSessionRequest = {
        title: data.title,
        groupSize: studentList.length,
        service: {
          id: data.service.id,
          name: data.service.name,
          description: data.service.description,
          area: data.service.area,
          type: data.service.serviceType,
        }, //hdata.service as SessionSDKService, // Cast as because difference in fields, one field is a later feature
        groupSetting: data.sessionType === "group",
        meetingDetails: {
          date: dayjs(data.startTime).startOf("day").toDate(),
          startTime: timezoneAdjustedStartTime as Date,
          endTime: timezoneAdjustedEndTime as Date,
          location: { name: data.location, description: data.locationDescription } as Location,
        },
        serviceProvider: actingServiceProvider,
        studentIds: data.studentList.map((s) => {
          if (s.id) {
            return s.id;
          } else {
            throw new Error(placeholderForFutureLogErrorText);
          }
        }),
        client: {
          id: loggedInClientAssignment.client!.id!,
          name: loggedInClientAssignment.client!.name!,
        },
      };

      // is recurring
      if (!data.recurrence || data.recurrence === "none") {
        await API_SESSIONS.v1SessionsPost(
          loggedInClientAssignment.client!.id!,
          userStateInUS,
          createSessionRequest,
        );
      } else {
        let recurrencePattern: CreateRecurrencePatternRequest = {};

        const includedDays: DayOfWeek[] = [
          DayOfWeek.NUMBER_0,
          DayOfWeek.NUMBER_1,
          DayOfWeek.NUMBER_2,
          DayOfWeek.NUMBER_3,
          DayOfWeek.NUMBER_4,
          DayOfWeek.NUMBER_5,
          DayOfWeek.NUMBER_6,
        ].filter((day, index) => dayofWeekSelection[index]);

        let recurrenceEndDate = undefined;
        let numberOfOccurrences = undefined;
        if (radio_onafter === "on") {
          recurrenceEndDate =
            dayjs(data.recurrenceCustom?.ends.onDate).format("YYYY-MM-DD") ??
            dayjs().add(5, "years").format("YYYY-MM-DD");
        } else {
          numberOfOccurrences = data.recurrenceCustom?.ends.afterOccurences;
        }

        let recurrenceMode;
        switch (data.recurrenceCustom?.repeatEveryDuration) {
          case "day":
            recurrenceMode = RecurrenceMode.NUMBER_0;
            break;
          case "week":
            recurrenceMode = RecurrenceMode.NUMBER_1;
            break;
          case "month":
            recurrenceMode = RecurrenceMode.NUMBER_2;
            break;
          default:
            recurrenceMode = RecurrenceMode.NUMBER_1;
            break;
        }

        recurrencePattern = {
          endDate: recurrenceEndDate,
          endNumberOfOccurrences: numberOfOccurrences,
          includedDaysOfWeek: includedDays,
          mode: recurrenceMode,
          interval:
            data.recurrenceCustom?.repeatEveryIncrement! >= 1
              ? data.recurrenceCustom?.repeatEveryIncrement
              : 1,
          includeNonSchoolDay: includeNonSchoolDays,
        };
        const createRecurringSessionRequest: CreateRecurringSesssionRequest = {
          session: createSessionRequest,
          recurrencePattern: recurrencePattern,
        };

        await API_SESSIONS.v1SessionsRecurringSessionPost(
          loggedInClientAssignment.client!.id!,
          userStateInUS,
          createRecurringSessionRequest,
        );
      }

      // refresh sessions
      props.onRequestRefreshSessions();
      refetchOnSessionAction();
    }
  };

  const editSessionFormWrapperProps = {
    open,
    startDate,
    endDate,
    onClose,
    submitCallback: onSubmit,
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    studentCaseload,
    setDayOfWeekSelection,
    dayofWeekSelection,
    setIncludeNonSchoolDays,
    includeNonSchoolDays,
    setradio_onafter,
    radio_onafter,
    handleDateCheckBoxChange,
    existingSessionRecurrencePattern,
  };
  return <EditSessionFormWrapper modalType="scheduler" {...editSessionFormWrapperProps} />;
}

export default SchedulerModal;
