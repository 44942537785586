import { API_SERVICEPROVIDERS } from "../../../../../api/api";
import useApiQueryData from "../../../../../api/hooks/use_api_query_data";
import useCaseLoadManager from "./use_case_load_manager";

export const useCaseLoadMutation = () => {
  const {
    state: { clientId, stateInUs, selectedApprover },
  } = useCaseLoadManager();
  return useApiQueryData({
    queryFn: async () =>
      await API_SERVICEPROVIDERS.v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet(
        selectedApprover?.id ?? "",
        stateInUs,
        clientId,
      ),
    queryKey: [
      "v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet",
      selectedApprover?.id,
      stateInUs,
      clientId,
    ],
    enabled: !!selectedApprover?.id,
  });
};
