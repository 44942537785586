import { ApproverCaseload } from "../../../../../profile-sdk";
import { CaseLoadUserTableRow } from "../tables/case_load_user_table";

export function convertToServiceProviderType(arg: ApproverCaseload): CaseLoadUserTableRow {
  const nameParts = arg?.providerName?.split(" ") || ["", ""];
  return {
    id: arg?.id ?? "",
    firstName: nameParts[0],
    lastName: nameParts.slice(1).join(" "), // Handle names with more than two parts
    primaryCampus: arg?.campus ?? "",
    providerRole: arg?.providerRole ?? "",
  };
}
