import { UserManagementStatus } from "../../../../../../../profile-sdk";

const statusRemap: Record<UserManagementStatus, { label: string; color: string }> = {
  0: {
    label: "Unapproved",
    color: "warning.2",
  },
  1: {
    label: "Approved",
    color: "success.2",
  },
  2: {
    label: "Denied",
    color: "error.2",
  },
};

export default statusRemap;
