import { Box } from "@mui/material";
import { Outlet } from "react-router";

/**
 * 
 * @remarks This is where the side bar will reside ultimately, but in order for that to happen successfully,
 * we would need to refactor the student manager
 */
export default function StudentsTabLayout() {
  return (
    <Box sx={{ height: "100%", maxHeight: "100%", position: "relative", overflow: "hidden" }}>
      <Outlet />
    </Box>
  );
}
