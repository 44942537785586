/**
 * This is an array returning the sortable columns of the session logs report.
 * StudentLastName = 0, StudentFirstName = 1, StudentId = 2, ServiceProviderName = 3, ServiceDate = 4, SessionStatus = 5, Service = 6,
 * SessionType = 7, GroupSize = 8, SessionNarrative = 9, StudentNarrative = 10, ScheduledDuration = 11, TimeAway = 12, NetDuration = 13,
 * StartTime = 14, EndTime = 15, Location = 16, DistrictOfLiability = 17, School = 18, Present = 19, Makeup = 20, SubmittedDate = 21,
 * PostedDate = 22, StudentObservations = 23, GoalsObjectives = 24, Activities = 25, Accommodations = 26, Modifications = 27
 * @export
 */

import { SessionLogsSortableColumn } from "@xng/reporting";

const sessionLogsReportSortableColumnsEnumMap = {
  studentLastName: SessionLogsSortableColumn.NUMBER_0,
  studentFirstName: SessionLogsSortableColumn.NUMBER_1,
  studentId: SessionLogsSortableColumn.NUMBER_2,
  serviceProviderName: SessionLogsSortableColumn.NUMBER_3,
  serviceDate: SessionLogsSortableColumn.NUMBER_4,
  sessionStatus: SessionLogsSortableColumn.NUMBER_5,
  service: SessionLogsSortableColumn.NUMBER_6,
  sessionType: SessionLogsSortableColumn.NUMBER_7,
  groupSize: SessionLogsSortableColumn.NUMBER_8,
  sessionNarrative: SessionLogsSortableColumn.NUMBER_9,
  studentNarrative: SessionLogsSortableColumn.NUMBER_10,
  scheduledDuration: SessionLogsSortableColumn.NUMBER_11,
  timeAway: SessionLogsSortableColumn.NUMBER_12,
  netDuration: SessionLogsSortableColumn.NUMBER_13,
  startTime: SessionLogsSortableColumn.NUMBER_14,
  endTime: SessionLogsSortableColumn.NUMBER_15,
  location: SessionLogsSortableColumn.NUMBER_16,
  districtOfLiability: SessionLogsSortableColumn.NUMBER_17,
  school: SessionLogsSortableColumn.NUMBER_18,
  present: SessionLogsSortableColumn.NUMBER_19,
  makeup: SessionLogsSortableColumn.NUMBER_20,
  submittedDate: SessionLogsSortableColumn.NUMBER_21,
  postedDate: SessionLogsSortableColumn.NUMBER_22,
  studentObservations: SessionLogsSortableColumn.NUMBER_23,
  goalsObjectives: SessionLogsSortableColumn.NUMBER_24,
  activities: SessionLogsSortableColumn.NUMBER_25,
  accommodations: SessionLogsSortableColumn.NUMBER_26,
  modifications: SessionLogsSortableColumn.NUMBER_27,
  billableMinutes: SessionLogsSortableColumn.NUMBER_28,
  serviceSupportMinutes: SessionLogsSortableColumn.NUMBER_29,
};

const sessionLogsReportSortableColumnsStringMap = {
  [Number(SessionLogsSortableColumn.NUMBER_0)]: "studentLastName",
  [Number(SessionLogsSortableColumn.NUMBER_1)]: "studentFirstName",
  [Number(SessionLogsSortableColumn.NUMBER_2)]: "studentId",
  [Number(SessionLogsSortableColumn.NUMBER_3)]: "serviceProviderName",
  [Number(SessionLogsSortableColumn.NUMBER_4)]: "serviceDate",
  [Number(SessionLogsSortableColumn.NUMBER_5)]: "sessionStatus",
  [Number(SessionLogsSortableColumn.NUMBER_6)]: "service",
  [Number(SessionLogsSortableColumn.NUMBER_7)]: "sessionType",
  [Number(SessionLogsSortableColumn.NUMBER_8)]: "groupSize",
  [Number(SessionLogsSortableColumn.NUMBER_9)]: "sessionNarrative",
  [Number(SessionLogsSortableColumn.NUMBER_10)]: "studentNarrative",
  [Number(SessionLogsSortableColumn.NUMBER_11)]: "scheduledDuration",
  [Number(SessionLogsSortableColumn.NUMBER_12)]: "timeAway",
  [Number(SessionLogsSortableColumn.NUMBER_13)]: "netDuration",
  [Number(SessionLogsSortableColumn.NUMBER_14)]: "startTime",
  [Number(SessionLogsSortableColumn.NUMBER_15)]: "endTime",
  [Number(SessionLogsSortableColumn.NUMBER_16)]: "location",
  [Number(SessionLogsSortableColumn.NUMBER_17)]: "districtOfLiability",
  [Number(SessionLogsSortableColumn.NUMBER_18)]: "school",
  [Number(SessionLogsSortableColumn.NUMBER_19)]: "present",
  [Number(SessionLogsSortableColumn.NUMBER_20)]: "makeup",
  [Number(SessionLogsSortableColumn.NUMBER_21)]: "submittedDate",
  [Number(SessionLogsSortableColumn.NUMBER_22)]: "postedDate",
  [Number(SessionLogsSortableColumn.NUMBER_23)]: "studentObservations",
  [Number(SessionLogsSortableColumn.NUMBER_24)]: "goalsObjectives",
  [Number(SessionLogsSortableColumn.NUMBER_25)]: "activities",
  [Number(SessionLogsSortableColumn.NUMBER_26)]: "accommodations",
  [Number(SessionLogsSortableColumn.NUMBER_27)]: "modifications",
  [Number(SessionLogsSortableColumn.NUMBER_28)]: "billableMinutes",
  [Number(SessionLogsSortableColumn.NUMBER_29)]: "serviceSupportMinutes",
};

export { sessionLogsReportSortableColumnsEnumMap, sessionLogsReportSortableColumnsStringMap };
