import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import XNGLabelTextContainer from "../../common/xng-label-text-container";
import XNGLabelText from "../../common/xng-label-text";
import dayjs from "dayjs";
import { useUnpostedSessionsBatchPostContext } from "../../../providers/unposted_sessions_batch_post_provider";
import { ActualSession } from "../../../../../../session-sdk";
import { timezoneAdjustedStartOrEndTimes } from "../../../../../../utils/timeZones";

interface SessionListItemProps {
  session: ActualSession;
  checked: boolean;
  onSelect: (checked: boolean) => void;
}

export default function SessionListItem({ session, checked, onSelect }: SessionListItemProps) {
  const { selectedStudentIds, stateInUs } = useUnpostedSessionsBatchPostContext();

  const { timezoneAdjustedStartTime: sessionStartTime, timezoneAdjustedEndTime: sessionEndTime } =
    timezoneAdjustedStartOrEndTimes(
      stateInUs,
      "display",
      dayjs(session.meetingDetails?.startTime),
      dayjs(session.meetingDetails?.endTime),
    );

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column", py:5}}
      >
        <FormControlLabel
          sx={{ marginBottom: 1.5 }}
          checked={checked}
          onChange={(_, checked) => onSelect(checked)}
          control={<Checkbox size="small" />}
          label={
            <XNGLabelTextContainer component="span" flexItems={false}>
              <XNGLabelText
                label="Service Provider: "
                text={
                  session &&
                  session.serviceProvider &&
                  session.serviceProvider.firstName! + session.serviceProvider.lastName
                }
                size="medium"
              />
              <XNGLabelText
                label="Date: "
                text={dayjs(sessionStartTime).format("MM/DD/YYYY")}
                size="medium"
              />
              <XNGLabelText
                label="Service: "
                text={session && session.service && session.service.name}
                size="medium"
              />
            </XNGLabelTextContainer>
          }
        />
      
        <XNGLabelTextContainer sx={{  marginBottom: 1.5 }}>
          <XNGLabelText label="Session Title: " text={session?.title} />
          <XNGLabelText label="Start Time: " text={dayjs(sessionStartTime).format("LT")} />
          <XNGLabelText label="End Time: " text={dayjs(sessionEndTime).format("LT")} />
          <XNGLabelText
            label="Duration: "
            text={`${dayjs(session?.meetingDetails?.endTime)
              .diff(session?.meetingDetails?.startTime, "m")
              .toString()} minutes`}
          />
          <XNGLabelText label="Location: " text={session?.meetingDetails?.location?.name} />
          <XNGLabelText
            label="Group Size: "
            text={
              session?.groupSetting
                ? `Group (${session?.studentJournalList?.length})`
                : "Individual"
            }
          />
          <XNGLabelText label="Session Narrative: " text={session?.sessionJournal?.narrative} />
        </XNGLabelTextContainer>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <XNGLabelText sx={{ marginBottom: 1.5 }} label="Students: " size="big" />

          {session?.studentJournalList?.map(
            ({
              student,
              studentAttendanceRecord,
              observationSection,
              careProvisionLedger,
              approval,
            }) => {
              const { signedByFullName, signedOnDateLocal } =
                approval?.authorizingProviderSignature || {};
              const {
                timezoneAdjustedStartTime: studentArrivalTime,
                timezoneAdjustedEndTime: studentDepartureTime,
              } = timezoneAdjustedStartOrEndTimes(
                stateInUs,
                "display",
                dayjs(studentAttendanceRecord?.arrivalTime || session.meetingDetails?.startTime),
                dayjs(studentAttendanceRecord?.departureTime || session.meetingDetails?.endTime),
              );

              return (
                selectedStudentIds.includes(student?.id ?? "") && (
                  <Box
                    key={student?.id}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      "&:not(:last-child)": {
                        marginBottom: 1.5,
                      },
                    }}
                  >
                    <XNGLabelTextContainer sx={{ marginBottom: 1.5 }}>
                      <XNGLabelText label={`${student?.firstName} ${student?.lastName}`} />

                      {approval?.authorizingProvider && (
                        <XNGLabelText
                          label={`Approved by ${signedByFullName} on ${dayjs(
                            signedOnDateLocal,
                          ).format("MM/DD/YYYY")}`}
                        />
                      )}
                    </XNGLabelTextContainer>
                    <XNGLabelTextContainer
                      sx={{
                        marginBottom: 1.5,
                      }}
                    >
                      <XNGLabelText
                        label="Start Time: "
                        text={dayjs(studentArrivalTime || sessionStartTime).format("LT")}
                      />
                      <XNGLabelText
                        label="End Time: "
                        text={dayjs(studentDepartureTime || sessionEndTime).format("LT")}
                      />
                      <XNGLabelText
                        label="Duration: "
                        text={`${dayjs(
                          studentAttendanceRecord?.departureTime || session.meetingDetails?.endTime,
                        )
                          .diff(
                            studentAttendanceRecord?.arrivalTime ||
                              session.meetingDetails?.startTime,
                            "m",
                          )
                          .toString()} minutes`}
                      />
                      {session.service?.area?.id === "5" && (
                        <XNGLabelText
                          label="Total Billable Minutes: "
                          text={`${!studentAttendanceRecord?.present ? 0 : careProvisionLedger?.activities?.reduce(
                            (total: number, activity) => {
                              return total + Number(activity.totalMinutesSpent ?? 0);
                            },
                            0,
                          )?.toString() ?? "0"} minutes`}
                        />
                      )}
                      <XNGLabelText label="Rationale: " text={studentAttendanceRecord?.rationale} />
                      <XNGLabelText
                        label="Student Narrative: "
                        text={observationSection?.narrative}
                      />
                      <XNGLabelText
                        label="Time Away: "
                        text={studentAttendanceRecord?.timeAwayMinutes?.toString() ?? ""}
                      />
                                      <XNGLabelText
                        label="Activities: "
      
                        text={(careProvisionLedger?.activities ?? [])
                          .map((activity: any) => activity.name)
                          .join(", ")}
                      />
                      <XNGLabelText
      
                        label="Accomodations: "
                        text={(careProvisionLedger?.accommodations ?? [])
                          .map((activity: any) => activity.name)
                          .join(", ")}
                      />
                      <XNGLabelText
      
                        label="Modifications: "
                        text={(careProvisionLedger?.modifications ?? [])
                          .map((activity: any) => activity.name)
                          .join(", ")}
                      />
                      <XNGLabelText
                        label="Observations: "
                        text={(
                          (observationSection)?.observations ?? []
                        )
                          .map((obs) => obs)
                          .join(", ")}
                      />
                    </XNGLabelTextContainer>
                  </Box>
                )
              );
            },
          )}
        </Box>
      </Box>
      <Divider />
    </>
  );
}
