import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
  Switch,
} from "@mui/material";
import { useNotatorTools } from "../../tools";
import { MSBTypedSelect } from "../../../../fortitude";
import { useMemo, useState } from "react";
import { ScheduleOfService, StudentProfile } from "../../../../profile-sdk";
import { LabelValue } from "./components/label_value";
import dayjs from "dayjs";
import useV1SessionGetMinutesSpentOnOrderedServiceArea from "./hooks/v1_session_get_minutes_spent_on_ordered_service_area";
import { NonSchoolDaysContainer } from "../../../../browser/non_school_days/district_non_school_days";
import useSessionStorage from "../../../../browser/use_session_storage";
import { useLabelCalculator } from "./hooks/use_label_calculator";

const SPACING = "1rem";

// refactor this to use base class i introduced

export function ServiceAnalysisView(props: Readonly<{ visibleStudentIndex: number }>) {
  const { draftSession, cachedResponsesByStudentIndex } = useNotatorTools();

  const studentProfile: StudentProfile | null = useMemo(() => {
    if (!cachedResponsesByStudentIndex) return null;

    return cachedResponsesByStudentIndex[props.visibleStudentIndex]?.studentProfile ?? null;
  }, [cachedResponsesByStudentIndex]);

  const studentScheduleOfServiceOptions: ScheduleOfService[] = useMemo(
    () =>
      studentProfile?.spedDossier?.scheduleOfServices?.filter((scheduledService) => {
        if (!scheduledService.endDate || !scheduledService.startDate) return false;
        const startDate = dayjs(scheduledService.startDate);
        const endDate = dayjs(scheduledService.endDate);
        const sessionDate = dayjs(draftSession.meetingDetails?.date);
        return sessionDate.isBetween(startDate, endDate, "day", "[]");
      }) ?? [],
    [studentProfile],
  );

  const [selectedOption, setSelectedOption] = useState<ScheduleOfService | null>(null);

  const minutesSpentOnOrderedServiceArea = useV1SessionGetMinutesSpentOnOrderedServiceArea({
    studentID: studentProfile?.id!,
    providerID: draftSession.serviceProvider!.id!,
    serviceAreaID: selectedOption?.serviceArea?.id!,
    start: dayjs(selectedOption?.startDate),
    end: dayjs(selectedOption?.endDate),
  });

  const [useTeamAggregatedData, setUseTeamAggregatedData] = useState<boolean>(false);

  const nonSchoolDayCtr = useSessionStorage("nonSchoolDays") as NonSchoolDaysContainer;

  const labels = useLabelCalculator({
    selectedOption,
    draftSession,
    minutesSpentOnOrderedServiceArea,
    useTeamAggregatedData,
    nonSchoolDayCtr,
  });

  return (
    <Box sx={{ minHeight: "6rem" }}>
      {studentScheduleOfServiceOptions.length > 0 && (
        <>
          <FormControl>
            <FormLabel sx={{ mb: SPACING }}>Ordered Service:</FormLabel>
            <MSBTypedSelect
              options={studentScheduleOfServiceOptions}
              getDisplayValue={(v) => v.subject ?? ""}
              onChange={(v) => setSelectedOption(v)}
            />
          </FormControl>

          {labels && (
            <Stack sx={{ py: SPACING, gap: SPACING }}>
              <>
                <LabelValue label="Duration:" value={labels.duration} />
                <LabelValue label="Frequency:" value={labels.frequency} />
                <LabelValue label="Start and End Date:" value={labels.startAndEndDate} />
                <LabelValue
                  label="Remaining Days of Services Available:"
                  value={labels.remainingDaysOfServicesAvailable}
                />
              </>

              <Divider />

              <FormControlLabel
                control={
                  <Switch
                    checked={useTeamAggregatedData}
                    onClick={() => setUseTeamAggregatedData(!useTeamAggregatedData)}
                  />
                }
                label="Use team aggregated data?"
              />

              <LabelValue label="Scheduled Minutes:" value={labels.scheduledMinutes} />
              <LabelValue label="Time Away from Student:" value={labels.timeAwayFromStudent} />
              <LabelValue label="Net Duration:" value={labels.netDuration} />
              <LabelValue label="Remaining Minutes Needed:" value={labels.remainingMinutesNeeded} />
            </Stack>
          )}
        </>
      )}
    </Box>
  );
}
