import { useCallback, useEffect, useState } from "react";
import { useNotatorTools } from "../../../tools";
import { NotatorObservation } from "../types/observation";
import { produce } from "immer";
import { ValidationResponse } from "../../../../../types/validation_response";
import toggleFromArray from "../../../../../utils/getToggledArray";

const CLEARED_VALUE = { checked: false, text: "" };

/**
 * One-off hook that encapsulates staging input component details for `observations.tsx`.
 */
export function useStagingInputDetails(props: {selectedStudentIndex: number | undefined}) {
  const { draftSession, setDraftSession, selectedStudentIndex } = useNotatorTools();
  const actualStudentIndex = props.selectedStudentIndex ? props.selectedStudentIndex : selectedStudentIndex;

  const [value, setValue] = useState<NotatorObservation>(CLEARED_VALUE);

  useEffect(() => setValue(CLEARED_VALUE), [selectedStudentIndex]);

  const handleValidate = useCallback(
    (v: NotatorObservation): ValidationResponse => {
      // Check if has text
      if (v.text.length === 0) return { isValid: false };

      // Make sure custom care provision ledger doesn't already contain text
      if (
        draftSession
          .sessionJournal!.customCareProvisionLedger!.observations!.map((ob) =>
            ob.toLocaleLowerCase(),
          )
          .includes(v.text.toLocaleLowerCase())
      )
        return { isValid: false, error: "This custom observation already exists" };

      return { isValid: true };
    },
    [draftSession, actualStudentIndex],
  );

  const handleConfirm = useCallback(
    (v: NotatorObservation) => {
      try {
        // Clear text, keep check status for convenience
        setValue({ checked: false, text: "" });
        setDraftSession(
          produce((draft) => {
            // Add to custom care provision ledger
            draft.sessionJournal!.customCareProvisionLedger!.observations!.push(v.text);

            // Add as checked if user checked the staged input
            if (v.checked) {
              const currentCheckedObs = [
                ...draft.studentJournalList![actualStudentIndex].observationSection!
                  .observations!,
              ];

              draft.studentJournalList![actualStudentIndex].observationSection!.observations =
                toggleFromArray(v.text, currentCheckedObs);
            }
          }),
        );
      } catch (e) {
        // TODO: display error
        console.error(e);
      }
    },
    [draftSession, setValue, actualStudentIndex, setDraftSession],
  );

  return {
    value,
    setValue,
    handleValidate,
    handleConfirm,
  };
}
