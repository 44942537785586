import * as yup from "yup";

const ERROR = "Entry is required";

export const locationOptions = [
  "School",
  "Telehealth - Virtual",
  "Telehealth - Audio",
  "Office",
  "Home",
  "Community",
]

export const sessionTypeOptions = ["group", "individual"]

export const EDIT_SESSION_MODAL_VALIDATION_SCHEMA = yup.object().shape({
  title: yup.string().required(ERROR),
  sessionType: yup.string().oneOf(sessionTypeOptions, ERROR).required(ERROR),
  dateOccurs: yup.date().required(ERROR),
  startTime: yup.date().required(ERROR),
  endTime: yup.date().required(ERROR),
  minutesDuration: yup
    .number()
    .typeError("Entry is not a number")
    .required(ERROR)
    .min(1, "Duration must be over 0"),
  location: yup.string().oneOf(locationOptions, ERROR).required(ERROR),
  groupSize: yup.number().required(ERROR),
  studentList: yup.array().min(1, "Must have at least one student in session"),
});

