import { useState, useEffect, useMemo } from "react";
import { API_FINANCIAL_DASHBOARD_REPORT } from "../../../../../api/api";
import Box from "../../../../../design/components-dev/BoxExtended";
import {
  BillingBlocks,
  ReimbursementByServiceWidget,
  ReimbursmentGoalTrackingWidget,
} from "../components/widgets";
import { FinancialDashboardReport } from "@xng/reporting";
import useFinancialDashboard from "../hooks/useFinancialDashboard";
import { ActionType } from "../context/financial_dashboard_actions";
import { useXNGSelector } from "../../../../../context/store";
import {
  ProviderParticipationByServiceCategoryDataGrid,
  RemitanceDataGrid,
  BillingSummaryWidget,
} from "../components";
import { DistrictRef } from "../../../../../profile-sdk";
import AdminHeaderDistrictSelector from "../../../common/admin_header_district_selector";
import { selectLoggedInClientAssignment } from "../../../../../context/slices/userProfileSlice";
import NotAuthorizedIndicator from "../../../../../components/not_authorized";
import useUserRoles from "../../../../../hooks/use_user_roles";

const DistrictProfile = () => {
  const [district, setDistrict] = useState<DistrictRef | null>(null);
  const { dispatch } = useFinancialDashboard();
  const isFinancialDashboardFeatureAllowed = useXNGSelector(
    (state) => state.featureFlags.flags["FinancialDashboardActive"],
  );
  const accountName = useXNGSelector((state) => state.loggedInClient?.name);
  const loggedInClient = useXNGSelector(selectLoggedInClientAssignment);
  const authorizedDistricts = loggedInClient.authorizedDistricts;
  useEffect(() => {
    if (!hasPermissionsToViewFinances) return;

    const fetchFinancialReport = async () => {
      try {
        // set loading state
        dispatch({ type: ActionType.LOADING, payload: "fetching financial dashboard api..." });
        const financialDashboardReport: FinancialDashboardReport =
          await API_FINANCIAL_DASHBOARD_REPORT.v1FinancialDashboardReportFinancialDashboardReportGetReportGet(
            { accountName, districtName: district?.name },
          );

        if (!financialDashboardReport) {
          console.log("financial dashboard report not found", financialDashboardReport);
        }

        // set global state
        dispatch({
          type: ActionType.SET_FINANCIAL_DASHBOARD_REPORT,
          payload: financialDashboardReport,
        });
      } catch (error) {
        console.log("failed to fetch financial dashboard api", error);
        // report error to context state
        dispatch({ type: ActionType.FAILED, payload: "failed to fetch financial dashboard api" });
      }
    };

    if (district?.name && accountName) {
      fetchFinancialReport();
    }
  }, [dispatch, district?.name, accountName]);

  useEffect(() => {
    if (Array.isArray(authorizedDistricts) && authorizedDistricts?.length > 0)
      setDistrict(authorizedDistricts[0]);
  }, [authorizedDistricts]);

  const userRoles = useUserRoles();
  const hasPermissionsToViewFinances = useMemo(
    () => userRoles.includes("Executive Admin"),
    [userRoles],
  );

  // only allow if dashboard feature is permitted
  if (!isFinancialDashboardFeatureAllowed) return null;

  return (
        hasPermissionsToViewFinances ? (
          <FinancialDashboard
            districtName={district?.name as string}
            header={
              <AdminHeaderDistrictSelector
                onChange={(d) => {
                  setDistrict(d);
                }}
              />
            }
          />
        ) : (
          <NotAuthorizedIndicator />
        )
  );
};

function FinancialDashboard(props: Readonly<{ header: React.ReactNode; districtName: string }>) {
  const { header, districtName } = props;

  return (
    <Box maxWidth={"1650px"}>
      <Box maxWidth={"98%"} pt={2} pl={2}>
        <Box width={"100%"}>
          <Box width={"100%"}>{header}</Box>
          <Box width={"100%"} mt={"24px"} borderRadius={4} overflow={"hidden"} boxShadow={5}>
            <ReimbursementByServiceWidget />
          </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
              margin: "24px 0px",
              width: "100%",
              height: "569px",
            }}
          >
            {/* Reimbursement goal tracking goes here */}
            <Box
              style={{
                flexShrink: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "38%",
              }}
              boxShadow={5}
              borderRadius={4}
            >
              <ReimbursmentGoalTrackingWidget />
            </Box>
            {/* 5 year billing summary goes here */}
            <Box
              style={{
                flexShrink: 0,
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: "1",
              }}
              px={4}
              boxShadow={5}
              borderRadius={4}
            >
              <BillingSummaryWidget />
            </Box>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
              margin: "24px 0px",
              maxHeight: "723px",
            }}
          >
            {/* billing blocks */}
            <Box
              style={{
                flexShrink: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "fit-content",

                overflow: "hidden",
              }}
              boxShadow={5}
              borderRadius={4}
            >
              <BillingBlocks />
            </Box>
            {/* Remmitance Data Grid goes here */}
            <Box style={{ flex: 1 }} boxShadow={5} overflow={"hidden"} borderRadius={4}>
              <RemitanceDataGrid districtName={districtName} />
            </Box>
          </div>
          <Box style={{ width: "100%", overflow: "hidden" }} boxShadow={5} borderRadius={4}>
            {/* Provider participation by service category goes here */}
            <ProviderParticipationByServiceCategoryDataGrid />
          </Box>
        </Box>
        <Box sx={{ height: "10vh", width: "100%", background: "transparent" }} />
      </Box>
    </Box>
  );
}

export default DistrictProfile;
