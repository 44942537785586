import { Box, Divider, FormHelperText, MenuItem, Typography } from "@mui/material";
import InputFieldGrid from "../components/input_field_grid";
import useStudentProfileContext from "../hooks/use_student_profile_context";
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { MSBInputErrorWrapper } from "../../../../../fortitude";
import { DistrictOfLiability, SchoolCampusAssignment } from "@xng/profile";
import useApiQueryCampusAndDistrictOptionsBasedOnState from "../hooks/general_info_tab/use_api_query_campus_and_district_options_based_on_state";
import StudentProfileTextField from "../components/student_profile_text_field";
import StudentImportDisabledInputTooltip from "../components/student_import_disabled_input_tooltip";

export default function GeneraInformationTab() {
  const {
    studentProfileForm: {
      register,
      control,
      formState: { errors },
    },
    queryStudentProfileApiClient: { data: studentProfile },
    mutateStudentProfileApiClient: { isPending: mutateStudentProfileIsLoading },
    globalState: { stateInUs },
  } = useStudentProfileContext();

  const {
    getDistrictsOptionsBasedOnState,
    campusApiQueryClientManager: {
      data: campusOptionsResponse,
      isError: isCampusOptionsError,
      isLoading: isCampusOptionsLoading,
      refetch: refetchCampusOptions,
    },
  } = useApiQueryCampusAndDistrictOptionsBasedOnState({
    stateInUs,
  });

  // this flag is responsible for disabling input fields when a student is imported from legacy.
  const isStudentImportFromLegacy = studentProfile?.createdBy === 1;
  const disableInputFields = mutateStudentProfileIsLoading;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Demographics
        </Typography>
        <Box>
          <InputFieldGrid
            items={[
              {
                key: "firstName",
                content: (
                  <StudentProfileTextField
                    id="firstName"
                    label="First Name"
                    {...register("generalInformation.firstName")}
                    helperText={errors.generalInformation?.firstName?.message}
                    FormHelperTextProps={{
                      error: !!errors.generalInformation?.firstName?.message,
                    }}
                    disabled={disableInputFields}
                  />
                ),
              },
              {
                key: "middleName",
                content: (
                  <StudentProfileTextField
                    id="middleName"
                    label="Middle Name"
                    {...register("generalInformation.middleName")}
                    helperText={errors.generalInformation?.middleName?.message}
                    FormHelperTextProps={{
                      error: !!errors.generalInformation?.middleName?.message,
                    }}
                    disabled={disableInputFields}
                  />
                ),
              },
              {
                key: "lastName",
                content: (
                  <StudentProfileTextField
                    id="lastName"
                    label="Last Name"
                    variant="outlined"
                    {...register("generalInformation.lastName")}
                    helperText={errors.generalInformation?.lastName?.message}
                    FormHelperTextProps={{
                      error: !!errors.generalInformation?.lastName?.message,
                    }}
                    disabled={disableInputFields}
                  />
                ),
              },
              {
                key: "grade",
                content: (
                  <Controller
                    control={control}
                    name="generalInformation.grade"
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <StudentImportDisabledInputTooltip
                          fullwidth
                          show={isStudentImportFromLegacy}
                          state={stateInUs}
                        >
                          <StudentProfileTextField
                            select
                            type="number"
                            id="grade"
                            label="Grade"
                            value={field.value}
                            onChange={field.onChange}
                            helperText={error?.message}
                            FormHelperTextProps={{
                              error: !!error?.message,
                            }}
                            disabled={isStudentImportFromLegacy || disableInputFields}
                          >
                            {[
                              {
                                value: 0,
                                label: "PreK",
                              },
                              {
                                value: 1,
                                label: "K",
                              },
                              {
                                value: 2,
                                label: "First",
                              },
                              {
                                value: 3,
                                label: "Second",
                              },
                              {
                                value: 4,
                                label: "Third",
                              },
                              {
                                value: 5,
                                label: "Fourth",
                              },
                              {
                                value: 6,
                                label: "Fifth",
                              },
                              {
                                value: 7,
                                label: "Sixth",
                              },
                              {
                                value: 8,
                                label: "Seventh",
                              },
                              {
                                value: 9,
                                label: "Eighth",
                              },
                              {
                                value: 10,
                                label: "Ninth",
                              },
                              {
                                value: 11,
                                label: "Tenth",
                              },
                              {
                                value: 12,
                                label: "Eleventh",
                              },
                              {
                                value: 13,
                                label: "Twelfth",
                              },
                              {
                                value: 14,
                                label: "Other",
                              },
                            ].map((grade) => (
                              <MenuItem key={grade.label} value={grade.value}>
                                {grade.label}
                              </MenuItem>
                            ))}
                          </StudentProfileTextField>
                        </StudentImportDisabledInputTooltip>
                      );
                    }}
                  />
                ),
              },
              {
                key: "dateOfBirth",
                content: (
                  <Controller
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <Box>
                          <DatePicker
                            label="Date of Birth"
                            value={dayjs(field.value)}
                            onChange={(date) => {
                              field.onChange(dayjs(date));
                            }}
                            slots={{
                              textField: StudentProfileTextField,
                            }}
                            slotProps={{
                              textField: {
                                FormHelperTextProps: {
                                  error: !!errors.generalInformation?.dateOfBirth?.message,
                                },
                              },
                              actionBar: {
                                actions: ["clear"],
                              },
                            }}
                            disabled={disableInputFields}
                          />
                          <FormHelperText
                            error={!!error?.message}
                            // sx={{ position: "absolute", bottom: -15, left: 1, whiteSpace: "nowrap" }}
                          >
                            {error?.message}
                          </FormHelperText>
                        </Box>
                      );
                    }}
                    name="generalInformation.dateOfBirth"
                    control={control}
                  />
                ),
              },
              {
                key: "gender",
                content: (
                  <Controller
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <StudentProfileTextField
                          id="gender"
                          label="Gender"
                          select
                          helperText={error?.message}
                          value={field.value}
                          onChange={field.onChange}
                          disabled={disableInputFields}
                          FormHelperTextProps={{
                            error: !!error?.message,
                          }}
                        >
                          {[
                            {
                              value: 0,
                              label: "Male",
                            },
                            {
                              value: 1,
                              label: "Female",
                            },
                            {
                              value: 2,
                              label: "Unknown",
                            },
                          ].map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </StudentProfileTextField>
                      );
                    }}
                    name="generalInformation.gender"
                    control={control}
                  />
                ),
              },
              {
                key: "primaryLanguage",
                content: (
                  <StudentProfileTextField
                    id="primaryLanguage"
                    label="Primary Language"
                    {...register("generalInformation.primaryLanguage")}
                    disabled={disableInputFields}
                    helperText={errors.generalInformation?.primaryLanguage?.message}
                    FormHelperTextProps={{
                      error: !!errors.generalInformation?.primaryLanguage?.message,
                    }}
                  />
                ),
              },
            ]}
          />
        </Box>
      </Box>
      <Divider />
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Identification
        </Typography>
        <Box>
          <InputFieldGrid
            items={[
              {
                key: "studentIdGivenBySchoolDistrict",
                content: (
                  <StudentProfileTextField
                    id="studentIdGivenBySchoolDistrict"
                    label="Student ID"
                    {...register("generalInformation.studentIdGivenBySchoolDistrict")}
                    disabled={disableInputFields}
                    helperText={errors.generalInformation?.studentIdGivenBySchoolDistrict?.message}
                    FormHelperTextProps={{
                      error: !!errors.generalInformation?.studentIdGivenBySchoolDistrict?.message,
                    }}
                  />
                ),
              },
              {
                key: "medicaidId",
                content: (
                  <StudentImportDisabledInputTooltip
                    fullwidth
                    show={isStudentImportFromLegacy}
                    state={stateInUs}
                  >
                    <StudentProfileTextField
                      id="medicaidId"
                      label="Medicaid ID"
                      disabled={isStudentImportFromLegacy || disableInputFields}
                      {...register("generalInformation.medicaidId")}
                      helperText={errors.generalInformation?.medicaidId?.message}
                      FormHelperTextProps={{
                        error: !!errors.generalInformation?.medicaidId?.message,
                      }}
                    />
                  </StudentImportDisabledInputTooltip>
                ),
              },
              {
                key: "studentIdGivenByState",
                content: (
                  <StudentImportDisabledInputTooltip
                    fullwidth
                    show={isStudentImportFromLegacy}
                    state={stateInUs}
                  >
                    <StudentProfileTextField
                      id="studentIdGivenByState"
                      label="Student State ID"
                      variant="outlined"
                      disabled={isStudentImportFromLegacy || disableInputFields}
                      {...register("generalInformation.studentIdGivenByState")}
                      helperText={errors.generalInformation?.studentIdGivenByState?.message}
                      FormHelperTextProps={{
                        error: !!errors.generalInformation?.studentIdGivenByState?.message,
                      }}
                    />
                  </StudentImportDisabledInputTooltip>
                ),
              },
            ]}
          />
        </Box>
      </Box>
      <Divider />
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          District Assignment
        </Typography>
        <Box>
          <InputFieldGrid
            items={[
              {
                key: "schoolCampus",
                content: (
                  <Controller
                    control={control}
                    name="generalInformation.schoolCampus"
                    render={({ field }) => {
                      return (
                        <StudentImportDisabledInputTooltip
                          fullwidth
                          show={isStudentImportFromLegacy}
                          state={stateInUs}
                        >
                          <MSBInputErrorWrapper
                            isError={isCampusOptionsError}
                            displayRefreshButton={isCampusOptionsError}
                            refetch={refetchCampusOptions}
                            errorText={
                              "Failed to retrieve list of campuses, please click refresh icon to try again."
                            }
                          >
                            <StudentProfileTextField
                              key={field.value?.id ?? ""}
                              select={(campusOptionsResponse?.schoolCampuses ?? []).length > 0}
                              id="schoolCampus"
                              label={isCampusOptionsLoading ? "Loading options..." : "Campus"}
                              disabled={
                                isCampusOptionsLoading ||
                                isCampusOptionsError ||
                                isStudentImportFromLegacy ||
                                disableInputFields
                              }
                              value={field.value ? field.value.id : ""}
                              onChange={(e) => {
                                const campus = campusOptionsResponse?.schoolCampuses?.find(
                                  (campus) => campus.id === e.target.value,
                                );
                                const campusWithDate: SchoolCampusAssignment = {
                                  ...campus,
                                  attendanceStartDate: new Date(),
                                  attendanceEndDate: null,
                                };
                                field.onChange(campusWithDate);
                              }}
                              helperText={
                                errors?.generalInformation?.schoolCampus?.message ??
                                errors.generalInformation?.schoolCampus?.id?.message
                              }
                              FormHelperTextProps={{
                                error:
                                  !!errors?.generalInformation?.schoolCampus?.message ||
                                  !!errors.generalInformation?.schoolCampus?.id?.message,
                              }}
                            >
                              {(campusOptionsResponse?.schoolCampuses ?? []).map((campus) => (
                                <MenuItem key={campus.id} value={campus.id}>
                                  {campus.name}
                                </MenuItem>
                              ))}
                            </StudentProfileTextField>
                          </MSBInputErrorWrapper>
                        </StudentImportDisabledInputTooltip>
                      );
                    }}
                  />
                ),
              },
              {
                key: "districtOfLiabilitydistrictOfLiabilityRecord",
                content: (
                  <Controller
                    control={control}
                    name="generalInformation.districtOfLiabilityRecord"
                    render={({ field }) => {
                      return (
                        <StudentImportDisabledInputTooltip
                          fullwidth
                          show={isStudentImportFromLegacy}
                          state={stateInUs}
                        >
                          <MSBInputErrorWrapper
                            isError={getDistrictsOptionsBasedOnState.isDistrictsError}
                            displayRefreshButton={getDistrictsOptionsBasedOnState.isDistrictsError}
                            refetch={getDistrictsOptionsBasedOnState.refetchDistricts}
                            errorText={
                              "Failed to retrieve list of districts, please click refresh icon to try again."
                            }
                          >
                            <StudentProfileTextField
                              key={JSON.stringify(field.value) ?? ""}
                              select={(getDistrictsOptionsBasedOnState.districts ?? []).length > 0}
                              id="districtOfLiabilityRecord"
                              label={
                                getDistrictsOptionsBasedOnState.isDistrictsLoading
                                  ? "Loading options..."
                                  : "Districts"
                              }
                              disabled={
                                getDistrictsOptionsBasedOnState.isDistrictsLoading ||
                                getDistrictsOptionsBasedOnState.isDistrictsError ||
                                isStudentImportFromLegacy ||
                                disableInputFields
                              }
                              value={field.value ? field.value.liableDistrict?.id : ""}
                              onChange={(e) => {
                                const district = getDistrictsOptionsBasedOnState.districts?.find(
                                  (district) => district.id === e.target.value,
                                );

                                const districtWithDate: DistrictOfLiability = {
                                  liableDistrict: district,
                                  startDate: new Date(),
                                  endDate: null,
                                };

                                field.onChange(districtWithDate);
                              }}
                              helperText={
                                errors?.generalInformation?.districtOfLiabilityRecord?.message ??
                                errors.generalInformation?.districtOfLiabilityRecord?.liableDistrict
                                  ?.id?.message
                              }
                              FormHelperTextProps={{
                                error:
                                  !!errors?.generalInformation?.districtOfLiabilityRecord
                                    ?.message ||
                                  !!errors.generalInformation?.districtOfLiabilityRecord
                                    ?.liableDistrict?.id?.message,
                              }}
                            >
                              {(getDistrictsOptionsBasedOnState.districts ?? []).map((district) => (
                                <MenuItem key={district.id} value={district.id}>
                                  {district.name}
                                </MenuItem>
                              ))}
                            </StudentProfileTextField>
                          </MSBInputErrorWrapper>
                        </StudentImportDisabledInputTooltip>
                      );
                    }}
                  />
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
}
