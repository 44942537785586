import { ButtonSidebarItemProps } from "../../../design/types/sidebar_content";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Tooltip } from "@mui/material";
import ButtonSidebarItem from "../../../design/high-level/sidebar/sidebar_item_button";
import { XNGIconRenderer, XNGICONS } from "../../../design";
import { useSidebarPalette, SIZE } from "../../../design/high-level/sidebar/_";
import { getSizing } from "../../../design/sizing";
import { useLocation } from "react-router-dom";
import { useXNGSelector } from "../../../context/store";
import { sidebarOpen } from "../../../context/slices/sidebarSlice";

export default function SidebarAccordion(props: {
  children: ButtonSidebarItemProps[],
  onClick: (description: string) => void,
  description: string,
  expanded: boolean
  icon: JSX.Element
}) {  
  const palette = useSidebarPalette();
  const location = useLocation();
  const isSidebarOpen = useXNGSelector(sidebarOpen);

  return (
    <Tooltip title={isSidebarOpen ? "" : props.description} disableInteractive placement="top-start">
      <Accordion
        disableGutters
        sx={{
          mb: 0,
          border: "1px",
          boxShadow: "none",
          "&::before": {
            opacity: "0 !important",
          },
          paddingLeft: "0 !important",
        }}
        onClick={() => props.onClick(props.description)}
        expanded={props.expanded}
      >
        <AccordionSummary
          expandIcon={
            <XNGIconRenderer left color="#4B4B4B" i={<XNGICONS.DownChevron />} size="md" />
          }
          sx={{
            bgcolor: palette.bgcolor,
            display: "flex",
            paddingLeft: "10px"
          }}
        >
          <XNGIconRenderer
            color={palette.selected}
            size={SIZE}
            i={props.icon}
          />
          <Typography color="black" sx={{paddingLeft: getSizing(3)}}>{props.description}</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            bgcolor: palette.bgcolor,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {props.children.map((item: ButtonSidebarItemProps) => {
            return <ButtonSidebarItem item={item} displayAsSelected={location.pathname === item.route} />
          })}
        </AccordionDetails>
      </Accordion>
    </Tooltip>
  )
}