/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Session API
 * The ASP.NET Core Web API for the Session Documentation services.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface ActualSession
 */
export interface ActualSession {
    /**
     * 
     * @type {string}
     * @memberof ActualSession
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualSession
     */
    seriesId?: string;
    /**
     * The title of the session
     * @type {string}
     * @memberof ActualSession
     */
    title?: string;
    /**
     * 
     * @type {Service}
     * @memberof ActualSession
     */
    service?: Service;
    /**
     * 
     * @type {boolean}
     * @memberof ActualSession
     */
    groupSetting?: boolean;
    /**
     * 
     * @type {SessionStatus}
     * @memberof ActualSession
     */
    status?: SessionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ActualSession
     */
    postedInLegacy?: boolean;
    /**
     * 
     * @type {MeetingDetails}
     * @memberof ActualSession
     */
    meetingDetails?: MeetingDetails;
    /**
     * 
     * @type {SharsServiceProvider}
     * @memberof ActualSession
     */
    serviceProvider?: SharsServiceProvider;
    /**
     * 
     * @type {SessionJournal}
     * @memberof ActualSession
     */
    sessionJournal?: SessionJournal;
    /**
     * 
     * @type {ElectronicSignature}
     * @memberof ActualSession
     */
    authorizingProviderSignature?: ElectronicSignature;
    /**
     * 
     * @type {ElectronicSignature}
     * @memberof ActualSession
     */
    documentingProviderSignature?: ElectronicSignature;
    /**
     * 
     * @type {Array<StudentJournal>}
     * @memberof ActualSession
     */
    studentJournalList?: Array<StudentJournal>;
    /**
     * The group size in the session.
     * @type {number}
     * @memberof ActualSession
     */
    groupSize?: number;
    /**
     * 
     * @type {ClientRef}
     * @memberof ActualSession
     */
    client?: ClientRef;
    /**
     * 
     * @type {boolean}
     * @memberof ActualSession
     */
    migratedBackToLegacy?: boolean;
}
/**
 * The request to add a student to a session
 * @export
 * @interface AddStudentToSessionRequest
 */
export interface AddStudentToSessionRequest {
    /**
     * The Id of the session to add the student to.
     * @type {string}
     * @memberof AddStudentToSessionRequest
     */
    id?: string;
    /**
     * The Id of the client that the student to add is a part of.
     * @type {string}
     * @memberof AddStudentToSessionRequest
     */
    clientId?: string;
    /**
     * The Id of the student to add to the
     * @type {Array<string>}
     * @memberof AddStudentToSessionRequest
     */
    studentIds?: Array<string>;
    /**
     * 
     * @type {ServiceArea}
     * @memberof AddStudentToSessionRequest
     */
    serviceArea?: ServiceArea;
    /**
     * The Id of the service provider in the session.
     * @type {string}
     * @memberof AddStudentToSessionRequest
     */
    serviceProviderId?: string;
    /**
     * The series id in case the session id is null.
     * @type {string}
     * @memberof AddStudentToSessionRequest
     */
    seriesId?: string;
    /**
     * The date of the session to add the student to.
     * @type {Date}
     * @memberof AddStudentToSessionRequest
     */
    sessionDate?: Date;
    /**
     * The timezone that the user is currently using to adjust the time.
     * @type {string}
     * @memberof AddStudentToSessionRequest
     */
    timezone?: string;
}
/**
 * 
 * @export
 * @interface AdditionalDocumentationRecord
 */
export interface AdditionalDocumentationRecord {
    /**
     * 
     * @type {string}
     * @memberof AdditionalDocumentationRecord
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalDocumentationRecord
     */
    name?: string;
    /**
     * 
     * @type {any}
     * @memberof AdditionalDocumentationRecord
     */
    value?: any;
}
/**
 * 
 * @export
 * @interface Campus
 */
export interface Campus {
    /**
     * 
     * @type {string}
     * @memberof Campus
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Campus
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface CareProvisionLedger
 */
export interface CareProvisionLedger {
    /**
     * Versions:  <para />  null - Initial version. For purposes of querying old data, a CareProvisionLedger with a null VersionNumber has not been  transitioned to version 1, which contains the top-level Session.Domain.Models.CareProvisionLedger.ObjectiveProgressions array.   <para />  1 - ObjectiveProgressions property was added to the care provision ledger in addition to the ObjectiveProgressions   property on the GoalProgressions. The new array should be the exact equivalent of the old array. However, when requests  are sent to the API, the top-level ObjectiveProgressions array will not be considered the authoritative data source   and is effectively readonly unless the VersionNumber is 2 or higher.  <para />  2 - The top-level ObjectiveProgressions array is now the authoritative data source for the ObjectiveProgressions.   Both fields should still be in place until all clients have been updated to use the new field.
     * @type {number}
     * @memberof CareProvisionLedger
     */
    versionNumber?: number;
    /**
     * 
     * @type {Array<ProvidedActivity>}
     * @memberof CareProvisionLedger
     */
    activities?: Array<ProvidedActivity>;
    /**
     * 
     * @type {Array<ProvidedAccommodation>}
     * @memberof CareProvisionLedger
     */
    accommodations?: Array<ProvidedAccommodation>;
    /**
     * 
     * @type {Array<ProvidedModification>}
     * @memberof CareProvisionLedger
     */
    modifications?: Array<ProvidedModification>;
    /**
     * 
     * @type {Array<GoalProgression>}
     * @memberof CareProvisionLedger
     */
    goalProgresssions?: Array<GoalProgression>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CareProvisionLedger
     */
    excludedGoals?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CareProvisionLedger
     */
    additionalGoals?: Array<string>;
    /**
     * 
     * @type {Array<ObjectiveProgression>}
     * @memberof CareProvisionLedger
     */
    objectiveProgressions?: Array<ObjectiveProgression>;
}
/**
 * 
 * @export
 * @interface ClientRef
 */
export interface ClientRef {
    /**
     * 
     * @type {string}
     * @memberof ClientRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientRef
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ConvertSingleToRecurringRequest
 */
export interface ConvertSingleToRecurringRequest {
    /**
     * 
     * @type {RecurrencePattern}
     * @memberof ConvertSingleToRecurringRequest
     */
    recurrencePattern?: RecurrencePattern;
    /**
     * 
     * @type {ActualSession}
     * @memberof ConvertSingleToRecurringRequest
     */
    currentSession?: ActualSession;
}
/**
 * The response contract for converting a single session to a recurring session.
 * @export
 * @interface ConvertSingleToRecurringResponse
 */
export interface ConvertSingleToRecurringResponse {
    /**
     * 
     * @type {string}
     * @memberof ConvertSingleToRecurringResponse
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConvertSingleToRecurringResponse
     */
    serviceProviderId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConvertSingleToRecurringResponse
     */
    seriesId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ConvertSingleToRecurringResponse
     */
    sessionDate?: Date;
}
/**
 * 
 * @export
 * @interface CreateRecurrencePatternRequest
 */
export interface CreateRecurrencePatternRequest {
    /**
     * 
     * @type {RecurrenceMode}
     * @memberof CreateRecurrencePatternRequest
     */
    mode?: RecurrenceMode;
    /**
     * 
     * @type {number}
     * @memberof CreateRecurrencePatternRequest
     */
    interval?: number;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof CreateRecurrencePatternRequest
     */
    includedDaysOfWeek?: Array<DayOfWeek>;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurrencePatternRequest
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateRecurrencePatternRequest
     */
    endNumberOfOccurrences?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRecurrencePatternRequest
     */
    includeNonSchoolDay?: boolean;
}
/**
 * The request contract for creating a recurring session.
 * @export
 * @interface CreateRecurringSesssionRequest
 */
export interface CreateRecurringSesssionRequest {
    /**
     * 
     * @type {CreateRecurrencePatternRequest}
     * @memberof CreateRecurringSesssionRequest
     */
    recurrencePattern?: CreateRecurrencePatternRequest;
    /**
     * 
     * @type {CreateSessionRequest}
     * @memberof CreateRecurringSesssionRequest
     */
    session?: CreateSessionRequest;
}
/**
 * 
 * @export
 * @interface CreateSessionRequest
 */
export interface CreateSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSessionRequest
     */
    title?: string;
    /**
     * 
     * @type {Service}
     * @memberof CreateSessionRequest
     */
    service?: Service;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSessionRequest
     */
    groupSetting?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateSessionRequest
     */
    groupSize?: number;
    /**
     * 
     * @type {MeetingDetails}
     * @memberof CreateSessionRequest
     */
    meetingDetails?: MeetingDetails;
    /**
     * 
     * @type {SharsServiceProvider}
     * @memberof CreateSessionRequest
     */
    serviceProvider?: SharsServiceProvider;
    /**
     * 
     * @type {SessionJournal}
     * @memberof CreateSessionRequest
     */
    sessionJournal?: SessionJournal;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSessionRequest
     */
    studentIds?: Array<string>;
    /**
     * 
     * @type {ClientRef}
     * @memberof CreateSessionRequest
     */
    client?: ClientRef;
}
/**
 * 
 * @export
 * @interface CustomCareProvisionLedger
 */
export interface CustomCareProvisionLedger {
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomCareProvisionLedger
     */
    activities?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomCareProvisionLedger
     */
    accommodations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomCareProvisionLedger
     */
    modifications?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomCareProvisionLedger
     */
    observations?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DayOfWeek {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6
}
/**
 * This contract is for editing the session series.
 * @export
 * @interface EditSessionSeriesRequest
 */
export interface EditSessionSeriesRequest {
    /**
     * The id of the series that we are editing.
     * @type {string}
     * @memberof EditSessionSeriesRequest
     */
    seriesId?: string;
    /**
     * 
     * @type {CreateRecurrencePatternRequest}
     * @memberof EditSessionSeriesRequest
     */
    recurrencePattern?: CreateRecurrencePatternRequest;
    /**
     * 
     * @type {CreateSessionRequest}
     * @memberof EditSessionSeriesRequest
     */
    session?: CreateSessionRequest;
    /**
     * A timezone information that we would like to adjust the time to.
     * @type {string}
     * @memberof EditSessionSeriesRequest
     */
    timezone?: string;
}
/**
 * The class represents an electronic signature for a specific statement type. The class is used as a property of the UserProfile class  as part of a hashset of electronic signatures which are used to track the user's agreement to various statements. No two statements  of the same type may exist in the hashset.
 * @export
 * @interface ElectronicSignature
 */
export interface ElectronicSignature {
    /**
     * 
     * @type {StatementType}
     * @memberof ElectronicSignature
     */
    statementType?: StatementType;
    /**
     * 
     * @type {boolean}
     * @memberof ElectronicSignature
     */
    isSigned?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ElectronicSignature
     */
    signedOnDateLocal?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ElectronicSignature
     */
    signedOnDateUtc?: Date;
    /**
     * 
     * @type {string}
     * @memberof ElectronicSignature
     */
    signedByFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ElectronicSignature
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ElectronicSignature
     */
    documentText?: string;
    /**
     * 
     * @type {string}
     * @memberof ElectronicSignature
     */
    requestIpAddress?: string;
}
/**
 * 
 * @export
 * @interface GetAllPostableSessionsResponse
 */
export interface GetAllPostableSessionsResponse {
    /**
     * 
     * @type {Array<ActualSession>}
     * @memberof GetAllPostableSessionsResponse
     */
    postableSessionsLists?: Array<ActualSession>;
}
/**
 * 
 * @export
 * @interface GetDayViewRequest
 */
export interface GetDayViewRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDayViewRequest
     */
    serviceProviderIds?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof GetDayViewRequest
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetDayViewRequest
     */
    endDate?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDayViewRequest
     */
    serviceAreaIdFilters?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDayViewRequest
     */
    studentIdFilters?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetDayViewRequest
     */
    timeZone?: string;
}
/**
 * The response object for the GetDayView endpoint.
 * @export
 * @interface GetDayViewResponse
 */
export interface GetDayViewResponse {
    /**
     * The sessions matching the filters provided in the request body.
     * @type {Array<SessionDayViewCard>}
     * @memberof GetDayViewResponse
     */
    sessions?: Array<SessionDayViewCard>;
    /**
     * 
     * @type {SessionFilterOptions}
     * @memberof GetDayViewResponse
     */
    filterOptions?: SessionFilterOptions;
}
/**
 * 
 * @export
 * @interface GetMinutesSpentOnOrderedServiceAreaResponse
 */
export interface GetMinutesSpentOnOrderedServiceAreaResponse {
    /**
     * 
     * @type {number}
     * @memberof GetMinutesSpentOnOrderedServiceAreaResponse
     */
    totalMinutesSpent?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMinutesSpentOnOrderedServiceAreaResponse
     */
    totalMinutesSpentWithServiceProvider?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMinutesSpentOnOrderedServiceAreaResponse
     */
    totalTimeAwayMinutes?: number;
}
/**
 * 
 * @export
 * @interface GetMonthViewResponse
 */
export interface GetMonthViewResponse {
    /**
     * 
     * @type {Array<SessionMonthViewCard>}
     * @memberof GetMonthViewResponse
     */
    sessions?: Array<SessionMonthViewCard>;
}
/**
 * 
 * @export
 * @interface GetMutableSessionsResponse
 */
export interface GetMutableSessionsResponse {
    /**
     * 
     * @type {Array<SessionDateCard>}
     * @memberof GetMutableSessionsResponse
     */
    sessionDateCards?: Array<SessionDateCard>;
}
/**
 * This contract is for getting the session series.
 * @export
 * @interface GetSessionSeriesResponse
 */
export interface GetSessionSeriesResponse {
    /**
     * The target series Id
     * @type {string}
     * @memberof GetSessionSeriesResponse
     */
    id?: string;
    /**
     * 
     * @type {RecurrencePattern}
     * @memberof GetSessionSeriesResponse
     */
    recurrencePattern?: RecurrencePattern;
}
/**
 * 
 * @export
 * @interface GetSessionsResponse
 */
export interface GetSessionsResponse {
    /**
     * 
     * @type {Array<ActualSession>}
     * @memberof GetSessionsResponse
     */
    actualSessions?: Array<ActualSession>;
}
/**
 * This is the response body for getting slim session cards. This response body is intended to be used for screens where a very minimal summary of the sessions  is needed, but where the results may need to be filterable on things like students and campuses.
 * @export
 * @interface GetSlimSessionCardsResponse
 */
export interface GetSlimSessionCardsResponse {
    /**
     * A dictionary with keys being the provider ID and values being  a list of slim session cards representing that provider's unposted sessions.
     * @type {{ [key: string]: Array<SessionSlimCard>; }}
     * @memberof GetSlimSessionCardsResponse
     */
    providerUnpostedSessionsDictionary?: { [key: string]: Array<SessionSlimCard>; };
    /**
     * A hashset detailing the IDs of students whose profiles could not be retrived. This will result in the   all student-derived information such as campuses not being included in the session cards within the sessions dictionaries.
     * @type {Array<string>}
     * @memberof GetSlimSessionCardsResponse
     */
    failedStudentIds?: Array<string>;
    /**
     * This is the unique list of students listed within the sessions for purposes of populating filter options on the frontend when   filtering the results set.
     * @type {Array<StudentRef>}
     * @memberof GetSlimSessionCardsResponse
     */
    students?: Array<StudentRef>;
}
/**
 * The response body for Unposted Sessions count. Returns the count of unposted sessions for the current user, and the count of unposted sessions for the current user's assistants and data entry users.
 * @export
 * @interface GetUnpostedSessionCountResponse
 */
export interface GetUnpostedSessionCountResponse {
    /**
     * 
     * @type {number}
     * @memberof GetUnpostedSessionCountResponse
     */
    myUnpostedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnpostedSessionCountResponse
     */
    assistantUnpostedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnpostedSessionCountResponse
     */
    dataEntryUnpostedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUnpostedSessionCountResponse
     */
    totalUnpostedCount?: number;
}
/**
 * 
 * @export
 * @interface GetWeekViewResponse
 */
export interface GetWeekViewResponse {
    /**
     * 
     * @type {Array<SessionWeekViewCard>}
     * @memberof GetWeekViewResponse
     */
    sessions?: Array<SessionWeekViewCard>;
}
/**
 * Enum: NoneSelected = 0, Discontinued = 1, Mastered = 2, NotMastered = 3, NotYetAddressed = 4, Regression = 5, WorkInProgress = 6
 * @export
 * @enum {string}
 */
export enum GoalAndObjectiveObservation {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6
}
/**
 * Enum: NoneSelected = 0, Cue = 1, Prompt = 2, Model = 3, Verbal = 4, Visual = 5, Physical = 6, Prop = 7
 * @export
 * @enum {string}
 */
export enum GoalAndObjectiveToolUsed {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7
}
/**
 * 
 * @export
 * @interface GoalProgression
 */
export interface GoalProgression {
    /**
     * 
     * @type {GoalRef}
     * @memberof GoalProgression
     */
    progressedGoal?: GoalRef;
    /**
     * 
     * @type {number}
     * @memberof GoalProgression
     */
    successes?: number;
    /**
     * 
     * @type {number}
     * @memberof GoalProgression
     */
    attempts?: number;
    /**
     * 
     * @type {number}
     * @memberof GoalProgression
     */
    percentageComplete?: number;
    /**
     * 
     * @type {GoalAndObjectiveObservation}
     * @memberof GoalProgression
     */
    goalObservation?: GoalAndObjectiveObservation;
    /**
     * 
     * @type {GoalAndObjectiveToolUsed}
     * @memberof GoalProgression
     */
    goalToolUsed?: GoalAndObjectiveToolUsed;
    /**
     * This is the new field for documenting multiple goal tools used (also referred to as \"Conditions\")  within a single goal progression. If the field has not been used before, it will default to a list  containing the value of the old GoalToolUsed field.
     * @type {Array<GoalAndObjectiveToolUsed>}
     * @memberof GoalProgression
     */
    goalToolsUsed?: Array<GoalAndObjectiveToolUsed>;
    /**
     * 
     * @type {string}
     * @memberof GoalProgression
     */
    goalNarrative?: string;
    /**
     * DEPRECATED: Use the higher-level Session.Domain.Models.CareProvisionLedger.ObjectiveProgressions instead.
     * @type {Array<ObjectiveProgression>}
     * @memberof GoalProgression
     */
    objectiveProgressions?: Array<ObjectiveProgression>;
}
/**
 * 
 * @export
 * @interface GoalRef
 */
export interface GoalRef {
    /**
     * 
     * @type {string}
     * @memberof GoalRef
     */
    internalId?: string;
    /**
     * 
     * @type {string}
     * @memberof GoalRef
     */
    goalNumberFromVendor?: string;
    /**
     * 
     * @type {string}
     * @memberof GoalRef
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface JournalApprovalRecord
 */
export interface JournalApprovalRecord {
    /**
     * 
     * @type {ServiceProviderRef}
     * @memberof JournalApprovalRecord
     */
    authorizingProvider?: ServiceProviderRef;
    /**
     * 
     * @type {ElectronicSignature}
     * @memberof JournalApprovalRecord
     */
    authorizingProviderSignature?: ElectronicSignature;
    /**
     * 
     * @type {UserRef}
     * @memberof JournalApprovalRecord
     */
    authorizingUser?: UserRef;
    /**
     * 
     * @type {UserRef}
     * @memberof JournalApprovalRecord
     */
    documentingUser?: UserRef;
    /**
     * 
     * @type {ElectronicSignature}
     * @memberof JournalApprovalRecord
     */
    documentingProviderSignature?: ElectronicSignature;
    /**
     * 
     * @type {ServiceProviderRef}
     * @memberof JournalApprovalRecord
     */
    documentingProvider?: ServiceProviderRef;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface MeetingDetails
 */
export interface MeetingDetails {
    /**
     * 
     * @type {Date}
     * @memberof MeetingDetails
     */
    date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MeetingDetails
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MeetingDetails
     */
    endTime?: Date;
    /**
     * 
     * @type {Location}
     * @memberof MeetingDetails
     */
    location?: Location;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingDetails
     */
    makeUpSession?: boolean;
}
/**
 * 
 * @export
 * @interface MergeStudentRequest
 */
export interface MergeStudentRequest {
    /**
     * 
     * @type {Student}
     * @memberof MergeStudentRequest
     */
    studentProfile?: Student;
    /**
     * 
     * @type {Array<string>}
     * @memberof MergeStudentRequest
     */
    studentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MergeStudentRequest
     */
    clientId?: string;
}
/**
 * 
 * @export
 * @interface Objective
 */
export interface Objective {
    /**
     * Objective Number from the IEP Vendor
     * @type {string}
     * @memberof Objective
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof Objective
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Objective
     */
    internalId?: string;
}
/**
 * 
 * @export
 * @interface ObjectiveProgression
 */
export interface ObjectiveProgression {
    /**
     * 
     * @type {GoalRef}
     * @memberof ObjectiveProgression
     */
    goalRef?: GoalRef;
    /**
     * 
     * @type {Objective}
     * @memberof ObjectiveProgression
     */
    progressedObjective?: Objective;
    /**
     * 
     * @type {number}
     * @memberof ObjectiveProgression
     */
    successes?: number;
    /**
     * 
     * @type {number}
     * @memberof ObjectiveProgression
     */
    attempts?: number;
    /**
     * 
     * @type {number}
     * @memberof ObjectiveProgression
     */
    percentageComplete?: number;
    /**
     * 
     * @type {GoalAndObjectiveObservation}
     * @memberof ObjectiveProgression
     */
    objectiveObservation?: GoalAndObjectiveObservation;
    /**
     * 
     * @type {GoalAndObjectiveToolUsed}
     * @memberof ObjectiveProgression
     */
    objectiveToolUsed?: GoalAndObjectiveToolUsed;
    /**
     * This is the new field for documenting multiple objective tools used (also referred to as \"Conditions\")  within a single objective progression. If the field has not been used before, it will default to a list  containing the value of the old ObjectiveToolUsed field.
     * @type {Array<GoalAndObjectiveToolUsed>}
     * @memberof ObjectiveProgression
     */
    objectiveToolsUsed?: Array<GoalAndObjectiveToolUsed>;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveProgression
     */
    objectiveNarrative?: string;
}
/**
 * 
 * @export
 * @interface ObservationSection
 */
export interface ObservationSection {
    /**
     * 
     * @type {Array<string>}
     * @memberof ObservationSection
     */
    observations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ObservationSection
     */
    narrative?: string;
}
/**
 * 
 * @export
 * @interface OrderedSubject
 */
export interface OrderedSubject {
    /**
     * 
     * @type {string}
     * @memberof OrderedSubject
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderedSubject
     */
    name?: string;
}
/**
 * This is the request body for the UpdateSessionElectronicSignature endpoint.
 * @export
 * @interface PatchSessionApprovalRequest
 */
export interface PatchSessionApprovalRequest {
    /**
     * 
     * @type {UserRef}
     * @memberof PatchSessionApprovalRequest
     */
    currentUser?: UserRef;
    /**
     * 
     * @type {ServiceProviderRef}
     * @memberof PatchSessionApprovalRequest
     */
    currentUserServiceProvider?: ServiceProviderRef;
    /**
     * 
     * @type {ElectronicSignature}
     * @memberof PatchSessionApprovalRequest
     */
    electronicSignature?: ElectronicSignature;
    /**
     * Indicates if the current user is autonomous.
     * @type {boolean}
     * @memberof PatchSessionApprovalRequest
     */
    isAutonomous?: boolean;
    /**
     * Indicates if the current user is an approver.
     * @type {boolean}
     * @memberof PatchSessionApprovalRequest
     */
    isApprover?: boolean;
    /**
     * Indicates if the current user is acting as a proxy for another service provider currently. This is necessary to distinguish  between an approver who also has the role of a proxy/data entry clerk acting in the role of a proxy and acting as an approver.
     * @type {boolean}
     * @memberof PatchSessionApprovalRequest
     */
    isActingAsProxy?: boolean;
}
/**
 * The request contract for the PatchSession operation
 * @export
 * @interface PatchSessionRequest
 */
export interface PatchSessionRequest {
    /**
     * 
     * @type {ActualSession}
     * @memberof PatchSessionRequest
     */
    session?: ActualSession;
}
/**
 * The response contract for the PatchSession operation
 * @export
 * @interface PatchSessionResponse
 */
export interface PatchSessionResponse {
    /**
     * 
     * @type {ActualSession}
     * @memberof PatchSessionResponse
     */
    session?: ActualSession;
}
/**
 * This request is used to patch the start and end times of a session. It will use the session series if the id is not provided.
 * @export
 * @interface PatchSessionSeriesTimeRequest
 */
export interface PatchSessionSeriesTimeRequest {
    /**
     * The session id that we are going to work with
     * @type {string}
     * @memberof PatchSessionSeriesTimeRequest
     */
    sessionId?: string;
    /**
     * The series ID that we will use if the session id is not provided
     * @type {string}
     * @memberof PatchSessionSeriesTimeRequest
     */
    seriesId?: string;
    /**
     * The original date for a session in a series so we can match it up in the current session series
     * @type {Date}
     * @memberof PatchSessionSeriesTimeRequest
     */
    originalDate?: Date;
    /**
     * The new start time of the session
     * @type {Date}
     * @memberof PatchSessionSeriesTimeRequest
     */
    newStartTime?: Date;
    /**
     * The new end time of the session
     * @type {Date}
     * @memberof PatchSessionSeriesTimeRequest
     */
    newEndTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof PatchSessionSeriesTimeRequest
     */
    timezone?: string;
}
/**
 * This is the request object for the PatchSessionStatus endpoint
 * @export
 * @interface PatchSessionStatusRequest
 */
export interface PatchSessionStatusRequest {
    /**
     * 
     * @type {SessionStatus}
     * @memberof PatchSessionStatusRequest
     */
    status?: SessionStatus;
    /**
     * 
     * @type {RevisionsNote}
     * @memberof PatchSessionStatusRequest
     */
    revisionsNote?: RevisionsNote;
}
/**
 * 
 * @export
 * @interface PatchSessionStudentJournal
 */
export interface PatchSessionStudentJournal {
    /**
     * 
     * @type {string}
     * @memberof PatchSessionStudentJournal
     */
    clientId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchSessionStudentJournal
     */
    sessionIds: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof PatchSessionStudentJournal
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchSessionStudentJournal
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PatchSessionStudentJournal
     */
    seriesId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchSessionStudentJournal
     */
    studentIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchSessionStudentJournal
     */
    postSession?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchSessionStudentJournal
     */
    serviceProviderId: string;
    /**
     * 
     * @type {UserRef}
     * @memberof PatchSessionStudentJournal
     */
    currentUser?: UserRef;
    /**
     * 
     * @type {ServiceProviderRef}
     * @memberof PatchSessionStudentJournal
     */
    currentUserServiceProvider?: ServiceProviderRef;
    /**
     * 
     * @type {ElectronicSignature}
     * @memberof PatchSessionStudentJournal
     */
    electronicSignature?: ElectronicSignature;
    /**
     * 
     * @type {boolean}
     * @memberof PatchSessionStudentJournal
     */
    isAutonomous?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchSessionStudentJournal
     */
    isApprover?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchSessionStudentJournal
     */
    isActingAsProxy?: boolean;
}
/**
 * 
 * @export
 * @interface PatchSessionStudentJournalApprovalResponse
 */
export interface PatchSessionStudentJournalApprovalResponse {
    /**
     * 
     * @type {number}
     * @memberof PatchSessionStudentJournalApprovalResponse
     */
    successfullyUpdatedCount?: number;
    /**
     * 
     * @type {Array<SessionIdentifier>}
     * @memberof PatchSessionStudentJournalApprovalResponse
     */
    failedSessions?: Array<SessionIdentifier>;
}
/**
 * 
 * @export
 * @interface PatchSessionsApprovalAndStatusRequest
 */
export interface PatchSessionsApprovalAndStatusRequest {
    /**
     * 
     * @type {Array<ActualSession>}
     * @memberof PatchSessionsApprovalAndStatusRequest
     */
    sessions?: Array<ActualSession>;
    /**
     * 
     * @type {string}
     * @memberof PatchSessionsApprovalAndStatusRequest
     */
    serviceProviderId?: string;
    /**
     * 
     * @type {UserRef}
     * @memberof PatchSessionsApprovalAndStatusRequest
     */
    currentUser?: UserRef;
    /**
     * 
     * @type {ServiceProviderRef}
     * @memberof PatchSessionsApprovalAndStatusRequest
     */
    currentUserServiceProvider?: ServiceProviderRef;
    /**
     * 
     * @type {ElectronicSignature}
     * @memberof PatchSessionsApprovalAndStatusRequest
     */
    electronicSignature?: ElectronicSignature;
    /**
     * 
     * @type {boolean}
     * @memberof PatchSessionsApprovalAndStatusRequest
     */
    isAutonomous?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchSessionsApprovalAndStatusRequest
     */
    isApprover?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchSessionsApprovalAndStatusRequest
     */
    isActingAsProxy?: boolean;
    /**
     * 
     * @type {SessionStatus}
     * @memberof PatchSessionsApprovalAndStatusRequest
     */
    sessionStatus?: SessionStatus;
}
/**
 * Request to patch set new dates for a series in a recurring session.
 * @export
 * @interface PatchSessionsSeriesDateRequest
 */
export interface PatchSessionsSeriesDateRequest {
    /**
     * The snapshot of the session that is moving
     * @type {string}
     * @memberof PatchSessionsSeriesDateRequest
     */
    sessionId?: string;
    /**
     * The series ID that we are going to work with
     * @type {string}
     * @memberof PatchSessionsSeriesDateRequest
     */
    seriesId?: string;
    /**
     * The original date that is getting dropped on the calendar
     * @type {Date}
     * @memberof PatchSessionsSeriesDateRequest
     */
    originalDate?: Date;
    /**
     * The new date that is getting dropped on the calendar
     * @type {Date}
     * @memberof PatchSessionsSeriesDateRequest
     */
    newStartDate?: Date;
    /**
     * The new date that is getting dropped on the calendar
     * @type {Date}
     * @memberof PatchSessionsSeriesDateRequest
     */
    newEndDate?: Date;
    /**
     * The timezone to adjust the time.
     * @type {string}
     * @memberof PatchSessionsSeriesDateRequest
     */
    timeZone?: string;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;
}
/**
 * 
 * @export
 * @interface ProvidedAccommodation
 */
export interface ProvidedAccommodation {
    /**
     * 
     * @type {string}
     * @memberof ProvidedAccommodation
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProvidedAccommodation
     */
    increments?: number;
}
/**
 * 
 * @export
 * @interface ProvidedActivity
 */
export interface ProvidedActivity {
    /**
     * 
     * @type {string}
     * @memberof ProvidedActivity
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProvidedActivity
     */
    increments?: number;
    /**
     * 
     * @type {number}
     * @memberof ProvidedActivity
     */
    averageMinutes?: number;
    /**
     * 
     * @type {number}
     * @memberof ProvidedActivity
     */
    totalMinutesSpent?: number;
}
/**
 * 
 * @export
 * @interface ProvidedModification
 */
export interface ProvidedModification {
    /**
     * 
     * @type {string}
     * @memberof ProvidedModification
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProvidedModification
     */
    increments?: number;
}
/**
 * 
 * @export
 * @interface ProviderAttendanceRecord
 */
export interface ProviderAttendanceRecord {
    /**
     * 
     * @type {boolean}
     * @memberof ProviderAttendanceRecord
     */
    present?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProviderAttendanceRecord
     */
    reasonAbsent?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RecurrenceMode {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * 
 * @export
 * @interface RecurrencePattern
 */
export interface RecurrencePattern {
    /**
     * 
     * @type {RecurrenceMode}
     * @memberof RecurrencePattern
     */
    mode?: RecurrenceMode;
    /**
     * The time in between each interval.
     * @type {number}
     * @memberof RecurrencePattern
     */
    interval?: number;
    /**
     * 
     * @type {string}
     * @memberof RecurrencePattern
     */
    startDate?: string;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof RecurrencePattern
     */
    includedDaysOfWeek?: Array<DayOfWeek>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecurrencePattern
     */
    excludedDates?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RecurrencePattern
     */
    endNumberOfOccurrences?: number;
    /**
     * 
     * @type {number}
     * @memberof RecurrencePattern
     */
    endNumberOfIterations?: number;
    /**
     * *IncludeNonSchoolDays (not renamed because it is a breaking change)
     * @type {boolean}
     * @memberof RecurrencePattern
     */
    includeNonSchoolDay?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RecurrencePattern
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @interface RecurringSessionRef
 */
export interface RecurringSessionRef {
    /**
     * 
     * @type {string}
     * @memberof RecurringSessionRef
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringSessionRef
     */
    dateOfSession?: string;
    /**
     * A flag that indicates whether or not this session has been customized from the original template.  Not in use currently.
     * @type {boolean}
     * @memberof RecurringSessionRef
     */
    isCustomized?: boolean;
}
/**
 * 
 * @export
 * @interface RequestRevisionsRequest
 */
export interface RequestRevisionsRequest {
    /**
     * 
     * @type {Array<SessionIdsGroupedByServiceProvider>}
     * @memberof RequestRevisionsRequest
     */
    sessionIdsGroupedByServiceProviders?: Array<SessionIdsGroupedByServiceProvider>;
    /**
     * 
     * @type {RevisionsNote}
     * @memberof RequestRevisionsRequest
     */
    revisionsNote?: RevisionsNote;
}
/**
 * 
 * @export
 * @interface RevisionsNote
 */
export interface RevisionsNote {
    /**
     * 
     * @type {ServiceProviderRef}
     * @memberof RevisionsNote
     */
    serviceProviderRequestingRevisions?: ServiceProviderRef;
    /**
     * 
     * @type {string}
     * @memberof RevisionsNote
     */
    note?: string;
}
/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    description?: string;
    /**
     * 
     * @type {ServiceArea}
     * @memberof Service
     */
    area?: ServiceArea;
    /**
     * 
     * @type {ServiceType}
     * @memberof Service
     */
    type?: ServiceType;
}
/**
 * 
 * @export
 * @interface ServiceArea
 */
export interface ServiceArea {
    /**
     * 
     * @type {string}
     * @memberof ServiceArea
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceArea
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ServiceProviderRef
 */
export interface ServiceProviderRef {
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderRef
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderRef
     */
    lastName?: string;
}
/**
 * 
 * @export
 * @interface ServiceType
 */
export interface ServiceType {
    /**
     * 
     * @type {string}
     * @memberof ServiceType
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceType
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface SessionDateCard
 */
export interface SessionDateCard {
    /**
     * 
     * @type {string}
     * @memberof SessionDateCard
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDateCard
     */
    sessionDate?: string;
}
/**
 * 
 * @export
 * @interface SessionDayViewCard
 */
export interface SessionDayViewCard {
    /**
     * 
     * @type {string}
     * @memberof SessionDayViewCard
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDayViewCard
     */
    title?: string;
    /**
     * 
     * @type {Service}
     * @memberof SessionDayViewCard
     */
    service?: Service;
    /**
     * 
     * @type {SessionStatus}
     * @memberof SessionDayViewCard
     */
    status?: SessionStatus;
    /**
     * 
     * @type {ServiceProviderRef}
     * @memberof SessionDayViewCard
     */
    serviceProvider?: ServiceProviderRef;
    /**
     * 
     * @type {boolean}
     * @memberof SessionDayViewCard
     */
    serviceProviderPresent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionDayViewCard
     */
    seriesId?: string;
    /**
     * 
     * @type {Date}
     * @memberof SessionDayViewCard
     */
    date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SessionDayViewCard
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SessionDayViewCard
     */
    endTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof SessionDayViewCard
     */
    duration?: string;
    /**
     * 
     * @type {Array<StudentRef>}
     * @memberof SessionDayViewCard
     */
    totalStudents?: Array<StudentRef>;
    /**
     * 
     * @type {Array<StudentRef>}
     * @memberof SessionDayViewCard
     */
    presentStudents?: Array<StudentRef>;
    /**
     * 
     * @type {Array<StudentRef>}
     * @memberof SessionDayViewCard
     */
    absentStudents?: Array<StudentRef>;
}
/**
 * The list of filters that can be applied to a collection of sessions.
 * @export
 * @interface SessionFilterOptions
 */
export interface SessionFilterOptions {
    /**
     * The list of Service Areas that can be filtered in the collection of sessions.
     * @type {Array<ServiceArea>}
     * @memberof SessionFilterOptions
     */
    serviceAreas?: Array<ServiceArea>;
    /**
     * The list of students that can be filtered in the collection of sessions.
     * @type {Array<StudentRef>}
     * @memberof SessionFilterOptions
     */
    students?: Array<StudentRef>;
}
/**
 * 
 * @export
 * @interface SessionIdentifier
 */
export interface SessionIdentifier {
    /**
     * 
     * @type {string}
     * @memberof SessionIdentifier
     */
    id?: string;
    /**
     * 
     * @type {SharsServiceProvider}
     * @memberof SessionIdentifier
     */
    serviceProvider?: SharsServiceProvider;
    /**
     * 
     * @type {string}
     * @memberof SessionIdentifier
     */
    seriesId?: string;
    /**
     * 
     * @type {Date}
     * @memberof SessionIdentifier
     */
    sessionDate?: Date;
}
/**
 * 
 * @export
 * @interface SessionIdsGroupedByServiceProvider
 */
export interface SessionIdsGroupedByServiceProvider {
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionIdsGroupedByServiceProvider
     */
    sessionIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SessionIdsGroupedByServiceProvider
     */
    serviceProviderId?: string;
}
/**
 * 
 * @export
 * @interface SessionJournal
 */
export interface SessionJournal {
    /**
     * 
     * @type {ProviderAttendanceRecord}
     * @memberof SessionJournal
     */
    providerAttendanceRecord?: ProviderAttendanceRecord;
    /**
     * 
     * @type {string}
     * @memberof SessionJournal
     */
    narrative?: string;
    /**
     * 
     * @type {CustomCareProvisionLedger}
     * @memberof SessionJournal
     */
    customCareProvisionLedger?: CustomCareProvisionLedger;
}
/**
 * 
 * @export
 * @interface SessionMonthViewCard
 */
export interface SessionMonthViewCard {
    /**
     * 
     * @type {string}
     * @memberof SessionMonthViewCard
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionMonthViewCard
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionMonthViewCard
     */
    serviceName?: string;
    /**
     * 
     * @type {SessionStatus}
     * @memberof SessionMonthViewCard
     */
    status?: SessionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof SessionMonthViewCard
     */
    serviceProviderPresent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionMonthViewCard
     */
    seriesId?: string;
    /**
     * 
     * @type {Date}
     * @memberof SessionMonthViewCard
     */
    date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SessionMonthViewCard
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SessionMonthViewCard
     */
    endTime?: Date;
}
/**
 * 
 * @export
 * @interface SessionResponse
 */
export interface SessionResponse {
    /**
     * 
     * @type {string}
     * @memberof SessionResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionResponse
     */
    seriesId?: string;
    /**
     * 
     * @type {Service}
     * @memberof SessionResponse
     */
    service?: Service;
    /**
     * 
     * @type {boolean}
     * @memberof SessionResponse
     */
    groupSetting?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SessionResponse
     */
    groupSize?: number;
    /**
     * 
     * @type {SessionStatus}
     * @memberof SessionResponse
     */
    status?: SessionStatus;
    /**
     * 
     * @type {MeetingDetails}
     * @memberof SessionResponse
     */
    meetingDetails?: MeetingDetails;
    /**
     * 
     * @type {SharsServiceProvider}
     * @memberof SessionResponse
     */
    serviceProvider?: SharsServiceProvider;
    /**
     * 
     * @type {SessionJournal}
     * @memberof SessionResponse
     */
    sessionJournal?: SessionJournal;
    /**
     * 
     * @type {ElectronicSignature}
     * @memberof SessionResponse
     */
    authorizingProviderSignature?: ElectronicSignature;
    /**
     * 
     * @type {ElectronicSignature}
     * @memberof SessionResponse
     */
    documentingProviderSignature?: ElectronicSignature;
    /**
     * 
     * @type {Array<StudentJournal>}
     * @memberof SessionResponse
     */
    studentJournalList?: Array<StudentJournal>;
    /**
     * 
     * @type {ClientRef}
     * @memberof SessionResponse
     */
    client?: ClientRef;
    /**
     * 
     * @type {boolean}
     * @memberof SessionResponse
     */
    migratedBackToLegacy?: boolean;
}
/**
 * A session series is a collection of sessions that are created from a \"template\" session. The sessions can be modified beyond the starting template.  The starting template is called the \"recurring session\". This is because most of the time, a session series has a Session.Domain.Models.SessionSeries.SessionSeries.RecurrencePattern.   This recurrence pattern defines the way the sessions are created and modified in groups.  <para />  One important facet of session series is that not all of the sessions in the series have a persisted document associated with them in the database.  Only the sessions that have been modified beyond the session template will actually have their own document to describe their details. The rest of the  sessions are simply defined by the dates that they occur on in the series' list of Session.Domain.Models.SessionSeries.SessionSeries.Sessions.
 * @export
 * @interface SessionSeries
 */
export interface SessionSeries {
    /**
     * 
     * @type {string}
     * @memberof SessionSeries
     */
    id?: string;
    /**
     * 
     * @type {ActualSession}
     * @memberof SessionSeries
     */
    recurringSession?: ActualSession;
    /**
     * 
     * @type {RecurrencePattern}
     * @memberof SessionSeries
     */
    recurrencePattern?: RecurrencePattern;
    /**
     * A list of references to the sessions in the series. This list describes the dates that the sessions occur on, and (if they have been modified  beyond the template session) the id of the session document that describes the details of the session.
     * @type {Array<RecurringSessionRef>}
     * @memberof SessionSeries
     */
    sessions?: Array<RecurringSessionRef>;
    /**
     * 
     * @type {SessionTimeDetail}
     * @memberof SessionSeries
     */
    sessionTimeDetail?: SessionTimeDetail;
}
/**
 * 
 * @export
 * @interface SessionSlimCard
 */
export interface SessionSlimCard {
    /**
     * 
     * @type {string}
     * @memberof SessionSlimCard
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionSlimCard
     */
    seriesId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionSlimCard
     */
    title?: string;
    /**
     * 
     * @type {Date}
     * @memberof SessionSlimCard
     */
    startTime?: Date;
    /**
     * 
     * @type {Array<StudentRef>}
     * @memberof SessionSlimCard
     */
    students?: Array<StudentRef>;
    /**
     * 
     * @type {Array<Campus>}
     * @memberof SessionSlimCard
     */
    campuses?: Array<Campus>;
}
/**
 * Enum: Scheduled = 0, InProgress = 1, AwaitingApproval = 2, RevisionsRequested = 3, Posted = 4, Closed = 5
 * @export
 * @enum {string}
 */
export enum SessionStatus {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5
}
/**
 * 
 * @export
 * @interface SessionTimeDetail
 */
export interface SessionTimeDetail {
    /**
     * 
     * @type {Date}
     * @memberof SessionTimeDetail
     */
    localStartTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SessionTimeDetail
     */
    localEndTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof SessionTimeDetail
     */
    timeZone?: string;
}
/**
 * 
 * @export
 * @interface SessionWeekViewCard
 */
export interface SessionWeekViewCard {
    /**
     * 
     * @type {string}
     * @memberof SessionWeekViewCard
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionWeekViewCard
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionWeekViewCard
     */
    serviceName?: string;
    /**
     * 
     * @type {SessionStatus}
     * @memberof SessionWeekViewCard
     */
    status?: SessionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof SessionWeekViewCard
     */
    serviceProviderPresent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionWeekViewCard
     */
    seriesId?: string;
    /**
     * 
     * @type {Date}
     * @memberof SessionWeekViewCard
     */
    date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SessionWeekViewCard
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SessionWeekViewCard
     */
    endTime?: Date;
}
/**
 * This is only the service provider information relevant for billing purposes.
 * @export
 * @interface SharsServiceProvider
 */
export interface SharsServiceProvider {
    /**
     * 
     * @type {string}
     * @memberof SharsServiceProvider
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SharsServiceProvider
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SharsServiceProvider
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SharsServiceProvider
     */
    middleName?: string;
}
/**
 * Enum: TrueAndAccurateDataAuthorization = 0, FerpaAuthorization = 1, ElectronicSignatureConsent = 2, CompletedSessionDocumentation = 3
 * @export
 * @enum {string}
 */
export enum StatementType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3
}
/**
 * This class should reflect the Student domain-model in the context of the   session auditor.
 * @export
 * @interface Student
 */
export interface Student {
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    middleName?: string;
    /**
     * 
     * @type {Date}
     * @memberof Student
     */
    dateOfBirth?: Date;
}
/**
 * 
 * @export
 * @interface StudentAttendanceRecord
 */
export interface StudentAttendanceRecord {
    /**
     * 
     * @type {boolean}
     * @memberof StudentAttendanceRecord
     */
    present?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudentAttendanceRecord
     */
    virtual?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof StudentAttendanceRecord
     */
    arrivalTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudentAttendanceRecord
     */
    departureTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof StudentAttendanceRecord
     */
    timeAwayMinutes?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentAttendanceRecord
     */
    serviceSupportMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof StudentAttendanceRecord
     */
    rationale?: string;
}
/**
 * 
 * @export
 * @interface StudentJournal
 */
export interface StudentJournal {
    /**
     * 
     * @type {Student}
     * @memberof StudentJournal
     */
    student?: Student;
    /**
     * 
     * @type {StudentAttendanceRecord}
     * @memberof StudentJournal
     */
    studentAttendanceRecord?: StudentAttendanceRecord;
    /**
     * 
     * @type {ObservationSection}
     * @memberof StudentJournal
     */
    observationSection?: ObservationSection;
    /**
     * 
     * @type {OrderedSubject}
     * @memberof StudentJournal
     */
    providedOrderedSubject?: OrderedSubject;
    /**
     * 
     * @type {CareProvisionLedger}
     * @memberof StudentJournal
     */
    careProvisionLedger?: CareProvisionLedger;
    /**
     * 
     * @type {Array<AdditionalDocumentationRecord>}
     * @memberof StudentJournal
     */
    additionalDocumentationRecords?: Array<AdditionalDocumentationRecord>;
    /**
     * The list of goals that could be worked on in the session. This list is populated by taking in the  default goals for this session's service. Default goals are determined by mapping each goal's AreaOfFocus  to the ServiceAreas that the AoF is included in.
     * @type {Array<GoalRef>}
     * @memberof StudentJournal
     */
    goalInventory?: Array<GoalRef>;
    /**
     * 
     * @type {Array<OrderedSubject>}
     * @memberof StudentJournal
     */
    excludedOrderedSubjectOptions?: Array<OrderedSubject>;
    /**
     * These are ordered service options that are included in addition to those that fall within the service area of the session.
     * @type {Array<OrderedSubject>}
     * @memberof StudentJournal
     */
    additionalOrderedSubjectOptions?: Array<OrderedSubject>;
    /**
     * 
     * @type {JournalApprovalRecord}
     * @memberof StudentJournal
     */
    approval?: JournalApprovalRecord;
    /**
     * 
     * @type {RevisionsNote}
     * @memberof StudentJournal
     */
    revisionsNote?: RevisionsNote;
}
/**
 * This is only the Student information necessary for the Calender card.
 * @export
 * @interface StudentRef
 */
export interface StudentRef {
    /**
     * 
     * @type {string}
     * @memberof StudentRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentRef
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentRef
     */
    lastName?: string;
}
/**
 * 
 * @export
 * @interface UpdateSessionRequest
 */
export interface UpdateSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    title?: string;
    /**
     * 
     * @type {Service}
     * @memberof UpdateSessionRequest
     */
    service?: Service;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSessionRequest
     */
    groupSetting?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateSessionRequest
     */
    groupSize?: number;
    /**
     * 
     * @type {MeetingDetails}
     * @memberof UpdateSessionRequest
     */
    meetingDetails?: MeetingDetails;
    /**
     * 
     * @type {SharsServiceProvider}
     * @memberof UpdateSessionRequest
     */
    serviceProvider?: SharsServiceProvider;
    /**
     * 
     * @type {SessionJournal}
     * @memberof UpdateSessionRequest
     */
    sessionJournal?: SessionJournal;
    /**
     * 
     * @type {Array<StudentJournal>}
     * @memberof UpdateSessionRequest
     */
    studentJournalList?: Array<StudentJournal>;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    seriesId?: string;
    /**
     * 
     * @type {ClientRef}
     * @memberof UpdateSessionRequest
     */
    client?: ClientRef;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSessionRequest
     */
    migratedBackToLegacy?: boolean;
}
/**
 * This contract is for updating the session to the future sessions in the series.
 * @export
 * @interface UpdateSessionToFutureSessionsInSeriesRequest
 */
export interface UpdateSessionToFutureSessionsInSeriesRequest {
    /**
     * 
     * @type {ActualSession}
     * @memberof UpdateSessionToFutureSessionsInSeriesRequest
     */
    currentSession?: ActualSession;
    /**
     * The current timezone we want to adjust the time for.
     * @type {string}
     * @memberof UpdateSessionToFutureSessionsInSeriesRequest
     */
    timeZone?: string;
}
/**
 * 
 * @export
 * @interface UserRef
 */
export interface UserRef {
    /**
     * 
     * @type {string}
     * @memberof UserRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRef
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRef
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRef
     */
    emailAddress?: string;
}
/**
 * SessionsApi - fetch parameter creator
 * @export
 */
export const SessionsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Modifies a single session (specified by ID) in the sessions container, by adding the student to the session
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {AddStudentToSessionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsAddStudentPatch(state: string, body?: AddStudentToSessionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsAddStudentPatch.');
            }
            const localVarPath = `/v1/Sessions/AddStudent`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddStudentToSessionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all sessions
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsByProviderServiceProviderIdGet(serviceProviderId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsByProviderServiceProviderIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsByProviderServiceProviderIdGet.');
            }
            const localVarPath = `/v1/Sessions/ByProvider/{serviceProviderId}`
                .replace(`{${"serviceProviderId"}}`, encodeURIComponent(String(serviceProviderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Map a single session to a series
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {ConvertSingleToRecurringRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsConvertSessionToSeriesPut(state: string, body?: ConvertSingleToRecurringRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsConvertSessionToSeriesPut.');
            }
            const localVarPath = `/v1/Sessions/ConvertSessionToSeries`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ConvertSingleToRecurringRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Requests:            GET api/Session/DayView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/WeekView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
         * @summary This endpoint gets the DayView Cards for the provided serviceProviderId, within  the given startDate and endDate.
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [startDate] 
         * @param {Date} [endDate] 
         * @param {string} [timezone] The timezone information that we need to adjust time accordingly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsDayViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsDayViewServiceProviderIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsDayViewServiceProviderIdGet.');
            }
            const localVarPath = `/v1/Sessions/DayView/{serviceProviderId}`
                .replace(`{${"serviceProviderId"}}`, encodeURIComponent(String(serviceProviderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any).toISOString();
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any).toISOString();
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a specified session by ID
         * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [id] 
         * @param {string} [seriesId] 
         * @param {Date} [sessionDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsDelete(serviceProviderId: string, state: string, id?: string, seriesId?: string, sessionDate?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsDelete.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsDelete.');
            }
            const localVarPath = `/v1/Sessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (seriesId !== undefined) {
                localVarQueryParameter['seriesId'] = seriesId;
            }

            if (sessionDate !== undefined) {
                localVarQueryParameter['sessionDate'] = (sessionDate as any).toISOString();
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a set of sessions, each specified by ID
         * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [ids] A list of IDs indicating which sessions to delete.
         * @param {string} [dates] 
         * @param {string} [seriesId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsDeleteManyDeleteDelete(serviceProviderId: string, state: string, ids?: string, dates?: string, seriesId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsDeleteManyDeleteDelete.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsDeleteManyDeleteDelete.');
            }
            const localVarPath = `/v1/Sessions/Delete/ManyDelete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (dates !== undefined) {
                localVarQueryParameter['dates'] = dates;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (seriesId !== undefined) {
                localVarQueryParameter['seriesId'] = seriesId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a session series by ID
         * @param {string} sessionSeriesId The ID of the session recurring series.
         * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsDeleteRecurringSessionDelete(sessionSeriesId: string, serviceProviderId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'sessionSeriesId' is not null or undefined
            if (sessionSeriesId === null || sessionSeriesId === undefined) {
                throw new RequiredError('sessionSeriesId','Required parameter sessionSeriesId was null or undefined when calling v1SessionsDeleteRecurringSessionDelete.');
            }
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsDeleteRecurringSessionDelete.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsDeleteRecurringSessionDelete.');
            }
            const localVarPath = `/v1/Sessions/Delete/RecurringSession`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sessionSeriesId !== undefined) {
                localVarQueryParameter['sessionSeriesId'] = sessionSeriesId;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This will update the session series to match the new pattern of the session series.
         * @param {EditSessionSeriesRequest} body 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsEditSessionSeriesPost(body: EditSessionSeriesRequest, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling v1SessionsEditSessionSeriesPost.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsEditSessionSeriesPost.');
            }
            const localVarPath = `/v1/Sessions/EditSessionSeries`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditSessionSeriesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a session by id
         * @param {string} serviceProviderId The ID of the Service Provider whose session is being retrieved.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [id] 
         * @param {string} [seriesId] 
         * @param {Date} [sessionDate] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGet(serviceProviderId: string, state: string, id?: string, seriesId?: string, sessionDate?: Date, timeZone?: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsGet.');
            }
            const localVarPath = `/v1/Sessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (seriesId !== undefined) {
                localVarQueryParameter['seriesId'] = seriesId;
            }

            if (sessionDate !== undefined) {
                localVarQueryParameter['sessionDate'] = (sessionDate as any).toISOString();
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all sessions in a series that are mutable (scheduled or in progress).
         * @param {string} sessionSeriesId 
         * @param {string} providerId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGetAllMutableStatusSessionsInSeriesGet(sessionSeriesId: string, providerId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'sessionSeriesId' is not null or undefined
            if (sessionSeriesId === null || sessionSeriesId === undefined) {
                throw new RequiredError('sessionSeriesId','Required parameter sessionSeriesId was null or undefined when calling v1SessionsGetAllMutableStatusSessionsInSeriesGet.');
            }
            // verify required parameter 'providerId' is not null or undefined
            if (providerId === null || providerId === undefined) {
                throw new RequiredError('providerId','Required parameter providerId was null or undefined when calling v1SessionsGetAllMutableStatusSessionsInSeriesGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsGetAllMutableStatusSessionsInSeriesGet.');
            }
            const localVarPath = `/v1/Sessions/GetAllMutableStatusSessionsInSeries`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sessionSeriesId !== undefined) {
                localVarQueryParameter['sessionSeriesId'] = sessionSeriesId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint takes a GetDayView_Request object and returns a GetDayViewResponse object.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {GetDayViewRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGetDayViewPost(state: string, body?: GetDayViewRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsGetDayViewPost.');
            }
            const localVarPath = `/v1/Sessions/GetDayView`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetDayViewRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The endpoint returns the number of minutes spent on an ordered Service Area by a student during a specified time period.
         * @param {string} studentId 
         * @param {string} providerId 
         * @param {string} serviceAreaId 
         * @param {Date} startDateTime 
         * @param {Date} endDateTime 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGetMinutesSpentOnOrderedServiceAreaGet(studentId: string, providerId: string, serviceAreaId: string, startDateTime: Date, endDateTime: Date, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling v1SessionsGetMinutesSpentOnOrderedServiceAreaGet.');
            }
            // verify required parameter 'providerId' is not null or undefined
            if (providerId === null || providerId === undefined) {
                throw new RequiredError('providerId','Required parameter providerId was null or undefined when calling v1SessionsGetMinutesSpentOnOrderedServiceAreaGet.');
            }
            // verify required parameter 'serviceAreaId' is not null or undefined
            if (serviceAreaId === null || serviceAreaId === undefined) {
                throw new RequiredError('serviceAreaId','Required parameter serviceAreaId was null or undefined when calling v1SessionsGetMinutesSpentOnOrderedServiceAreaGet.');
            }
            // verify required parameter 'startDateTime' is not null or undefined
            if (startDateTime === null || startDateTime === undefined) {
                throw new RequiredError('startDateTime','Required parameter startDateTime was null or undefined when calling v1SessionsGetMinutesSpentOnOrderedServiceAreaGet.');
            }
            // verify required parameter 'endDateTime' is not null or undefined
            if (endDateTime === null || endDateTime === undefined) {
                throw new RequiredError('endDateTime','Required parameter endDateTime was null or undefined when calling v1SessionsGetMinutesSpentOnOrderedServiceAreaGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsGetMinutesSpentOnOrderedServiceAreaGet.');
            }
            const localVarPath = `/v1/Sessions/GetMinutesSpentOnOrderedServiceArea`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (serviceAreaId !== undefined) {
                localVarQueryParameter['serviceAreaId'] = serviceAreaId;
            }

            if (startDateTime !== undefined) {
                localVarQueryParameter['startDateTime'] = (startDateTime as any).toISOString();
            }

            if (endDateTime !== undefined) {
                localVarQueryParameter['endDateTime'] = (endDateTime as any).toISOString();
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} studentId 
         * @param {string} providerId 
         * @param {string} orderedSubjectId 
         * @param {Date} startDateTime 
         * @param {Date} endDateTime 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGetMinutesSpentOnOrderedSubjectGet(studentId: string, providerId: string, orderedSubjectId: string, startDateTime: Date, endDateTime: Date, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling v1SessionsGetMinutesSpentOnOrderedSubjectGet.');
            }
            // verify required parameter 'providerId' is not null or undefined
            if (providerId === null || providerId === undefined) {
                throw new RequiredError('providerId','Required parameter providerId was null or undefined when calling v1SessionsGetMinutesSpentOnOrderedSubjectGet.');
            }
            // verify required parameter 'orderedSubjectId' is not null or undefined
            if (orderedSubjectId === null || orderedSubjectId === undefined) {
                throw new RequiredError('orderedSubjectId','Required parameter orderedSubjectId was null or undefined when calling v1SessionsGetMinutesSpentOnOrderedSubjectGet.');
            }
            // verify required parameter 'startDateTime' is not null or undefined
            if (startDateTime === null || startDateTime === undefined) {
                throw new RequiredError('startDateTime','Required parameter startDateTime was null or undefined when calling v1SessionsGetMinutesSpentOnOrderedSubjectGet.');
            }
            // verify required parameter 'endDateTime' is not null or undefined
            if (endDateTime === null || endDateTime === undefined) {
                throw new RequiredError('endDateTime','Required parameter endDateTime was null or undefined when calling v1SessionsGetMinutesSpentOnOrderedSubjectGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsGetMinutesSpentOnOrderedSubjectGet.');
            }
            const localVarPath = `/v1/Sessions/GetMinutesSpentOnOrderedSubject`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (orderedSubjectId !== undefined) {
                localVarQueryParameter['orderedSubjectId'] = orderedSubjectId;
            }

            if (startDateTime !== undefined) {
                localVarQueryParameter['startDateTime'] = (startDateTime as any).toISOString();
            }

            if (endDateTime !== undefined) {
                localVarQueryParameter['endDateTime'] = (endDateTime as any).toISOString();
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restores a deleted session by a specific ID
         * @param {string} id 
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsIdUndoDeletePatch(id: string, serviceProviderId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1SessionsIdUndoDeletePatch.');
            }
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsIdUndoDeletePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsIdUndoDeletePatch.');
            }
            const localVarPath = `/v1/Sessions/{id}/UndoDelete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint updates the specified session with the given electronic signature.
         * @param {string} id 
         * @param {string} serviceProviderId The ID of the service provider who provided the services in the session. This is used as the partition key   to retrieve the session from the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionApprovalRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsIdUpdateSessionElectronicSignaturePatch(id: string, serviceProviderId: string, state: string, body?: PatchSessionApprovalRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1SessionsIdUpdateSessionElectronicSignaturePatch.');
            }
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsIdUpdateSessionElectronicSignaturePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsIdUpdateSessionElectronicSignaturePatch.');
            }
            const localVarPath = `/v1/Sessions/{id}/UpdateSessionElectronicSignature`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchSessionApprovalRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint updates the specified session with the given status.
         * @param {string} id 
         * @param {string} serviceProviderId The id of the original authoring service provider for this session.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionStatusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsIdUpdateSessionStatusPatch(id: string, serviceProviderId: string, state: string, body?: PatchSessionStatusRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1SessionsIdUpdateSessionStatusPatch.');
            }
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsIdUpdateSessionStatusPatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsIdUpdateSessionStatusPatch.');
            }
            const localVarPath = `/v1/Sessions/{id}/UpdateSessionStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchSessionStatusRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Requests:            GET api/Session/MonthView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/MonthView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
         * @summary This endpoint gets the MonthView Cards for the provided serviceProviderId, within  the given startDate and endDate.
         * @param {string} serviceProviderId The ID of the service provider for whom to get the MonthView Cards.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [startDate] The startDate of the date range for which to get the service provider&#x27;s sessions.
         * @param {Date} [endDate] The endDate of the date range for which to get the service provider&#x27;s sessions.
         * @param {string} [timezone] The timezone information that we need to adjust time accordingly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsMonthViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsMonthViewServiceProviderIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsMonthViewServiceProviderIdGet.');
            }
            const localVarPath = `/v1/Sessions/MonthView/{serviceProviderId}`
                .replace(`{${"serviceProviderId"}}`, encodeURIComponent(String(serviceProviderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any).toISOString();
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any).toISOString();
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The endpoint for patching a session.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPatch(state: string, body?: PatchSessionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsPatch.');
            }
            const localVarPath = `/v1/Sessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchSessionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Posts multiple students with the relation of the poster recorded and updates electronic signature
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionStudentJournal} [body] The request body for patching journal approval for a select set of students.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPatchSessionStudentJournalApprovalPatch(state: string, body?: PatchSessionStudentJournal, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsPatchSessionStudentJournalApprovalPatch.');
            }
            const localVarPath = `/v1/Sessions/PatchSessionStudentJournalApproval`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchSessionStudentJournal" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionsApprovalAndStatusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPatchSessionsApprovalAndStatusPatch(state: string, body?: PatchSessionsApprovalAndStatusRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsPatchSessionsApprovalAndStatusPatch.');
            }
            const localVarPath = `/v1/Sessions/PatchSessionsApprovalAndStatus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchSessionsApprovalAndStatusRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a single session.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateSessionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPost(clientId: string, state: string, body?: CreateSessionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1SessionsPost.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsPost.');
            }
            const localVarPath = `/v1/Sessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateSessionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all postable sessions for a given date range and provider range.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [startDate] 
         * @param {Date} [endDate] 
         * @param {boolean} [isProxyDataEntry] 
         * @param {boolean} [isAutonomous] 
         * @param {string} [currentUserServiceProviderId] 
         * @param {string} [assistantServiceProviderIds] 
         * @param {string} [dataEntryServiceProvidersIds] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPostableSessionsByDateRangeAndProviderRangeGet(state: string, startDate?: Date, endDate?: Date, isProxyDataEntry?: boolean, isAutonomous?: boolean, currentUserServiceProviderId?: string, assistantServiceProviderIds?: string, dataEntryServiceProvidersIds?: string, timeZone?: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsPostableSessionsByDateRangeAndProviderRangeGet.');
            }
            const localVarPath = `/v1/Sessions/PostableSessionsByDateRangeAndProviderRange`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any).toISOString();
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any).toISOString();
            }

            if (isProxyDataEntry !== undefined) {
                localVarQueryParameter['isProxyDataEntry'] = isProxyDataEntry;
            }

            if (isAutonomous !== undefined) {
                localVarQueryParameter['isAutonomous'] = isAutonomous;
            }

            if (currentUserServiceProviderId !== undefined) {
                localVarQueryParameter['currentUserServiceProviderId'] = currentUserServiceProviderId;
            }

            if (assistantServiceProviderIds !== undefined) {
                localVarQueryParameter['assistantServiceProviderIds'] = assistantServiceProviderIds;
            }

            if (dataEntryServiceProvidersIds !== undefined) {
                localVarQueryParameter['dataEntryServiceProvidersIds'] = dataEntryServiceProvidersIds;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary depricated
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateSessionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPut(state: string, body?: UpdateSessionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsPut.');
            }
            const localVarPath = `/v1/Sessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateSessionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a recurring session.
         * @param {string} clientId The id of the client in which the user is operating.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateRecurringSesssionRequest} [body] The request body for creating a recurring session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsRecurringSessionPost(clientId: string, state: string, body?: CreateRecurringSesssionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1SessionsRecurringSessionPost.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsRecurringSessionPost.');
            }
            const localVarPath = `/v1/Sessions/RecurringSession`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateRecurringSesssionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace old students with new student profile from a merge.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {MergeStudentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsReplaceStudentInSessionsPost(state: string, body?: MergeStudentRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsReplaceStudentInSessionsPost.');
            }
            const localVarPath = `/v1/Sessions/ReplaceStudentInSessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MergeStudentRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint updates many sessions to the status of RevisionsRequested and adds a note to each student journal.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RequestRevisionsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsRequestRevisionsAndUpdateStatusPatch(state: string, body?: RequestRevisionsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsRequestRevisionsAndUpdateStatusPatch.');
            }
            const localVarPath = `/v1/Sessions/RequestRevisionsAndUpdateStatus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RequestRevisionsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restores a session series that was previously deleted
         * @param {string} seriesId 
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsSeriesSeriesIdUndoDeletePatch(seriesId: string, serviceProviderId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'seriesId' is not null or undefined
            if (seriesId === null || seriesId === undefined) {
                throw new RequiredError('seriesId','Required parameter seriesId was null or undefined when calling v1SessionsSeriesSeriesIdUndoDeletePatch.');
            }
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsSeriesSeriesIdUndoDeletePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsSeriesSeriesIdUndoDeletePatch.');
            }
            const localVarPath = `/v1/Sessions/Series/{seriesId}/UndoDelete`
                .replace(`{${"seriesId"}}`, encodeURIComponent(String(seriesId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a session series by series id.
         * @param {string} serviceProviderId 
         * @param {string} sessionSeriesId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsSeriesSessionSeriesIdGet(serviceProviderId: string, sessionSeriesId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsSeriesSessionSeriesIdGet.');
            }
            // verify required parameter 'sessionSeriesId' is not null or undefined
            if (sessionSeriesId === null || sessionSeriesId === undefined) {
                throw new RequiredError('sessionSeriesId','Required parameter sessionSeriesId was null or undefined when calling v1SessionsSeriesSessionSeriesIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsSeriesSessionSeriesIdGet.');
            }
            const localVarPath = `/v1/Sessions/Series/{sessionSeriesId}`
                .replace(`{${"sessionSeriesId"}}`, encodeURIComponent(String(sessionSeriesId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint gets slim session cards representing the unposted sessions for the given provider IDs.
         * @param {string} serviceProviderIds The IDs of the service providers for whom to get the slim session cards.
         * @param {string} statuses The statuses to filter the session card results by (only those in these statuses will be returned).
         * @param {string} clientId The ID of the client in which the user is operating.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [fromDateTime] The starting datetime from which to get the session cards. This is optionally and is usually not provided.
         * @param {Date} [toDateTime] The datetime up until which to get the session cards. This is usually today&#x27;s date, which is the default when not specified.
         * @param {string} [timezone] The timezone information that we need to adjust time accordingly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsSlimGet(serviceProviderIds: string, statuses: string, clientId: string, state: string, fromDateTime?: Date, toDateTime?: Date, timezone?: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderIds' is not null or undefined
            if (serviceProviderIds === null || serviceProviderIds === undefined) {
                throw new RequiredError('serviceProviderIds','Required parameter serviceProviderIds was null or undefined when calling v1SessionsSlimGet.');
            }
            // verify required parameter 'statuses' is not null or undefined
            if (statuses === null || statuses === undefined) {
                throw new RequiredError('statuses','Required parameter statuses was null or undefined when calling v1SessionsSlimGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1SessionsSlimGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsSlimGet.');
            }
            const localVarPath = `/v1/Sessions/Slim`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderIds !== undefined) {
                localVarQueryParameter['serviceProviderIds'] = serviceProviderIds;
            }

            if (statuses !== undefined) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (fromDateTime !== undefined) {
                localVarQueryParameter['fromDateTime'] = (fromDateTime as any).toISOString();
            }

            if (toDateTime !== undefined) {
                localVarQueryParameter['toDateTime'] = (toDateTime as any).toISOString();
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restores a set of sessions, each specified by ID
         * @param {string} serviceProviderId The ID of the service provider whose session is being restore.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [ids] A list of IDs indicating which sessions to restore.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUndoDeleteManyUndoDelete(serviceProviderId: string, state: string, ids?: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsUndoDeleteManyUndoDelete.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsUndoDeleteManyUndoDelete.');
            }
            const localVarPath = `/v1/Sessions/UndoDelete/ManyUndo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [userId] The Id of the currently logged in User.
         * @param {string} [clientId] The Id of the currently logged in Client Account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUnpostedCountGet(state: string, userId?: string, clientId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsUnpostedCountGet.');
            }
            const localVarPath = `/v1/Sessions/UnpostedCount`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint updates the sesison time based off the drag and drop.
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionsSeriesDateRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUpdateSeriesTimePut(serviceProviderId: string, state: string, body?: PatchSessionsSeriesDateRequest, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsUpdateSeriesTimePut.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsUpdateSeriesTimePut.');
            }
            const localVarPath = `/v1/Sessions/UpdateSeriesTime`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchSessionsSeriesDateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This will update all sessions with the same details as the current session.
         * @param {UpdateSessionToFutureSessionsInSeriesRequest} body The details of the current session that we want to update future sessions with
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUpdateSessionToFutureSessionsPost(body: UpdateSessionToFutureSessionsInSeriesRequest, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling v1SessionsUpdateSessionToFutureSessionsPost.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsUpdateSessionToFutureSessionsPost.');
            }
            const localVarPath = `/v1/Sessions/UpdateSessionToFutureSessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateSessionToFutureSessionsInSeriesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:        PATCH api/Session/
         * @summary This endpoint updates the specified session with the given new start time and new end time.
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionSeriesTimeRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUpdateTimePatch(serviceProviderId: string, state: string, body?: PatchSessionSeriesTimeRequest, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsUpdateTimePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsUpdateTimePatch.');
            }
            const localVarPath = `/v1/Sessions/UpdateTime`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchSessionSeriesTimeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Requests:            GET api/Session/WeekView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/WeekView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
         * @summary This endpoint gets the WeekView Cards for the provided serviceProviderId, within  the given startDate and endDate.
         * @param {string} serviceProviderId The ID of the service provider for whom to get the WeekView Cards.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [startDate] The startDate of the date range for which to get the service provider&#x27;s sessions.
         * @param {Date} [endDate] The endDate of the date range for which to get the service provider&#x27;s sessions.
         * @param {string} [timezone] The timezone information that we need to adjust time accordingly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsWeekViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1SessionsWeekViewServiceProviderIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1SessionsWeekViewServiceProviderIdGet.');
            }
            const localVarPath = `/v1/Sessions/WeekView/{serviceProviderId}`
                .replace(`{${"serviceProviderId"}}`, encodeURIComponent(String(serviceProviderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any).toISOString();
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any).toISOString();
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionsApi - functional programming interface
 * @export
 */
export const SessionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Modifies a single session (specified by ID) in the sessions container, by adding the student to the session
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {AddStudentToSessionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsAddStudentPatch(state: string, body?: AddStudentToSessionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SessionResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsAddStudentPatch(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets all sessions
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsByProviderServiceProviderIdGet(serviceProviderId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetSessionsResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsByProviderServiceProviderIdGet(serviceProviderId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Map a single session to a series
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {ConvertSingleToRecurringRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsConvertSessionToSeriesPut(state: string, body?: ConvertSingleToRecurringRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConvertSingleToRecurringResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsConvertSessionToSeriesPut(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Requests:            GET api/Session/DayView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/WeekView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
         * @summary This endpoint gets the DayView Cards for the provided serviceProviderId, within  the given startDate and endDate.
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [startDate] 
         * @param {Date} [endDate] 
         * @param {string} [timezone] The timezone information that we need to adjust time accordingly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsDayViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetDayViewResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsDayViewServiceProviderIdGet(serviceProviderId, state, startDate, endDate, timezone, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Deletes a specified session by ID
         * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [id] 
         * @param {string} [seriesId] 
         * @param {Date} [sessionDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsDelete(serviceProviderId: string, state: string, id?: string, seriesId?: string, sessionDate?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsDelete(serviceProviderId, state, id, seriesId, sessionDate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Deletes a set of sessions, each specified by ID
         * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [ids] A list of IDs indicating which sessions to delete.
         * @param {string} [dates] 
         * @param {string} [seriesId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsDeleteManyDeleteDelete(serviceProviderId: string, state: string, ids?: string, dates?: string, seriesId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsDeleteManyDeleteDelete(serviceProviderId, state, ids, dates, seriesId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Deletes a session series by ID
         * @param {string} sessionSeriesId The ID of the session recurring series.
         * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsDeleteRecurringSessionDelete(sessionSeriesId: string, serviceProviderId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsDeleteRecurringSessionDelete(sessionSeriesId, serviceProviderId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This will update the session series to match the new pattern of the session series.
         * @param {EditSessionSeriesRequest} body 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsEditSessionSeriesPost(body: EditSessionSeriesRequest, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsEditSessionSeriesPost(body, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets a session by id
         * @param {string} serviceProviderId The ID of the Service Provider whose session is being retrieved.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [id] 
         * @param {string} [seriesId] 
         * @param {Date} [sessionDate] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGet(serviceProviderId: string, state: string, id?: string, seriesId?: string, sessionDate?: Date, timeZone?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SessionResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsGet(serviceProviderId, state, id, seriesId, sessionDate, timeZone, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets all sessions in a series that are mutable (scheduled or in progress).
         * @param {string} sessionSeriesId 
         * @param {string} providerId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGetAllMutableStatusSessionsInSeriesGet(sessionSeriesId: string, providerId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetMutableSessionsResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsGetAllMutableStatusSessionsInSeriesGet(sessionSeriesId, providerId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This endpoint takes a GetDayView_Request object and returns a GetDayViewResponse object.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {GetDayViewRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGetDayViewPost(state: string, body?: GetDayViewRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetDayViewResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsGetDayViewPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary The endpoint returns the number of minutes spent on an ordered Service Area by a student during a specified time period.
         * @param {string} studentId 
         * @param {string} providerId 
         * @param {string} serviceAreaId 
         * @param {Date} startDateTime 
         * @param {Date} endDateTime 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGetMinutesSpentOnOrderedServiceAreaGet(studentId: string, providerId: string, serviceAreaId: string, startDateTime: Date, endDateTime: Date, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetMinutesSpentOnOrderedServiceAreaResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsGetMinutesSpentOnOrderedServiceAreaGet(studentId, providerId, serviceAreaId, startDateTime, endDateTime, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} studentId 
         * @param {string} providerId 
         * @param {string} orderedSubjectId 
         * @param {Date} startDateTime 
         * @param {Date} endDateTime 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGetMinutesSpentOnOrderedSubjectGet(studentId: string, providerId: string, orderedSubjectId: string, startDateTime: Date, endDateTime: Date, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsGetMinutesSpentOnOrderedSubjectGet(studentId, providerId, orderedSubjectId, startDateTime, endDateTime, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Restores a deleted session by a specific ID
         * @param {string} id 
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsIdUndoDeletePatch(id: string, serviceProviderId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SessionResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsIdUndoDeletePatch(id, serviceProviderId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This endpoint updates the specified session with the given electronic signature.
         * @param {string} id 
         * @param {string} serviceProviderId The ID of the service provider who provided the services in the session. This is used as the partition key   to retrieve the session from the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionApprovalRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsIdUpdateSessionElectronicSignaturePatch(id: string, serviceProviderId: string, state: string, body?: PatchSessionApprovalRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SessionResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsIdUpdateSessionElectronicSignaturePatch(id, serviceProviderId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This endpoint updates the specified session with the given status.
         * @param {string} id 
         * @param {string} serviceProviderId The id of the original authoring service provider for this session.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionStatusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsIdUpdateSessionStatusPatch(id: string, serviceProviderId: string, state: string, body?: PatchSessionStatusRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SessionResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsIdUpdateSessionStatusPatch(id, serviceProviderId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Requests:            GET api/Session/MonthView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/MonthView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
         * @summary This endpoint gets the MonthView Cards for the provided serviceProviderId, within  the given startDate and endDate.
         * @param {string} serviceProviderId The ID of the service provider for whom to get the MonthView Cards.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [startDate] The startDate of the date range for which to get the service provider&#x27;s sessions.
         * @param {Date} [endDate] The endDate of the date range for which to get the service provider&#x27;s sessions.
         * @param {string} [timezone] The timezone information that we need to adjust time accordingly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsMonthViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetMonthViewResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsMonthViewServiceProviderIdGet(serviceProviderId, state, startDate, endDate, timezone, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary The endpoint for patching a session.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPatch(state: string, body?: PatchSessionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PatchSessionResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsPatch(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Posts multiple students with the relation of the poster recorded and updates electronic signature
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionStudentJournal} [body] The request body for patching journal approval for a select set of students.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPatchSessionStudentJournalApprovalPatch(state: string, body?: PatchSessionStudentJournal, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PatchSessionStudentJournalApprovalResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsPatchSessionStudentJournalApprovalPatch(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionsApprovalAndStatusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPatchSessionsApprovalAndStatusPatch(state: string, body?: PatchSessionsApprovalAndStatusRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsPatchSessionsApprovalAndStatusPatch(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Creates a single session.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateSessionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPost(clientId: string, state: string, body?: CreateSessionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SessionResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsPost(clientId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets all postable sessions for a given date range and provider range.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [startDate] 
         * @param {Date} [endDate] 
         * @param {boolean} [isProxyDataEntry] 
         * @param {boolean} [isAutonomous] 
         * @param {string} [currentUserServiceProviderId] 
         * @param {string} [assistantServiceProviderIds] 
         * @param {string} [dataEntryServiceProvidersIds] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPostableSessionsByDateRangeAndProviderRangeGet(state: string, startDate?: Date, endDate?: Date, isProxyDataEntry?: boolean, isAutonomous?: boolean, currentUserServiceProviderId?: string, assistantServiceProviderIds?: string, dataEntryServiceProvidersIds?: string, timeZone?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetAllPostableSessionsResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsPostableSessionsByDateRangeAndProviderRangeGet(state, startDate, endDate, isProxyDataEntry, isAutonomous, currentUserServiceProviderId, assistantServiceProviderIds, dataEntryServiceProvidersIds, timeZone, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary depricated
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateSessionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPut(state: string, body?: UpdateSessionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SessionResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsPut(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Creates a recurring session.
         * @param {string} clientId The id of the client in which the user is operating.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateRecurringSesssionRequest} [body] The request body for creating a recurring session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsRecurringSessionPost(clientId: string, state: string, body?: CreateRecurringSesssionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SessionSeries> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsRecurringSessionPost(clientId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Replace old students with new student profile from a merge.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {MergeStudentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsReplaceStudentInSessionsPost(state: string, body?: MergeStudentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsReplaceStudentInSessionsPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This endpoint updates many sessions to the status of RevisionsRequested and adds a note to each student journal.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RequestRevisionsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsRequestRevisionsAndUpdateStatusPatch(state: string, body?: RequestRevisionsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsRequestRevisionsAndUpdateStatusPatch(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Restores a session series that was previously deleted
         * @param {string} seriesId 
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsSeriesSeriesIdUndoDeletePatch(seriesId: string, serviceProviderId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsSeriesSeriesIdUndoDeletePatch(seriesId, serviceProviderId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets a session series by series id.
         * @param {string} serviceProviderId 
         * @param {string} sessionSeriesId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsSeriesSessionSeriesIdGet(serviceProviderId: string, sessionSeriesId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetSessionSeriesResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsSeriesSessionSeriesIdGet(serviceProviderId, sessionSeriesId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This endpoint gets slim session cards representing the unposted sessions for the given provider IDs.
         * @param {string} serviceProviderIds The IDs of the service providers for whom to get the slim session cards.
         * @param {string} statuses The statuses to filter the session card results by (only those in these statuses will be returned).
         * @param {string} clientId The ID of the client in which the user is operating.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [fromDateTime] The starting datetime from which to get the session cards. This is optionally and is usually not provided.
         * @param {Date} [toDateTime] The datetime up until which to get the session cards. This is usually today&#x27;s date, which is the default when not specified.
         * @param {string} [timezone] The timezone information that we need to adjust time accordingly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsSlimGet(serviceProviderIds: string, statuses: string, clientId: string, state: string, fromDateTime?: Date, toDateTime?: Date, timezone?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetSlimSessionCardsResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsSlimGet(serviceProviderIds, statuses, clientId, state, fromDateTime, toDateTime, timezone, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Restores a set of sessions, each specified by ID
         * @param {string} serviceProviderId The ID of the service provider whose session is being restore.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [ids] A list of IDs indicating which sessions to restore.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUndoDeleteManyUndoDelete(serviceProviderId: string, state: string, ids?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SessionResponse>> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsUndoDeleteManyUndoDelete(serviceProviderId, state, ids, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [userId] The Id of the currently logged in User.
         * @param {string} [clientId] The Id of the currently logged in Client Account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUnpostedCountGet(state: string, userId?: string, clientId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetUnpostedSessionCountResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsUnpostedCountGet(state, userId, clientId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This endpoint updates the sesison time based off the drag and drop.
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionsSeriesDateRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUpdateSeriesTimePut(serviceProviderId: string, state: string, body?: PatchSessionsSeriesDateRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsUpdateSeriesTimePut(serviceProviderId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This will update all sessions with the same details as the current session.
         * @param {UpdateSessionToFutureSessionsInSeriesRequest} body The details of the current session that we want to update future sessions with
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUpdateSessionToFutureSessionsPost(body: UpdateSessionToFutureSessionsInSeriesRequest, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsUpdateSessionToFutureSessionsPost(body, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:        PATCH api/Session/
         * @summary This endpoint updates the specified session with the given new start time and new end time.
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionSeriesTimeRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUpdateTimePatch(serviceProviderId: string, state: string, body?: PatchSessionSeriesTimeRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SessionResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsUpdateTimePatch(serviceProviderId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Requests:            GET api/Session/WeekView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/WeekView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
         * @summary This endpoint gets the WeekView Cards for the provided serviceProviderId, within  the given startDate and endDate.
         * @param {string} serviceProviderId The ID of the service provider for whom to get the WeekView Cards.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [startDate] The startDate of the date range for which to get the service provider&#x27;s sessions.
         * @param {Date} [endDate] The endDate of the date range for which to get the service provider&#x27;s sessions.
         * @param {string} [timezone] The timezone information that we need to adjust time accordingly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsWeekViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetWeekViewResponse> {
            const localVarFetchArgs = SessionsApiFetchParamCreator(configuration).v1SessionsWeekViewServiceProviderIdGet(serviceProviderId, state, startDate, endDate, timezone, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SessionsApi - factory interface
 * @export
 */
export const SessionsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Modifies a single session (specified by ID) in the sessions container, by adding the student to the session
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {AddStudentToSessionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsAddStudentPatch(state: string, body?: AddStudentToSessionRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsAddStudentPatch(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets all sessions
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsByProviderServiceProviderIdGet(serviceProviderId: string, state: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsByProviderServiceProviderIdGet(serviceProviderId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Map a single session to a series
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {ConvertSingleToRecurringRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsConvertSessionToSeriesPut(state: string, body?: ConvertSingleToRecurringRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsConvertSessionToSeriesPut(state, body, options)(fetch, basePath);
        },
        /**
         * Sample Requests:            GET api/Session/DayView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/WeekView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
         * @summary This endpoint gets the DayView Cards for the provided serviceProviderId, within  the given startDate and endDate.
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [startDate] 
         * @param {Date} [endDate] 
         * @param {string} [timezone] The timezone information that we need to adjust time accordingly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsDayViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsDayViewServiceProviderIdGet(serviceProviderId, state, startDate, endDate, timezone, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Deletes a specified session by ID
         * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [id] 
         * @param {string} [seriesId] 
         * @param {Date} [sessionDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsDelete(serviceProviderId: string, state: string, id?: string, seriesId?: string, sessionDate?: Date, options?: any) {
            return SessionsApiFp(configuration).v1SessionsDelete(serviceProviderId, state, id, seriesId, sessionDate, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Deletes a set of sessions, each specified by ID
         * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [ids] A list of IDs indicating which sessions to delete.
         * @param {string} [dates] 
         * @param {string} [seriesId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsDeleteManyDeleteDelete(serviceProviderId: string, state: string, ids?: string, dates?: string, seriesId?: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsDeleteManyDeleteDelete(serviceProviderId, state, ids, dates, seriesId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Deletes a session series by ID
         * @param {string} sessionSeriesId The ID of the session recurring series.
         * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsDeleteRecurringSessionDelete(sessionSeriesId: string, serviceProviderId: string, state: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsDeleteRecurringSessionDelete(sessionSeriesId, serviceProviderId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This will update the session series to match the new pattern of the session series.
         * @param {EditSessionSeriesRequest} body 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsEditSessionSeriesPost(body: EditSessionSeriesRequest, state: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsEditSessionSeriesPost(body, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets a session by id
         * @param {string} serviceProviderId The ID of the Service Provider whose session is being retrieved.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [id] 
         * @param {string} [seriesId] 
         * @param {Date} [sessionDate] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGet(serviceProviderId: string, state: string, id?: string, seriesId?: string, sessionDate?: Date, timeZone?: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsGet(serviceProviderId, state, id, seriesId, sessionDate, timeZone, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets all sessions in a series that are mutable (scheduled or in progress).
         * @param {string} sessionSeriesId 
         * @param {string} providerId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGetAllMutableStatusSessionsInSeriesGet(sessionSeriesId: string, providerId: string, state: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsGetAllMutableStatusSessionsInSeriesGet(sessionSeriesId, providerId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This endpoint takes a GetDayView_Request object and returns a GetDayViewResponse object.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {GetDayViewRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGetDayViewPost(state: string, body?: GetDayViewRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsGetDayViewPost(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary The endpoint returns the number of minutes spent on an ordered Service Area by a student during a specified time period.
         * @param {string} studentId 
         * @param {string} providerId 
         * @param {string} serviceAreaId 
         * @param {Date} startDateTime 
         * @param {Date} endDateTime 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGetMinutesSpentOnOrderedServiceAreaGet(studentId: string, providerId: string, serviceAreaId: string, startDateTime: Date, endDateTime: Date, state: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsGetMinutesSpentOnOrderedServiceAreaGet(studentId, providerId, serviceAreaId, startDateTime, endDateTime, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Depricated
         * @param {string} studentId 
         * @param {string} providerId 
         * @param {string} orderedSubjectId 
         * @param {Date} startDateTime 
         * @param {Date} endDateTime 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsGetMinutesSpentOnOrderedSubjectGet(studentId: string, providerId: string, orderedSubjectId: string, startDateTime: Date, endDateTime: Date, state: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsGetMinutesSpentOnOrderedSubjectGet(studentId, providerId, orderedSubjectId, startDateTime, endDateTime, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Restores a deleted session by a specific ID
         * @param {string} id 
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsIdUndoDeletePatch(id: string, serviceProviderId: string, state: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsIdUndoDeletePatch(id, serviceProviderId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This endpoint updates the specified session with the given electronic signature.
         * @param {string} id 
         * @param {string} serviceProviderId The ID of the service provider who provided the services in the session. This is used as the partition key   to retrieve the session from the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionApprovalRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsIdUpdateSessionElectronicSignaturePatch(id: string, serviceProviderId: string, state: string, body?: PatchSessionApprovalRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsIdUpdateSessionElectronicSignaturePatch(id, serviceProviderId, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This endpoint updates the specified session with the given status.
         * @param {string} id 
         * @param {string} serviceProviderId The id of the original authoring service provider for this session.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionStatusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsIdUpdateSessionStatusPatch(id: string, serviceProviderId: string, state: string, body?: PatchSessionStatusRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsIdUpdateSessionStatusPatch(id, serviceProviderId, state, body, options)(fetch, basePath);
        },
        /**
         * Sample Requests:            GET api/Session/MonthView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/MonthView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
         * @summary This endpoint gets the MonthView Cards for the provided serviceProviderId, within  the given startDate and endDate.
         * @param {string} serviceProviderId The ID of the service provider for whom to get the MonthView Cards.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [startDate] The startDate of the date range for which to get the service provider&#x27;s sessions.
         * @param {Date} [endDate] The endDate of the date range for which to get the service provider&#x27;s sessions.
         * @param {string} [timezone] The timezone information that we need to adjust time accordingly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsMonthViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsMonthViewServiceProviderIdGet(serviceProviderId, state, startDate, endDate, timezone, options)(fetch, basePath);
        },
        /**
         * 
         * @summary The endpoint for patching a session.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPatch(state: string, body?: PatchSessionRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsPatch(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Posts multiple students with the relation of the poster recorded and updates electronic signature
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionStudentJournal} [body] The request body for patching journal approval for a select set of students.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPatchSessionStudentJournalApprovalPatch(state: string, body?: PatchSessionStudentJournal, options?: any) {
            return SessionsApiFp(configuration).v1SessionsPatchSessionStudentJournalApprovalPatch(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Depricated
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionsApprovalAndStatusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPatchSessionsApprovalAndStatusPatch(state: string, body?: PatchSessionsApprovalAndStatusRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsPatchSessionsApprovalAndStatusPatch(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Creates a single session.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateSessionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPost(clientId: string, state: string, body?: CreateSessionRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsPost(clientId, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets all postable sessions for a given date range and provider range.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [startDate] 
         * @param {Date} [endDate] 
         * @param {boolean} [isProxyDataEntry] 
         * @param {boolean} [isAutonomous] 
         * @param {string} [currentUserServiceProviderId] 
         * @param {string} [assistantServiceProviderIds] 
         * @param {string} [dataEntryServiceProvidersIds] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPostableSessionsByDateRangeAndProviderRangeGet(state: string, startDate?: Date, endDate?: Date, isProxyDataEntry?: boolean, isAutonomous?: boolean, currentUserServiceProviderId?: string, assistantServiceProviderIds?: string, dataEntryServiceProvidersIds?: string, timeZone?: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsPostableSessionsByDateRangeAndProviderRangeGet(state, startDate, endDate, isProxyDataEntry, isAutonomous, currentUserServiceProviderId, assistantServiceProviderIds, dataEntryServiceProvidersIds, timeZone, options)(fetch, basePath);
        },
        /**
         * 
         * @summary depricated
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateSessionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsPut(state: string, body?: UpdateSessionRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsPut(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Creates a recurring session.
         * @param {string} clientId The id of the client in which the user is operating.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateRecurringSesssionRequest} [body] The request body for creating a recurring session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsRecurringSessionPost(clientId: string, state: string, body?: CreateRecurringSesssionRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsRecurringSessionPost(clientId, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Replace old students with new student profile from a merge.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {MergeStudentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsReplaceStudentInSessionsPost(state: string, body?: MergeStudentRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsReplaceStudentInSessionsPost(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This endpoint updates many sessions to the status of RevisionsRequested and adds a note to each student journal.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RequestRevisionsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsRequestRevisionsAndUpdateStatusPatch(state: string, body?: RequestRevisionsRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsRequestRevisionsAndUpdateStatusPatch(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Restores a session series that was previously deleted
         * @param {string} seriesId 
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsSeriesSeriesIdUndoDeletePatch(seriesId: string, serviceProviderId: string, state: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsSeriesSeriesIdUndoDeletePatch(seriesId, serviceProviderId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets a session series by series id.
         * @param {string} serviceProviderId 
         * @param {string} sessionSeriesId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsSeriesSessionSeriesIdGet(serviceProviderId: string, sessionSeriesId: string, state: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsSeriesSessionSeriesIdGet(serviceProviderId, sessionSeriesId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This endpoint gets slim session cards representing the unposted sessions for the given provider IDs.
         * @param {string} serviceProviderIds The IDs of the service providers for whom to get the slim session cards.
         * @param {string} statuses The statuses to filter the session card results by (only those in these statuses will be returned).
         * @param {string} clientId The ID of the client in which the user is operating.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [fromDateTime] The starting datetime from which to get the session cards. This is optionally and is usually not provided.
         * @param {Date} [toDateTime] The datetime up until which to get the session cards. This is usually today&#x27;s date, which is the default when not specified.
         * @param {string} [timezone] The timezone information that we need to adjust time accordingly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsSlimGet(serviceProviderIds: string, statuses: string, clientId: string, state: string, fromDateTime?: Date, toDateTime?: Date, timezone?: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsSlimGet(serviceProviderIds, statuses, clientId, state, fromDateTime, toDateTime, timezone, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Restores a set of sessions, each specified by ID
         * @param {string} serviceProviderId The ID of the service provider whose session is being restore.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [ids] A list of IDs indicating which sessions to restore.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUndoDeleteManyUndoDelete(serviceProviderId: string, state: string, ids?: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsUndoDeleteManyUndoDelete(serviceProviderId, state, ids, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [userId] The Id of the currently logged in User.
         * @param {string} [clientId] The Id of the currently logged in Client Account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUnpostedCountGet(state: string, userId?: string, clientId?: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsUnpostedCountGet(state, userId, clientId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This endpoint updates the sesison time based off the drag and drop.
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionsSeriesDateRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUpdateSeriesTimePut(serviceProviderId: string, state: string, body?: PatchSessionsSeriesDateRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsUpdateSeriesTimePut(serviceProviderId, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This will update all sessions with the same details as the current session.
         * @param {UpdateSessionToFutureSessionsInSeriesRequest} body The details of the current session that we want to update future sessions with
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUpdateSessionToFutureSessionsPost(body: UpdateSessionToFutureSessionsInSeriesRequest, state: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsUpdateSessionToFutureSessionsPost(body, state, options)(fetch, basePath);
        },
        /**
         * Sample Request:        PATCH api/Session/
         * @summary This endpoint updates the specified session with the given new start time and new end time.
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchSessionSeriesTimeRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsUpdateTimePatch(serviceProviderId: string, state: string, body?: PatchSessionSeriesTimeRequest, options?: any) {
            return SessionsApiFp(configuration).v1SessionsUpdateTimePatch(serviceProviderId, state, body, options)(fetch, basePath);
        },
        /**
         * Sample Requests:            GET api/Session/WeekView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/WeekView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
         * @summary This endpoint gets the WeekView Cards for the provided serviceProviderId, within  the given startDate and endDate.
         * @param {string} serviceProviderId The ID of the service provider for whom to get the WeekView Cards.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [startDate] The startDate of the date range for which to get the service provider&#x27;s sessions.
         * @param {Date} [endDate] The endDate of the date range for which to get the service provider&#x27;s sessions.
         * @param {string} [timezone] The timezone information that we need to adjust time accordingly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionsWeekViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options?: any) {
            return SessionsApiFp(configuration).v1SessionsWeekViewServiceProviderIdGet(serviceProviderId, state, startDate, endDate, timezone, options)(fetch, basePath);
        },
    };
};

/**
 * SessionsApi - interface
 * @export
 * @interface SessionsApi
 */
export interface SessionsApiInterface {
    /**
     * 
     * @summary Modifies a single session (specified by ID) in the sessions container, by adding the student to the session
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {AddStudentToSessionRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsAddStudentPatch(state: string, body?: AddStudentToSessionRequest, options?: any): Promise<SessionResponse>;

    /**
     * 
     * @summary Gets all sessions
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsByProviderServiceProviderIdGet(serviceProviderId: string, state: string, options?: any): Promise<GetSessionsResponse>;

    /**
     * 
     * @summary Map a single session to a series
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {ConvertSingleToRecurringRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsConvertSessionToSeriesPut(state: string, body?: ConvertSingleToRecurringRequest, options?: any): Promise<ConvertSingleToRecurringResponse>;

    /**
     * Sample Requests:            GET api/Session/DayView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/WeekView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
     * @summary This endpoint gets the DayView Cards for the provided serviceProviderId, within  the given startDate and endDate.
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {string} [timezone] The timezone information that we need to adjust time accordingly
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsDayViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options?: any): Promise<GetDayViewResponse>;

    /**
     * 
     * @summary Deletes a specified session by ID
     * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [id] 
     * @param {string} [seriesId] 
     * @param {Date} [sessionDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsDelete(serviceProviderId: string, state: string, id?: string, seriesId?: string, sessionDate?: Date, options?: any): Promise<{}>;

    /**
     * 
     * @summary Deletes a set of sessions, each specified by ID
     * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [ids] A list of IDs indicating which sessions to delete.
     * @param {string} [dates] 
     * @param {string} [seriesId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsDeleteManyDeleteDelete(serviceProviderId: string, state: string, ids?: string, dates?: string, seriesId?: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Deletes a session series by ID
     * @param {string} sessionSeriesId The ID of the session recurring series.
     * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsDeleteRecurringSessionDelete(sessionSeriesId: string, serviceProviderId: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary This will update the session series to match the new pattern of the session series.
     * @param {EditSessionSeriesRequest} body 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsEditSessionSeriesPost(body: EditSessionSeriesRequest, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Gets a session by id
     * @param {string} serviceProviderId The ID of the Service Provider whose session is being retrieved.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [id] 
     * @param {string} [seriesId] 
     * @param {Date} [sessionDate] 
     * @param {string} [timeZone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsGet(serviceProviderId: string, state: string, id?: string, seriesId?: string, sessionDate?: Date, timeZone?: string, options?: any): Promise<SessionResponse>;

    /**
     * 
     * @summary Gets all sessions in a series that are mutable (scheduled or in progress).
     * @param {string} sessionSeriesId 
     * @param {string} providerId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsGetAllMutableStatusSessionsInSeriesGet(sessionSeriesId: string, providerId: string, state: string, options?: any): Promise<GetMutableSessionsResponse>;

    /**
     * 
     * @summary This endpoint takes a GetDayView_Request object and returns a GetDayViewResponse object.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {GetDayViewRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsGetDayViewPost(state: string, body?: GetDayViewRequest, options?: any): Promise<GetDayViewResponse>;

    /**
     * 
     * @summary The endpoint returns the number of minutes spent on an ordered Service Area by a student during a specified time period.
     * @param {string} studentId 
     * @param {string} providerId 
     * @param {string} serviceAreaId 
     * @param {Date} startDateTime 
     * @param {Date} endDateTime 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsGetMinutesSpentOnOrderedServiceAreaGet(studentId: string, providerId: string, serviceAreaId: string, startDateTime: Date, endDateTime: Date, state: string, options?: any): Promise<GetMinutesSpentOnOrderedServiceAreaResponse>;

    /**
     * 
     * @summary Depricated
     * @param {string} studentId 
     * @param {string} providerId 
     * @param {string} orderedSubjectId 
     * @param {Date} startDateTime 
     * @param {Date} endDateTime 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsGetMinutesSpentOnOrderedSubjectGet(studentId: string, providerId: string, orderedSubjectId: string, startDateTime: Date, endDateTime: Date, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Restores a deleted session by a specific ID
     * @param {string} id 
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsIdUndoDeletePatch(id: string, serviceProviderId: string, state: string, options?: any): Promise<SessionResponse>;

    /**
     * 
     * @summary This endpoint updates the specified session with the given electronic signature.
     * @param {string} id 
     * @param {string} serviceProviderId The ID of the service provider who provided the services in the session. This is used as the partition key   to retrieve the session from the database.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionApprovalRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsIdUpdateSessionElectronicSignaturePatch(id: string, serviceProviderId: string, state: string, body?: PatchSessionApprovalRequest, options?: any): Promise<SessionResponse>;

    /**
     * 
     * @summary This endpoint updates the specified session with the given status.
     * @param {string} id 
     * @param {string} serviceProviderId The id of the original authoring service provider for this session.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionStatusRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsIdUpdateSessionStatusPatch(id: string, serviceProviderId: string, state: string, body?: PatchSessionStatusRequest, options?: any): Promise<SessionResponse>;

    /**
     * Sample Requests:            GET api/Session/MonthView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/MonthView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
     * @summary This endpoint gets the MonthView Cards for the provided serviceProviderId, within  the given startDate and endDate.
     * @param {string} serviceProviderId The ID of the service provider for whom to get the MonthView Cards.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [startDate] The startDate of the date range for which to get the service provider&#x27;s sessions.
     * @param {Date} [endDate] The endDate of the date range for which to get the service provider&#x27;s sessions.
     * @param {string} [timezone] The timezone information that we need to adjust time accordingly
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsMonthViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options?: any): Promise<GetMonthViewResponse>;

    /**
     * 
     * @summary The endpoint for patching a session.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsPatch(state: string, body?: PatchSessionRequest, options?: any): Promise<PatchSessionResponse>;

    /**
     * 
     * @summary Posts multiple students with the relation of the poster recorded and updates electronic signature
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionStudentJournal} [body] The request body for patching journal approval for a select set of students.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsPatchSessionStudentJournalApprovalPatch(state: string, body?: PatchSessionStudentJournal, options?: any): Promise<PatchSessionStudentJournalApprovalResponse>;

    /**
     * 
     * @summary Depricated
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionsApprovalAndStatusRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsPatchSessionsApprovalAndStatusPatch(state: string, body?: PatchSessionsApprovalAndStatusRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary Creates a single session.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateSessionRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsPost(clientId: string, state: string, body?: CreateSessionRequest, options?: any): Promise<SessionResponse>;

    /**
     * 
     * @summary Gets all postable sessions for a given date range and provider range.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {boolean} [isProxyDataEntry] 
     * @param {boolean} [isAutonomous] 
     * @param {string} [currentUserServiceProviderId] 
     * @param {string} [assistantServiceProviderIds] 
     * @param {string} [dataEntryServiceProvidersIds] 
     * @param {string} [timeZone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsPostableSessionsByDateRangeAndProviderRangeGet(state: string, startDate?: Date, endDate?: Date, isProxyDataEntry?: boolean, isAutonomous?: boolean, currentUserServiceProviderId?: string, assistantServiceProviderIds?: string, dataEntryServiceProvidersIds?: string, timeZone?: string, options?: any): Promise<GetAllPostableSessionsResponse>;

    /**
     * 
     * @summary depricated
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateSessionRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsPut(state: string, body?: UpdateSessionRequest, options?: any): Promise<SessionResponse>;

    /**
     * 
     * @summary Creates a recurring session.
     * @param {string} clientId The id of the client in which the user is operating.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateRecurringSesssionRequest} [body] The request body for creating a recurring session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsRecurringSessionPost(clientId: string, state: string, body?: CreateRecurringSesssionRequest, options?: any): Promise<SessionSeries>;

    /**
     * 
     * @summary Replace old students with new student profile from a merge.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {MergeStudentRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsReplaceStudentInSessionsPost(state: string, body?: MergeStudentRequest, options?: any): Promise<string>;

    /**
     * 
     * @summary This endpoint updates many sessions to the status of RevisionsRequested and adds a note to each student journal.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {RequestRevisionsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsRequestRevisionsAndUpdateStatusPatch(state: string, body?: RequestRevisionsRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary Restores a session series that was previously deleted
     * @param {string} seriesId 
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsSeriesSeriesIdUndoDeletePatch(seriesId: string, serviceProviderId: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Gets a session series by series id.
     * @param {string} serviceProviderId 
     * @param {string} sessionSeriesId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsSeriesSessionSeriesIdGet(serviceProviderId: string, sessionSeriesId: string, state: string, options?: any): Promise<GetSessionSeriesResponse>;

    /**
     * 
     * @summary This endpoint gets slim session cards representing the unposted sessions for the given provider IDs.
     * @param {string} serviceProviderIds The IDs of the service providers for whom to get the slim session cards.
     * @param {string} statuses The statuses to filter the session card results by (only those in these statuses will be returned).
     * @param {string} clientId The ID of the client in which the user is operating.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [fromDateTime] The starting datetime from which to get the session cards. This is optionally and is usually not provided.
     * @param {Date} [toDateTime] The datetime up until which to get the session cards. This is usually today&#x27;s date, which is the default when not specified.
     * @param {string} [timezone] The timezone information that we need to adjust time accordingly
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsSlimGet(serviceProviderIds: string, statuses: string, clientId: string, state: string, fromDateTime?: Date, toDateTime?: Date, timezone?: string, options?: any): Promise<GetSlimSessionCardsResponse>;

    /**
     * 
     * @summary Restores a set of sessions, each specified by ID
     * @param {string} serviceProviderId The ID of the service provider whose session is being restore.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [ids] A list of IDs indicating which sessions to restore.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsUndoDeleteManyUndoDelete(serviceProviderId: string, state: string, ids?: string, options?: any): Promise<Array<SessionResponse>>;

    /**
     * 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [userId] The Id of the currently logged in User.
     * @param {string} [clientId] The Id of the currently logged in Client Account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsUnpostedCountGet(state: string, userId?: string, clientId?: string, options?: any): Promise<GetUnpostedSessionCountResponse>;

    /**
     * 
     * @summary This endpoint updates the sesison time based off the drag and drop.
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionsSeriesDateRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsUpdateSeriesTimePut(serviceProviderId: string, state: string, body?: PatchSessionsSeriesDateRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary This will update all sessions with the same details as the current session.
     * @param {UpdateSessionToFutureSessionsInSeriesRequest} body The details of the current session that we want to update future sessions with
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsUpdateSessionToFutureSessionsPost(body: UpdateSessionToFutureSessionsInSeriesRequest, state: string, options?: any): Promise<{}>;

    /**
     * Sample Request:        PATCH api/Session/
     * @summary This endpoint updates the specified session with the given new start time and new end time.
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionSeriesTimeRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsUpdateTimePatch(serviceProviderId: string, state: string, body?: PatchSessionSeriesTimeRequest, options?: any): Promise<SessionResponse>;

    /**
     * Sample Requests:            GET api/Session/WeekView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/WeekView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
     * @summary This endpoint gets the WeekView Cards for the provided serviceProviderId, within  the given startDate and endDate.
     * @param {string} serviceProviderId The ID of the service provider for whom to get the WeekView Cards.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [startDate] The startDate of the date range for which to get the service provider&#x27;s sessions.
     * @param {Date} [endDate] The endDate of the date range for which to get the service provider&#x27;s sessions.
     * @param {string} [timezone] The timezone information that we need to adjust time accordingly
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    v1SessionsWeekViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options?: any): Promise<GetWeekViewResponse>;

}

/**
 * SessionsApi - object-oriented interface
 * @export
 * @class SessionsApi
 * @extends {BaseAPI}
 */
export class SessionsApi extends BaseAPI implements SessionsApiInterface {
    /**
     * 
     * @summary Modifies a single session (specified by ID) in the sessions container, by adding the student to the session
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {AddStudentToSessionRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsAddStudentPatch(state: string, body?: AddStudentToSessionRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsAddStudentPatch(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets all sessions
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsByProviderServiceProviderIdGet(serviceProviderId: string, state: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsByProviderServiceProviderIdGet(serviceProviderId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Map a single session to a series
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {ConvertSingleToRecurringRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsConvertSessionToSeriesPut(state: string, body?: ConvertSingleToRecurringRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsConvertSessionToSeriesPut(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Requests:            GET api/Session/DayView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/WeekView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
     * @summary This endpoint gets the DayView Cards for the provided serviceProviderId, within  the given startDate and endDate.
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {string} [timezone] The timezone information that we need to adjust time accordingly
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsDayViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsDayViewServiceProviderIdGet(serviceProviderId, state, startDate, endDate, timezone, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Deletes a specified session by ID
     * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [id] 
     * @param {string} [seriesId] 
     * @param {Date} [sessionDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsDelete(serviceProviderId: string, state: string, id?: string, seriesId?: string, sessionDate?: Date, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsDelete(serviceProviderId, state, id, seriesId, sessionDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Deletes a set of sessions, each specified by ID
     * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [ids] A list of IDs indicating which sessions to delete.
     * @param {string} [dates] 
     * @param {string} [seriesId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsDeleteManyDeleteDelete(serviceProviderId: string, state: string, ids?: string, dates?: string, seriesId?: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsDeleteManyDeleteDelete(serviceProviderId, state, ids, dates, seriesId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Deletes a session series by ID
     * @param {string} sessionSeriesId The ID of the session recurring series.
     * @param {string} serviceProviderId The ID of the service provider whose session is being deleted.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsDeleteRecurringSessionDelete(sessionSeriesId: string, serviceProviderId: string, state: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsDeleteRecurringSessionDelete(sessionSeriesId, serviceProviderId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This will update the session series to match the new pattern of the session series.
     * @param {EditSessionSeriesRequest} body 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsEditSessionSeriesPost(body: EditSessionSeriesRequest, state: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsEditSessionSeriesPost(body, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets a session by id
     * @param {string} serviceProviderId The ID of the Service Provider whose session is being retrieved.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [id] 
     * @param {string} [seriesId] 
     * @param {Date} [sessionDate] 
     * @param {string} [timeZone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsGet(serviceProviderId: string, state: string, id?: string, seriesId?: string, sessionDate?: Date, timeZone?: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsGet(serviceProviderId, state, id, seriesId, sessionDate, timeZone, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets all sessions in a series that are mutable (scheduled or in progress).
     * @param {string} sessionSeriesId 
     * @param {string} providerId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsGetAllMutableStatusSessionsInSeriesGet(sessionSeriesId: string, providerId: string, state: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsGetAllMutableStatusSessionsInSeriesGet(sessionSeriesId, providerId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This endpoint takes a GetDayView_Request object and returns a GetDayViewResponse object.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {GetDayViewRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsGetDayViewPost(state: string, body?: GetDayViewRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsGetDayViewPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary The endpoint returns the number of minutes spent on an ordered Service Area by a student during a specified time period.
     * @param {string} studentId 
     * @param {string} providerId 
     * @param {string} serviceAreaId 
     * @param {Date} startDateTime 
     * @param {Date} endDateTime 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsGetMinutesSpentOnOrderedServiceAreaGet(studentId: string, providerId: string, serviceAreaId: string, startDateTime: Date, endDateTime: Date, state: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsGetMinutesSpentOnOrderedServiceAreaGet(studentId, providerId, serviceAreaId, startDateTime, endDateTime, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Depricated
     * @param {string} studentId 
     * @param {string} providerId 
     * @param {string} orderedSubjectId 
     * @param {Date} startDateTime 
     * @param {Date} endDateTime 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsGetMinutesSpentOnOrderedSubjectGet(studentId: string, providerId: string, orderedSubjectId: string, startDateTime: Date, endDateTime: Date, state: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsGetMinutesSpentOnOrderedSubjectGet(studentId, providerId, orderedSubjectId, startDateTime, endDateTime, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Restores a deleted session by a specific ID
     * @param {string} id 
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsIdUndoDeletePatch(id: string, serviceProviderId: string, state: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsIdUndoDeletePatch(id, serviceProviderId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This endpoint updates the specified session with the given electronic signature.
     * @param {string} id 
     * @param {string} serviceProviderId The ID of the service provider who provided the services in the session. This is used as the partition key   to retrieve the session from the database.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionApprovalRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsIdUpdateSessionElectronicSignaturePatch(id: string, serviceProviderId: string, state: string, body?: PatchSessionApprovalRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsIdUpdateSessionElectronicSignaturePatch(id, serviceProviderId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This endpoint updates the specified session with the given status.
     * @param {string} id 
     * @param {string} serviceProviderId The id of the original authoring service provider for this session.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionStatusRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsIdUpdateSessionStatusPatch(id: string, serviceProviderId: string, state: string, body?: PatchSessionStatusRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsIdUpdateSessionStatusPatch(id, serviceProviderId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Requests:            GET api/Session/MonthView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/MonthView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
     * @summary This endpoint gets the MonthView Cards for the provided serviceProviderId, within  the given startDate and endDate.
     * @param {string} serviceProviderId The ID of the service provider for whom to get the MonthView Cards.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [startDate] The startDate of the date range for which to get the service provider&#x27;s sessions.
     * @param {Date} [endDate] The endDate of the date range for which to get the service provider&#x27;s sessions.
     * @param {string} [timezone] The timezone information that we need to adjust time accordingly
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsMonthViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsMonthViewServiceProviderIdGet(serviceProviderId, state, startDate, endDate, timezone, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary The endpoint for patching a session.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsPatch(state: string, body?: PatchSessionRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsPatch(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Posts multiple students with the relation of the poster recorded and updates electronic signature
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionStudentJournal} [body] The request body for patching journal approval for a select set of students.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsPatchSessionStudentJournalApprovalPatch(state: string, body?: PatchSessionStudentJournal, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsPatchSessionStudentJournalApprovalPatch(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Depricated
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionsApprovalAndStatusRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsPatchSessionsApprovalAndStatusPatch(state: string, body?: PatchSessionsApprovalAndStatusRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsPatchSessionsApprovalAndStatusPatch(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Creates a single session.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateSessionRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsPost(clientId: string, state: string, body?: CreateSessionRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsPost(clientId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets all postable sessions for a given date range and provider range.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {boolean} [isProxyDataEntry] 
     * @param {boolean} [isAutonomous] 
     * @param {string} [currentUserServiceProviderId] 
     * @param {string} [assistantServiceProviderIds] 
     * @param {string} [dataEntryServiceProvidersIds] 
     * @param {string} [timeZone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsPostableSessionsByDateRangeAndProviderRangeGet(state: string, startDate?: Date, endDate?: Date, isProxyDataEntry?: boolean, isAutonomous?: boolean, currentUserServiceProviderId?: string, assistantServiceProviderIds?: string, dataEntryServiceProvidersIds?: string, timeZone?: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsPostableSessionsByDateRangeAndProviderRangeGet(state, startDate, endDate, isProxyDataEntry, isAutonomous, currentUserServiceProviderId, assistantServiceProviderIds, dataEntryServiceProvidersIds, timeZone, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary depricated
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateSessionRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsPut(state: string, body?: UpdateSessionRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsPut(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Creates a recurring session.
     * @param {string} clientId The id of the client in which the user is operating.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateRecurringSesssionRequest} [body] The request body for creating a recurring session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsRecurringSessionPost(clientId: string, state: string, body?: CreateRecurringSesssionRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsRecurringSessionPost(clientId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Replace old students with new student profile from a merge.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {MergeStudentRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsReplaceStudentInSessionsPost(state: string, body?: MergeStudentRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsReplaceStudentInSessionsPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This endpoint updates many sessions to the status of RevisionsRequested and adds a note to each student journal.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {RequestRevisionsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsRequestRevisionsAndUpdateStatusPatch(state: string, body?: RequestRevisionsRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsRequestRevisionsAndUpdateStatusPatch(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Restores a session series that was previously deleted
     * @param {string} seriesId 
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsSeriesSeriesIdUndoDeletePatch(seriesId: string, serviceProviderId: string, state: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsSeriesSeriesIdUndoDeletePatch(seriesId, serviceProviderId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets a session series by series id.
     * @param {string} serviceProviderId 
     * @param {string} sessionSeriesId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsSeriesSessionSeriesIdGet(serviceProviderId: string, sessionSeriesId: string, state: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsSeriesSessionSeriesIdGet(serviceProviderId, sessionSeriesId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This endpoint gets slim session cards representing the unposted sessions for the given provider IDs.
     * @param {string} serviceProviderIds The IDs of the service providers for whom to get the slim session cards.
     * @param {string} statuses The statuses to filter the session card results by (only those in these statuses will be returned).
     * @param {string} clientId The ID of the client in which the user is operating.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [fromDateTime] The starting datetime from which to get the session cards. This is optionally and is usually not provided.
     * @param {Date} [toDateTime] The datetime up until which to get the session cards. This is usually today&#x27;s date, which is the default when not specified.
     * @param {string} [timezone] The timezone information that we need to adjust time accordingly
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsSlimGet(serviceProviderIds: string, statuses: string, clientId: string, state: string, fromDateTime?: Date, toDateTime?: Date, timezone?: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsSlimGet(serviceProviderIds, statuses, clientId, state, fromDateTime, toDateTime, timezone, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Restores a set of sessions, each specified by ID
     * @param {string} serviceProviderId The ID of the service provider whose session is being restore.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [ids] A list of IDs indicating which sessions to restore.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsUndoDeleteManyUndoDelete(serviceProviderId: string, state: string, ids?: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsUndoDeleteManyUndoDelete(serviceProviderId, state, ids, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [userId] The Id of the currently logged in User.
     * @param {string} [clientId] The Id of the currently logged in Client Account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsUnpostedCountGet(state: string, userId?: string, clientId?: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsUnpostedCountGet(state, userId, clientId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This endpoint updates the sesison time based off the drag and drop.
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionsSeriesDateRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsUpdateSeriesTimePut(serviceProviderId: string, state: string, body?: PatchSessionsSeriesDateRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsUpdateSeriesTimePut(serviceProviderId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This will update all sessions with the same details as the current session.
     * @param {UpdateSessionToFutureSessionsInSeriesRequest} body The details of the current session that we want to update future sessions with
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsUpdateSessionToFutureSessionsPost(body: UpdateSessionToFutureSessionsInSeriesRequest, state: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsUpdateSessionToFutureSessionsPost(body, state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:        PATCH api/Session/
     * @summary This endpoint updates the specified session with the given new start time and new end time.
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchSessionSeriesTimeRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsUpdateTimePatch(serviceProviderId: string, state: string, body?: PatchSessionSeriesTimeRequest, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsUpdateTimePatch(serviceProviderId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Requests:            GET api/Session/WeekView/{serviceProviderId}?startDate=7/1/2021&endDate=7/31/2021            GET api/Session/WeekView/{serviceProviderId}?startDate=2023-01-23T00:00:00.000Z&endDate=2023-01-24T00:00:00.000Z
     * @summary This endpoint gets the WeekView Cards for the provided serviceProviderId, within  the given startDate and endDate.
     * @param {string} serviceProviderId The ID of the service provider for whom to get the WeekView Cards.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [startDate] The startDate of the date range for which to get the service provider&#x27;s sessions.
     * @param {Date} [endDate] The endDate of the date range for which to get the service provider&#x27;s sessions.
     * @param {string} [timezone] The timezone information that we need to adjust time accordingly
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public v1SessionsWeekViewServiceProviderIdGet(serviceProviderId: string, state: string, startDate?: Date, endDate?: Date, timezone?: string, options?: any) {
        return SessionsApiFp(this.configuration).v1SessionsWeekViewServiceProviderIdGet(serviceProviderId, state, startDate, endDate, timezone, options)(this.fetch, this.basePath);
    }

}
