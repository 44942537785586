import { Box, BoxProps } from "@mui/material";
import React from "react";

/**
 * A wrapper component for the goals and objectives tab navbar.
 *
 * This component uses Material-UI's `Box` component to create a styled container
 * that wraps around its children. It provides a sticky navbar with a white background,
 * positioned at the top of its container, and includes some padding and margin for spacing.
 * Reused in both the listview and createOrEdit view
 *
 * @param props - The properties for the component.
 * @param props.sx - Optional styling to be applied to the `Box` component.
 * @param props.children - The content to be rendered inside the `Box` component.
 *
 * @returns A styled `Box` component that wraps around its children.
 */
export default function GoalsAndObjTabNavbarWrapper(
  props: Readonly<{
    sx?: BoxProps["sx"];
    children: React.ReactNode;
  }>,
) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        mb: 1,
        py: 1,
        bgcolor: "white",
        position: "sticky",
        top: 0,
        zIndex: 1,
        borderBottom: "1px solid",
        borderColor: "divider",
        ...(props?.sx ?? {}),
      }}
    >
      {props.children}
    </Box>
  );
}
