import StudentImportDisabledInputTooltip from "../student_import_disabled_input_tooltip";
import StudentProfileTextField from "../student_profile_text_field";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import InputFieldGrid from "../input_field_grid";
import { Controller } from "react-hook-form";
import useStudentProfileContext from "../../hooks/use_student_profile_context";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import useApiQueryData from "../../../../../../api/hooks/use_api_query_data";
import { API_PRESCRIBING_PROVIDER } from "../../../../../../api/api";
import { MSBInputErrorWrapper } from "../../../../../../fortitude";
import produce from "immer";

/**
 * This component renders a list of prescribed service areas and a composition of components used to mutate the data in the student profile.
 * It utilizes various input fields and controls to allow users to view and edit details related to prescribed service areas.
 *
 * @param {Readonly<Props>} props - The properties passed to the component.
 * @param {object} props.serviceAreaOptionsQueryClient - Query client for fetching service area options.
 * @param {Function} props.getValues - Function to get form values.
 * @param {boolean} props.disbaleIputFields - Flag to disable input fields.
 * @param {Function} props.setValue - Function to set form values.
 * @param {boolean} props.stateInUs - Flag indicating if the state is in the US.
 * @param {object} props.control - Control object for form handling.
 * @param {object} props.prescribingProviderOptionsQueryClient - Query client for fetching prescribing provider options.
 *
 * @returns {JSX.Element} The rendered component.
 */

type Props = {
  control: ReturnType<typeof useStudentProfileContext>["studentProfileForm"]["control"];
  serviceAreaName: string;
  disbaleIputFields: boolean;
  stateInUs: string;
  formInputIndex: number;

  prescribingProviderInput: {
    prescribingProvidersOptionsApiClient: ReturnType<
      typeof useApiQueryData<
        Awaited<ReturnType<typeof API_PRESCRIBING_PROVIDER.v1PrescribingProvidersGet>>
      >
    >;
  };
};

export default function PrescribedServiceArea(props: Readonly<Props>) {
  const {
    serviceAreaName,
    control,
    disbaleIputFields,
    stateInUs,
    formInputIndex,
    prescribingProviderInput,
  } = props;

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="h6" fontWeight={700}>
        {serviceAreaName}
      </Typography>
      <Divider sx={{ maxWidth: "300px", borderWidth: "1.5px", mb: 1.5 }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <InputFieldGrid
          items={[
            {
              key: "prescribed service area decision",
              content: (
                <Controller
                  name={`medicaidInfo.prescribedServiceAreas.${formInputIndex}.decision`}
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <StudentImportDisabledInputTooltip
                        fullwidth
                        show={disbaleIputFields}
                        state={stateInUs}
                      >
                        <FormControl disabled={disbaleIputFields}>
                          <RadioGroup
                            row
                            name="prescribed-service-area-decision"
                            value={field?.value ?? 2}
                            onChange={(e) => {
                              const value = Number(e.target.value);
                              field.onChange(value);
                            }}
                          >
                            <FormControlLabel
                              value={0}
                              control={<Radio size="small" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={1}
                              control={<Radio size="small" />}
                              label="No"
                            />
                            <FormControlLabel
                              value={2}
                              control={<Radio size="small" />}
                              label="None Selected"
                            />
                          </RadioGroup>
                          <FormHelperText error={!!error} sx={{ color: "error.main" }}>
                            {error?.message}
                          </FormHelperText>
                        </FormControl>
                      </StudentImportDisabledInputTooltip>
                    );
                  }}
                />
              ),
            },
          ]}
        />
        <InputFieldGrid
          items={[
            {
              key: "prescribed service area start date",
              content: (
                <Controller
                  control={control}
                  name={`medicaidInfo.prescribedServiceAreas.${formInputIndex}.startDate`}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <StudentImportDisabledInputTooltip
                        fullwidth
                        show={disbaleIputFields}
                        state={stateInUs}
                      >
                        <DatePicker
                          label={`Start Date`}
                          disabled={disbaleIputFields}
                          value={field.value ? dayjs(field.value) : null}
                          ref={field.ref}
                          onChange={(date) => {
                            field.onChange(dayjs(date));
                          }}
                          slots={{
                            textField: StudentProfileTextField,
                          }}
                          slotProps={{
                            textField: {
                              FormHelperTextProps: {
                                error: !!error?.message,
                              },
                              helperText: error?.message,
                              onBlur: field.onBlur,
                            },
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                        />
                      </StudentImportDisabledInputTooltip>
                    );
                  }}
                />
              ),
            },
            {
              key: "prescribed service area end date",
              content: (
                <Controller
                  control={control}
                  name={`medicaidInfo.prescribedServiceAreas.${formInputIndex}.endDate`}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <StudentImportDisabledInputTooltip
                        fullwidth
                        show={disbaleIputFields}
                        state={stateInUs}
                      >
                        <DatePicker
                          label={`End Date`}
                          disabled={disbaleIputFields}
                          value={field.value ? dayjs(field.value) : null}
                          ref={field.ref}
                          onChange={(date) => {
                            field.onChange(dayjs(date));
                          }}
                          slots={{
                            textField: StudentProfileTextField,
                          }}
                          slotProps={{
                            textField: {
                              FormHelperTextProps: {
                                error: !!error?.message,
                              },
                              helperText: error?.message,
                              onBlur: field.onBlur,
                            },
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                        />
                      </StudentImportDisabledInputTooltip>
                    );
                  }}
                />
              ),
            },
          ]}
        />
        <InputFieldGrid
          items={[
            {
              key: "prescribed service provider",
              content: (
                <Controller
                  control={control}
                  name={`medicaidInfo.prescribedServiceAreas.${formInputIndex}.prescribingProvider`}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <StudentImportDisabledInputTooltip
                        fullwidth
                        show={disbaleIputFields}
                        state={stateInUs}
                      >
                        <MSBInputErrorWrapper
                          isError={
                            prescribingProviderInput.prescribingProvidersOptionsApiClient.isError
                          }
                          errorText={
                            "Failed to fetch prescribing providers options, please click refresh button to try again."
                          }
                          displayRefreshButton={
                            prescribingProviderInput.prescribingProvidersOptionsApiClient.isError
                          }
                          refetch={
                            prescribingProviderInput.prescribingProvidersOptionsApiClient.refetch
                          }
                        >
                          <StudentProfileTextField
                            disabled={
                              disbaleIputFields ||
                              prescribingProviderInput.prescribingProvidersOptionsApiClient
                                .isError ||
                              prescribingProviderInput.prescribingProvidersOptionsApiClient
                                .isLoading
                            }
                            id="prescribed service provider"
                            label={
                              prescribingProviderInput.prescribingProvidersOptionsApiClient
                                .isLoading
                                ? "Loading prescribing providers..."
                                : "Prescribed Service Provider"
                            }
                            select
                            helperText={error?.message}
                            FormHelperTextProps={{
                              error: !!error?.message,
                            }}
                            value={field.value?.id ? field.value?.id : ""}
                            onChange={(e) => {
                              const selectedPrescribingProvider = (
                                prescribingProviderInput.prescribingProvidersOptionsApiClient.data
                                  ?.prescribingProviders ?? []
                              ).find((p) => p.id === e.target.value);
                              const updatedPrescribedServiceArea = produce(
                                selectedPrescribingProvider,
                                (draft) => draft,
                              );
                              field.onChange(updatedPrescribedServiceArea);
                            }}
                          >
                            {(
                              prescribingProviderInput.prescribingProvidersOptionsApiClient.data
                                ?.prescribingProviders ?? []
                            ).map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.firstName} {option.lastName}
                              </MenuItem>
                            ))}
                          </StudentProfileTextField>
                        </MSBInputErrorWrapper>
                      </StudentImportDisabledInputTooltip>
                    );
                  }}
                />
              ),
            },
            {
              key: "NPI",
              content: (
                <Controller
                  control={control}
                  name={`medicaidInfo.prescribedServiceAreas.${formInputIndex}.prescribingProvider`}
                  render={({ field }) => {
                    return (
                      <Typography sx={{ py: "7px", verticalAlign: "center" }}>
                        NPI: {field.value?.npi ?? "N/A"}
                      </Typography>
                    );
                  }}
                />
              ),
            },
          ]}
        />
      </Box>
    </Box>
  );
}
