import React from "react";
import { SchoolCampusRef, Service } from "../../../../../../profile-sdk/api";
import GridSectionLayout from "../../../../../../design/high-level/common/grid_section_layout";
import { Controller } from "react-hook-form";
import {
  FFY_OPTIONS, 
  InstanceReportFormSchema,
} from "../../constants/instance_report_form_schema"; 
import {
  MSBInputErrorWrapper,
  MSBSearchMultiselect,
  MSBSearchSingleSelect,
} from "../../../../../../fortitude";
import { MSBQueryResultType } from "../../../../../../api/hooks/use_api_query_data";
import msbMUIAutoCompleteFilterOptions from "../../../../../../utils/msb_mui_auto_complete_filter_options";
import { Box, Button } from "@mui/material";
import { INSTANCE_REPORT_MAX_WIDTH } from "../../constants/ui";
import useInstanceReportContext from "../../hooks/use_instance_report";

const InstanceReportForm: React.FC<{
  inputComponentProps: {
    campuses: InputComponentOptionsProps<SchoolCampusRef>;
    serviceTypes: InputComponentOptionsProps<Service>;
  };
  handleSubmit: (data: InstanceReportFormSchema) => void;
}> = (props) => {
  const {
    instanceReportForm: {
      control,
      handleSubmit,
      formState: { isValid },
    },
  } = useInstanceReportContext(); 
 
  return (
    <form onSubmit={handleSubmit(props?.handleSubmit)}>
      <GridSectionLayout
        headerConfig={{
          title: "Instance Report Filter(s)",
          title_sx: {
            fontWeight: 700,
          },
        }}
        maxWidth={INSTANCE_REPORT_MAX_WIDTH}
        bottomMargin={"2rem"}
        rows={[
          {
            rowSx: {
              alignItems: "stretch",
            },
            cellSizes: {
              xs: 12,
              sm: 6,
              lg: 4,
            },
            useCellStyling: {
              sx: {
                py: "18px",
                px: "20px",
                display: "flex",
                alignItems: "center",
                bgcolor: "contrasts.1",
              },
            },
            cells: [
              <Controller
                key={2}
                control={control}
                name={"schoolCampuses"}
                render={({ field, fieldState: { error } }) => {
                  // render campuses input field and display component states based on form state or API status
                  const { options, optionsApiStatus, refetchOptions } =
                    props.inputComponentProps.campuses;
                  const isApiError = optionsApiStatus === "error";
                  const errorText =
                    optionsApiStatus === "error"
                      ? "Error loading campuses, please click refresh button to try again"
                      : error?.message ?? "";
                  const label = optionsApiStatus === "pending" ? "Loading..." : "Campus";

                  return (
                    <MSBInputErrorWrapper
                      errorText={errorText}
                      isError={isApiError || !!error}
                      refetch={refetchOptions}
                      displayRefreshButton={isApiError}
                      rootProps={{
                        width: "100%",
                      }}
                    >
                      <MSBSearchMultiselect
                        selectedOptions={field.value ?? []}
                        options={options}
                        getOptionLabel={(option) => option?.name ?? ""}
                        onChange={(so) => field.onChange(so)}
                        renderOptionVariant="checkbox"
                        variant="no overflow after 1"
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        label={label}
                        sx={{ width: "100%", backgroundColor: "white" }}
                        autocompleteProps={{
                          disableCloseOnSelect: true,
                          filterOptions: msbMUIAutoCompleteFilterOptions(),
                        }}
                      />
                    </MSBInputErrorWrapper>
                  );
                }}
              />,
              <Controller
                key={3}
                control={control}
                name={"federalFiscalYear"}
                render={({ field, fieldState: { error } }) => (
                  <MSBSearchSingleSelect
                    autocompleteProps={{
                      sx: { width: "100%", backgroundColor: "white" },
                      disableClearable: true,
                    }}
                    onChange={(item) => {
                      field.onChange(item);
                    }}
                    label={"Federal Fiscal Year"}
                    options={FFY_OPTIONS?.map((item) => item.label)}
                    selectedOption={field.value as any}
                    getOptionLabel={(option) => option}
                  />
                )}
              />,
              <Controller
                key={4}
                control={control}
                name={"serviceTypes"}
                render={({ field, fieldState: { error } }) => {
                  const { options, optionsApiStatus, refetchOptions } =
                    props.inputComponentProps.serviceTypes;
                  const isApiError = optionsApiStatus === "error";
                  const errorText =
                    optionsApiStatus === "error"
                      ? "Error loading Service Category Types, please click refresh button to try again"
                      : error?.message ?? "";
                  const label =
                    optionsApiStatus === "pending" ? "Loading..." : "Service Category Type";
                  return (
                    <MSBInputErrorWrapper
                      errorText={errorText}
                      isError={isApiError || !!error}
                      refetch={refetchOptions}
                      displayRefreshButton={isApiError}
                      rootProps={{
                        width: "100%",
                      }}
                    >
                      <MSBSearchMultiselect
                        autocompleteProps={{
                          sx: { width: "100%", backgroundColor: "white" },
                          disableClearable: true,
                          disableCloseOnSelect: true,
                          filterOptions: msbMUIAutoCompleteFilterOptions(),
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOptionVariant="checkbox"
                        onChange={(so) => {
                          field.onChange(so);
                        }}
                        label={label}
                        disabled={optionsApiStatus === "pending"}
                        variant="no overflow after 1"
                        options={options?.map((item) => ({ id: item.id, name: item.name }))}
                        selectedOptions={field.value ?? []}
                        getOptionLabel={(option) => option?.name ?? ""}
                      />
                    </MSBInputErrorWrapper>
                  );
                }}
              />,
            ],
          },
          {
            rowSx: {
              mt: "3rem",
            },
            cellSizes: {
              xs: 12,
              sm: 12,
              lg: 4,
            },
            cells: [
              <></>,
              <></>,
              <Box key={5} display={"flex"} justifyContent={"flex-end"}>
                <Button
                  disabled={!isValid}
                  sx={{
                    width: "178px",
                    py: "1.5rem",
                  }}
                  type="submit"
                >
                  Generate
                </Button>
              </Box>,
            ],
          },
        ]}
      />
    </form>
  );
};

export default InstanceReportForm;

type InputComponentOptionsProps<T> = {
  options: T[];
  optionsApiStatus: MSBQueryResultType["status"];
  refetchOptions: MSBQueryResultType["refetch"];
};
