import { GridColDef } from "@mui/x-data-grid";
import { Typography } from "@mui/material";

export default function useCaseLoadManagerColumnDefinition() {
  const columnDefDefaultProps: Partial<GridColDef> = {
    flex: 1,
    minWidth: 120,
    headerAlign: "left",
    valueGetter(params) {
      return params.value || emptyCellValue;
    },
    renderHeader(params) {
      return (
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          {getHeaderNameFromField(params.field)}
        </Typography>
      );
    },
  };

  const emptyCellValue = "N/A";

  const staffDirectoryColumnDefinitions: GridColDef[] = [
    {
      ...columnDefDefaultProps,
      field: "firstName",
      headerName: "First Name",
    } as GridColDef,
    {
      ...columnDefDefaultProps,
      field: "lastName",
      headerName: "Last Name",
    } as GridColDef,
    {
      ...columnDefDefaultProps,
      field: "primaryCampus",
      headerName: "Primary Campus",
    } as GridColDef,
    {
      ...columnDefDefaultProps,
      field: "providerRole",
      headerName: "Service Provider Type",
    } as GridColDef,
  ];

  return { staffDirectoryColumnDefinitions };
}

  function getHeaderNameFromField(field: string): string {
    switch (field) {
      case "firstName":
        return "First Name";
      case "lastName":
        return "Last Name";
      case "primaryCampus":
        return "Primary Campus";
      case "select":
        return "All"
      // case "email":
      //   return "Email Address";
      // case "docType":
      //   return "Doc Type";
      // case "serviceProviderTypes":
      //   return "Service Provider Type(s)";
      // case "campusAssignments":
      //   return "Campus(es)";
      // case "clientAssignmentStatus":
      //   return "Xlogs Status";
      // case "employeeId":
      //   return "Employee ID";
      case "providerRole":
        return "Service Provider Type";
      default:
        return "N/A";
    }
  }