import { PrescribingProviderStateType } from "../../types/prescribing_provider_context_types";
import { PrescribingProviderAction, PrescribingProviderActionType } from "../prescribing_provider_actions"; 

export function prescribingProviderReducer(state:PrescribingProviderStateType,action:PrescribingProviderAction){
    switch(action.type){
        case PrescribingProviderActionType.SET_PRESCRIBING_PROVIDERS_LIST:
            return {
                ...state,
                data: action.payload
            }
        case PrescribingProviderActionType.SET_PRESCRIBING_PROVIDER:
            return {
                ...state,
                prescribingProvider: action.payload,
                isEditing:true,
                isAdding:false
            }
        case PrescribingProviderActionType.ADD_PRESCRIBING_PROVIDER: 
            return {
                ...state,
                isEditing: false,
                isAdding: true
            }
        case PrescribingProviderActionType.SET_PRESCRIBING_PROVIDER_TO_DELETE:
            return {
                ...state,
                checkedPrescribingProviders: action.payload
            }

        case PrescribingProviderActionType.CLOSE_ADD_PRESCRIBING_PROVIDER_MODAL:
            return {
                ...state,
                isEditing: false,
                isAdding: false
            }

        case PrescribingProviderActionType.CLOSE_EDIT_PRESCRIBING_PROVIDER_MODAL:
            return {
                ...state,
                selectedPrescribingProvider: undefined,
                isEditing: false,
                isAdding: false
            }
        
        case PrescribingProviderActionType.SET_ADD_PRESCRIBING_PROVIDER_ERROR_MESSAGE:
            return {
                ...state,
                error: action.payload,
                isEditing: false,
                isAdding: false
            }
     
        default:
            return state
    }
} 