import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  useMediaQuery,
} from "@mui/material";

import UserApprovalsTabsEnum from "../../types/user_approvals_tabs_enum";
import XNGCheckboxLabel from "../../../../../../../../design/low-level/checkbox_label";
import XNGSimpleSearchBar from "../../../../../../../../design/low-level/simple_searchbar";
import UsersTabState, { UsersTabAction } from "../../types/users_tabs_state";
import UsersHistoryTabState, {
  UsersHistoryTabAction,
  UsersHistoryTabStatusFilter,
} from "../../types/users_history_tabs_state";
import { useMemo } from "react";
import { MSBICONS } from "../../../../../../../../fortitude";

type Props = {
  selectedTab: UserApprovalsTabsEnum;
  tabData: UsersTabState | UsersHistoryTabState;
  tabDispatch: React.Dispatch<UsersTabAction> | React.Dispatch<UsersHistoryTabAction>;
  disableFilter?: boolean;
};

const UserApprovalFilter = (props: Props) => {
  const { selectedTab, tabData, tabDispatch } = props;
  const hasHitBreakpoint = useMediaQuery("(width <= 736px)");

  const searchOptions = useMemo(() => {
    const options = Array.from(tabData.usersOptions.values()).map(
      (v) => `${v.user?.firstName} ${v.user?.lastName}`,
    );
    return options;
  }, [tabData.usersOptions]);

  const { Filter } = MSBICONS;

  return (
    <Stack
      width={"100%"}
      py={2}
      gap={3}
      alignItems={"center"}
      sx={{
        flexDirection: hasHitBreakpoint ? "column" : "row",
        justifyContent: {
          justifyContent: hasHitBreakpoint ? "center" : "start",
        },
      }}
    >
      <SvgIcon
        sx={{
          display: hasHitBreakpoint ? "none" : "flex",
          svg: {
            width: "24px",
            height: "24px",
          },
        }}
      >
        <Filter />
      </SvgIcon>

      {selectedTab === UserApprovalsTabsEnum.approval_denial_history && (
        <FormControl fullWidth sx={{ maxWidth: "100px" }}>
          <InputLabel size="small">Status</InputLabel>
          <Select
            value={(tabData as UsersHistoryTabState).statusFilter}
            onChange={(e) =>
              (tabDispatch as React.Dispatch<UsersHistoryTabAction>)({
                type: "select_status_filter",
                payload: e.target.value as UsersHistoryTabStatusFilter,
              })
            }
            size="small"
            label="Status"
            fullWidth
            disabled={props.disableFilter}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"Approved"}>Approved</MenuItem>
            <MenuItem value={"Denied"}>Denied</MenuItem>
          </Select>
        </FormControl>
      )}
      <Box
        width={250}
        display={"flex"}
        gap={3}
        alignItems={"center"}
        sx={{
          flexDirection: {
            flexDirection: "column",
            sm: "row",
          },
          justifyContent: {
            justifyContent: "center",
            // sm: "flex-start"
          },
        }}
      >
        <Box flexGrow={1} width={"100%"}>
          <XNGSimpleSearchBar
            id={"search-id"}
            options={searchOptions}
            useFilterOptions={{ limit: 20 }}
            value={tabData.searchValue}
            onInputChange={(_, v) => tabDispatch({ type: "search_users", payload: v })}
            size={"small"}
            useInputField={{
              label: "",
              placeholder: " Search User",
            }}
            useStartAdornment
            disableDropdown
            disabled={props.disableFilter}
          />
        </Box>
      </Box>
      {selectedTab !== UserApprovalsTabsEnum.approval_denial_history && (
        <Box display={"flex"} flexDirection={"row"}>
          <XNGCheckboxLabel
            label={"Select All"}
            size="small"
            disabled={props.disableFilter || tabData.filteredUsers.length === 0}
            checked={tabData.filteredUsers.length !== 0 && (tabData as UsersTabState).selectedUsers.length === tabData.filteredUsers.length}
            onChange={(_, checked) => {
              if (checked) {
                (tabDispatch as React.Dispatch<UsersTabAction>)({
                  type: "select_users",
                  payload: tabData.filteredUsers,
                });
              } else {
                (tabDispatch as React.Dispatch<UsersTabAction>)({
                  type: "select_users",
                  payload: [],
                });
              }
            }}
          />
          <XNGCheckboxLabel
            label={"View Selected"}
            size="small"
            disabled={(tabData as UsersTabState).selectedUsers.length === 0 || props.disableFilter}
            checked={(tabData as UsersTabState).viewOnlySelected}
            onChange={(_, checked) => {
              (tabDispatch as React.Dispatch<UsersTabAction>)({
                type: "view_selected",
                payload: checked,
              });
            }}
          />
        </Box>
      )}
    </Stack>
  );
};

export default UserApprovalFilter;
