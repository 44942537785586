import { object, array, string, InferType } from "yup";

export interface FFYOption {
  label: string;
  value: string;
  startDate: string;
  endDate: string;
} 

export const FFY_OPTIONS:FFYOption[] = [
  { label: "FFY25 | 10/1/24 - 9/30/25", value: "FFY25", startDate: "10/1/24", endDate: "9/30/25" },
  { label: "FFY24 | 10/1/23 - 9/30/24", value: "FFY24", startDate: "10/1/23", endDate: "9/30/24" },
  { label: "FFY23 | 10/1/22 - 9/30/23", value: "FFY23", startDate: "10/1/22", endDate: "9/30/23" },
  { label: "FFY22 | 10/1/21 - 9/30/22", value: "FFY22", startDate: "10/1/21", endDate: "9/30/22" },
];

export const instanceReportFormSchema = object().shape({
  schoolCampuses: array().of(
    object().shape({
      id: string(),
      name: string(),
    }),
  ).min(1, "Please select at least one school campus."),
  federalFiscalYear: string()
    .oneOf(FFY_OPTIONS?.map((option) => option.label), "Invalid time period")
    .required("Time period is required."),

  serviceTypes: array()
    .of(
      object().shape({
        id: string(),
        name: string(),
      }),
    )
    .min(1, "Please select at least one service provider type."),
});

export type InstanceReportFormSchema = InferType<typeof instanceReportFormSchema>;
