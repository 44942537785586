import { Box, SvgIcon, Typography } from "@mui/material";
import StudentProfileBackBtn from "./student_profile_back_btn";
import { MSBICONS } from "../../../../../fortitude";
import SaveBtn from "./save_btn";

type Props = {
  studentProfileData: {
    firstName: string;
    middleName: string;
    lastName: string;
    status: number;
  };
  saveBtn: {
    disable: boolean;
    hide: boolean;
    loading: boolean;
  };
  onBackBtnClick: () => void;
};

// student xlogs status
//Pending = 0, Active = 1, Inactive = 2, Referral = 3, Transfer = 4

const studentStatusMap: Record<
  number,
  { color: string; text: "Pending" | "Active" | "Inactive" | "Referral" | "Transfer" }
> = {
  0: {
    color: "warning.main",
    text: "Pending",
  },
  1: {
    color: "success.main",
    text: "Active",
  },
  2: {
    color: "error.main",
    text: "Inactive",
  },
  3: {
    color: "info.main",
    text: "Referral",
  },
  4: {
    color: "primary.main",
    text: "Transfer",
  },
};

export default function StudentProfilePageHeader(props: Readonly<Props>) {
  const { studentProfileData, onBackBtnClick } = props;
  const { LoadingAnimation } = MSBICONS;
  return (
    <Box>
      <Box sx={{ p: 1 }}>
        <StudentProfileBackBtn onClick={onBackBtnClick} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 1 }}>
        <Typography component={"h1"} variant="h4" sx={{ mb: 1.5 }}>
          Student Profile
          {props.saveBtn.loading && (
            <SvgIcon
              sx={{
                transform: "translateY(15px) translateX(5px)",
              }}
              fontSize="large"
            >
              <LoadingAnimation />
            </SvgIcon>
          )}
        </Typography>
        {!props.saveBtn.hide && (
          <SaveBtn disable={props.saveBtn.disable} loading={props.saveBtn.loading} />
        )}
      </Box>

      <Box>
        <Typography component={"h3"} variant="h6">
          {studentProfileData?.firstName} {studentProfileData.middleName}{" "}
          {studentProfileData?.lastName}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box
            sx={{
              borderRadius: "50%",
              height: "15px",
              width: "15px",
              bgcolor: studentStatusMap[studentProfileData?.status ?? 0]?.color ?? "error.main",
            }}
          ></Box>
          <Typography variant="body2">
            {studentStatusMap[studentProfileData?.status]?.text ?? "N/A"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
