import { Outlet, Navigate } from "react-router";
import { useXNGSelector } from "./context/store";
import { selectLoggedInClientAssignment, selectUser } from "./context/slices/userProfileSlice";
import { ROUTES_XLOGS } from "./constants/URLs";
import SidebarLayout from "./layouts/SidebarLayout";
import useSidebarLayoutTabs from "./views/admin/constants/sidebar_layout_btns";

export default function AdminProtectedRoute() {
  const loggedInClientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const user = useXNGSelector(selectUser);
  const { userManagementButtons } = useSidebarLayoutTabs();

  const isClientAdmin =
    loggedInClientAssignment.isDelegatedAdmin || loggedInClientAssignment.isExecutiveAdmin;
  const isMSBAdmin = user?.isMsbAdmin || user?.isSuperAdmin;

  return isClientAdmin || isMSBAdmin ? (
    <SidebarLayout view="admin" sidebarContent={userManagementButtons} content={<Outlet />} />
  ) : (
    <Navigate to={ROUTES_XLOGS.calendar} replace />
  );
}
