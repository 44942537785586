import { useRef } from 'react';
import { recurrencePatternMapper } from '../../scheduler/utils/recurence_pattern_mapper';
import useApiMutateData from '../../../api/hooks/use_api_mutate_data';
import { API_SESSIONS } from '../../../api/api';
import { ActualSession, ConvertSingleToRecurringRequest } from '../../../session-sdk';
import dayjs from 'dayjs';
import produce from "immer";

export default function useConvertSessionToSeriesHandler() {
    const recurrentPattern = useRef<undefined | ReturnType<typeof recurrencePatternMapper>>(undefined);
 
    const handleSetRecurrencePattern = (pattern: ReturnType<typeof recurrencePatternMapper>) => {
        recurrentPattern.current = pattern
    }

    const { mutateAsync, status:convertToSessionSeriesRequestStatus } = useApiMutateData({
        mutationKey: ["v1SessionsConvertSessionToSeriesPut"],
        mutationFn: async ({
            state,
            body
        }: {
            state: string, body?: ConvertSingleToRecurringRequest
        }) => {
            return await API_SESSIONS.v1SessionsConvertSessionToSeriesPut(state, body);
        },

    })

    async function convertSingleSessionToSessionSeries({ userStateInUS, session }: {
        userStateInUS: string,
        session: ActualSession
    }): Promise<{
        status: "none" | "complete" | "error",
        session: ActualSession | Error | null
    }> 
    {

        if (recurrentPattern.current) {
            try {
                const newSesh = await mutateAsync({
                    state: userStateInUS,
                    body: {
                        currentSession: session,
                        recurrencePattern: {
                            ...recurrentPattern.current,
                            startDate: dayjs(session.meetingDetails?.date).format("YYYY-MM-DD"),
                            mode: 1, // weekly
                        },
                    }
                },);


                const updatedSession = produce(session || {}, (draft) => {
                    draft.seriesId = newSesh.seriesId;
                });

                return {
                    status: "complete",
                    session: updatedSession
                }
            } catch (e) {
                console.error(e);
                return {
                    status: "error",
                    session: e as Error
                }
            }
        }

        return {
            status: "none",
            session: null
        }

    }

    return {
        handleSetRecurrencePattern,
        convertSingleSessionToSessionSeries,
        convertToSessionSeriesRequestStatus
    }
}
