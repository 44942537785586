import { Box, Divider, SxProps, Typography } from "@mui/material";
import React from "react";
import useBreakpointHelper from "../../../design/hooks/use_breakpoint_helper";
import usePalette from "../../../hooks/usePalette";

type TabInnerViewportLayoutTwoSectionsProps = Readonly<{
  right: React.ReactNode;
  left: React.ReactNode;
  rightConfig?: ColumnConfigProp;
  leftConfig?: ColumnConfigProp;
  sx?: SxProps;
}>;

type ColumnConfigProp = {
  title?: string;
  useLink?: UseLinkProp;
  sx?: SxProps;
};

type UseLinkProp = {
  onClick: () => void;
  text: string;
  disabled?: boolean;
};

/**
 * Renders two columns in a flex row, and breaks to a flex-column at the internally specified breakpoint.
 *
 * This breakpoint likely shouldn't be overridden for consistency across the notator tabs.
 */
function AutoBreakpointNotatorLayoutTwoColumns(props: TabInnerViewportLayoutTwoSectionsProps) {
  const isWidescreen = useBreakpointHelper().isGreaterThanEqualTo("md");
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        pr: ".75rem", // Chosen to right-align content to the right of the Next button
        flexDirection: isWidescreen ? "row" : "column",
        ...props.sx,
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", width: "100%", ...props.leftConfig?.sx }}
      >
        <ColumnContent title={props.leftConfig?.title} useLink={props.leftConfig?.useLink}>
          {props.left}
        </ColumnContent>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", width: "100%", ...props.rightConfig?.sx }}
      >
        <ColumnContent title={props.rightConfig?.title} useLink={props.rightConfig?.useLink}>
          {props.right}
        </ColumnContent>
      </Box>
    </Box>
  );
}

function ColumnContent(
  props: Readonly<{
    title?: string | React.ReactNode;
    children?: React.ReactNode;
    useLink?: UseLinkProp;
  }>,
) {
  const palette = usePalette();
  return (
    <>
      <Box
        sx={{
          height: "4.35rem", // Chosen to be consistent with other notator tab layouts
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography variant="h6">{props.title}</Typography>
        {props.useLink && (
          <a style={{color: props?.useLink?.disabled ? palette.disabled : palette.primary[2]}} onClick={() => !props.useLink!.disabled && props.useLink!.onClick()}  target="#">
            {props.useLink!.text}
          </a>
        )}
      </Box>

      <Divider sx={{ mb: "1rem" }} />

      {props.children}
    </>
  );
}

export default AutoBreakpointNotatorLayoutTwoColumns;
