import React, { useMemo, useReducer } from "react";
import { useXNGSelector } from "../../../../../context/store";
import {
  selectLoggedInClientAssignment,
  selectUser,
} from "../../../../../context/slices/userProfileSlice";
import { selectStateInUS } from "../../../../../context/slices/stateInUsSlice";
import { selectClientID } from "../../../../../context/slices/loggedInClientSlice";
import { ClientAssignment, DistrictRef, UserResponse } from "@xng/profile";
import {
  instanceReportFormSchema,
  InstanceReportFormSchema,
} from "../constants/instance_report_form_schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  InstancesReportActions,
  instancesReportInit,
  InstancesReportStoreType,
  intancesReportReducer,
} from "./reducers/instances_report_reducer";

export interface InstanceReportContextState {
  clientAssignment: ClientAssignment;
  user: UserResponse;
  stateInUs: string;
  clientId: string;
  authorizedDistricts: DistrictRef[];
  instanceReportForm: ReturnType<typeof useForm<InstanceReportFormSchema>>;
  store: InstancesReportStoreType;
  instancesReportDispatcher: React.Dispatch<InstancesReportActions>;
}
const InstanceReportProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const clientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const user = useXNGSelector(selectUser);
  const stateInUs = useXNGSelector(selectStateInUS);
  const clientId = useXNGSelector(selectClientID);
  const authorizedDistricts = clientAssignment.authorizedDistricts;

  const [state, dispatch] = useReducer(intancesReportReducer, undefined, instancesReportInit);
  const instanceReportForm = useForm<InstanceReportFormSchema>({
    resolver: yupResolver(instanceReportFormSchema),
    defaultValues: {
      federalFiscalYear: "",
      schoolCampuses: [],
      serviceTypes: [],
    },
  });
  const formState = instanceReportForm.formState;

  const value = useMemo(
    () => ({
      clientAssignment,
      user: user as UserResponse,
      stateInUs,
      clientId,
      authorizedDistricts: authorizedDistricts ?? [],
      instanceReportForm,
      store: state,
      instancesReportDispatcher: dispatch,
    }),
    [clientAssignment, user, stateInUs, clientId, authorizedDistricts, formState, state],
  );

  return (
    <InstanceReportContext.Provider
      value={value}
    >
      {children}
    </InstanceReportContext.Provider>
  );
};

export default InstanceReportProvider;

export const InstanceReportContext = React.createContext({} as InstanceReportContextState);
