import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AdminHeaderDistrictSelector from "../../../common/admin_header_district_selector";
import { ADMIN_VISUAL_STANDARD_SPACING } from "../../../constants/spacing";
import { MSBSearch } from "../../../../../fortitude";
import useApiQueryServiceProviders from "../../../../../api/hooks/service_provider/use_api_query_service_providers";
import useCaseLoadManager from "../hooks/use_case_load_manager";
import React, { useEffect, useMemo, useState } from "react";
import { CaseLoadUserTable } from "../tables/case_load_user_table";
import { ServiceProviderRef } from "@xng/profile";
import QueryStatusModal from "../../../../../design/modal_templates/query_status_modal";
import useCaseLoadDebouncedDispatchers from "../hooks/use_debounced_search_query_dispatchers";
import { useCaseLoadMutation } from "../hooks/use_case_load_mutation";
import produce from "immer";
import { ApproverCaseload, ServiceProviderCaseloadOption } from "../../../../../profile-sdk";
import { AddServiceProviderToCaseloadModal } from "../modal/add_approver_modal";
import { usePatchUserToClientAssignments } from "../hooks/use_add_user_to_case_load";
import FullPageLoadingScreen from "../../../../../design/high-level/common/full_page_loading_screen";
import useGetUserProfileData from "../hooks/use_get_user_profile";
import refetchUserAndUnpostedSessions from "../../../../unposted_sessions/utils/refetch_user_and_unposted_sessions";
import useRefetchUnpostedSessionsRequests from "../../../../unposted_sessions/hooks/use_refetch_unposted_sessions_requests";
import useCaseLoadServiceProviders from "../hooks/use_case_load_service_providers";
import { placeholderForFutureLogErrorText } from "../../../../../temp/errorText";

const REM_SPACING = "1.5rem";

export default function ApproverCaseloadsView() {
  const {
    state: {
      clientId,
      stateInUs,
      selectedApprover,
      user, 
      selectedServiceProviderCaseLoads: supervicedServiceProviders,
    },
    dispatch: caseLoadApproverDispatch,
  } = useCaseLoadManager();
  const addUserToCaseLoadMutation = usePatchUserToClientAssignments();
  const removeUserFromCaseLoadMutation = usePatchUserToClientAssignments();
  const profileMuation = useGetUserProfileData();
  const caseLoadMutation = useCaseLoadMutation();
  const [showAddToApproverCaseloadModal, setShowAddToApproverCaseloadModal] =
    useState<boolean>(false);
  const [
    showFailedToFetchApproverServiceProviderProfile,
    setShowFailedToFetchApproverServiceProviderProfile,
  ] = useState(false);
  const [showErrorRemovingUserFromCaseload, setShowErrorRemovingUserFromCaseload] = useState(false);
  const [addUserToCaseLoadStatusModal, setAddUserToCaseLoadStatusModal] = useState(false);
  const [showFailedToGetCurrentApproverCaseLoad, setShowFailedToGetCurrentApproverCaseLoad] =
    useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [approverSearchValue, setApproverSearchValue] = useState<string>("");
  const {
    updateSearchFilterQueryParamHandlerForApprover,
    setControlledServiceProviderUnderApproverSearchQuery, 
    controlledServiceProviderUnderApproverSearchQuery, 
  } = useCaseLoadDebouncedDispatchers();

  const queryApiProviders = useApiQueryServiceProviders({
    queryParams: {
      clientId: clientId,
      state: stateInUs,
    },
    options: {
      enabled: clientId !== null && stateInUs !== null,
    },
  });
  const getServiceProvidersDropDownList = useCaseLoadServiceProviders();
  const { refetchOnCaseloadAction } = useRefetchUnpostedSessionsRequests();

  const searchProviderOptions = React.useMemo(() => {
    return queryApiProviders.data?.serviceProviders?.filter((sp) => {
      const source = `${sp.firstName}${sp.lastName}`?.trim()?.toLowerCase();
      const target = `${user?.firstName}${user?.lastName}`?.trim()?.toLowerCase();
      return source !== target;
    });
  }, [queryApiProviders?.data?.serviceProviders, user]);

  const memoisedFilteredAllServiceProvidersList = useMemo(() => {
    return getServiceProvidersDropDownList?.data?.serviceProviderCaseloadOptions?.filter((sp) => {
      return !caseLoadMutation?.data?.approverCaseloads?.some(
        (appointedSp) => appointedSp.id === sp.id,
      );
    });
  }, [caseLoadMutation?.data, getServiceProvidersDropDownList?.data]);

  const handleRemove = () => {
    const supervicedServiceProvidersFiltered = produce(convertedApproverCaseLoad, (draftState) => {
      if (!draftState) return;
      return draftState.filter(
        (item) => !supervicedServiceProviders?.some((provider) => provider.id === item.id),
      );
    });

    removeUserFromCaseLoadMutation.mutate(
      {
        body: { supervisedServiceProviders: supervicedServiceProvidersFiltered ?? [] },
        userId: profileMuation?.data?.id ?? "",
      },
      {
        onSuccess: () => {
          caseLoadMutation?.refetch();
          if (profileMuation?.data?.id === user?.id) {
            // if target approver is currently logged in user then update the profile
            refetchUserAndUnpostedSessions();
            refetchOnCaseloadAction();
          }
          setIsAllChecked(false);
        },
        onError: (removeServicePrvoiderFromCaseLoadError) => {
          console.error(removeServicePrvoiderFromCaseLoadError);
          setShowErrorRemovingUserFromCaseload(true);
        },
      },
    );

    caseLoadApproverDispatch({
      type: "SET_SELECTED_SERVICE_PROVIDERS",
      payload: {
        approverCaseLoadServiceProviders: [],
      },
    });
  };

  const handleAddUserToApproverCaseLoad = (provider: ServiceProviderCaseloadOption) => {
    const newSupervisedServiceProvider = {
      firstName: provider?.firstName as string,
      lastName: provider?.lastName as string,
      id: provider?.id as string,
      email: provider?.emailAddress,
    };
    const updatedSupervisedServiceProviders = [
      ...(convertedApproverCaseLoad ?? []),
      newSupervisedServiceProvider,
    ];
    addUserToCaseLoadMutation.mutate(
      {
        body: {
          supervisedServiceProviders: updatedSupervisedServiceProviders,
        },
        userId: profileMuation?.data?.id ?? "",
      },
      {
        onSuccess: () => {
          caseLoadMutation.refetch();
          setAddUserToCaseLoadStatusModal(true);
          if (profileMuation?.data?.id === user?.id) {
            // if target approver is currently logged in user then update the profile
            refetchUserAndUnpostedSessions();
            refetchOnCaseloadAction();
          }
          setIsAllChecked(false);
        },
        onError: (addServicePrvoiderToCaseLoadError) => {
          console.error(addServicePrvoiderToCaseLoadError);
          setAddUserToCaseLoadStatusModal(true);
        },
      },
    );
  };

  function getLoadingText() {
    if (addUserToCaseLoadMutation?.isPending) {
      return "Adding User To CaseLoad";
    } else if (removeUserFromCaseLoadMutation?.isPending) {
      return "Removing User From CaseLoad";
    }
    return "Loading...";
  }

  const convertedApproverCaseLoad = useMemo(() => {
    return caseLoadMutation?.data?.approverCaseloads?.map(convertToServiceProviderType);
  }, [caseLoadMutation?.data]);

  const filteredRows = useMemo(() => {
    return caseLoadMutation?.data?.approverCaseloads
      ?.filter(
        (item) =>
          item.providerName
            ?.toLowerCase()
            ?.includes(String(controlledServiceProviderUnderApproverSearchQuery ?? "").toLowerCase()),
      )
      ?.map(convertToServiceProviderType);
  }, [caseLoadMutation?.data, controlledServiceProviderUnderApproverSearchQuery]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
  });

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setApproverSearchValue(event.target.value);
  }

  function handleSelectionChange(
    event: React.ChangeEvent<{}>,
    selectedOption: ServiceProviderCaseloadOption | null,
  ) {
    if (!selectedOption) throw new Error(placeholderForFutureLogErrorText);
    setApproverSearchValue(getOptionLabel(selectedOption));
    updateSearchFilterQueryParamHandlerForApprover(selectedOption as ServiceProviderRef);
  }

  function getOptionLabel(serviceProvider: ServiceProviderRef) {
    return `${serviceProvider?.firstName} ${serviceProvider?.lastName} - ${
      serviceProvider?.email === null ? "No Email" : serviceProvider.email
    }`;
  }

  useEffect(() => {
    if (caseLoadMutation?.error) {
      setShowFailedToGetCurrentApproverCaseLoad(true);
    }
  }, [caseLoadMutation?.error]);

  useEffect(() => {
    if (profileMuation?.error) {
      setShowFailedToFetchApproverServiceProviderProfile(true);
    }
  }, [profileMuation?.error]);

  useEffect(()=>{
    if(selectedApprover){
      setApproverSearchValue(getOptionLabel(selectedApprover));
    }
  },[selectedApprover])

  return (
    <Box
      p={REM_SPACING}
      sx={{
        p: ADMIN_VISUAL_STANDARD_SPACING,
        display: "flex",
        flexDirection: "column",
        gap: REM_SPACING,
      }}
    >
      {(addUserToCaseLoadMutation?.isPending ||
        removeUserFromCaseLoadMutation?.isPending ||
        profileMuation?.isLoading) && <FullPageLoadingScreen text={getLoadingText()} />}

      <QueryStatusModal
        status={addUserToCaseLoadMutation?.status}
        isOpen={addUserToCaseLoadStatusModal}
        onSettledClose={() => {
          setAddUserToCaseLoadStatusModal(false);
        }}
        content={{
          successTitle: "Thank You!",
          successBody: "Based on selection provider(s) has been added.",
          errorTitle: "Failed Adding Service Provider(s)",
          errorBody:
            addUserToCaseLoadMutation?.error?.message ??
            "Failed to add service provider(s) to case load",
          cancelButtonText: "close",
        }}
      />

      <QueryStatusModal
        status="error"
        isOpen={showErrorRemovingUserFromCaseload}
        onSettledClose={() => {
          setShowErrorRemovingUserFromCaseload(false);
        }}
        content={{
          errorTitle: "Failed to remove user from caseload",
          errorBody:
            removeUserFromCaseLoadMutation?.error?.message ?? "Failed to remove user from caseload",
          cancelButtonText: "close",
        }}
      />

      <QueryStatusModal
        status="error"
        isOpen={showFailedToGetCurrentApproverCaseLoad}
        onSettledClose={() => {
          setShowFailedToGetCurrentApproverCaseLoad(false);
        }}
        content={{
          errorTitle: "Failed to remove user from caseload",
          errorBody:
            removeUserFromCaseLoadMutation?.error?.message ?? "Failed to remove user from caseload",
          cancelButtonText: "close",
        }}
      />

      <QueryStatusModal
        status="error"
        isOpen={showFailedToFetchApproverServiceProviderProfile}
        onSettledClose={() => {
          setShowFailedToFetchApproverServiceProviderProfile(false);
        }}
        content={{
          errorTitle: "Failed to fetch approver service provider profile",
          errorBody:
            profileMuation?.error?.message ?? "Failed to fetch approver service provider profile",
          cancelButtonText: "close",
        }}
      />

      <AddServiceProviderToCaseloadModal
        open={showAddToApproverCaseloadModal}
        onClose={() => {
          setShowAddToApproverCaseloadModal(false);
        }}
        onCreateUserClick={() => {}}
        options={memoisedFilteredAllServiceProvidersList ?? []}
        onUserClick={(provider) => {
          handleAddUserToApproverCaseLoad(provider);
        }}
      />

      <AdminHeaderDistrictSelector onChange={(e) => {}} />
      {/*  */}
      <Typography mt={4} sx={{ fontWeight: 700 }} variant="h6">
        Approver Caseload Set Up
      </Typography>
      <Box>
        <Typography sx={{ fontWeight: 700 }}>Manage Caseload</Typography>
        <Divider sx={{ marginTop: "10px" }} />
      </Box>
      {/*  select approver  */}
      <Stack gap={2} direction={"row"} alignItems={"center"}>
        <Typography>Please select the Approver :</Typography>

        <Autocomplete
          options={searchProviderOptions ?? []}
          getOptionKey={(option: ServiceProviderCaseloadOption) => option?.id as string}
          // @ts-ignore
          getOptionLabel={(option: ServiceProviderCaseloadOption) => getOptionLabel(option)}
          id="auto-comp-id"
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={""}
              variant="outlined"
              onChange={handleSearchChange}
              fullWidth 
              InputProps={{ ...params.InputProps }}
            />
          )}
          renderOption={(props, option, index) => {
            const key = `listItem-${index.index}`;
            return (
              <Box component={"li"} {...props} key={key}>
                {getOptionLabel(option as ServiceProviderRef)}
              </Box>
            );
          }}
          value={selectedApprover as ServiceProviderRef }
          onChange={handleSelectionChange}
          inputValue={approverSearchValue}
          autoSelect
          autoHighlight
          disableClearable={true}
          sx={{ width: "350px" }}
          filterOptions={filterOptions}
        />
      </Stack>
      {/* show/hide depeding on approver selected */}
      {selectedApprover && (
        <>
          <Typography fontWeight={700}>
            Below is a list of provider(s) in{" "}
            {selectedApprover?.firstName + " " + selectedApprover?.lastName}'s caseload
          </Typography>
          <Divider />
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Stack direction={"row"} gap={1}>
              <Button onClick={() => setShowAddToApproverCaseloadModal(true)}>Add</Button>
              <Button
                color="error"
                disabled={caseLoadMutation?.isPending || supervicedServiceProviders?.length === 0}
                onClick={() => handleRemove()}
              >
                Remove
              </Button>
            </Stack>
            <MSBSearch
              value={controlledServiceProviderUnderApproverSearchQuery ?? ""}
              onChange={(e) => {
                const value = e?.target?.value;
                setControlledServiceProviderUnderApproverSearchQuery(value); 
              }}
            />
          </Stack>
          <CaseLoadUserTable
            isAllChecked={isAllChecked}
            setIsAllChecked={setIsAllChecked}
            rows={filteredRows ?? []}
            totalNumberOfRows={caseLoadMutation?.data?.approverCaseloads?.length ?? 0}
            initialPageSize={20}
            onSelectionChange={(selectedRows) => {
              caseLoadApproverDispatch({
                type: "SET_SELECTED_SERVICE_PROVIDERS",
                payload: {
                  approverCaseLoadServiceProviders: selectedRows,
                },
              });
            }}
            loading={caseLoadMutation?.isPending}
          />
        </>
      )}
    </Box>
  );
}

function convertToServiceProviderType(arg: ApproverCaseload) {
  const nameParts = arg?.providerName?.split(" ") || ["", ""];
  return {
    id: arg?.id ?? "",
    firstName: nameParts[0],
    lastName: nameParts.slice(1).join(" "), // Handle names with more than two parts
    primaryCampus: arg?.campus ?? "",
    providerRole: arg?.providerRole ?? "",
  };
}
