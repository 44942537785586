/**
 * Checks if the current state matches the specified state to check.
 *
 * @param currentState - The current state code.
 * @param stateToCheck - The state code to check against ("TX" or "NH").
 * @returns `true` if the current state matches the state to check, otherwise `false`.
 *
 * @remarks
 * This function helps avoid issues because there is no type safety for our states,
 * and we have to manually type "NH" or "TX", which is prone to errors.
 */
export default function isInState(currentState: string, stateToCheck: "TX" | "NH") {
  return currentState === stateToCheck;
}
