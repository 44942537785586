import { PrescribingProviderRef } from "../../../../../profile-sdk";

export type PrescribingProviders = {
  id: string;
  name: string;
  npi: string;
  stateMedicaidNumber: string;
};

export const normalizeText = (text?: string): string =>
  text?.replace(/\s+/g, " ").trim().toLowerCase() ?? "";

export const providerExists = (
  { firstName, lastName, npi, stateMedicaidNumber,id }: PrescribingProviderRef,
  prescribingProviders: PrescribingProviders[],
): boolean => {
  if (!prescribingProviders?.length) return false;

  const fullName = normalizeText(`${firstName} ${lastName}`);

  return prescribingProviders.some(
    ({ name, npi: providerNpi, stateMedicaidNumber: providerMedicaid, id:providerId }) => {
     if(id === providerId) return false
     return normalizeText(name) === fullName && normalizeText(providerNpi) === normalizeText(npi)
    }
  );
};
 