import { Alert, Box, Button, MenuItem, Typography } from "@mui/material";
import { GoalsAndObjectivesStateProps } from "../../tabs/goals_and_objectives_tab";
import GoalCard from "./goal_card";
import GoalsAndObjTabNavbarWrapper from "./goals_and_obj_tab_navbar_wrapper";
import useStudentProfileContext from "../../hooks/use_student_profile_context";
import { useMemo } from "react";
import { MSBInputErrorWrapper } from "../../../../../../fortitude";
import StudentProfileTextField from "../student_profile_text_field";
import useGoalAreaOfFocusOptionsAndFilterSelectionHandler from "../../hooks/goals_and_objectives_tab/use_goal_area_of_focus_options_and_filter_selection_handler";
import studentProfileGoalsStatusFilterOptions from "../../constants/student_profile_goals_active_filter_options";
import produce from "immer";

type Props = {
  goalAreaOfFocusSelectionAndOptions: ReturnType<
    typeof useGoalAreaOfFocusOptionsAndFilterSelectionHandler
  >;
  handleChangeView: (view: GoalsAndObjectivesStateProps) => void;
};

export default function GoalsAndObjectivesListView(props: Readonly<Props>) {
  const {
    studentProfileForm: { watch },
  } = useStudentProfileContext();

  const {
    handleChangeView,
    goalAreaOfFocusSelectionAndOptions: {
      goalAreaOfFocusFilterOptions,
      goalAreaOfFocusOptionsApiClient,
      selectedGoalAreaOfFocus,
      handleChangeGoalAreaOfFocusFilter,
      selectedGoalStatusFilter,
      handleChangeGoalStatusFilter,
    },
  } = props;

  const goalsList = watch("goalsAndObjectives.goals") ?? [];

  const filteredGoalsList = useMemo(() => {
    if (selectedGoalAreaOfFocus.id === "all" && selectedGoalStatusFilter === "All")
      return goalsList;

    // filter by goal area of focus - first pass
    const goalAreaOfFocusFilter = produce(goalsList, (draft) => {
      if (selectedGoalAreaOfFocus.id === "all") return draft;
      return draft?.filter((goal) => goal.goalAreaOfFocus?.id === selectedGoalAreaOfFocus.id) ?? [];
    });

    // filter by goal status - second pass
    const goalStatusFilter = produce(goalAreaOfFocusFilter, (draft) => {
      if (selectedGoalStatusFilter === "All") return draft;
      return draft?.filter((goal) => goal.status === selectedGoalStatusFilter) ?? [];
    });

    return goalStatusFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoalAreaOfFocus, selectedGoalStatusFilter]);

  return (
    <Box position={"relative"}>
      <GoalsAndObjTabNavbarWrapper
        sx={{
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          }}
        >
          <MSBInputErrorWrapper
            isError={goalAreaOfFocusOptionsApiClient.isError}
            displayRefreshButton={goalAreaOfFocusOptionsApiClient.isError}
            refetch={goalAreaOfFocusOptionsApiClient.refetch}
            errorText={"Unable to load goal area of focus options. Please refresh to try again."}
          >
            <StudentProfileTextField
              key={selectedGoalAreaOfFocus.id}
              sx={{
                width: 200,
              }}
              select
              id="goalAreaOfFocus"
              label={
                goalAreaOfFocusOptionsApiClient.isLoading
                  ? "Loading options..."
                  : "Goal Area of Focus"
              }
              disabled={
                goalAreaOfFocusOptionsApiClient.isLoading || goalAreaOfFocusOptionsApiClient.isError
              }
              value={selectedGoalAreaOfFocus.id}
              onChange={(e) => {
                const goalAreaOfFocus = (goalAreaOfFocusFilterOptions ?? []).find(
                  (goalAreaOfFocus) => goalAreaOfFocus.id === e.target.value,
                );

                if (!goalAreaOfFocus) return;
                handleChangeGoalAreaOfFocusFilter(goalAreaOfFocus);
              }}
            >
              {(goalAreaOfFocusFilterOptions ?? []).map((goalAreaOfFocus) => (
                <MenuItem key={goalAreaOfFocus.id} value={goalAreaOfFocus.id}>
                  {goalAreaOfFocus.name}
                </MenuItem>
              ))}
            </StudentProfileTextField>
          </MSBInputErrorWrapper>
          <StudentProfileTextField
            select
            id="status"
            sx={{
              width: 200,
            }}
            label={"Goal Status"}
            value={selectedGoalStatusFilter}
            onChange={(e) => {
              const selectedStatus = !e.target.value
                ? "All"
                : (e.target.value as (typeof studentProfileGoalsStatusFilterOptions)[number]);
              handleChangeGoalStatusFilter(selectedStatus);
            }}
          >
            {studentProfileGoalsStatusFilterOptions.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </StudentProfileTextField>
          <Typography variant="body2" fontWeight={600}>
            ({filteredGoalsList?.length ?? 0}) Goal(s)
          </Typography>
        </Box>
        <Button
          onClick={() => {
            handleChangeView({
              type: "createOrEditView",
              goal: null,
            });
          }}
          sx={{ borderRadius: 0 }}
        >
          Create New Goal
        </Button>
      </GoalsAndObjTabNavbarWrapper>

      <Box
        sx={{
          py: 1,
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "start",
          justifyContent: {
            xs: "center",
            sm: "flex-start",
          },
          flexWrap: "wrap",
        }}
      >
        {(goalsList ?? []).length < 1 && (
          <Alert severity="info">No goals have been added yet</Alert>
        )}
        {filteredGoalsList?.length === 0 && goalsList.length > 0 && (
          <Alert severity="info">No goals found for the selected filters</Alert>
        )}
        {filteredGoalsList?.map((goal, index) => {
          return (
            <GoalCard
              key={goal.internalId ?? goal.number ?? index + 1}
              /* 
              our form type definition is not in sync with the backend type definition, 
              due to our validation requirements and the fact that every property in our backend data models are unrequired by default,
               so we have to cast it to any for now
              */
              goal={goal as any}
              OnClick={() => {
                handleChangeView({
                  type: "createOrEditView",
                  /* 
                    again, this casting is due to a mismatch in the type definition of our form validation and the backend data model,
                  */
                  goal: goal as GoalsAndObjectivesStateProps["goal"],
                });
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
}
