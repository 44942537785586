import { useRef } from "react";
import useStudentProfileContext from "./use_student_profile_context";
import { StudentProfileValidationSchemaT } from "../constants/student_profile_validation_schema";
import produce from "immer";
import { useFormState } from "react-hook-form";

type AllErrorKeys =
  | keyof StudentProfileValidationSchemaT["generalInformation"]
  | keyof StudentProfileValidationSchemaT["planOfCareInfo"]
  | keyof StudentProfileValidationSchemaT["medicaidInfo"]
  | keyof StudentProfileValidationSchemaT["goalsAndObjectives"];

type ToastErrorSchema = Record<
  AllErrorKeys,
  {
    message: string;
    tabIndex: number;
    show: boolean;
  }
>;

type Props = {
  formControl: ReturnType<typeof useStudentProfileContext>["studentProfileForm"]["control"];
};

/**
 * Custom hook to handle validation errors for the student profile form.
 *
 * @param {Props} props - The properties object.
 * @param {Control} props.formControl - The form control object used to manage form state.
 *
 * @returns {Object} - An object containing ordered visible error keys and the error stack.
 * @returns {AllErrorKeys[]} returns.orderedVisibleErrorKeys - An array of ordered visible error keys.
 * @returns {ToastErrorSchema} returns.errorStack - The error stack object containing validation errors.
 *
 * @description
 * This hook manages the validation errors for different sections of the student profile form.
 * It processes errors from the form state and updates a reference object containing the error stack.
 * The errors are categorized and handled based on their respective sections such as general information,
 * plan of care, Medicaid information, and goals and objectives.
 * The hook also maintains an ordered list of visible error keys to ensure the latest errors are shown first.
 */
export default function useStudentProfileValidationFormErrorStackHandler(props: Props) {
  const formState = useFormState({
    control: props.formControl,
  });

  const validationErrorsRef = useRef({
    stack: {} as ToastErrorSchema,
  });

  const orderedVisibleErrorKeys = useRef({
    keys: Object.keys(validationErrorsRef.current.stack) as AllErrorKeys[],
  });

  const { generalInformation, planOfCareInfo, medicaidInfo, goalsAndObjectives } = formState.errors;

  let allValidationRefErrorKeys = Object.keys(validationErrorsRef.current.stack) as AllErrorKeys[];

  let currentformStateValidationKeys = Object.keys({
    ...generalInformation,
    ...planOfCareInfo,
    ...medicaidInfo,
    ...goalsAndObjectives,
  }) as AllErrorKeys[];

  // Remove keys that are not in the formState.errors
  allValidationRefErrorKeys.forEach((key) => {
    if (!currentformStateValidationKeys.includes(key)) {
      validationErrorsRef.current.stack = produce(validationErrorsRef.current.stack, (draft) => {
        draft[key] = {
          message: "",
          tabIndex: 0,
          show: false,
        };
      });
    }
  });

  // handle the generalInformation errors
  if (generalInformation) {
    const generalInfoKeys = Object.keys(generalInformation) as (keyof typeof generalInformation)[];

    generalInfoKeys.forEach((key) => {
      const tabContextMsg = " in the (general Info Tab)";
      switch (key) {
        // Skip these keys
        case "message":
        case "ref":
        case "type":
        case "types":
        case "root":
          break;
        // school campus and district of liability have nested objects, therefore we need to handle them differently, moving the message to the parent object
        case "schoolCampus": {
          let message: string | undefined = "";
          if (generalInformation[key]?.id?.message) {
            message = generalInformation[key]?.id?.message;
          } else if (generalInformation[key]?.message) {
            message = generalInformation[key]?.message;
          }

          validationErrorsRef.current.stack = produce(
            validationErrorsRef.current.stack,
            (draft) => {
              draft[key] = {
                message: (message ?? "") + tabContextMsg,
                tabIndex: 0,
                show: true,
              };
            },
          );
          break;
        }
        case "districtOfLiabilityRecord": {
          let message: string | undefined = "";
          if (generalInformation[key]?.liableDistrict?.id?.message) {
            message = generalInformation[key]?.liableDistrict?.id?.message;
          } else if (generalInformation[key]?.message) {
            message = generalInformation[key]?.message;
          }

          validationErrorsRef.current.stack = produce(
            validationErrorsRef.current.stack,
            (draft) => {
              draft[key] = {
                message: (message ?? "") + tabContextMsg,
                tabIndex: 0,
                show: true,
              };
            },
          );

          break;
        }
        default: {
          validationErrorsRef.current.stack = produce(
            validationErrorsRef.current.stack,
            (draft) => {
              draft[key] = {
                message: (generalInformation[key]?.message ?? "") + tabContextMsg,
                tabIndex: 0,
                show: true,
              };
            },
          );
          break;
        }
      }
    });
  }

  // handle the planOfCareInfo errors
  if (planOfCareInfo) {
    const planOfCareInfoKeys = Object.keys(planOfCareInfo) as (keyof typeof planOfCareInfo)[];

    planOfCareInfoKeys.forEach((key) => {
      const tabContextMsg = " in the (Plan Of Care Info Tab)";
      switch (key) {
        case "message":
        case "ref":
        case "type":
        case "types":
        case "root":
          break;
        // plansOfCare is an array, therefore we need to handle it differently, moving the message to the parent object
        case "plansOfCare": {
          let message: string | undefined = "";
          if (planOfCareInfo[key]?.length) {
            planOfCareInfo[key]?.some?.((p) => {
              if (p?.message) {
                message = p.message;
                return true;
              } else if (p?.startDate?.message || p?.endDate?.message) {
                message = "Investigate plan of care start and end dates";
                return true;
              }
              return false;
            });
          } else if (planOfCareInfo[key]?.message) {
            message = planOfCareInfo[key]?.message;
          }

          validationErrorsRef.current.stack = produce(
            validationErrorsRef.current.stack,
            (draft) => {
              draft[key] = {
                message: (message ?? "") + tabContextMsg,
                tabIndex: 1,
                show: true,
              };
            },
          );
          break;
        }
        default: {
          validationErrorsRef.current.stack = produce(
            validationErrorsRef.current.stack,
            (draft) => {
              draft[key] = {
                message: (planOfCareInfo[key]?.message ?? "") + tabContextMsg,
                tabIndex: 1,
                show: true,
              };
            },
          );
          break;
        }
      }
    });
  }

  if (medicaidInfo) {
    const medicaidInfoKeys = Object.keys(medicaidInfo) as (keyof typeof medicaidInfo)[];
    const tabContextMsg = " in the (Medicaid Info Tab)";
    medicaidInfoKeys.forEach((key) => {
      switch (key) {
        case "message":
        case "ref":
        case "type":
        case "types":
        case "root":
          break;
        // eligibilityRecord and consentRecord are nested objects, therefore we need to handle them differently, moving the message to the parent object
        case "eligibilityRecord": {
          let message: string | undefined = "";
          if (medicaidInfo[key]?.status?.message) {
            message = medicaidInfo[key]?.status?.message;
          } else if (medicaidInfo[key]?.message) {
            message = medicaidInfo[key]?.message;
          }

          validationErrorsRef.current.stack = produce(
            validationErrorsRef.current.stack,
            (draft) => {
              draft[key] = {
                message: (message ?? "") + tabContextMsg,
                tabIndex: 2,
                show: true,
              };
            },
          );
          break;
        }
        case "consentRecord": {
          let message: string | undefined = "";
          if (medicaidInfo[key]?.decision?.message) {
            message = medicaidInfo[key]?.decision?.message;
          } else if (medicaidInfo[key]?.message) {
            message = medicaidInfo[key]?.message;
          } else if (
            medicaidInfo[key]?.consentDate?.message ||
            medicaidInfo[key]?.refusalDate?.message
          ) {
            message = "Investigate parental consent and refusal dates";
          }

          validationErrorsRef.current.stack = produce(
            validationErrorsRef.current.stack,
            (draft) => {
              draft[key] = {
                message: (message ?? "") + tabContextMsg,
                tabIndex: 2,
                show: true,
              };
            },
          );
          break;
        }
        // default: {
        //     validationErrorsRef.current.stack = produce(validationErrorsRef.current.stack, (draft) => {
        //         draft[key] = {
        //             message: medicaidInfo[key]?.message ?? "",
        //             tabIndex: 2,
        //             show: true,
        //         };
        //     });
        //     break;
        // }
      }
    });
  }

  if (goalsAndObjectives) {
    const goalsAndObjectivesKeys = Object.keys(
      goalsAndObjectives,
    ) as (keyof typeof goalsAndObjectives)[];

    goalsAndObjectivesKeys.forEach((key) => {
      const tabContextMsg = " in the (Goals and Objectives Tab)";
      switch (key) {
        case "message":
        case "ref":
        case "type":
        case "types":
        case "root":
          break;
        // eligibilityRecord and consentRecord are nested objects, therefore we need to handle them differently, moving the message to the parent object
        case "goalForm": {
          let message: string | undefined = undefined;
          if (goalsAndObjectives[key]?.message) {
            message = goalsAndObjectives[key]?.message;
          } else if (goalsAndObjectives[key]?.objectives?.message) {
            message = goalsAndObjectives[key]?.objectives?.message;
          } else if (
            goalsAndObjectives?.[key]?.objectives?.some?.(
              (objective) => !!objective?.number?.message || !!objective?.description?.message,
            )
          ) {
            goalsAndObjectives[key]?.objectives?.some?.((objective) => {
              if (objective?.number?.message || objective?.description?.message) {
                message = objective.number?.message ?? objective.description?.message;
                return true;
              }
              return false;
            });
          }
          validationErrorsRef.current.stack = produce(
            validationErrorsRef.current.stack,
            (draft) => {
              draft[key] = {
                message: (message ?? "investigate goals and objectives form") + tabContextMsg,
                tabIndex: 3,
                show: true,
              };
            },
          );
          break;
        }
        default: {
          validationErrorsRef.current.stack = produce(
            validationErrorsRef.current.stack,
            (draft) => {
              draft[key] = {
                message:
                  (goalsAndObjectives[key]?.message ?? "investigate goals and objectives form") +
                  tabContextMsg,
                tabIndex: 2,
                show: true,
              };
            },
          );
          break;
        }
      }
    });
  }

  // Update the orderedVisibleErrorKeys by adding new keys if they exist to the stack
  (Object.keys(validationErrorsRef.current.stack) as AllErrorKeys[]).forEach((key) => {
    if (!orderedVisibleErrorKeys.current.keys.includes(key)) {
      orderedVisibleErrorKeys.current.keys.push(key);
    }
  });

  // Sort the orderedVisibleErrorKeys by the show property to give the effect of the latest error being shown first
  orderedVisibleErrorKeys.current.keys.sort((a, b) => {
    if (validationErrorsRef.current.stack[a].show && !validationErrorsRef.current.stack[b].show) {
      return -1;
    } else if (
      !validationErrorsRef.current.stack[a].show &&
      validationErrorsRef.current.stack[b].show
    ) {
      return 1;
    } else {
      return 0;
    }
  });

  return {
    orderedVisibleErrorKeys: orderedVisibleErrorKeys.current.keys,
    errorStack: validationErrorsRef.current.stack,
  };
}
