import { Box } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router";
import { ROUTES_XLOGS } from "../../../../constants/URLs";
import { useEffect } from "react";

export default function CaseloadManagementLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname.replace(/\/$/, ""); // Remove trailing slash

  useEffect(() => {
    // re-direct to index page if user is on the user management route
    if (pathName === ROUTES_XLOGS._admin.index) {
      navigate(ROUTES_XLOGS._admin.userApproval);
    }
  }, [location, navigate, pathName]);

  return (
    <Box>
      {/* caseload management toolbar will go here */}
      <Outlet />
    </Box>
  );
}
