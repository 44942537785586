import { Box, Tooltip } from "@mui/material";
import { useState, forwardRef } from "react";
import isInState from "../utils/is_in_state";

type Props = {
  children: React.ReactNode;
  fullwidth?: boolean;
  show?: boolean;
  state?: string;
};

/**
 * Tooltip for disabled inputs that are imported from the district's IEP/SIS software
 * @param props - children, fullwidth
 * @param ref - reference to the tooltip
 */
const StudentImportDisabledInputTooltip = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [open, setOpen] = useState(false);

  const toolTipMessage = isInState(props.state ?? "", "TX")
    ? "This data is imported from your district's IEP/SIS software, and cannot be edited in X Logs"
    : "If you need assistance please reach out to the Support Team via the Live Chat or to your Program Specialist";

  return (
    <Tooltip title={toolTipMessage} arrow placement={"top"} open={open}>
      <Box
        ref={ref}
        component={"div"}
        width={props.fullwidth ? "100%" : "fit-content"}
        onPointerEnter={() => {
          if (!props.show) return;
          setOpen(true);
        }}
        onPointerLeave={() => {
          setOpen(false);
        }}
      >
        {props.children}
      </Box>
    </Tooltip>
  );
});

export default StudentImportDisabledInputTooltip;
