import * as yup from "yup";
import dayjs from "dayjs";
import studentProfileGoalsStatusFilterOptions from "./student_profile_goals_active_filter_options";

// source Icd9 = 0, Icd10 = 1
const icdDisabilitySchema = ({ name }: { name: string }) =>
  yup
    .object()
    .shape({
      diagnosisCode: yup.string().nullable(),
      name: yup.string().nullable(),
      source: yup.number().oneOf([0, 1], `${name} source must be one of: ICD9, ICD10`).nullable(), // ICD9 = 0, ICD10 = 1
    })
    .optional()
    .nullable();

const prescribedServiceArea = yup.object().shape({
  //Enum: Yes = 0, No = 1, NoneSelected = 2
  id: yup.string().nullable(),
  decision: yup
    .number()
    .oneOf(
      [0, 1, 2],
      "Prescribed service area decision must be one of: Yes, No, Refused, NoneSelected",
    )
    .required("Prescribed service area decision is required"), // Yes = 0, No = 1, Refused = 2, NoneSelected = 3
  endDate: yup.date().nullable().typeError("End date must be a valid date"),
  startDate: yup.date().nullable().typeError("Start date must be a valid date"),
  serviceArea: yup
    .object()
    .shape({
      id: yup.string().required("Service area field is required"),
      name: yup.string().notRequired(),
    })
    .nullable(),
  prescribingProvider: yup
    .object()
    .shape({
      id: yup.string().notRequired(),
      firstName: yup.string().notRequired(),
      lastName: yup.string().notRequired(),
      middleName: yup.string().notRequired(),
      npi: yup.string().notRequired(),
      stateMedicaidNumber: yup.string().notRequired(),
    })
    .notRequired(),
});

const goalFormSchema = yup.object().shape({
  internalId: yup.string().required("Goal internal ID is required"),
  duplicatedIds: yup.array().of(yup.string()).nullable(),
  legacyGoalId: yup.number().nullable(),
  legacyStudentId: yup.number().nullable(),
  number: yup.string().required("Goal number is required"),
  description: yup.string().required("Goal description is required"),
  startDate: yup
    .date()
    .typeError("Goal start date must be a valid date")
    .required("Goal start date is required")
    .test(
      "start-date-before-end-date",
      "Goal start date must be before end date",
      function (value) {
        return !value || !this.parent.endDate || dayjs(value).isBefore(dayjs(this.parent.endDate));
      },
    ),
  endDate: yup
    .date()
    .typeError("Goal end date must be a valid date")
    .required("Goal end date is required")
    .test("end-date-after-start-date", "Goal end date must be after start date", function (value) {
      return !value || !this.parent.startDate || dayjs(value).isAfter(dayjs(this.parent.startDate));
    }),
  creationDate: yup.date().nullable(),
  deactivationDate: yup.date().nullable(),
  completionDate: yup.date().nullable(),
  goalAreaOfFocus: yup
    .object()
    .shape({
      id: yup.string().required("Goal area of focus input is required"),
      name: yup.string().notRequired(),
      legacyName: yup.string().notRequired(),
      legacyId: yup.string().notRequired(),
    })
    .required("Goal area of focus is required"),
  status: yup
    .string()
    .oneOf(
      studentProfileGoalsStatusFilterOptions.filter((g) => g !== "All"),
      "Goal Status must be one of the following values: Active, Inactive",
    )
    .required("Goal status is required"),
  includeInReports: yup.boolean().nullable(),
  objectives: yup
    .array()
    .of(
      yup.object().shape({
        description: yup.string().required("Objective description is required"),
        number: yup.string().required("Objective number is required"),
        internalId: yup.string().required(),
      }),
    )
    .nullable(),
});

const careProvisionSchema = yup
  .array()
  .of(
    yup.object().shape({
      id: yup.string().nullable(),
      name: yup.string(),
      startDate: yup.date().nullable(),
      endDate: yup.date().nullable(),
      createdAt: yup.date().nullable(),
      updatedAt: yup.date().nullable(),
    }),
  )
  .required("Care provisions is required");

// This represents the entire student profile form validation schema
const studentProfileValidationSchema = yup.object().shape({
  generalInformation: yup.object().shape({
    firstName: yup.string().required("First name is required"),
    middleName: yup.string(),
    lastName: yup.string().required("Last name is required"),
    grade: yup
      .number()
      .oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], "please select a valid grade")
      .typeError("Grade must be a number")
      .required("Grade is required"), //Enum: PreK = 0, K = 1, First = 2, Second = 3, Third = 4, Fourth = 5, Fifth = 6, Sixth = 7, Seventh = 8, Eighth = 9, Ninth = 10, Tenth = 11, Eleventh = 12, Twelfth = 13, Other = 14
    dateOfBirth: yup
      .date()
      .required("Date of birth is required")
      .typeError("Date of birth must be a valid date"),
    gender: yup
      .number()
      .oneOf([0, 1, 2], "Gender must be one of: Male, Female, Unknown")
      .required("Gender is required"), // Male = 0, Female = 1, Unknown = 2
    primaryLanguage: yup.string(),
    studentIdGivenBySchoolDistrict: yup.string(),
    medicaidId: yup.string(),
    studentIdGivenByState: yup.string(),
    schoolCampus: yup
      .object()
      .shape({
        id: yup.string().required("School Campus input is required"),
        name: yup.string(),
        attendanceStartDate: yup.date(),
        attendanceEndDate: yup.date().nullable(),
      })
      .required("School Campus is required"),
    districtOfLiabilityRecord: yup
      .object()
      .shape({
        liableDistrict: yup.object().shape({
          id: yup.string().required("District of liability input is required"),
          name: yup.string(),
        }),
        startDate: yup.date(),
        endDate: yup.date().nullable(),
      })
      .required("District of liability is required"),
  }),
  planOfCareInfo: yup.object().shape({
    plansOfCare: yup
      .array()
      .of(
        yup.object().shape({
          // Iep = 0, Referral = 1, _504 = 2, RtiMtss = 3, Ell = 4, Other = 5
          type: yup
            .number()
            .oneOf(
              [0, 1, 2, 3, 4, 5],
              "Plan of care must be one of: IEP (Special Education), Referral, 504, RTI / MTSS, ELL, Other Plan of Care",
            )
            .required("Plan of care is required"), // IEP (Special Education) = 0, Referral = 1, 504 = 2, RTI / MTSS = 3, ELL = 4, Other Plan of Care = 5
          startDate: yup
            .date()
            .typeError("Start date must be a valid date")
            .nullable()
            .test(
              "start-date-before-end-date",
              "Plan of care start date must be before end date",
              function (value) {
                return !value || !this.parent.endDate || value < this.parent.endDate;
              },
            ),
          endDate: yup
            .date()
            .typeError("End date must be a valid date")
            .nullable()
            .test(
              "end-date-after-start-date",
              "Plan of care end date must be after start date",
              function (value) {
                return !value || !this.parent.startDate || value > this.parent.startDate;
              },
            ),
          description: yup.string().notRequired(),
        }),
      )
      .required("Plans of care is required"),
    primaryDisability: icdDisabilitySchema({ name: "Primary" }),
    secondaryDisability: icdDisabilitySchema({ name: "Secondary" }),
    tertiaryDisability: icdDisabilitySchema({ name: "Tertiary" }),
  }),
  medicaidInfo: yup.object().shape({
    // * Enum: Eligible = 0 | Ineligible = 1 | Unknown = 2
    eligibilityRecord: yup
      .object()
      .shape({
        status: yup
          .number()
          .oneOf([0, 1], "Eligibility status must be one of: Eligible, Ineligible")
          .required("Eligibility status is required"), // Eligible = 0, Ineligible = 1, Unknown = 2
        startDate: yup
          .date()
          .typeError("Eligibility start date must be a valid date")
          .nullable()
          .test("start-date-required", "Eligibility start date is required", function (value) {
            return !!value;
          }),
        endDate: yup.date().nullable().typeError("Eligibility end date must be a valid date"),
      })
      .nullable()
      .notRequired(),
    consentRecord: yup
      .object()
      .shape({
        //Yes = 0, No = 1, Refused = 2, NoneSelected = 3
        decision: yup.number().nullable(), //.oneOf([0, 1, 2, 3], 'Consent decision must be one of: Yes, No, Refused').required('Consent decision is required'), // Yes = 0, No = 1, Refused = 2, NoneSelected = 3
        consentDate: yup
          .date()
          .nullable()
          .typeError("Consent start date must be a valid date")
          .test(
            "consent-date-cannot-exist-alongside-refused-date",
            "Consent date cannot exist alongside refused date",
            function (value) {
              const bothExist = !!value && !!this.parent.refusalDate;
              return !bothExist;
            },
          ),
        refusalDate: yup
          .date()
          .nullable()
          .typeError("Consent end date must be a valid date")
          .test(
            "refused-date-cannot-exist-alongside-consent-date",
            "Refusal date cannot exist alongside consent date",
            function (value) {
              const bothExist = !!value && !!this.parent.consentDate;
              return !bothExist;
            },
          ),
      })
      .nullable()
      .notRequired(),
    prescribedServiceAreas: yup.array().of(prescribedServiceArea).notRequired(),
    personalCareServicesDecision: yup.number().oneOf([0, 1, 2]),
    specializedTransportationDecision: yup.number().oneOf([0, 1, 2]),
    personalCareServicesOrderedOnTheBusDecision: yup.number().oneOf([0, 1, 2]),
    personalCareSupplement: yup.boolean().nullable(),
    speciallyAdjustedVehicle: yup.boolean().nullable(),
    billingBlockTerm: yup
      .object()
      .shape({
        blockBilling: yup.boolean().notRequired(),
        blockBillingStartDate: yup
          .date()
          .nullable()
          .typeError("Block billing start date must be a valid date"),
        blockBillingEndDate: yup
          .date()
          .nullable()
          .typeError("Block billing end date must be a valid date"),
      })
      .notRequired(),
  }),
  goalsAndObjectives: yup.object().shape({
    goals: yup.array().of(
      yup.object().shape({
        ...goalFormSchema.shape,
        internalId: yup.string().nullable(),
        number: yup.string().nullable(),
        description: yup.string().nullable(),
        startDate: yup.date().nullable(),
        endDate: yup.date().nullable(),
        goalAreaOfFocus: yup
          .object()
          .shape({
            id: yup.string().notRequired(),
            name: yup.string().notRequired(),
            legacyName: yup.string().notRequired(),
            legacyId: yup.string().notRequired(),
          })
          .nullable(),
        status: yup.string().nullable(),
      }),
    ),
    goalForm: goalFormSchema.nullable(),
  }),
  careProvisions: yup.object().shape({
    activities: careProvisionSchema,
    accommodations: careProvisionSchema,
    modifications: careProvisionSchema,
  }),
});

type StudentProfileValidationSchemaT = yup.InferType<typeof studentProfileValidationSchema>;

export type { StudentProfileValidationSchemaT };

export default studentProfileValidationSchema;
