import {
  Dialog,
  Typography,
  Autocomplete,
  TextField,
  Box
} from "@mui/material";
import { getSizing } from "../../../sizing";
import XNGClose from "../../../low-level/button_close";
import { useState } from "react";
import { ServiceProviderRef, ServiceProviderCaseloadOption } from "../../../../profile-sdk";
import XNGButton from "../../../low-level/button";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { placeholderForFutureLogErrorText } from "../../../../temp/errorText";

type AddServiceProviderToCaseloadModalProps = Readonly<{
  open: boolean;
  onClose: () => void;
  options: ServiceProviderCaseloadOption[];
  onUserClick: (serviceProvider: ServiceProviderRef) => void;
  onCreateUserClick: () => void;
}>;

export function AddServiceProviderToCaseloadModal(props: AddServiceProviderToCaseloadModalProps) {
  const [selectedServiceProvider, setSelectedServiceProvider] = useState<ServiceProviderRef>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<ServiceProviderCaseloadOption>();

  const filterOptions = createFilterOptions({
    matchFrom: "any",
  });

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(event.target.value);
  }

  function handleSelectionChange(
    event: React.ChangeEvent<{}>,
    selectedOption: ServiceProviderCaseloadOption | null,
  ) {
    if (!selectedOption) throw new Error(placeholderForFutureLogErrorText);
    setSearchValue(getOptionLabel(selectedOption));
    setSelectedValue(selectedOption);

    onSelect(selectedOption as ServiceProviderRef);
  }

  function getOptionLabel(serviceProvider: ServiceProviderCaseloadOption | string) {
    if (typeof serviceProvider === "string") {
      return "Create New User";
    } else {
      return `${serviceProvider.firstName} ${serviceProvider.lastName} - ${
        serviceProvider.emailAddress === null ? "No Email" : serviceProvider.emailAddress
      }`;
    }
  }

  function onSelect(serviceProvider: ServiceProviderRef | string) {
    if (typeof serviceProvider === "string") {
      props.onCreateUserClick();
      props.onClose();
    } else setSelectedServiceProvider(serviceProvider);
  }

  return (
    <Dialog
      className="noselect"
      onClose={() => {
        props.onClose();
      }}
      open={props.open}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: getSizing(2),
          width: getSizing(55),
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ marginTop: getSizing(1), paddingLeft: getSizing(1) }} variant="h6">
            Add Provider to Profile
          </Typography>
          <XNGClose
            onClick={() => {
              props.onClose();
              setSearchValue("");
              setSelectedValue(undefined);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: getSizing(2),
            paddingTop: 0,
            gap: getSizing(2),
          }}
        >
          <Typography variant="body1">Type a provider's name to search: </Typography>
          <Autocomplete
            options={props.options}
            getOptionKey={(option: ServiceProviderCaseloadOption) => option.toString()}
            // @ts-ignore
            getOptionLabel={(option: ServiceProviderCaseloadOption) => getOptionLabel(option)}
            fullWidth
            id="auto-comp-id"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={""}
                variant="outlined"
                onChange={handleSearchChange}
                fullWidth
                InputProps={{ ...params.InputProps }}
              />
            )}
            renderOption={(props, option, index) => {
              const key = `listItem-${index.index}`;
              return (
                <Box component={"li"} {...props} key={key}>
                  {getOptionLabel(option as (string | ServiceProviderCaseloadOption))}
                </Box>
              );
            }}
            value={selectedValue}
            onChange={handleSelectionChange}
            inputValue={searchValue}
            autoSelect
            autoHighlight
            disableClearable={true}
            sx={{}}
            filterOptions={filterOptions}
          />
          <XNGButton
            onClick={() => {
              setSearchValue("");
              setSelectedValue(undefined);
              props.onClose();
              if (selectedServiceProvider) {
                props.onUserClick(selectedServiceProvider);
              } else {
                console.error("selectedServiceProvider was null!");
              }
            }}
          >
            Request Access
          </XNGButton>
        </Box>
      </Box>
    </Dialog>
  );
}
