import { Box, Button } from "@mui/material";
import React from "react";
import GridSectionLayout from "../../../../../../../../../design/high-level/common/grid_section_layout";
import XNGDropDown from "../../../../../../../../../design/low-level/dropdown2";
import { XNGDateField } from "../../../../../../../../unposted_sessions/components/common/date_field";
import XNGRadioGroup from "../../../../../../../../../design/low-level/radio_group";
import { ServiceProviderResponse, TrainingType } from "../../../../../../../../../profile-sdk";
import StaffDirectoryProfileTabToolbar from "../../../components/interactive/staff_directory_profile_tab_toolbar";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  GetTrainingTypeEnumFromString,
  GetTrainingTypeStringFromEnum,
} from "../../../../../../../../../utils/xlogs_training_type_mapper";
import GetTrainingTypeOptions from "../../../../../../../../../data/get_training_type_options";
import dayjs from "dayjs";
import produce from "immer";
import useApiMutatePatchServiceProviderById from "../../../../../../../../../api/hooks/service_provider/use_api_mutate_patch_service_provider_by_id";
import useApiQueryServiceProviderById from "../../../../../../../../../api/hooks/service_provider/use_api_query_service_provider_by_id";
import QueryStatusModal from "../../../../../../../../../design/modal_templates/query_status_modal";
import {
  trainingInfoFormSchema,
  TrainingInfoFormType,
} from "../../../constants/training_info_tab_form_schema";

type Props = {
  serviceProvider: ServiceProviderResponse;
  stateInUs: string;
  clientId: string;
  refetchServiceProvider: ReturnType<typeof useApiQueryServiceProviderById>["refetch"];
};

const TrainingInformationTabContent = (props: Props) => {
  //#region CUSTOM HOOKS
  const [showQueryStatusModal, setShowQueryStatusModal] = React.useState(false);
  //#endregion

  //#region API

  const { mutate: mutateServiceProvider, status: mutateServiceProviderStatus } =
    useApiMutatePatchServiceProviderById({
      queryParams: {
        id: props.serviceProvider.id ?? "",
        clientId: props.clientId,
        state: props.stateInUs,
      },
      options: {
        onSuccess: () => {
          props.refetchServiceProvider();
        },
      },
    });

  //#endregion

  //#region HOOKFORMS
  const { control, watch, handleSubmit, setValue } = useForm<TrainingInfoFormType>({
    resolver: yupResolver(trainingInfoFormSchema),
    defaultValues: {
      trainingInfoLog: props.serviceProvider.trainingInformationLog?.map((trainingInfo) => {
        return {
          certificateSent: trainingInfo.certificateSent ?? false,
          trainingDate: (trainingInfo.trainingDate ?? null) as Date,
          trainingType: (trainingInfo.trainingType ? GetTrainingTypeStringFromEnum(
            trainingInfo.trainingType
          ) : ""),
        };
      }),
    },
  });

  const trainingInfoLogInput = watch("trainingInfoLog");
  //#endregion

  //#region METHODS
  function onSubmitTrainingInfoFormData(data: TrainingInfoFormType) {
    onSaveTrainingTabInfo(data);
  }

  async function onSaveTrainingTabInfo(data: TrainingInfoFormType) {
    const serviceProviderProfile = produce(props.serviceProvider, (draft) => {
      draft.trainingInformationLog = data.trainingInfoLog.map((trainingInfo) => {
        return {
          certificateSent: trainingInfo.certificateSent,
          trainingDate: trainingInfo.trainingDate,
          trainingType: GetTrainingTypeEnumFromString(trainingInfo.trainingType),
          visibleOnProfile: true,
        };
      });
    });
    setShowQueryStatusModal(true);
    mutateServiceProvider({ profile: serviceProviderProfile });
  }
  //#endregion

  //#region SECTIONS
  const trainingInformation = (
    <GridSectionLayout
      headerConfig={{
        title: "Training Information",
      }}
      maxHeight={"550px"}
      rows={trainingInfoLogInput?.map((_, row_index, array) => {
        return {
          fullwidth: true,
          cells: [
            <GridSectionLayout
              key={"training-info-" + row_index}
              headerConfig={{}}
              rows={[
                {
                  useCellStyling: {
                    indexes: 2,
                    sx: {
                      display: "flex",
                      alignItems: "center",
                    },
                  },
                  cells: [
                    <Controller
                      key={"training-info-drop-down" + row_index}
                      control={control}
                      name={`trainingInfoLog.${row_index}.trainingType`}
                      render={({ field, fieldState: { error } }) => (
                        <XNGDropDown
                          id={"training-type" + row_index}
                          ref={field.ref}
                          value={field.value}
                          name={field.name}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          useError={error?.message}
                          items={GetTrainingTypeOptions()}
                          label={"Training Type"}
                          maxwidth="100%"
                          fullWidth
                        />
                      )}
                    />,
                    <Controller
                      key={"training-info-date" + row_index}
                      name={`trainingInfoLog.${row_index}.trainingDate`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <XNGDateField
                          label={"Training Date"}
                          useError={error?.message}
                          value={field.value ? dayjs(field.value).utc(): null}
                          onChange={field.onChange}
                          fullWidth
                        />
                      )}
                    />,
                    <Button
                      key={"add-another-button" + row_index}
                      variant="outlined"
                      sx={{
                        borderWidth: "2px",
                        display: row_index === array.length - 1 ? "flex" : "none",
                      }}
                      onClick={() =>
                        setValue(
                          "trainingInfoLog",
                          produce(trainingInfoLogInput, (draft) => {
                            draft.push({
                              certificateSent: false,
                              trainingDate: new Date(),
                              trainingType: GetTrainingTypeStringFromEnum(TrainingType.NUMBER_0),
                            });
                          }),
                        )
                      }
                    >
                      Add Another
                    </Button>,
                  ],
                },
                {
                  useCellStyling: {
                    indexes: 0,
                    sx: {
                      pt: 3,
                    },
                  },
                  cells: [
                    <Controller
                      key={"training-info-radio" + row_index}
                      control={control}
                      name={`trainingInfoLog.${row_index}.certificateSent`}
                      render={({ field, fieldState: { error } }) => (
                        <XNGRadioGroup
                          value={field.value ? "yes" : "no"}
                          onChange={(e) => {
                            field.onChange(e.target.value === "yes");
                          }}
                          radioSx={{}}
                          options={["Yes", "No"]}
                          values={["yes", "no"]}
                          formLabel={"Certificate sent?"}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            pl: "5px",
                            mb: 2,
                          }}
                        />
                      )}
                    />,
                  ],
                },
              ]}
            />,
          ],
        };
      })}
    />
  );
  //#endregion

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmitTrainingInfoFormData)}>
          <StaffDirectoryProfileTabToolbar />
          {trainingInformation}
        </form>
      </Box>
      <QueryStatusModal
        isOpen={showQueryStatusModal}
        onSettledClose={() => setShowQueryStatusModal(false)}
        status={mutateServiceProviderStatus}
        content={{
          errorTitle: "Error!",
          errorBody: "Failed to save Training Information. Please try again later.",
          successTitle: "Success!",
          successBody: "Training Information saved successfully",
          pendingTitle: "Saving Training Information",
        }}
      />
    </>
  );
};

export default TrainingInformationTabContent;
