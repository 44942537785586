import { Box, Divider, Skeleton } from "@mui/material";
import InputFieldGrid from "../input_field_grid";

export default function PrescribedServiceAreaSkeleton() {
  return (
    <Box sx={{ mb: 1 }}>
      <Skeleton width={200} height={50} />
      <Divider sx={{ maxWidth: "300px", borderWidth: "1.5px", mb: 1.5 }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <InputFieldGrid
          items={[
            {
              key: "prescribed service area decision",
              content: <Skeleton width={250} height={50} />,
            },
          ]}
        />
        <InputFieldGrid
          items={[
            {
              key: "prescribed service area start date",
              content: <Skeleton width={"100%"} height={50} />,
            },
            {
              key: "prescribed service area end date",
              content: <Skeleton width={"100%"} height={50} />,
            },
          ]}
        />
        <InputFieldGrid
          items={[
            {
              key: "prescribed service provider",
              content: <Skeleton width={"100%"} height={50} />,
            },
            {
              key: "NPI",
              content: <Skeleton width={100} height={50} />,
            },
          ]}
        />
      </Box>
    </Box>
  );
}
