import { Box } from "@mui/material";
import React from "react";

export default function TabContentWrapper(
  props: Readonly<{
    children: React.ReactNode;
  }>,
) {
  return <Box sx={{ py: 3, maxWidth: "lg", height: "100%" }}>{props.children}</Box>;
}
