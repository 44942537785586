import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import InputFieldGrid from "../components/input_field_grid";
import useStudentProfileContext from "../hooks/use_student_profile_context";
import produce from "immer";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import useApiQueryData from "../../../../../api/hooks/use_api_query_data";
import { Disability } from "@xng/profile";
import plansOfCareLabels from "../constants/plan_of_care_labels";
import StudentProfileTextField from "../components/student_profile_text_field";

export default function PlanOfCareInfoTab() {
  const {
    studentProfileForm: {
      control,
      watch,
      setValue,
      clearErrors,
      formState: { defaultValues },
    },
    mutateStudentProfileApiClient: { isPending: mutateStudentProfileIsLoading },
  } = useStudentProfileContext();

  const plansOfCareList = watch("planOfCareInfo.plansOfCare") ?? [];

  const disableInputFields = mutateStudentProfileIsLoading;

  // Fetch the ICD codes from the public file, returns an array of strings with the format "code description"
  const { data: icdCodesOptions } = useApiQueryData({
    queryKey: ["v1IcdCodesGet"],
    queryFn: async () => {
      const icdCodesFile = "/icd10cm_codes_2024.txt";
      const response = await fetch(icdCodesFile);
      const codes = (await response.text()).split("\n");
      return codes;
    },
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
  });

  const handlePlanOfCareCheckboxToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newPlansOfCare = produce(plansOfCareList ?? [], (draft) => {
      if (event.target.checked) {
        draft.push({
          type: index,
          startDate:
            defaultValues?.planOfCareInfo?.plansOfCare?.find((poc) => poc?.type === index)
              ?.startDate ?? null,
          endDate:
            defaultValues?.planOfCareInfo?.plansOfCare?.find((poc) => poc?.type === index)
              ?.endDate ?? null,
          description: "",
        });
      } else {
        draft.splice(
          draft.findIndex((poc) => poc.type === index),
          1,
        );
      }
    });

    clearErrors(`planOfCareInfo.plansOfCare.${index}.startDate`);
    clearErrors(`planOfCareInfo.plansOfCare.${index}.endDate`);

    setValue("planOfCareInfo.plansOfCare", newPlansOfCare, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  /**
   * Constructs a Disability record from a given string value.
   * This function is used by our disability search input fields.
   *
   * @param newValue - The string value to evaluate (e.g., "01 Orthopedic Impairment").
   * @returns A Disability record if newValue is provided, otherwise null.
   */
  function constructDisabilityRecord(newValue: string | null): Disability | null {
    if (!newValue) {
      return null;
    }

    const disabilityRecord: Disability = {
      source: 1, // Enum: Icd9 = 0, Icd10 = 1
      diagnosisCode: newValue.split(" ")[0], // e.g., "01"
      name: newValue, // e.g., "01 Orthopedic Impairment"
      id: null,
    };

    return disabilityRecord;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Plan Of Care Dates
        </Typography>
        {plansOfCareList.length < 1 && (
          <Alert severity="info">
            This student currently does not have an assigned plan of care. Please select one below
            to edit the plan of care dates.
          </Alert>
        )}
        {plansOfCareList?.map((poc, i) => (
          <Box key={poc.type} mb={2}>
            <InputFieldGrid
              items={[
                {
                  key: poc.type.toString() + "startDate",
                  content: (
                    <Controller
                      control={control}
                      name={`planOfCareInfo.plansOfCare.${i}.startDate`}
                      // defaultValue={poc.startDate}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <DatePicker
                            label={`${plansOfCareLabels[poc.type]} Start Date`}
                            value={field.value ? dayjs(field.value) : null}
                            ref={field.ref}
                            disabled={disableInputFields}
                            onChange={(date) => {
                              field.onChange(date);
                            }}
                            slots={{
                              textField: StudentProfileTextField,
                            }}
                            slotProps={{
                              textField: {
                                FormHelperTextProps: {
                                  error: !!error?.message,
                                },
                                helperText: error?.message,
                                onBlur: field.onBlur,
                              },
                              actionBar: {
                                actions: ["clear"],
                              },
                            }}
                          />
                        );
                      }}
                    />
                  ),
                },
                {
                  key: poc.type.toString() + "endDate",
                  content: (
                    <Controller
                      control={control}
                      name={`planOfCareInfo.plansOfCare.${i}.endDate`}
                      // defaultValue={poc.endDate}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <DatePicker
                            label={`${plansOfCareLabels[poc.type]} End Date`}
                            ref={field.ref}
                            value={field.value ? dayjs(field.value) : null}
                            disabled={disableInputFields}
                            onChange={(date) => {
                              field.onChange(date);
                            }}
                            slots={{
                              textField: StudentProfileTextField,
                            }}
                            slotProps={{
                              textField: {
                                FormHelperTextProps: {
                                  error: !!error?.message,
                                },
                                onBlur: field.onBlur,
                                helperText: error?.message,
                              },
                              actionBar: {
                                actions: ["clear"],
                              },
                            }}
                          />
                        );
                      }}
                    />
                  ),
                },
              ]}
            />
          </Box>
        ))}
      </Box>
      <Divider />
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Disability Information
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {[
            {
              controlName: "planOfCareInfo.primaryDisability",
              label: "Primary Disability",
            },
            {
              controlName: "planOfCareInfo.secondaryDisability",
              label: "Secondary Disability",
            },
            {
              controlName: "planOfCareInfo.tertiaryDisability",
              label: "Tertiary Disability",
            },
          ].map((disability) => {
            return (
              <InputFieldGrid
                key={disability.controlName}
                items={[
                  {
                    key: disability.controlName,
                    content: (
                      <Controller
                        control={control}
                        name={disability.controlName as "planOfCareInfo.primaryDisability"} // we are to use a type assertion here to convince typescript that this is correct, but we must also inturn ensure that the controlNames using the correct types
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <Autocomplete
                              fullWidth
                              disabled={disableInputFields}
                              value={field.value?.name ?? ""}
                              onChange={(_, newValue) => {
                                const disabilityRecord: Disability | null =
                                  constructDisabilityRecord(newValue);
                                field.onChange(disabilityRecord);
                              }}
                              ref={field.ref}
                              disablePortal
                              options={icdCodesOptions ?? []}
                              renderInput={(params) => (
                                <StudentProfileTextField
                                  {...params}
                                  FormHelperTextProps={{
                                    error: !!error?.message,
                                  }}
                                  label={disability.label}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          );
                        }}
                      />
                    ),
                  },
                ]}
              />
            );
          })}
        </Box>
      </Box>
      <Divider />
      <Box>
        <Typography variant="h6">Plan(s) of Care</Typography>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.35 }}>
            {plansOfCareLabels.map((label, index) => {
              // we are able to use index as the key because the labels are static
              return (
                <FormControlLabel
                  key={label}
                  disabled={disableInputFields}
                  control={
                    <Checkbox
                      checked={!!plansOfCareList.find((poc) => poc.type === index)}
                      onChange={(event) => handlePlanOfCareCheckboxToggle(event, index)}
                      name={label}
                    />
                  }
                  label={label}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
