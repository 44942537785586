import { Box, Typography, Checkbox } from "@mui/material";
import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { StaffDirectoryRecords } from "../../user_management/views/staff_directory_manager/hooks/table/use_staff_directory_table_column_definition";
import useCaseLoadManagerColumnDefinition from "../hooks/table/use_case_load_management_column_definition";
import { MSBDataGridNoRowsOverlay } from "../../../../../fortitude";

export interface CaseLoadUserTableRow {
  id: string;
  firstName: string;
  lastName: string;
  primaryCampus: string;
  providerRole: string;
}

type Props = {
  rows: CaseLoadUserTableRow[];
  initialPageSize?: number;
  onRowClick?: (row: StaffDirectoryRecords[number]) => void;
  onSelectionChange?: (selectedRows: CaseLoadUserTableRow[]) => void;
  loading: boolean;
  totalNumberOfRows: number;
  isAllChecked: boolean;
  setIsAllChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

const CustomCheckboxHeader = ({
  isChecked,
  onHeaderCheckboxClick,
  disabled,
}: {
  isChecked: boolean;
  onHeaderCheckboxClick: (checked: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
      <Checkbox
        disabled={disabled}
        checked={isChecked}
        onChange={(event) => onHeaderCheckboxClick(event.target.checked)}
        sx={{ padding: "0px", marginRight: "4px" }}
      />
      <Typography variant="body2" sx={{ fontWeight: 700 }}>
        All
      </Typography>
    </Box>
  );
};

const CustomCheckboxCell = ({
  checked,
  onChange,
  disabled,
}: {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <Checkbox
      checked={checked}
      onChange={(event) => onChange(event.target.checked)}
      sx={{ padding: "0px" }}
    />
  );
};

export function CaseLoadUserTable(props: Readonly<Props>) {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const { isAllChecked, setIsAllChecked } = props;
  const rowCount = props?.rows?.length ?? 0;

  const handleHeaderCheckboxClick = (checked: boolean) => {
    if (checked) {
      const allIds = props.rows.map((row) => row.id);
      setSelectionModel(allIds);
      setSelectedRows(props.rows);
      setIsAllChecked(true);
    } else {
      setSelectionModel([]);
      setSelectedRows([]);
      setIsAllChecked(false);
    }
    props.onSelectionChange?.(checked ? props.rows : []);
  };

  // handle when a row checkbox is checked
  const handleRowCheckboxChange = (id: string, checked: boolean) => {
    let newSelectionModel: GridRowSelectionModel;
    if (checked) {
      newSelectionModel = [...selectionModel, id];
    } else {
      newSelectionModel = selectionModel.filter((rowId) => rowId !== id);
    }

    setSelectionModel(newSelectionModel);
    const newSelectedRows = props.rows.filter((row) => newSelectionModel.includes(row.id));
    setSelectedRows(newSelectedRows);
    setIsAllChecked(newSelectionModel.length === props.rows.length);
    props?.onSelectionChange?.(newSelectedRows);
  };

  // update when a row checkbox is checked remove if already existed add if not
  const handleSelectionModelChange = (newSelectionModel: GridRowSelectionModel) => {
    setSelectionModel(newSelectionModel);
    const newSelectedRows = props?.rows?.filter((row) => newSelectionModel.includes(row.id));
    setSelectedRows(newSelectedRows);
    setIsAllChecked(newSelectionModel.length === props?.rows?.length);
    props?.onSelectionChange?.(newSelectedRows);
  };

  const baseColumns = useCaseLoadManagerColumnDefinition().staffDirectoryColumnDefinitions;

  const columnDef = useMemo(() => {
    const selectionColumn = {
      field: "selection",
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <CustomCheckboxHeader
          disabled={rowCount === 0}
          isChecked={rowCount > 0 && selectedRows?.length === rowCount && isAllChecked}
          onHeaderCheckboxClick={handleHeaderCheckboxClick}
        />
      ),
      renderCell: (params: any) => (
        <CustomCheckboxCell
          checked={selectionModel.includes(params.row.id)}
          onChange={(checked) => handleRowCheckboxChange(params.row.id, checked)}
        />
      ),
    };
    return [selectionColumn, ...baseColumns];
  }, [baseColumns, isAllChecked, selectionModel, rowCount]);

  return (
    <Box
      aria-label="staff-directory-table"
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "800px",
        height: props.rows.length <= 8 ? "400px" : "650px",
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "contrasts.1",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          justifyContent: "left",
        },
        "& [role=row]:hover": {
          cursor: "pointer",
        },
      }}
    >
      <DataGrid
        rows={props.rows ?? []}
        columns={columnDef}
        rowCount={rowCount}
        slots={{
          noRowsOverlay: MSBDataGridNoRowsOverlay(
            "There are no managed providers on this users account",
          ),
        }}
        sortingOrder={["asc", "desc"]}
        onRowSelectionModelChange={handleSelectionModelChange}
        pagination
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: props?.initialPageSize ?? 10,
            },
          },
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        loading={props.loading}
        disableRowSelectionOnClick
        onRowClick={(params) => {
          const isChecked = selectionModel.includes(params.row.id);
          handleRowCheckboxChange(params.row.id, !isChecked);
          props.onRowClick?.(params.row);
        }}
        sx={{
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: "transparent !important", // Prevents selected row highlight
          },
          "& .MuiDataGrid-row:focus, & .MuiDataGrid-row:focus-within": {
            outline: "none !important", // Removes focus outline
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
        }}
      />
    </Box>
  );
}
