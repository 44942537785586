import { debounce } from "@mui/material";
import React, { useState } from "react";
import useCaseLoadManager from "./use_case_load_manager";
import { ServiceProviderRef } from "@xng/profile";

const useCaseLoadDebouncedDispatchers = () => {
  const { dispatch: caseLoadManagerDispatch } = useCaseLoadManager();
  const [controlledApproverSearchQuery, setControlledApproverSearchQuery] =
    useState<ServiceProviderRef>();
  const [controlledDECApproverSearchQuery, setControlledDECApproverSearchQuery] =
    useState<ServiceProviderRef>();
  const [
    controlledServiceProviderUnderApproverSearchQuery,
    setControlledServiceProviderUnderApproverSearchQuery,
  ] = useState<string>("");
  const updateSearchFilterQueryParamHandlerForApprover = React.useMemo(() => {
    return debounce((searchValue: ServiceProviderRef) => {
      setControlledApproverSearchQuery(searchValue);
      caseLoadManagerDispatch({
        type: "SET_APPROVER",
        payload: { searchedApprover: searchValue },
      });
    }, 250);
  }, []);
  const updateSearchFilterQueryParamHandlerForDECApprover = React.useMemo(() => {
    return debounce((searchValue: ServiceProviderRef) => {
      setControlledDECApproverSearchQuery(searchValue);
      caseLoadManagerDispatch({
        type: "SET_DEC_APPROVER",
        payload: { searchedDecApprover: searchValue },
      });
    }, 250);
  }, []);

  return {
    updateSearchFilterQueryParamHandlerForApprover,
    controlledApproverSearchQuery,
    controlledDECApproverSearchQuery,
    setControlledApproverSearchQuery,
    controlledServiceProviderUnderApproverSearchQuery,
    setControlledServiceProviderUnderApproverSearchQuery,
    updateSearchFilterQueryParamHandlerForDECApprover,
  };
};

export default useCaseLoadDebouncedDispatchers;
