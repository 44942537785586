import { API_SERVICEPROVIDERS } from "../../../../../api/api";
import useApiQueryData from "../../../../../api/hooks/use_api_query_data";
import useCaseLoadManager from "./use_case_load_manager";

export const useDECCaseLoadMutation = () => {
  const {
    state: { clientId, stateInUs, selectedDecApprover },
  } = useCaseLoadManager();
  return useApiQueryData({
    queryFn: async () =>
      await API_SERVICEPROVIDERS.v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet(
        selectedDecApprover?.id ?? "",
        stateInUs,
        clientId,
      ),
    queryKey: [
      "v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet",
      selectedDecApprover?.id,
      stateInUs,
      clientId,
    ],
    enabled: !!selectedDecApprover?.id,
  });
};
