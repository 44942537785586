import produce from "immer";
import { CaseLoadManagementRootState } from "./caseload_management_provider";
import { ServiceProviderRef } from "@xng/profile";
import { CaseLoadUserTableRow } from "../tables/case_load_user_table";

export type ACTION_SET_APPROVER = {
  type: "SET_APPROVER";
  payload: {
    searchedApprover: ServiceProviderRef;
  };
};

export type ACTION_SET_SELECTED_SERVICE_PROVIDERS = {
  type: "SET_SELECTED_SERVICE_PROVIDERS";
  payload: {
    approverCaseLoadServiceProviders: CaseLoadUserTableRow[];
  };
};

export type ACTION_SET_SELCETED_DEC_SERVICE_PROVIDERS = {
  type: "SET_SELCETED_DEC_SERVICE_PROVIDERS";
  payload: {
    decCaseLoadServiceProviders: CaseLoadUserTableRow[];
  };
};


export type ACTION_SET_DEC_APPROVER = {
  type: "SET_DEC_APPROVER";
  payload: {
    searchedDecApprover: ServiceProviderRef;
  };
}

export type ACTION_REMOVE_APPROVER = { type: "REMOVE_APPROVER" };

export type ACTION_RESET = { type: "RESET" };

export type CaseLoadManagementActions =
  | ACTION_SET_SELECTED_SERVICE_PROVIDERS
  | ACTION_REMOVE_APPROVER 
  | ACTION_SET_APPROVER
  | ACTION_SET_SELCETED_DEC_SERVICE_PROVIDERS
  | ACTION_RESET 
  | ACTION_SET_DEC_APPROVER

export const caseLoadManagerReducer = (
  state: CaseLoadManagementRootState,
  action: CaseLoadManagementActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case "SET_APPROVER": {
        draftState.selectedApprover = action.payload?.searchedApprover;
        draftState.selectedServiceProviderCaseLoads = [];
        break;
      }
      case "SET_SELECTED_SERVICE_PROVIDERS": {
        draftState.selectedServiceProviderCaseLoads =
          action?.payload?.approverCaseLoadServiceProviders;
        break;
      } 
      case "REMOVE_APPROVER": {
        draftState.selectedApprover = null;
        break;
      }

      case "SET_SELCETED_DEC_SERVICE_PROVIDERS": {
        draftState.decServiceProviders = action.payload?.decCaseLoadServiceProviders;
        break;
      }

      case "SET_DEC_APPROVER":{
        draftState.selectedDecApprover = action.payload?.searchedDecApprover;
        break;
      }
      
      case "RESET": {
        draftState.selectedApprover = null;
        draftState.selectedServiceProviderCaseLoads = []; 
        draftState.decServiceProviders = [];
        break;
      }
    }
  });
