import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ServiceProviderResponse } from "../../profile-sdk";

type ServiceProviderResponseSliceStateType = ServiceProviderResponse | null;

const initialState: ServiceProviderResponseSliceStateType = null;

export const serviceProviderProfileSlice = createSlice({
  name: "service provider profile",
  initialState: initialState as ServiceProviderResponseSliceStateType,
  reducers: {
    setServiceProviderProfile: (
      state,
      action: PayloadAction<ServiceProviderResponseSliceStateType>,
    ) => {
      return action.payload;
    },
  },
});

export function selectServiceProviderProfile(state: RootState): ServiceProviderResponse | null {
  return state.serviceProviderResponse;
}

export const { setServiceProviderProfile } = serviceProviderProfileSlice.actions;

export default serviceProviderProfileSlice.reducer;
