import { useEffect, useMemo, useState } from 'react';
import { useXNGSelector } from '../../../../../context/store';
import { selectStateInUS } from '../../../../../context/slices/stateInUsSlice';
import useUserRoles from '../../../../../hooks/use_user_roles';
import { XLogsRoleStrings } from '../../../../../context/types/xlogsrole';
import { selectLoggedInClientAssignment, selectUser } from '../../../../../context/slices/userProfileSlice';

type OperationOnStudent = "create" | "update" | "upload";

const useValidateUserPermission = (operationOnStudent: OperationOnStudent): boolean => {
  const [isPermitted, setIsPermitted] = useState(true);
  const userRoles = useUserRoles();
  const loggedInClientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const userProfile = useXNGSelector(selectUser);
  const isSuperAdmin = userProfile?.isSuperAdmin;
  const isMSBAdmin = userProfile?.isMsbAdmin;

  const haveAdminPrevilage = useMemo(
    () =>
      loggedInClientAssignment.isDelegatedAdmin ||
      loggedInClientAssignment.isExecutiveAdmin ||
      userProfile?.isSuperAdmin ||
      userProfile?.isMsbAdmin,
    [isSuperAdmin, isMSBAdmin, userProfile])

  // Hide this button for the following roles:
  const restrictedRoles:XLogsRoleStrings[] = useMemo(
    () => ["Approver", "Proxy Data Entry", "Service Provider - Autonomous", "Service Provider - Assistant"],
    []
  );

  const state = useXNGSelector(selectStateInUS);
  const isNH = state === "NH";

  // Check if the user has any restricted role
  const hasRestrictedRole = useMemo(
    () => userRoles.some((role) => restrictedRoles.includes(role)) && operationOnStudent === "create",
    [userRoles, restrictedRoles,operationOnStudent]
  );

  useEffect(() => {
    // Permission logic: If in NH and user has a restricted role, they are not permitted
    if(haveAdminPrevilage){ 
      return setIsPermitted(true);
    }else {
      setIsPermitted(!(isNH && hasRestrictedRole));
    }
  }, [isNH, hasRestrictedRole,haveAdminPrevilage]);

  return isPermitted;
};

export default useValidateUserPermission;
