import useApiQueryData from "../../../../../api/hooks/use_api_query_data";
import { API_SERVICEPROVIDERS } from "../../../../../api/api";
import useCaseLoadManager from "./use_case_load_manager";

const useCaseLoadServiceProviders = () => {
  const {
    state: { clientId, stateInUs },
  } = useCaseLoadManager();
  return useApiQueryData({
    queryFn: async () =>
      await API_SERVICEPROVIDERS.v1ServiceProvidersGetAllServiceProvidersInCampusesGet(
        clientId,
        stateInUs,
      ),
    queryKey: ["v1ServiceProvidersGetAllServiceProvidersInCampusesGet", clientId, stateInUs],
  });
};

export default useCaseLoadServiceProviders;
