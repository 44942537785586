import { Box, Button, CircularProgress, SvgIcon } from "@mui/material";
import React from "react";
import SaveIcon from "@mui/icons-material/Save";

type Props = {
  disable: boolean;
  loading: boolean;
  onClick?: () => void;
};

export default function SaveBtn({ disable, loading, onClick }: Readonly<Props>) {
  return (
    <Button
      size="large"
      sx={{ borderRadius: 0 }}
      type="submit"
      disabled={disable}
      startIcon={
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SvgIcon>
            <SaveIcon />
          </SvgIcon>
          <Box
            sx={{
              position: "absolute",
              left: -50,
            }}
          >
            {loading && <CircularProgress color="primary" thickness={7} size={20} />}
          </Box>
        </Box>
      }
      onClick={onClick}
    >
      Save
    </Button>
  );
}
