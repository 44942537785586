import produce from "immer";
import { UserManagementHistoryCard } from "../../../../../../../../profile-sdk";
import UsersHistoryTabState, { UsersHistoryTabAction } from "../../types/users_history_tabs_state";

export function initialUserHistoryState(tabData: {
  cards: UserManagementHistoryCard[];
}): UsersHistoryTabState {
  const usersOptions = new Map<string, UserManagementHistoryCard>();

  tabData.cards.forEach((card) => {
    usersOptions.set(card.user?.id ?? "", card);
  });

  return {
    searchValue: "",
    filteredUsers: Array.from(usersOptions.keys()),
    usersOptions: usersOptions,
    statusFilter: "All",
  };
}

function userApprovalsHistoryReducer(state: UsersHistoryTabState, action: UsersHistoryTabAction) {
  switch (action.type) {
    case "search_users": {
      const newstate = produce(state, (draftState) => {
        draftState.searchValue = action.payload;
        draftState.filteredUsers = Array.from(draftState.usersOptions.values())
          .filter((userManagementCard) => {
            const fullName = `${userManagementCard.user?.firstName} ${userManagementCard.user?.lastName}`;
            return fullName.toLowerCase().includes(action.payload?.toLowerCase() ?? "");
          })
          .map((userManagementCard) => userManagementCard.user?.id ?? "");
      });
      return newstate;
    }
    case "select_status_filter": {
      // unapproved = 0, approved = 1, denied = 2
      const newstate = produce(state, (draftState) => {
        draftState.statusFilter = action.payload;
        draftState.filteredUsers = Array.from(draftState.usersOptions.values())
          .filter((userManagementCard) => {
            if (action.payload === "All") return true;
            if (action.payload === "Approved") return userManagementCard.currentStatus === 1;
            if (action.payload === "Denied") return userManagementCard.currentStatus === 2;
            return true;
          })
          .map((userManagementCard) => userManagementCard.user?.id ?? "");
      });
      return newstate;
    }
    case "initialize": {
      const newState = produce(state, (_) => {
        return initialUserHistoryState({ cards: action.payload });
      });

      return newState;
    }
    default:
      return state;
  }
}

export default userApprovalsHistoryReducer;
