import React, { useMemo } from 'react' 
import { useNotatorTools } from '../../../tools';
import { SessionStatus } from '@xng/reporting'; 
import { selectLoggedInClientAssignment } from '../../../../../context/slices/userProfileSlice';
import { useXNGSelector } from '../../../../../context/store';


const useCheckSessionInRevisionStatusAndUserIsApprover = ():boolean => { 
    const { draftSession } = useNotatorTools()
    const loggedInClientAssignment = useXNGSelector(selectLoggedInClientAssignment);
    const isSessionInRevisionStatus = useMemo(() => draftSession?.status === SessionStatus.NUMBER_3, [draftSession]);
 
    return useMemo(() => !!loggedInClientAssignment?.isApprover && isSessionInRevisionStatus, [loggedInClientAssignment, isSessionInRevisionStatus]);
   
}

export default useCheckSessionInRevisionStatusAndUserIsApprover