import { 
  StudentInstanceReportFilterParameters,
} from "@xng/reporting";
import { API_STUDENT_INSTANCES_REPORT } from "../../../../../api/api"; 
import useApiMutateData from "../../../../../api/hooks/use_api_mutate_data";

const useInstanceReportMutation = () => {
  const poll = useApiMutateData({
    mutationFn: async (filterParameters: StudentInstanceReportFilterParameters) => {
      return await API_STUDENT_INSTANCES_REPORT.v1StudentReportsStudentInstancesReportQueueReportPost(
        {
          queueStudentInstanceReportRequest: {
            filterParameters: {
              serviceAreaIds: filterParameters?.serviceAreaIds,
              startDate: filterParameters?.startDate,
              endDate: filterParameters?.endDate,
              clientId: filterParameters?.clientId,
              campusId: filterParameters?.campusId,
            },
          },
        },
      );
    },
    mutationKey: ["v1StudentReportsStudentInstancesReportQueueReportPost"],
  });

  return poll;
};

export default useInstanceReportMutation;
