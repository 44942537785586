import { useEffect, useState } from "react";
import TrackedApp from "../App";
import { initializeFeatureFlags, selectFeatureFlags } from "../context/slices/featureFlagsSlice";
import { selectUser } from "../context/slices/userProfileSlice";
import { useXNGDispatch, useXNGSelector } from "../context/store";
import IfElseBox from "../design/components-dev/if_else_box";
import FullScreenLoadingMessage from "../design/high-level/fullscreen_loading_spinner";
import { eventEmitter } from "../event_emitter";
import "../global.css";
import { ServiceProviderResponse, StatementType } from "../profile-sdk";
import SignElectronicSignature, { SignatureData } from "../utils/signElectronicSignature";
import OnboardingFlowHandler from "../views/registration_flow/flow_handler";
import InactiveUserView from "../views/registration_flow/user_onboarding/inactive_user_view";
import {
  STATEMENT_ELECTRONIC_SIGNATURE_CONSENT,
  STATEMENT_FERPA_AUTHORIZATION,
  STATEMENT_TRUE_AND_ACCURATE_DATA_AUTHORIZATION,
} from "../views/registration_flow/user_onboarding/statements";
import { FormFourValues } from "../views/registration_flow/user_onboarding/types";
import { FourthView } from "../views/registration_flow/user_onboarding/views";
import { MSALProvider } from "./msalProvider";
import { usePopulateReduxStore } from "./usePopulateReduxStore";
import { msalAccount } from "./msalEventCallback";
import { msalInstance } from "./msalInstance";
import MaintenanceSplashPage from "./maintenanceSplashPage";
import useUserRoles from "../hooks/use_user_roles";
import { selectServiceProviderProfile } from "../context/slices/serviceProviderProfileSlice";

export function AuthProvider() {
  // STATE
  const user = useXNGSelector(selectUser);
  const [showSignatoryVeiw, setShowSignatoryVeiw] = useState<boolean>(false);
  const [flagsInitialized, setFlagsInitialized] = useState<boolean>(false);
  // HOOKS
  const dispatch = useXNGDispatch();
  const userRoles = useUserRoles();
  const serviceProviderProfile = useXNGSelector(selectServiceProviderProfile);

  const { reduxPopulated, userInactive, disable_flow } = usePopulateReduxStore(
    msalAccount!,
    setShowSignatoryVeiw,
  );

  const initializePendo = (userData: any, serviceProviderProfile: ServiceProviderResponse) => {
    if (window.pendo && serviceProviderProfile) {
      window.pendo.initialize({
        apiKey: "b472aa5a-0ab0-4811-7a1a-fb11e62b4635",
        visitor: {
          id: user?.emailAddress,
          user_id: userData.oid,
          userName: user?.firstName + " " + user?.lastName,
          district: serviceProviderProfile.districtsOfOperation?.map(district => district.name),
          roles: userRoles,
          campus: serviceProviderProfile.activeSchoolCampuses?.map(campus => campus.name),
          serviceProviderTypes: user?.serviceProviderTypes?.map(type => type.name),
          state: userData.state,
        },
        account: {
          id: serviceProviderProfile.client?.name,
          client_id: serviceProviderProfile.client?.id,
          state: userData.state,
        },
      });
    }
  };

  // This effect initializes the feature flags when the API is ready
  useEffect(() => {
    function handleApiReady() {
      if (!flagsInitialized) {
        initializeFeatureFlagsAsync();
      }
    }

    if (serviceProviderProfile) {
      initializePendo(msalAccount?.idTokenClaims, serviceProviderProfile);
    }

    eventEmitter.addEventListener("apiInitialized", handleApiReady);
    return () => {
      eventEmitter.removeEventListener("apiInitialized", handleApiReady);
    };
  }, [reduxPopulated]);

  async function initializeFeatureFlagsAsync() {
    dispatch(initializeFeatureFlags());
    setFlagsInitialized(true);
  }

  const DISABLE_FLOW = false;
  const maintenanceModeActive = useXNGSelector((state) =>
    selectFeatureFlags(state, "MaintenenceMode"),
  );

  return disable_flow ? (
    <TrackedApp />
  ) : (
    <MSALProvider>
      <IfElseBox
        if={maintenanceModeActive}
        then={<MaintenanceSplashPage onLogout={() => msalInstance.logoutRedirect()} />}
        else={
          <IfElseBox
            if={userInactive}
            then={<InactiveUserView onLogout={() => msalInstance.logoutRedirect()} />}
            else={
              <IfElseBox
                if={showSignatoryVeiw}
                then={
                  <FourthView
                    onValidNext={async (values: FormFourValues) => {
                      // Handle values...

                      const signatureData: SignatureData = {
                        fullName: `${user?.firstName as string} ${user?.lastName as string}`,
                        iod: msalAccount?.idTokenClaims?.oid as string,
                        ipAddress: "",
                        state: msalAccount?.idTokenClaims?.state as string,
                      };
                      try {
                        await SignElectronicSignature(
                          STATEMENT_TRUE_AND_ACCURATE_DATA_AUTHORIZATION,
                          StatementType.NUMBER_0,
                          signatureData,
                        );
                        await SignElectronicSignature(
                          STATEMENT_FERPA_AUTHORIZATION,
                          StatementType.NUMBER_1,
                          signatureData,
                        );
                        await SignElectronicSignature(
                          STATEMENT_ELECTRONIC_SIGNATURE_CONSENT,
                          StatementType.NUMBER_2,
                          signatureData,
                        );
                        setShowSignatoryVeiw(false);
                      } catch (err) {
                        console.log("UNABLE SIGN ELECTRONIC SIGNATURE: ", err);
                      }
                    }}
                    apiDependentValues={{
                      ferpaAuthorizationStatement: STATEMENT_FERPA_AUTHORIZATION,
                      electronicSignatureConsent: STATEMENT_ELECTRONIC_SIGNATURE_CONSENT,
                      trueAndAccurateDataAuthorization:
                        STATEMENT_TRUE_AND_ACCURATE_DATA_AUTHORIZATION,
                    }}
                  />
                }
                else={
                  <IfElseBox
                    if={msalAccount === null}
                    then={<FullScreenLoadingMessage message="Logging you in..." />}
                    else={
                      <IfElseBox
                        if={!(reduxPopulated && flagsInitialized)}
                        then={<FullScreenLoadingMessage message="Gathering info..." />}
                        else={
                          <OnboardingFlowHandler
                            onLogout={() => msalInstance.logoutRedirect()}
                            account={msalAccount!}
                          />
                        }
                      />
                    }
                  />
                }
              />
            }
          />
        }
      />
    </MSALProvider>
  );
}
