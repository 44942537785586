import dayjs, { Dayjs } from "dayjs";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { StudentRef } from "../../profile-sdk";
import {
  DayOfWeek,
  GetSessionSeriesResponse,
  Location,
  CreateSessionRequest,
  EditSessionSeriesRequest,
  SessionResponse,
} from "../../session-sdk";
import { API_SESSIONS } from "../../api/api";
import { useXNGSelector } from "../../context/store";
import { selectStateInUS } from "../../context/slices/stateInUsSlice";
import { selectLoggedInClientAssignment } from "../../context/slices/userProfileSlice";
import { placeholderForFutureLogErrorText } from "../../temp/errorText";
import EditSessionFormWrapper from "../modals/edit_session_form_wrapper";
import { SchedulerFieldValues } from "./types";
import { selectActingServiceProvider } from "../../context/slices/dataEntryProvider";
import sessionStorageKeys from "../../browser/sessionStorageKeys";
import { useLocation, useNavigate } from "react-router-dom";
import { timezoneAdjustedStartOrEndTimes, getUserTimeZone } from "../../utils/timeZones";
import { ROUTES_XLOGS } from "../../constants/URLs";
import { EDIT_SESSION_MODAL_VALIDATION_SCHEMA } from "../../constants/edit_session_modal_validation_schema";
import { NotatorTools } from "../notator/tools";
import { recurrencePatternMapper } from "./utils/recurence_pattern_mapper";

export interface IEditSessionSeriesModal {
  open: boolean;
  startDate: Date;
  endDate: Date;
  onClose: () => void;
  onRequestRefreshSessions?: () => void;
  editedSession?: SessionResponse;
  studentCaseload?: StudentRef[];
  studentList?: StudentRef[];
  saveSession?: (draftSession: SessionResponse) => Promise<SessionResponse>;
  bypassUnsavedChanges?: () => void;
  setDraftSession?: React.Dispatch<React.SetStateAction<SessionResponse>>;
  notatorTools?: NotatorTools;
}
export function EditSessionSeriesModal(props: IEditSessionSeriesModal) {
  // REDUX SELECTORS
  const userStateInUS = useXNGSelector(selectStateInUS);
  const loggedInClientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const actingServiceProvider = useXNGSelector(selectActingServiceProvider);

  const [modalNeverOpened, setModalNeverOpened] = useState<boolean>(true);
  const [session, setSession] = useState<SessionResponse>({} as SessionResponse);
  const [existingSessionRecurrencePattern, setExistingSessionRecurrencePattern] =
    useState<GetSessionSeriesResponse>({} as GetSessionSeriesResponse);
  const focusedDateKey = sessionStorageKeys.FOCUSED_DATE_KEY;
  const location = useLocation();
  const navigate = useNavigate();

  // REACT HOOK FORM
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<SchedulerFieldValues>({
    resolver: yupResolver(EDIT_SESSION_MODAL_VALIDATION_SCHEMA),
  });

  const {
    open,
    startDate,
    endDate,
    onClose,
    editedSession,
    saveSession,
    notatorTools,
    setDraftSession,
    bypassUnsavedChanges,
    onRequestRefreshSessions,
    studentCaseload,
  } = props;

  // INITIAL VALUES
  const { timezoneAdjustedStartTime: START, timezoneAdjustedEndTime: END } =
    timezoneAdjustedStartOrEndTimes(userStateInUS, "display", dayjs(startDate), dayjs(endDate));

  // --------------- USEEFFECTS / API ---------------
  // SET DEFAULTS ON OPEN / CLOSE, CASELOAD CHANGE
  useEffect(() => {
    //reset();
    if (open) {
      fetchAndSetRecurrencePattern(editedSession?.seriesId);
      if (!location.pathname.includes("notator")) {
        sessionStorage.setItem(focusedDateKey, START.toISOString());
      }
    }

    setValue("minutesDuration", (END as Dayjs).diff(START, "minutes"));
    setSession(editedSession || ({} as SessionResponse));
  }, [open]);

  // --------------- CUSTOM STATES FOR CUSTOM FORM ---------------
  const [dayofWeekSelection, setDayOfWeekSelection] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [radio_onafter, setradio_onafter] = useState<"on" | "after">("on");
  const [includeNonSchoolDays, setIncludeNonSchoolDays] = useState(false);

  const handleDateCheckBoxChange = (index: number) => {
    const updatedSelection = [...dayofWeekSelection];
    updatedSelection[index] = !updatedSelection[index];
    setDayOfWeekSelection(updatedSelection);
  };

  // ---- DATABASE / API ----

  async function fetchAndSetRecurrencePattern(seriesID: string | undefined) {
    const serviceProviderId = actingServiceProvider?.id;
    if (!serviceProviderId) throw new Error(placeholderForFutureLogErrorText);
    const recurrenceResponse = await API_SESSIONS.v1SessionsSeriesSessionSeriesIdGet(
      serviceProviderId,
      seriesID!,
      userStateInUS,
    );

    setExistingSessionRecurrencePattern(recurrenceResponse);

    setradio_onafter(recurrenceResponse.recurrencePattern?.endDate ? "on" : "after");

    setDayOfWeekSelection([
      recurrenceResponse.recurrencePattern?.includedDaysOfWeek?.includes(DayOfWeek.NUMBER_0)
        ? true
        : false,
      recurrenceResponse.recurrencePattern?.includedDaysOfWeek?.includes(DayOfWeek.NUMBER_1)
        ? true
        : false,
      recurrenceResponse.recurrencePattern?.includedDaysOfWeek?.includes(DayOfWeek.NUMBER_2)
        ? true
        : false,
      recurrenceResponse.recurrencePattern?.includedDaysOfWeek?.includes(DayOfWeek.NUMBER_3)
        ? true
        : false,
      recurrenceResponse.recurrencePattern?.includedDaysOfWeek?.includes(DayOfWeek.NUMBER_4)
        ? true
        : false,
      recurrenceResponse.recurrencePattern?.includedDaysOfWeek?.includes(DayOfWeek.NUMBER_5)
        ? true
        : false,
      recurrenceResponse.recurrencePattern?.includedDaysOfWeek?.includes(DayOfWeek.NUMBER_6)
        ? true
        : false,
    ]);
    setIncludeNonSchoolDays(recurrenceResponse.recurrencePattern?.includeNonSchoolDay!);
  }

  // --------------- ONSUBMIT ---------------
  async function onSubmit(data: SchedulerFieldValues) {
    const savedSession = await saveSession?.(editedSession!);
    await editSessionSeries().then(() => {
      if (savedSession?.id === null) {
        onClose();
        navigate(0);
      }
      if (location.pathname.includes("notator")) {
        notatorTools?.setSoftRefreshSwitch((p) => !p);
      }
    });
    onClose();

    async function editSessionSeries() {
      let path: null | string = null;
      if (
        location.pathname.includes("notator/null") &&
        savedSession &&
        saveSession &&
        setDraftSession
      ) {
        const sessionResponse = await saveSession(savedSession);
        path = `${ROUTES_XLOGS.notator}/${sessionResponse.id!}`;
        setDraftSession(sessionResponse);
      }
      // date occurs does not actually create the session. TODO: REFACTOR this form to use starttime and endttime as the single source only.

      // Timezone adjustments
      const { timezoneAdjustedStartTime, timezoneAdjustedEndTime } =
        timezoneAdjustedStartOrEndTimes(userStateInUS, "data", data.startTime, data.endTime);

      const createSessionRequest: CreateSessionRequest = {
        title: data.title,
        service: {
          id: data.service.id,
          name: data.service.name,
          description: data.service.description,
          area: data.service.area,
          type: data.service.serviceType,
        }, //hdata.service as SessionSDKService, // Cast as because difference in fields, one field is a later feature
        groupSetting: data.sessionType === "group",
        meetingDetails: {
          date: dayjs(data.startTime).startOf("day").toDate(),
          startTime: timezoneAdjustedStartTime as Date,
          endTime: timezoneAdjustedEndTime as Date,
          location: { name: data.location, description: data.locationDescription } as Location, // Todo: ID later
        },
        serviceProvider: actingServiceProvider,
        studentIds: data.studentList.map((s) => {
          if (s.id) {
            return s.id;
          } else {
            throw new Error(placeholderForFutureLogErrorText);
          }
        }),
        client: {
          id: loggedInClientAssignment.client?.id,
          name: loggedInClientAssignment.client?.name,
        },
      };

      const recurrencePattern = recurrencePatternMapper({
        data,
        session,
        dayofWeekSelection,
        existingSessionRecurrencePattern,
        includeNonSchoolDays,
        modalNeverOpened,
        radio_onafter,
        savedSession: savedSession ?? {},
      });
      const editRecurringSessionRequest: EditSessionSeriesRequest = {
        seriesId: savedSession?.seriesId || session.seriesId,
        recurrencePattern: recurrencePattern,
        session: createSessionRequest,
        timezone: getUserTimeZone(),
      };

      try {
        await API_SESSIONS.v1SessionsEditSessionSeriesPost(
          editRecurringSessionRequest,
          userStateInUS,
        );
      } catch (err) {
        console.error(err);
        return;
      }

      if (path) {
        bypassUnsavedChanges?.();
        navigate(
          {
            pathname: path,
            search: location.search,
          },
          {
            replace: true,
          },
        );
        // refresh sessions
      } else if (onRequestRefreshSessions && !path) {
        onRequestRefreshSessions();
      }
    }
  }

  const editSessionFormWrapperProps = {
    open,
    startDate,
    endDate,
    onClose,
    editedSession,
    submitCallback: onSubmit,
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    studentCaseload,
    setDayOfWeekSelection,
    dayofWeekSelection,
    setIncludeNonSchoolDays,
    includeNonSchoolDays,
    setradio_onafter,
    radio_onafter,
    handleDateCheckBoxChange,
    existingSessionRecurrencePattern,
    setRecurrenceModalNeverOpened: setModalNeverOpened,
  };

  return <EditSessionFormWrapper modalType="series" {...editSessionFormWrapperProps} />;
}
export default EditSessionSeriesModal;
