import produce from "immer";

export interface InstancesReportStoreType {
  pageSize: number;
  pageNumber: number;
}

export function instancesReportInit(): InstancesReportStoreType {
  return {
    pageSize: 10,
    pageNumber: 1,
  };
}

export type UPDATE_PAGE_SIZE = {
  type: "UPDATE_PAGE_SIZE";
  payload: number;
};

export type UPDATE_PAGE_NUMBER = {
  type: "UPDATE_PAGE_NUMBER";
  payload: number;
};

export type InstancesReportActions = UPDATE_PAGE_SIZE | UPDATE_PAGE_NUMBER;

export const intancesReportReducer = (state: InstancesReportStoreType, action: InstancesReportActions) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case "UPDATE_PAGE_SIZE": {
        draftState.pageSize = action.payload;
        break;
      }

      case "UPDATE_PAGE_NUMBER": {
        draftState.pageNumber = action.payload;
        break;
      }

      default:
        break;
    }
  });
};
