import { Box, Divider, Typography } from "@mui/material";
import InputFieldGrid from "../components/input_field_grid";
import useStudentProfileContext from "../hooks/use_student_profile_context";
import { StudentProfileValidationSchemaT } from "../constants/student_profile_validation_schema";
import produce from "immer";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import { useEffect, useRef } from "react";
import StudentProfileTextField from "../components/student_profile_text_field";
import { Activity } from "../../../../../profile-sdk";
import CareProvisionsInputFieldEndAdornment from "../components/care_provisions/care_provisions_input_field_end_adornment";
dayjs.extend(minMax);

export default function CareProvisionsTab() {
  const {
    studentProfileForm: { setValue, watch },
    mutateStudentProfileApiClient: { isPending: mutateStudentProfileIsLoading },
  } = useStudentProfileContext();
  const disableInputFields = mutateStudentProfileIsLoading;
  /**
   * An array of care provision sections, each containing details about a specific type of care provision.
   * specifically Activities, Accommodations, Modifications.
   *
   * Each section includes:
   * - `formName`: The name of the form control field associated with the care provision.
   * - `title`: An object containing the singular and plural titles for the care provision.
   * - `provisions`: The current value of the care provision array, watched from the form state.
   * - `containerRef`: A reference to the HTML div element that contains the care provision section.
   *
   * @type {CareProvisionsSectionT[]}
   */
  const careProvisionsSections: CareProvisionsSectionT[] = [
    {
      formName: "careProvisions.activities",
      title: {
        singular: "Activity",
        plural: "Activities",
      },
      provisions: watch("careProvisions.activities"),
      containerRef: useRef<HTMLDivElement>(null),
    },
    {
      formName: "careProvisions.accommodations",
      title: {
        singular: "Accommodation",
        plural: "Accommodations",
      },
      provisions: watch("careProvisions.accommodations"),
      containerRef: useRef<HTMLDivElement>(null),
    },
    {
      formName: "careProvisions.modifications",
      title: {
        singular: "Modification",
        plural: "Modifications",
      },
      provisions: watch("careProvisions.modifications"),
      containerRef: useRef<HTMLDivElement>(null),
    },
  ];

  /**
   * Returns the most recent start date from a list of care provisions in ISO format (YYYY-MM-DD).
   * If no start date is available, returns "N/A".
   *
   * @param {CareProvisionsSectionT["provisions"]} provisions - The list of care provisions.
   * @returns {string} The most recent start date in ISO format or "N/A" if no provisions are available.
   */
  const lastUpdatedDate = (provisions: CareProvisionsSectionT["provisions"]): string => {
    //we compare to 1 to account for the extra empty input field we add to the list of provisions
    if (provisions.length > 1) {
      const sortedArray = produce((provisions as Activity[]) ?? [], (draft) => {
        draft.sort((provisionA, provisionB) => {
          if (provisionA?.startDate) return 1;
          if (provisionB?.startDate) return -1;
          return dayjs(provisionA.startDate).isAfter(dayjs(provisionB.startDate)) ? -1 : 1;
        });
      });

      return sortedArray[sortedArray.length - 1]?.startDate
        ? dayjs(sortedArray[sortedArray.length - 1]?.startDate)
            .toISOString()
            .slice(0, 10)
        : "N/A";
    } else {
      return "N/A";
    }
  };

  /**
   * Adds a new provision to the provisions list and updates the form state.
   *
   * @param params - The parameters for adding a new provision.
   * @param params.newProvisionName - The name of the new provision.
   * @param params.provisionSection - The section of care provisions to which the provision belongs.
   * @param params.provision - The provision object to be updated.
   * @param params.provisionIndex - The index of the provision in the provisions list.
   *
   * This function updates the specified provision with the new name and timestamps.
   * If the provision is the last in the list, it adds a new empty provision to the list
   * and scrolls to the bottom of the container.
   */
  function addNewProvisionToProvisionsList(params: {
    newProvisionName: string;
    provisionSection: CareProvisionsSectionT;
    provision: CareProvisionsSectionT["provisions"][number];
    provisionIndex: number;
  }) {
    const { provisionSection, provision, newProvisionName, provisionIndex } = params;
    const updatedProvision = produce(provision ?? {}, (draft) => {
      draft.name = newProvisionName;
      draft.createdAt = draft.createdAt ?? new Date();
      draft.updatedAt = new Date();
      draft.startDate = draft.startDate ?? new Date();
    });

    setValue(`${provisionSection.formName}.${provisionIndex}`, updatedProvision, {
      shouldDirty: true,
      shouldTouch: true,
    });

    // here we implement the extra input field behavior, by checking if the current provision is the last in the list
    if (provisionIndex === provisionSection.provisions.length - 1) {
      //add new input field
      const newProvision: StudentProfileValidationSchemaT["careProvisions"]["activities"][number] =
        {
          name: "",
          id: crypto.randomUUID(),
        };

      const updatedProvisionsList = produce(provisionSection.provisions ?? [], (draft) => {
        draft.push(newProvision);
      });

      setValue(`${provisionSection.formName}`, updatedProvisionsList);
      // scroll to bottom of div
      provisionSection.containerRef.current?.scrollTo({
        top: -provisionSection.containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  function removeCareProvisionFromProvisionsList(params: {
    provisionSection: CareProvisionsSectionT;
    provision: CareProvisionsSectionT["provisions"][number];
  }) {
    const { provisionSection, provision } = params;
    const updatedProvisionsList = produce(provisionSection.provisions ?? [], (draft) => {
      return draft.filter((p) => p.id !== provision.id);
    });
    setValue(`${provisionSection.formName}`, updatedProvisionsList, {
      shouldDirty: true,
      shouldTouch: true,
    });
  }

  /**
   * useEffect hook to initialize care provisions with extra empty input fields.
   * this is were we initialize the extra input fields behavior for the care provisions.
   *
   * This effect runs once when the component mounts. It iterates over each care provision section
   * and checks if the provisions array is empty or if the last provision in the array has a non-empty name.
   * If either condition is met, it adds a new empty provision to the array.
   *
   * The new provision includes:
   * - `name`: An empty string.
   * - `id`: A unique identifier generated using `crypto.randomUUID()`.
   *
   * The updated provisions list is then set in the form state using the `setValue` function.
   */
  useEffect(() => {
    // Initialize care provisions with extra empty input fields

    careProvisionsSections.forEach((section, i) => {
      if (
        !section.provisions ||
        section.provisions.length === 0 ||
        (section.provisions.length > 0 &&
          section.provisions[section.provisions.length - 1].name?.trim()) //if the last input field has a value, add new input field
      ) {
        //add new input field
        const newProvision: StudentProfileValidationSchemaT["careProvisions"]["activities"][number] =
          {
            name: "",
            id: crypto.randomUUID(),
          };

        const updatedProvisionsList = produce(section.provisions ?? [], (draft) => {
          draft.push(newProvision);
        });

        setValue(`${section.formName}`, updatedProvisionsList);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careProvisionsSections]);

  return (
    <Box>
      {careProvisionsSections.map((provisionSection, sectionIndex) => {
        return (
          <Box
            component={"section"}
            key={provisionSection.title.plural}
            mb={provisionSection.provisions.length > 4 ? 3 : 0}
          >
            {/* SECTION HEADER */}
            <InputFieldGrid
              items={[
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  key: "activities",
                  content: (
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {provisionSection.title.plural}
                    </Typography>
                  ),
                },
                {
                  key: "last updated on",
                  content: (
                    <Typography variant="body1">
                      Last updated on:{" "}
                      <Box
                        component={"span"}
                        sx={{ borderBottom: "1px solid", borderColor: "black", fontWeight: 500 }}
                      >
                        {lastUpdatedDate(provisionSection.provisions ?? [])}
                      </Box>
                    </Typography>
                  ),
                },
              ]}
            />

            <Divider sx={{ mb: 2 }} />
            <Box
              ref={provisionSection.containerRef}
              sx={{
                minHeight: "150px",
                maxHeight: "300px",
                overflowY: "auto",
                borderBottom: provisionSection.provisions.length > 14 ? 2 : 0,
                borderBottomColor: "divider",
                pb: 1,
              }}
            >
              <InputFieldGrid
                gridContainerProps={{ spacing: 0 }}
                // iterate over each provision and return a text field for each one
                items={provisionSection.provisions?.map((provision, provisionIndex) => {
                  return {
                    key: provisionIndex.toString(),
                    xs: 12,
                    md: 6,
                    sm: 6,
                    lg: 6,
                    sx: { pr: "5px" },
                    content: (
                      <StudentProfileTextField
                        multiline
                        placeholder={`Add ${
                          sectionIndex === 2 ? "a" : "an"
                        } ${provisionSection.title.singular.toLowerCase()}...`}
                        value={provision.name}
                        disabled={disableInputFields}
                        onChange={(e) => {
                          addNewProvisionToProvisionsList({
                            newProvisionName: e.target.value,
                            provisionSection,
                            provision,
                            provisionIndex,
                          });
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "text.primary",
                            },
                            "& fieldset": {
                              border: provision?.name?.trim() ? 0 : 1,
                              borderColor: "divider",
                            },
                            "& textarea::spelling-error": {
                              textDecorationLine: "none",
                            },
                            "&.Mui-focused textarea::spelling-error": {
                              textDecorationLine: "underline",
                              textDecorationStyle: "wavy",
                            },
                            "&:hover fieldset": {
                              border: 1,
                              borderColor: "text.primary",
                            },
                            "&.Mui-focused .MuiInputAdornment-root": {
                              display: provision?.name?.trim() ? "flex" : "none",
                            },
                            "&:hover .MuiInputAdornment-root": {
                              display: provision?.name?.trim() ? "flex" : "none",
                            },
                            "& .MuiInputAdornment-root": {
                              display: "none",
                            },
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <CareProvisionsInputFieldEndAdornment
                              tooltipTitle={`Remove ${provisionSection.title.singular.toLowerCase()}`}
                              onRemoveBtnClick={() => {
                                removeCareProvisionFromProvisionsList({
                                  provisionSection,
                                  provision,
                                });
                              }}
                            />
                          ),
                        }}
                      />
                    ),
                  };
                })}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

type CareProvisionsSectionT = {
  formName:
    | "careProvisions.activities"
    | "careProvisions.accommodations"
    | "careProvisions.modifications";
  title: {
    singular: string;
    plural: string;
  };
  provisions: StudentProfileValidationSchemaT["careProvisions"][
    | "activities"
    | "accommodations"
    | "modifications"];
  containerRef: React.RefObject<HTMLDivElement>;
};
