import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SessionListAction from "./session-list-action";
import SessionListItem from "./session-list-item";
import usePalette from "../../../../../../hooks/usePalette";
import { ActualSession } from "../../../../../../session-sdk";
import XNGSpinner from "../../../../../../design/low-level/spinner";
import { Typography } from "@mui/material";
import { useMemo } from "react";
import produce from "immer";

interface SessionListContainerProps {
  sessions: ActualSession[];
  selectedIds: string[];
  onSelect: (sessionId: string, checked: boolean, index: number) => void;
  onSelectAll: (checked: boolean) => void;
  isLoading: boolean;
  isPosting: boolean;
}

export default function SessionListContainer({
  sessions,
  selectedIds,
  onSelect,
  onSelectAll,
  isLoading,
  isPosting,
}: Readonly<SessionListContainerProps>) {
  const palette = usePalette();

  const sortedSessionsByDate = useMemo(()=>{
    const newSessionsList = produce(sessions, (draft) => {
      draft.sort((a, b) => {
        if (new Date(a.meetingDetails?.date ?? 0).getTime() > new Date(b.meetingDetails?.date ?? 0).getTime()) {
          return -1;
        }
        if (new Date(a.meetingDetails?.date ?? 0).getTime() < new Date(b.meetingDetails?.date ?? 0).getTime()) {
          return 1;
        }
        return 0;
      });
    });

    return newSessionsList
  }, [sessions]);

  return (
    <Box sx={{ paddingX: "18px" }}>

      <SessionListAction
        total={sessions.length}
        selectedAll={sessions.length === selectedIds.length}
        onSelectAll={onSelectAll}
      />

      <Divider sx={{ borderWidth: "2px", borderColor: palette.primary[2] }} />
      {!isLoading && !isPosting ? (
        sortedSessionsByDate.map((session, index: number) =>{ 
          return(

          <SessionListItem
            key={session.id}
            session={session}
            checked={selectedIds.includes(session.id!)}
            onSelect={(checked) => onSelect(session.id!, checked, index)}
          />
        )})
      ) : (
        <Box
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={3}
        >
          <XNGSpinner />
          <Typography variant="h5" color={palette.primary[1]} textAlign={"center"}>
            {`${!isPosting ? "Loading unposted" : "Approving"} sessions...`}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
