import dayjs from "dayjs";
import { CreateRecurrencePatternRequest, DayOfWeek, GetSessionSeriesResponse, RecurrencePattern, SessionResponse } from "../../../session-sdk";
import { SchedulerFieldValues } from "../types";

export function recurrencePatternMapper({
    existingSessionRecurrencePattern,
    includeNonSchoolDays,
    radio_onafter,
    dayofWeekSelection,
    modalNeverOpened,
    data,
}: {
    session: SessionResponse;
    existingSessionRecurrencePattern: GetSessionSeriesResponse;
    savedSession: SessionResponse;
    // createSessionRequest: CreateSessionRequest;
    includeNonSchoolDays: boolean;
    radio_onafter: string;
    dayofWeekSelection: boolean[];
    modalNeverOpened: boolean;
    data: SchedulerFieldValues
}) {
    let componentRecurrencePattern: CreateRecurrencePatternRequest = {};
    const includedDays: DayOfWeek[] = [
        DayOfWeek.NUMBER_0,
        DayOfWeek.NUMBER_1,
        DayOfWeek.NUMBER_2,
        DayOfWeek.NUMBER_3,
        DayOfWeek.NUMBER_4,
        DayOfWeek.NUMBER_5,
        DayOfWeek.NUMBER_6,
    ].filter((day, index) => dayofWeekSelection[index]);

    let recurrenceEndDate = undefined;
    let numberOfOccurrences = undefined;

    if (radio_onafter === "on") {
        const editDate =
            data.recurrenceCustom?.ends.onDate ??
            existingSessionRecurrencePattern.recurrencePattern?.endDate;
        recurrenceEndDate = dayjs(editDate).format("YYYY-MM-DD");
    } else {
        numberOfOccurrences =
            data.recurrenceCustom?.ends.afterOccurences ??
            existingSessionRecurrencePattern.recurrencePattern?.endNumberOfOccurrences;
    }

    componentRecurrencePattern = {
        // We'll need to decide how long to run these for.
        endDate: recurrenceEndDate,
        endNumberOfOccurrences: numberOfOccurrences,
        includedDaysOfWeek: includedDays,
        mode: existingSessionRecurrencePattern.recurrencePattern?.mode, // weekly
        interval:
            data.recurrenceCustom?.repeatEveryIncrement ??
            existingSessionRecurrencePattern.recurrencePattern?.interval,
        includeNonSchoolDay: includeNonSchoolDays,
    };

    const recurrencePattern: RecurrencePattern | CreateRecurrencePatternRequest | undefined=  modalNeverOpened
            ? existingSessionRecurrencePattern.recurrencePattern
            : componentRecurrencePattern
  


    return recurrencePattern;
}