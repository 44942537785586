import { useMemo } from "react";
import useApiQueryGetServiceAreasByDate from "../../../../../../api/hooks/state_snapshots/use_api_query_get_service_areas_by_date";
import dayjs from "dayjs";
import useApiQueryData from "../../../../../../api/hooks/use_api_query_data";
import { API_PRESCRIBING_PROVIDER } from "../../../../../../api/api";

type Props = {
  stateInUs: string;
  clientId: string;
};

export default function usePrescribedServiceAreasData(props: Props) {
  const { stateInUs, clientId } = props;
  const todaysDate = {
    day: dayjs().day(),
    month: dayjs().month(),
    year: dayjs().year(),
  };
  const memoizedDate = useMemo(() => {
    return dayjs().startOf("day").toDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todaysDate.day, todaysDate.month, todaysDate.year]);

  const serviceAreaOptionsQueryClient = useApiQueryGetServiceAreasByDate({
    queryParams: {
      state: stateInUs,
      date: memoizedDate,
      filterForMedicaidInfoOnly: true,
    },
    options: {
      staleTime: 4 * 60 * 60 * 1000, // 4 hours
    },
  });

  const prescribingProviderOptionsQueryClient = useApiQueryData({
    queryKey: ["v1PrescribingProvidersGet", stateInUs, clientId],
    queryFn: async () => {
      return await API_PRESCRIBING_PROVIDER.v1PrescribingProvidersGet(clientId, stateInUs);
    },
    staleTime: 4 * 60 * 60 * 1000, // 4 hours
  });

  return {
    serviceAreaOptionsQueryClient: {
      ...serviceAreaOptionsQueryClient,
      data: serviceAreaOptionsQueryClient.data?.serviceAreas ?? [],
    },
    prescribingProviderOptionsQueryClient,
  };
}
