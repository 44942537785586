import { GridColDef } from "@mui/x-data-grid";
import { SessionLogsReportData } from "..";
import dayjs from "dayjs";
import { getUserTimeZone } from "../../../../../../utils/timeZones";
import { Box, Tooltip, Typography } from "@mui/material";
import {
  PopoutTableCell,
} from "../../components/tables/session_logs_report_table";

type GridRowsColumnDefType = GridColDef<NonNullable<SessionLogsReportData["pageRecords"]>[number]>;
export type SessionLogsGoalsObjectives = NonNullable<
  NonNullable<SessionLogsReportData["pageRecords"]>[number]["goalsObjectives"]
>;

type GoalsObjectivesColumnDefinition = GridColDef<SessionLogsGoalsObjectives[number]>;

export default function useSessionLogsReportTableColumnDefinition() {
  const columnDefDefaultProps: Partial<GridRowsColumnDefType> = {
    flex: 1,
    minWidth: 120,
    headerAlign: "left",
    valueGetter(params) {
      if(typeof params.value === 'number') return params.value;
      return params.value || emptyCellValue;
    },
  };

  const emptyCellValue = "N/A";

  const sessionLogsReportTablecolumns: GridRowsColumnDefType[] = [
    {
      ...columnDefDefaultProps,
      field: "studentLastName",
      headerName: "Last Name",
    },
    {
      ...columnDefDefaultProps,
      field: "studentFirstName",
      headerName: "First Name",
    },
    {
      ...columnDefDefaultProps,
      field: "studentId",
      headerName: "Student ID",
      minWidth: 150,
    },
    {
      ...columnDefDefaultProps,
      field: "serviceProviderName",
      headerName: "Service Provider",
    },
    {
      ...columnDefDefaultProps,
      field: "serviceDate",
      headerName: "Service Date",
    },
    {
      ...columnDefDefaultProps,
      field: "sessionStatus",
      headerName: "Session Status",
    },
    {
      ...columnDefDefaultProps,
      field: "service",
      headerName: "Service Type",
      minWidth: 150,
    },
    {
      ...columnDefDefaultProps,
      field: "groupSize",
      headerName: "Group Size",
    },
    {
      ...columnDefDefaultProps,
      field: "sessionNarrative",
      headerName: "Session Notes",
      sortable: false,
      renderCell(params) {
        if (!params.value || params.value === "N/A") return emptyCellValue;
        const cellData = Array.isArray(params.value) ? params.value : [params.value];
        //implement nested table
        const newRows = cellData.map((cell) => ({ id: cell, sessionNarrative: cell }));
        return Array.isArray(cellData) && cellData?.length > 0 ? (
          <PopoutTableCell
            rows={newRows}
            columnName="sessionNarrative"
          />
        ) : (
          emptyCellValue
        );
      },
    },
    {
      ...columnDefDefaultProps,
      field: "studentNarrative",
      headerName: "Student Notes",
      sortable: false,
      renderCell(params) {
        if (!params.value || params.value === "N/A") return emptyCellValue;
        const cellData = Array.isArray(params.value) ? params.value : [params.value];
        //implement nested table
        const newRows = cellData.map((cell) => ({ id: cell, studentNarrative: cell }));
        return Array.isArray(cellData) && cellData?.length > 0 ? (
          <PopoutTableCell
            rows={newRows}
            columnName="studentNarrative"
          />
        ) : (
          emptyCellValue
        );
      },
    },
    {
      ...columnDefDefaultProps,
      field: "scheduledDuration",
      headerName: "Scheduled Duration",
    },
    {
      ...columnDefDefaultProps,
      field: "timeAway",
      headerName: "Time Away",
    },
    {
      ...columnDefDefaultProps,
      field: "serviceSupportMinutes",
      headerName: "Service Support Minutes",
    },
    {
      ...columnDefDefaultProps,
      field: "netDuration",
      headerName: "Duration",
    },
    {
      ...columnDefDefaultProps,
      field: "startTime",
      headerName: "Start Time",
      valueGetter(params) {
        return getTimeZoneTime(params?.value) || emptyCellValue;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "endTime",
      headerName: "End Time",
      valueGetter(params) {
        return getTimeZoneTime(params?.value) || emptyCellValue;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "location",
      headerName: "Location",
    },
    {
      ...columnDefDefaultProps,
      field: "districtOfLiability",
      headerName: "District of Liability",
    },
    {
      ...columnDefDefaultProps,
      field: "school",
      headerName: "School",
      minWidth: 250,
    },
    {
      ...columnDefDefaultProps,
      field: "present",
      headerName: "Present",
    },
    {
      ...columnDefDefaultProps,
      field: "makeup",
      headerName: "Makeup",
    },
    {
      ...columnDefDefaultProps,
      field: "submittedDate",
      headerName: "Submitted Date",
      valueGetter(params) {
        return params.value ? params.value : emptyCellValue;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "postedDate",
      headerName: "Posted Date",
      valueGetter(params) {
        return params.value ? params.value : emptyCellValue;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "studentObservations",
      headerName: "Student Observations",
      sortable: false,
      renderCell(params) {
        if (!params.value || params.value === "N/A") return emptyCellValue;
        const cellData = Array.isArray(params.value) ? params.value : [params.value];
        //implement nested table
        const newRows = cellData.map((cell) => ({ id: cell, studentObservations: cell }));

        return Array.isArray(cellData) && cellData?.length > 0 ? (
          <PopoutTableCell
            rows={newRows}
            columnName="studentObservations"
          />
        ) : (
          emptyCellValue
        );
      },
    },
    {
      ...columnDefDefaultProps,
      field: "goalsObjectives",
      sortable: false,
      headerName: "Goals/Objectives",
      minWidth: 200,
      renderCell(params) {
        const cellData = params.value as NonNullable<GoalsObjectivesColumnDefinition>;
        //implement nested table
        return Array.isArray(cellData) && cellData?.length > 0 ? (
          <PopoutTableCell rows={cellData} columnName="goalsObjectives" />
        ) : (
          emptyCellValue
        );
      },
      /*  useOverride: {
         overrideCell(row) {
           return row.observations && row.observations.length > 0
             ? "View Observations"
             : undefined;
         },
         useNestedTable(row) {
           const cell_data = row?.observations;

           return {
             title: `${row?.studentFirstName} ${row?.studentLastName}'s Observations`,
             expandOnCellClick: true,
             columns: [
               {
                 key: "refNumber",
                 headerName: "Ref Number",
               },
               {
                 key: "description",
                 headerName: "Description",
               },
               {
                 key: "observation",
                 headerName: "Observation",
                 // minWidth: 200,
               },
               {
                 key: "narrative",
                 headerName: "Narrative",
               },
             ],
             rows: cell_data,
           };
         },
       }, */
    },
    {
      ...columnDefDefaultProps,
      field: "activities",
      headerName: "Activities",
      sortable: false,
      width: 100,
      renderCell(params) {
        const cellData = Array.isArray(params.value) ? params.value : [params.value];
        //implement nested table
        const newRows = cellData.map((cell) => ({ id: cell, activities: cell }));
        return cellData?.length > 0 ? (
          <PopoutTableCell rows={newRows} columnName="activities" />
        ) : (
          emptyCellValue
        );
      },
      /* useTooltip: {
        overrideTitle: (cell) =>
          renderObservationsActivitiesAccommodationsModifications(cell as string),
      }, */
    },
    {
      ...columnDefDefaultProps,
      field: "accommodations",
      headerName: "Accommodations",
      sortable: false,
      width: 100,
      renderCell(params) {
        const cellData = Array.isArray(params.value) ? params.value : [params.value];
        //implement nested table
        const newRows = cellData.map((cell) => ({ id: cell, accommodations: cell }));
        return cellData?.length > 0 ? (
          <PopoutTableCell
            rows={newRows}
            columnName="accommodations"
          />
        ) : (
          emptyCellValue
        );
      },
      /* useTooltip: {
        overrideTitle: (cell) =>
          renderObservationsActivitiesAccommodationsModifications(cell as string),
      }, */
    },
    {
      ...columnDefDefaultProps,
      field: "modifications",
      headerName: "Modifications",
      sortable: false,
      width: 100,
      renderCell(params) {
        const cellData = Array.isArray(params.value) ? params.value : [params.value];
        //implement nested table
        const newRows = cellData.map((cell) => ({ id: cell, modifications: cell }));
        return cellData?.length > 0 ? (
          <PopoutTableCell rows={newRows} columnName="modifications" />
        ) : (
          emptyCellValue
        );
      },
      /* useTooltip: {
        overrideTitle: (cell) =>
          renderObservationsActivitiesAccommodationsModifications(cell as string),
      }, */
    },
    {
      ...columnDefDefaultProps,
      field: "billableMinutes",
      headerName: " Total Billable Minutes",
    },
  ];

  const renderObservationsActivitiesAccommodationsModifications = (cell: string) => {
    const splitString = cell?.split("-");

    const cellData = splitString?.map((item, index) => {
      return (
        <Typography key={index + index} fontSize={"10.5px"}>
          {item}
        </Typography>
      );
    });

    return (
      <Tooltip title={cellData}>
        <Box>{cellData}</Box>
      </Tooltip>
    );
  };

  const goalsObjectivesColumnDefDefaultProps: Partial<GoalsObjectivesColumnDefinition> = {
    sortable: false,
    disableColumnMenu: true,
  };

  const goalsObjectivesTablecolumns: GoalsObjectivesColumnDefinition[] = [
    {
      ...goalsObjectivesColumnDefDefaultProps,
      field: "refNumber",
      headerName: "Ref Number",
    },
    {
      ...goalsObjectivesColumnDefDefaultProps,
      field: "description",
      headerName: "Description",
      renderCell(params) {
        return (
          renderObservationsActivitiesAccommodationsModifications(params.value) || emptyCellValue
        );
      },
    },
    {
      ...goalsObjectivesColumnDefDefaultProps,
      field: "studentObservations",
      headerName: "Student Observations",
      renderCell(params) {
        return (
          renderObservationsActivitiesAccommodationsModifications(params.value) || emptyCellValue
        );
      },
    },
    {
      ...goalsObjectivesColumnDefDefaultProps,
      field: "narrative",
      headerName: "Narrative",
      renderCell(params) {
        return (
          renderObservationsActivitiesAccommodationsModifications(params.value) || emptyCellValue
        );
      },
    },
  ];

  const activityTableColumns: GridRowsColumnDefType[] = [
    {
      ...columnDefDefaultProps,
      field: "activities",
      headerName: "Activities",
    },
  ];

  const accommodationsTableColumns: GridRowsColumnDefType[] = [
    {
      ...columnDefDefaultProps,
      field: "accommodations",
      headerName: "Accommodations",
    },
  ];

  const modificationsTableColumns: GridRowsColumnDefType[] = [
    {
      ...columnDefDefaultProps,
      field: "modifications",
      headerName: "Modifications",
    },
  ];

  const studentObservationsTableColumns: GridRowsColumnDefType[] = [
    {
      ...columnDefDefaultProps,
      field: "studentObservations",
      headerName: "Student Observations",
    },
  ];

  const studentNotesTableColumns: GridRowsColumnDefType[] = [
    {
      ...columnDefDefaultProps,
      field: "studentNarrative",
      headerName: "Student Notes",
    },
  ];

  const sessionNotesTableColumns: GridRowsColumnDefType[] = [
    {
      ...columnDefDefaultProps,
      field: "sessionNarrative",
      headerName: "Session Notes",
    },
  ];

  type PopoutTableColumns = {
    [key: string]: GridRowsColumnDefType[];
  };

  const popoutTableColumns: PopoutTableColumns = {
    activities: activityTableColumns,
    accommodations: accommodationsTableColumns,
    modifications: modificationsTableColumns,
    studentObservations: studentObservationsTableColumns,
    studentNarrative: studentNotesTableColumns,
    sessionNarrative: sessionNotesTableColumns,
  };

  function getTimeZoneTime(time: string | null | undefined) {
    const timZoneId = getUserTimeZone();
    let utcTime = time;
    let format = "h:mm A";
    return dayjs(utcTime, format).tz(timZoneId).format(format);
  }

  return {
    sessionLogsReportTablecolumns,
    goalsObjectivesTablecolumns,
    popoutTableColumns,
  };
}
