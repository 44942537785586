import { forwardRef } from "react";
import GridSectionLayout from "../../../../../design/high-level/common/grid_section_layout";
import { InstanceReportTable, InstanceReportUserTableRow } from "./tables/instance_report_table";
import { INSTANCE_REPORT_MAX_WIDTH } from "../constants/ui";
import { GridPaginationModel } from "@mui/x-data-grid";

interface Props {
  table: {
    rows: InstanceReportUserTableRow[];
    dataLoading: boolean;
  };
  paginationState: {
    paginationModel: GridPaginationModel;
    setPaginationModel: (paginationModel: GridPaginationModel) => void;
    totalRecords?: number;
}
}

const InstanceReportTableLayout = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <GridSectionLayout
      ref={ref}
      headerConfig={{
        title: "",
        title_sx: {
          fontWeight: 700,
        },
      }}
      maxWidth={INSTANCE_REPORT_MAX_WIDTH}
      bottomMargin={"10rem"}
      rows={[
        {
          fullwidth: true,
          useCellStyling: {
            sx: {
              marginTop: "50px",
            },
          },
          cells: [
            <InstanceReportTable
              paginationState={props?.paginationState}
              key={0}
              rows={props?.table?.rows}
              loading={props?.table?.dataLoading}
            />,
          ],
        },
      ]}
    />
  );
});

export default InstanceReportTableLayout;
