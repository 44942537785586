import { useState } from "react";
import { StudentProfileContextT } from "../context/student_profile_context_provider";

type Props = {
  formIsDirty: boolean;
  onBackBtnClicked?: () => void;
};

/**
 * Custom hook to handle the back button click event in the student profile form.
 * a middleware for the back button click event in the student profile form.
 *
 * @param {Props} props - The properties passed to the hook.
 * @returns {Object} An object containing the state of the back button click and the handler function.
 * @returns {boolean} backBtnClicked - A state indicating whether the back button has been clicked.
 * @returns {function} handleBackBtnClick - The function to handle the back button click event.
 *
 * The `handleBackBtnClick` function takes a parameter object with the following optional properties:
 * - `ignoreIsDirty`: If true, the back button click event is handled immediately without checking if the form is dirty.
 * - `resetClickState`: If true, the back button click state is reset to false.
 *
 * The function performs the following actions based on the parameter object:
 * - If `ignoreIsDirty` is true, it calls the `onBackBtnClicked` callback from props and returns.
 * - If `resetClickState` is true, it resets the `backBtnClicked` state to false and returns.
 * - If the form is dirty (`props.formIsDirty` is true), it sets the `backBtnClicked` state to true and returns.
 * - Otherwise, it calls the `onBackBtnClicked` callback from props.
 */
export default function useStudentProfileBackbtnClickedMiddleware(props: Props): {
  backBtnClicked: boolean;
  handleBackBtnClick: StudentProfileContextT["navigation"]["handleBackBtnClick"];
} {
  const [backBtnClicked, setBackBtnClicked] = useState(false);

  const handleBackBtnClick: StudentProfileContextT["navigation"]["handleBackBtnClick"] = (
    param,
  ) => {
    if (param?.ignoreIsDirty) {
      props?.onBackBtnClicked?.();
      return;
    }

    if (param?.resetClickState) {
      setBackBtnClicked(false);
      return;
    }

    if (props.formIsDirty) {
      setBackBtnClicked(true);
      return;
    }
    props?.onBackBtnClicked?.();
  };

  return {
    backBtnClicked,
    handleBackBtnClick,
  };
}
