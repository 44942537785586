import React, { useEffect, useMemo } from "react";
import GridSectionLayout from "../../../../../design/high-level/common/grid_section_layout";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { ROUTES_XLOGS } from "../../../../../constants/URLs";
import { SessionLogsSortableColumn, SessionLogsSortableColumnSortColumn } from "@xng/reporting";
import produce from "immer";
import dayjs from "dayjs";
import { MSBICONS } from "../../../../../fortitude";
import { getUserTimeZone } from "../../../../../utils/timeZones";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  useApiMutatePollSessionLogsCsvDownload,
  useApiMutatePollSessionLogsPdfDownload,
  useSessionLogsContext,
} from "../hooks";
import { SessionLogsReportTable } from "../components";
import { GridSortModel } from "@mui/x-data-grid";
import {
  sessionLogsReportSortableColumnsEnumMap,
  sessionLogsReportSortableColumnsStringMap,
} from "../utils";
import QueryStatusModal from "../../../../../design/modal_templates/query_status_modal";
import downloadFile from "../../../../../utils/downloadFile";

dayjs.extend(utc);
dayjs.extend(timezone);

export const ServiceReportView = () => {
  //#region REACT ROUTER DOM
  const navigate = useNavigate();
  //#endregion

  const {
    sessionLogsReportState: {
      pollSessionLogsReportApiClientManager: {
        data: reportsResponseData,
        mutate: pollSessionLogsReport,
        status: pollSessionLogsReportStatus,
        variables: pollSessionLogsReportPayload,
      },
    },
  } = useSessionLogsContext();

  const [openCsvDownloadQueryStatusModal, setOpenCsvDownloadQueryStatusModal] =
    React.useState(false);
  const {
    mutate: downloadCsv,
    isPending: csvDownloadPending,
    status: csvDownloadStatus,
  } = useApiMutatePollSessionLogsCsvDownload({
    options: {
      onSuccess(data) {
        downloadFile(data, "SessionLogs.csv");
        setOpenCsvDownloadQueryStatusModal(true);
      },
      onError() {
        setOpenCsvDownloadQueryStatusModal(true);
      },
    },
  });

  const [openPdfDownloadQueryStatusModal, setOpenPdfDownloadQueryStatusModal] =
    React.useState(false);
  const {
    mutate: downloadPdf,
    isPending: pdfDownloadPending,
    status: pdfDownloadStatus,
  } = useApiMutatePollSessionLogsPdfDownload({
    options: {
      onSuccess(data) {
        downloadFile(data, "SessionLogs.pdf");
        setOpenPdfDownloadQueryStatusModal(true);
      },
      onError() {
        setOpenPdfDownloadQueryStatusModal(true);
      },
    },
  });

  //#region METHODS
  function backToSessionLogsHandler() {
    navigate(ROUTES_XLOGS.reports.sessionLogs);
  }
  //#endregion

  const { LoadingAnimation, Print, CSV: Csv } = MSBICONS;
  const disableToolbarButtons =
    pollSessionLogsReportStatus !== "success" ||
    !reportsResponseData?.pageRecords ||
    reportsResponseData?.pageRecords.length === 0;
  const UNDEFINED_DATA = "N/A";

  const sortModel: GridSortModel = useMemo(() => {
    const sortFieldEnum =
      pollSessionLogsReportPayload?.getSessionLogsReportPostRequest?.sortColumns?.values().next()
        .value?.column ?? SessionLogsSortableColumn.NUMBER_0;
    const sortDirection = pollSessionLogsReportPayload?.getSessionLogsReportPostRequest?.sortColumns
      ?.values()
      .next().value?.sortDirection
      ? "asc"
      : "desc";

    const model: GridSortModel = [
      {
        field: sessionLogsReportSortableColumnsStringMap[sortFieldEnum],
        sort: sortDirection,
      },
    ];

    return model;
  }, [pollSessionLogsReportPayload]);

  useEffect(() => {
    if (!pollSessionLogsReportPayload) {
      navigate(ROUTES_XLOGS.reports.sessionLogs);
    }
  }, [navigate, pollSessionLogsReportPayload]);

  return (
    <>
      <GridSectionLayout
        headerConfig={{
          title: "Report",
          title_sx: {
            fontWeight: 700,
          },
          headerContent: (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                justifyContent: {
                  xs: "center",
                  md: "space-between",
                },
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  justifyContent: "center",
                  flexGrow: 1,
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                  textAlign: {
                    xs: "center",
                    sm: "left",
                  },
                }}
              >
                <Typography>
                  Sessions Total:{" "}
                  <strong>{reportsResponseData?.totalRecords ?? UNDEFINED_DATA}</strong>{" "}
                </Typography>
                <Typography>
                  Scheduled Duration:{" "}
                  <strong>
                    {reportsResponseData?.totalScheduledDurationHours ?? UNDEFINED_DATA}
                  </strong>
                </Typography>
                <Typography>
                  Time Away:{" "}
                  <strong>{reportsResponseData?.totalTimeAwayHours ?? UNDEFINED_DATA}</strong>
                </Typography>
                <Typography>
                  Net Duration:{" "}
                  <strong>{reportsResponseData?.totalNetDurationHours ?? UNDEFINED_DATA}</strong>
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"row"} gap={2} whiteSpace={"nowrap"}>
                <Button
                  disabled={disableToolbarButtons || pdfDownloadPending}
                  variant="outlined"
                  startIcon={pdfDownloadPending ? <LoadingAnimation /> : <Print />}
                  onClick={() => {
                    const reportPayload =
                      pollSessionLogsReportPayload!.getSessionLogsReportPostRequest;

                    downloadPdf({
                      getSessionLogsPdfPostRequest: {
                        reportRunDate: reportPayload?.dateRun,
                        reportRunId: reportPayload?.reportRunId,
                        timeZoneId: getUserTimeZone(),
                      },
                    });
                  }}
                >
                  {pdfDownloadPending ? "Loading PDF File..." : "Print PDF"}
                </Button>
                <Button
                  disabled={disableToolbarButtons || csvDownloadPending}
                  variant="outlined"
                  startIcon={csvDownloadPending ? <LoadingAnimation /> : <Csv />}
                  onClick={() => {
                    const reportPayload =
                      pollSessionLogsReportPayload!.getSessionLogsReportPostRequest;

                    downloadCsv({
                      getSessionLogsCsvPostRequest: {
                        reportRunDate: reportPayload?.dateRun,
                        reportRunId: reportPayload?.reportRunId,
                        timeZoneId: getUserTimeZone(),
                      },
                    });
                  }}
                >
                  {csvDownloadPending ? "Loading CSV File..." : "Export to CSV"}
                </Button>
              </Box>
            </Box>
          ),
        }}
        rows={[
          {
            fullwidth: true,
            cells: [
              <SessionLogsReportTable
                key={0}
                rows={reportsResponseData?.pageRecords ?? []}
                paginationState={{
                  paginationModel: {
                    pageSize: reportsResponseData?.pageSize ?? 10,
                    page: reportsResponseData?.pageNumber ? reportsResponseData.pageNumber - 1 : 0,
                  },
                  setPaginationModel(paginationModel) {
                    const updatedReportPayload = produce(pollSessionLogsReportPayload!, (draft) => {
                      draft.getSessionLogsReportPostRequest!.pageParameters = {
                        pageNumber: paginationModel.page + 1,
                        pageSize: paginationModel.pageSize,
                      };
                    });
                    pollSessionLogsReport(updatedReportPayload);
                  },
                  totalRecords: reportsResponseData?.totalRecords ?? 0,
                }}
                sortingState={{
                  sortModel,
                  setSortModel(sortingModel) {
                    const key = sortingModel[0]
                      .field as keyof typeof sessionLogsReportSortableColumnsEnumMap;
                    const sortableColumn =
                      sessionLogsReportSortableColumnsEnumMap[key] ??
                      sessionLogsReportSortableColumnsEnumMap["studentLastName"];
                    const sortDirection = sortingModel[0].sort === "asc" ? 1 : 0;
                    const updatedReportPayload = produce(pollSessionLogsReportPayload!, (draft) => {
                      draft.getSessionLogsReportPostRequest!.sortColumns =
                        new Set<SessionLogsSortableColumnSortColumn>([
                          {
                            column: sortableColumn,
                            sortDirection,
                          },
                        ]);
                    });
                    pollSessionLogsReport(updatedReportPayload);
                  },
                }}
                loading={pollSessionLogsReportStatus === "pending"}
              />,
            ],
          },
          {
            fullwidth: true,
            rowSx: {
              marginTop: "1rem",
              marginBottom: "3rem",
            },
            useCellStyling: {
              sx: {
                display: "flex",
                justifyContent: "flex-end",
              },
            },
            cells: [
              <Button
                key={0}
                disabled={false}
                sx={{
                  padding: "1.5rem 2rem",
                }}
                onClick={backToSessionLogsHandler}
              >
                Back to Session Logs
              </Button>,
            ],
          },
        ]}
      />
      <QueryStatusModal
        isOpen={openCsvDownloadQueryStatusModal}
        status={csvDownloadStatus}
        onSettledClose={() => {
          setOpenCsvDownloadQueryStatusModal(false);
        }}
        content={{
          successTitle: "Success!",
          successBody: "Your Session Logs Csv File has been downloaded successfully",
          errorTitle: "Error!",
          errorBody: "Problem downloading session logs csv file, please try again.",
          pendingTitle: "Please wait, Downloading Session Logs Csv...",
        }}
      />
      <QueryStatusModal
        isOpen={openPdfDownloadQueryStatusModal}
        status={pdfDownloadStatus}
        onSettledClose={() => {
          setOpenPdfDownloadQueryStatusModal(false);
        }}
        content={{
          successTitle: "Success!",
          successBody: "Your Session Logs Pdf File has been downloaded successfully",
          errorTitle: "Error!",
          errorBody: "Problem downloading session logs pdf file, please try again.",
          pendingTitle: "Please wait, Downloading Session Logs Pdf...",
        }}
      />
    </>
  );
};
