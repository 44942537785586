import useInstanceReportContext from "../hooks/use_instance_report";
import useApiQueryStateSnapshotsByDateByServiceProviderType from "../../../../../api/hooks/state_snapshots/use_api_query_state_snapshots_bydate_by_service_provider_type";
import InstanceReportForm from "../components/forms/instance_report_form";
import useApiQuerySchoolCampusesDropdownDisplaysGet from "../../../../../api/hooks/districts/use_api_query_school_campuses_drop_down_displays_get";
import { DistrictRef } from "@xng/profile";
import { Box, Button } from "@mui/material";
import InstanceReportTableLayout from "../components/instance_report_table_layout";
import { useRef, useState } from "react";
import { FFY_OPTIONS, InstanceReportFormSchema } from "../constants/instance_report_form_schema";
import useGenerateInstanceReportPolling from "../hooks/use_generate_instance_report";
import useInstanceReportMutation from "../hooks/use_instance_report_mutation";
import { StudentInstanceReportFilterParameters } from "@xng/reporting";
import QueryStatusModal from "../../../../../design/modal_templates/query_status_modal";
import {
  INSTANCE_REPORT_ERROR_MESSAGE,
  INSTANCE_REPORT_ERROR_TITLE,
  INSTANCE_REPORT_SUCCESS_MESSAGE,
  INSTANCE_REPORT_SUCCESS_TITLE,
} from "../constants/modal-content";
import useGenerateInstanceReportTableRows from "../hooks/use_generate_row";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useApiMutatePollInstanceReport } from "../hooks/use_generate_csv";
import downloadFile from "../../../../../utils/downloadFile";
import { MSBICONS } from "../../../../../fortitude";
import { GridPaginationModel } from "@mui/x-data-grid";
import { PAGE_PARAMS_DEFAULT } from "../constants/pagination";
dayjs.extend(utc);

const mutationOptions = {
  retry: 3,
  retryDelay: 1000,
  gcTime: 1000 * 60 * 60 * 5, // 5 hours
};

const InstanceReportView = () => {
  const { stateInUs, authorizedDistricts, clientId } = useInstanceReportContext();
  const instanceReportDataGridRef = useRef<HTMLDivElement>(null);
  const [instanceReportModal, setInstanceReportModal] = useState(false);
  const [isPollingModal, setIsPollingModal] = useState(false);
  const [renderReport, setRenderReport] = useState(false);
  const [csvModal, setCsvModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const { LoadingAnimation, CSV } = MSBICONS;
  const {
    instancesReportDispatcher,
    store: { pageSize, pageNumber },
  } = useInstanceReportContext();
  const {
    data: campusOptions,
    status: campusLoadingStatus,
    refetch: refetchCampusOptions,
  } = useApiQuerySchoolCampusesDropdownDisplaysGet({
    queryParams: {
      districtIds: authorizedDistricts?.map((d: DistrictRef) => d.id ?? "").join(",") || "",
      state: stateInUs,
    },
    options: {
      enabled: !!(authorizedDistricts && authorizedDistricts.length > 0),
      staleTime: 60 * 60 * 1000,
    },
  });

  const {
    data: serviceTypesResponse,
    status: serviceTypesResponseStatus,
    refetch: refetchServiceTypes,
  } = useApiQueryStateSnapshotsByDateByServiceProviderType({
    queryParams: {
      stateInUs: stateInUs,
    },
  });

  const reportDateRunIdMutation = useInstanceReportMutation();
  const instancesReportPoll = useGenerateInstanceReportPolling({ options: mutationOptions });
  const csvPoll = useApiMutatePollInstanceReport({ options: mutationOptions });
  const pdfPoll = useApiMutatePollInstanceReport({ options: mutationOptions });
  const rows = useGenerateInstanceReportTableRows(instancesReportPoll?.data?.records ?? []);

  const handleFormSubmit = (data: InstanceReportFormSchema) => {
    setRenderReport(false);
    const filterParameters: StudentInstanceReportFilterParameters = {
      clientId,
      serviceAreaIds: data.serviceTypes?.map((item) => item?.id as string) ?? [],
      startDate: dayjs(FFY_OPTIONS.find((item) => item.label === data.federalFiscalYear)?.startDate)
        .utc(true)
        .toDate(),
      endDate: dayjs(FFY_OPTIONS.find((item) => item.label === data.federalFiscalYear)?.endDate)
        .utc(true)
        .toDate(),
      campusId: data.schoolCampuses?.map((item) => item?.id as string) ?? [],
    };
    setIsPollingModal(true);
    reportDateRunIdMutation.mutate(filterParameters, {
      onSuccess: (data) => {
        if (data?.reportRunId && data?.reportRunDate) {
          instancesReportPoll.mutate(
            {
              getStudentInstanceReportRequest: {
                reportRunId: data.reportRunId,
                dateRun: data.reportRunDate,
                ...PAGE_PARAMS_DEFAULT,
              },
            },
            {
              onSuccess: () => {
                setRenderReport(true);
                setTimeout(() => {
                  instanceReportDataGridRef?.current?.scrollIntoView({behavior:"smooth", block:"start"});
                },300)
              },
            },
          );
          setIsPollingModal(false);
          setInstanceReportModal(true);
        }
      },
    });
  };

  const handleCSVgeneration = () => {
    setCsvModal(true);
    csvPoll.mutate(
      {
        getStudentInstanceReportRequest: {
          dateRun: reportDateRunIdMutation?.data?.reportRunDate,
          reportRunId: reportDateRunIdMutation?.data?.reportRunId,
          ...PAGE_PARAMS_DEFAULT,
        },
      },
      {
        onSuccess: (blob) => { 
          const fname = `AccountStudentReport_${reportDateRunIdMutation?.data?.reportRunDate}.csv`;
          downloadFile(blob, fname);
        },
      },
    );
  };

  const handleInstanceReportModalClose = () => {
    setInstanceReportModal(false);
  };

  const disableToolbarButtons =
    !instancesReportPoll?.data?.records ||
    instancesReportPoll?.data?.records?.length === 0 ||
    instancesReportPoll?.status !== "success";

  return (
    <Box sx={{ overflowY: "auto", minHeight: "100vh" }}>
      <QueryStatusModal
        status={reportDateRunIdMutation?.status}
        onSettledClose={() => {
          setIsPollingModal(false);
        }}
        isOpen={isPollingModal}
        content={{
          successTitle: INSTANCE_REPORT_SUCCESS_TITLE,
          successBody: "Instance report queued successfully.",
          errorTitle: INSTANCE_REPORT_ERROR_TITLE,
          errorBody: "Problem queuing instance report, please try again.",
          pendingTitle: "Queuing instances report",
        }}
      />

      <QueryStatusModal
        status={instancesReportPoll?.status}
        onSettledClose={handleInstanceReportModalClose}
        isOpen={instanceReportModal}
        content={{
          errorTitle: INSTANCE_REPORT_ERROR_TITLE,
          errorBody: INSTANCE_REPORT_ERROR_MESSAGE,
          successTitle: INSTANCE_REPORT_SUCCESS_TITLE,
          successBody: INSTANCE_REPORT_SUCCESS_MESSAGE,
          pendingTitle: "Generating Report, Please wait...",
        }}
      />
      <QueryStatusModal
        status={csvPoll?.status}
        onSettledClose={() => {
          setCsvModal(false);
        }}
        isOpen={csvModal}
        content={{
          errorTitle: "Failed to generate CSV file",
          errorBody:
            csvPoll?.error?.message ??
            "Sorry, not able to generate csv at this time. Please try again later",
          successTitle: "Success",
          successBody: `Successfuly downloading CSV File, Please open your download menu/history to view the 
          AccountStudentReport_${reportDateRunIdMutation?.data?.reportRunDate}.csv file download progress`,
          pendingTitle: "Downloading CSV, Please wait...",
        }}
      />
      <QueryStatusModal
        status={pdfPoll?.status}
        onSettledClose={() => {
          setPdfModal(false);
        }}
        isOpen={pdfModal}
        content={{
          errorTitle: "Failed to generate pdf file",
          errorBody:
            pdfPoll?.error?.message ??
            "Sorry, not able to generate pdf at this time. Please try again later",
          successTitle: "Success",
          successBody: `Successfuly downloading pdf file, Please open your download menu/history to view the 
          AccountStudentReport_${reportDateRunIdMutation?.data?.reportRunDate}.pdf file download progress`,
          pendingTitle: "Downloading pdf, Please wait...",
        }}
      />
      <InstanceReportForm
        inputComponentProps={{
          campuses: {
            options: campusOptions?.schoolCampuses ?? [],
            refetchOptions: refetchCampusOptions,
            optionsApiStatus: campusLoadingStatus,
          },
          serviceTypes: {
            options: serviceTypesResponse?.services ?? [],
            refetchOptions: refetchServiceTypes,
            optionsApiStatus: serviceTypesResponseStatus,
          },
        }}
        handleSubmit={handleFormSubmit}
      />

      {renderReport && (
        <>
          <Button
            disabled={disableToolbarButtons || csvPoll?.isPending}
            variant="outlined"
            startIcon={csvPoll?.isPending ? <LoadingAnimation /> : <CSV />}
            onClick={handleCSVgeneration}
            sx={{ ml: 2 }}
          >
            {csvPoll?.isPending ? "Loading CSV File..." : "Export to CSV"}
          </Button>
          <InstanceReportTableLayout
            paginationState={{
              paginationModel: {
                pageSize: pageSize ?? 10,
                page: pageNumber ? pageNumber - 1 : 0,
              },
              setPaginationModel: function (paginationModel: GridPaginationModel): void {
                instancesReportDispatcher({
                  type: "UPDATE_PAGE_NUMBER",
                  payload: paginationModel.page + 1,
                });
                instancesReportDispatcher({
                  type: "UPDATE_PAGE_SIZE",
                  payload: paginationModel.pageSize,
                });
              },
              totalRecords: instancesReportPoll?.data?.totalRecords,
            }}
            ref={instanceReportDataGridRef}
            table={{
              rows,
              dataLoading: instancesReportPoll?.isError || instancesReportPoll?.isPending,
            }}
          />
        </>
      )}
    </Box>
  );
};

export default InstanceReportView;
